import {useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import notify from '../Notify/Notify'
import { deleteAcount } from '../../Redux/Actions/authAction'

const DeleteAcountHook = () => {
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.authReducer.deleteAcount)

    const onDeleteAcount = async(e)=>{
        e.preventDefault()
        setLoading(true)
        setIsPress(true)
        await dispatch(deleteAcount())
        setLoading(false)
        setIsPress(false)
    }
    /*Start logout function*/
    const logOut =()=>{
        if(localStorage.getItem("NozoulnaUser")!= null){
            localStorage.removeItem("NozoulnaUser")
         }
         if(localStorage.getItem("NozoulnaToken")!= null){
             localStorage.removeItem("NozoulnaToken")
         }
         if(localStorage.getItem("start_date")!= null){
             localStorage.removeItem("start_date")
         }
         if(localStorage.getItem("end_date")!= null){
             localStorage.removeItem("end_date")
         }
         if(localStorage.getItem("room_id")!= null){
             localStorage.removeItem("room_id")
         }
         if(localStorage.getItem("partners_Qty")!= null){
             localStorage.removeItem("partners_Qty")
         }
         if(localStorage.getItem("partner_two")!= null){
             localStorage.removeItem("partner_two")
         }
         if(localStorage.getItem("partner_Three")!= null){
             localStorage.removeItem("partner_Three")
         }
         if(localStorage.getItem("adults")!= null){
             localStorage.removeItem("adults")
         }
    }
    /*End logout function */
    useEffect(()=>{
        if(loading === false){
            if(res){
                console.log(res)
                if(res.status===200){
                    notify(`${res.data.message}`,"warn")
                    logOut()
                    setTimeout(() => {
                        window.location.href="/"
                    }, 1000);
                }else if(res.status===400){
                    notify(`${res.data.message}`,"error")
                }else if(res.status===401){
                    notify(`${res.data.message}`,"error")
                }
                if (res.data.errors) {
                    if(res.data.errors.token!=null){
                        notify(`${res.data.errors.token}`,"error")
                    }else if(res.data.errors.password!=null){
                        notify(`${res.data.errors.password}`,"error")
                    }
                }
            }
        }
    },[loading])
    return [onDeleteAcount,loading,isPress]
}

export default DeleteAcountHook