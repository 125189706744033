import React, { useState } from "react";
import "./FilterSidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown,faCalendarDay,faLocation,faStar,} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import SideBarSearchHook from "../../../CustomHooks/Search/SideBarSearchHook";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";

const FilterSidebar = ({service_type,category_id}) => {
  const [open, setOpen] = useState(false);
  const [showState, setShowState] = useState("more");
  const [,onChangeStars,minPrice,maxPrice,onChangeMinPrice,onChangeMaxPrice,town,onChangeTown,
    ,check_out,onChangeCheck_out,check_inn,onChangeCheck_inn,onChangeRooms,rooms,name,onChangeName
  ]= SideBarSearchHook(service_type,category_id);
  const handleCollaps = () => {
    setOpen(!open);
    showState === "more" ? setShowState("less") : setShowState("more");
  };
  /*========================================================================*/
  /*Start range slider */
  const [value,setValue] = useState([0,1000]);
  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue)
  };
  /*End range slider */
  /*========================================================================*/
  // start code to translate
  const [t,i18n]=useTranslation()
  // end code to translate
  return (
    <div className="filter-sidebar">
      <div className="sidebar mt-0">
        <div className="sidebar-widget">
          <h3 className="title stroke-shape">{t("filterSidebar.title")}</h3>
          <div className="sidebar-widget-item">
            <div className="contact-form-action">
              <form action="#">
                <div className="input-box">
                  <label className="label-text">{t("filterSidebar.sidebar-weidget-one.town")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLocation} />
                    <input
                      onChange={onChangeTown}
                      className="custom-input"
                      type="text"
                      name="text"
                      defaultValue={town}
                      placeholder={t("filterSidebar.sidebar-weidget-one.town")}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("filterSidebar.sidebar-weidget-one.name")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faLocation} />
                    <input
                      onChange={onChangeName}
                      className="custom-input"
                      type="text"
                      name="text"
                      defaultValue={name}
                      placeholder={t("filterSidebar.sidebar-weidget-one.name")}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("filterSidebar.sidebar-weidget-one.check-in")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <input
                      onChange={onChangeCheck_inn}
                      onFocus={(e)=>e.target.type="date"}
                      onBlur={(e)=>e.target.type="text"}
                      className="date-range custom-input"
                      type="text"
                      name="check_inn"
                      defaultValue={check_inn}
                      placeholder={t("filterSidebar.sidebar-weidget-one.check-in")}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("filterSidebar.sidebar-weidget-one.check-out")}</label>
                  <div className="form-group">
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <input
                      onChange={onChangeCheck_out}
                      onFocus={(e)=>e.target.type="date"}
                      onBlur={(e)=>e.target.type="text"}
                      className="date-range custom-input"
                      type="text"
                      name="check_out"
                      defaultValue={check_out}
                      placeholder={t("filterSidebar.sidebar-weidget-one.check-out")}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="sidebar-widget-item">
            <div className="qty-box mb-2 d-flex align-items-center justify-content-between">
              <label className="font-size-16">{t("filterSidebar.sidebar-weidget-one.rooms")}</label>
              <div className="qtyBtn d-flex align-items-center">
                <input
                  onChange={onChangeRooms}
                  defaultValue={rooms}
                  className="custom-input"
                  type="number"
                  min={1}
                  name="rooms"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-widget">
          <h3 className="title stroke-shape">{t("filterSidebar.sidebar-weidget-two.title")}</h3>
          <div className="sidebar-price-range">
            <div className="main-search-input-item">
              <div className="price-slider-amount padding-bottom-20px">
                <div className="range-slider">
                  <p className="filter-price">
                  {t("filterSidebar.sidebar-weidget-two.title")}
                    <span className="parent-span">
                      <span className="value-0">{`$${minPrice}`}</span>
                      <span>--</span>
                      <span className="value-1">{`$${maxPrice}`}</span>
                    </span>
                  </p>
                  <Box sx={{ width: "100%" }}>
                    <Slider
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                    />
                  </Box>
                </div>
              </div>
              <div id="slider-range2"></div>
            </div>
          </div>
        </div>
        <div className="sidebar-widget">
          <h3 className="title stroke-shape">{t("filterSidebar.sidebar-weidget-three.title")}</h3>
          <div className="sidebar-review">
            <label className="custom-checkbox">
              <span className="ratings d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <span className="color-text-3 font-size-13 ml-1">(5)</span>
              </span>
              <input
                defaultValue={5}
                onChange={onChangeStars}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
              <span className="ratings d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <span className="color-text-3 font-size-13 ml-1">(4)</span>
              </span>
              <input
                defaultValue={4}
                onChange={onChangeStars}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
              <span className="ratings d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <span className="color-text-3 font-size-13 ml-1">(3)</span>
              </span>
              <input
                defaultValue={3}
                onChange={onChangeStars}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
              <span className="ratings d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <span className="color-text-3 font-size-13 ml-1">(2)</span>
              </span>
              <input
                defaultValue={2}
                onChange={onChangeStars}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
              <span className="ratings d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} />
                <span className="color-text-3 font-size-13 ml-1">(1)</span>
              </span>
              <input
                defaultValue={1}
                onChange={onChangeStars}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <div className="sidebar-widget">
          <h3 className="title stroke-shape">{t("filterSidebar.sidebar-weidget-four.title")}</h3>
          <div className="sidebar-category">
            <label className="custom-checkbox" defaultValue={"Excellent"}>
            {t("filterSidebar.sidebar-weidget-four.list.item1")}
              <input type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox" defaultValue={"Very Good"}>
            {t("filterSidebar.sidebar-weidget-four.list.item2")}
              <input type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox" defaultValue={"Average"}>
            {t("filterSidebar.sidebar-weidget-four.list.item3")}
              <input type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox" defaultValue={"Poor"}>
            {t("filterSidebar.sidebar-weidget-four.list.item4")}
              <input type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <div className="sidebar-widget">
          <h3 className="title stroke-shape">{t("filterSidebar.sidebar-weidget-five.title")}</h3>
          <div className="sidebar-category">
            <label className="custom-checkbox">
            {t("filterSidebar.sidebar-weidget-five.list.item1")}
              <input
                defaultValue={"Air Conditioning"}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
            {t("filterSidebar.sidebar-weidget-five.list.item2")}
              <input
                defaultValue={"Airport Transport"}
                type="checkbox"
                id="r6"
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
            {t("filterSidebar.sidebar-weidget-five.list.item3")}
              <input defaultValue={"Fitness Center"} type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
            {t("filterSidebar.sidebar-weidget-five.list.item4")}
              <input defaultValue={"Flat Tv"} type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
            {t("filterSidebar.sidebar-weidget-five.list.item5")}
              <input defaultValue={"Heater"} type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <label className="custom-checkbox">
            {t("filterSidebar.sidebar-weidget-five.list.item6")}
              <input defaultValue={"Internet Wifi"} type="checkbox" id="r6" />
              <span className="checkmark"></span>
            </label>
            <span
              className="show-more"
              onClick={handleCollaps}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Show {showState} <FontAwesomeIcon icon={faAngleDown} />
            </span>
            <Collapse in={open}>
              <div id="example-collapse-text">
                <label className="custom-checkbox">
                {t("filterSidebar.sidebar-weidget-five.list.item7")}
                  <input defaultValue={"Parking"} type="checkbox" id="r6" />
                  <span className="checkmark"></span>
                </label>
                <label className="custom-checkbox" defaultValue={"Pool"}>
                {t("filterSidebar.sidebar-weidget-five.list.item8")}
                  <input defaultValue={"Pool"} type="checkbox" id="r6" />
                  <span className="checkmark"></span>
                </label>
                <label className="custom-checkbox" defaultValue={"Restaurant"}>
                {t("filterSidebar.sidebar-weidget-five.list.item9")}
                  <input defaultValue={"Restaurant"} type="checkbox" id="r6" />
                  <span className="checkmark"></span>
                </label>
                <label
                  className="custom-checkbox"
                  defaultValue={"Smoking Room"}
                >
                  {t("filterSidebar.sidebar-weidget-five.list.item10")}
                  <input
                    defaultValue={"Smoking Room"}
                    type="checkbox"
                    id="r6"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="custom-checkbox" defaultValue={"Sauna"}>
                {t("filterSidebar.sidebar-weidget-five.list.item11")}
                  <input
                    defaultValue={"Spa &amp; Sauna"}
                    type="checkbox"
                    id="r6"
                  />
                  <span className="checkmark"></span>
                </label>
                <label
                  className="custom-checkbox"
                  defaultValue={"Washer &amp; Dryer"}
                >
                  {t("filterSidebar.sidebar-weidget-five.list.item12")}
                  <input
                    defaultValue={" Washer &amp; Dryer"}
                    type="checkbox"
                    id="r6"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FilterSidebar;
