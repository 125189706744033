import { useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getWebsiteReviews } from '../../../Redux/Actions/userActions'
const GetWebsiteReviewsHook = () => {
  const dispatch = useDispatch()
    const res = useSelector((state)=>state.UserReducer.allWebsiteReviews)
    const loading = useSelector((state)=>state.UserReducer.websiteReviewLoading)
    useEffect(()=>{
        const getdata =async()=>{
            await dispatch(getWebsiteReviews())
        }
        getdata()
    },[loading])

    let reviews =[];
    try{
      if(loading===false){
        if(res){
          if(res.data){
            if(res.data.data){
              reviews=res.data.data
            }
          }
        }else{
        reviews=[]
        }
      }
    }catch(e){}

    return [reviews,loading]
}


export default GetWebsiteReviewsHook