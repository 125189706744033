import React from 'react'
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import WhyFlyWithUs from '../../../Components/Flights/WhyFlyWithUs/WhyFlyWithUs'
import FlightsSearchPageLand from '../../../Components/Flights/FlightsSearchPageLand/FlightsSearchPageLand';
import FlightsFilterBar from '../../../Components/Flights/FlightsFilterBar/FlightsFilterBar';
import SearchFlightsContainer from '../../../Components/Flights/SearchFlightsContainer/SearchFlightsContainer';
const FlightsSearchResultPage = () => {
  return (
    <div className="service-search-result-page">
    <Header activeFlights={"activeFlights"} />
    <FlightsSearchPageLand />
    <div className="container">
      <div className="row">
        <FlightsFilterBar />
        <div className="result-content">
       <SearchFlightsContainer />
        </div>
        <WhyFlyWithUs />
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default FlightsSearchResultPage