import React from 'react'
import './CarCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faFillDrip} from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import ServiceCardHook from '../../../CustomHooks/ServicesHooks/ServiceCardHook'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const CarCard = ({car,favItem}) => {
  const [wishlistActive,handleClickWishlist,type] = ServiceCardHook(car,favItem)
  //start code for translate
  const [t,i18n]=useTranslation()
  //End code for translate
  return (
    <div className='car-card-wrapper service-card-wrapper'>
    <div className='car-card service-card'>
    <div className="card-img">
                      {car?
                      car.attachment_relation.length>0?
                      car.attachment_relation[0].url?
                      <Link to={`/${type}-details-page/:${car.id}`}>
                      <img src={car.attachment_relation[0].url} alt="service-img"  loading="lazy"/>
                      </Link>
                      :null
                      :null
                      :null
                      }
                      {
                        car?
                        car.display==="1"?
                        <span className="badge">{t("serviceCard.mostBooked")}</span>
                        :null
                        :null
                      }
                      <div onClick={()=>handleClickWishlist(car.id,"cars")}
                        className={`${wishlistActive} icon-element`}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Bookmark"
                      >
                      <FontAwesomeIcon icon={faHeart} />
                      </div>
                    </div>
                  <div className="card-body">
                    <p className="card-meta">{i18n.language==="ar"?car.town_ar:car.town_en}</p>
                    <h3 className="card-title">
                        {
                          car.id?
                          car.name_en?
                          <Link to={`/${type}-details-page/:${car.id}`} className='transition'>
                            {i18n.language==="ar"?`${car.name_ar} ${car.type_ar}`:`${car.name_en} ${car.type_en}`}
                        </Link>
                          :null
                          :<p>{t("serviceCard.no-name")}</p>
                        }
                      </h3>
                    <div className="card-rating">
                        <span className="badge_2">{car.stars}/5</span>
                        <span className="review__text">{t("serviceCard.average")}</span>
                        <span className="rating__text">({car.num_reviews} {t("serviceCard.reviews")})</span>
                      </div>
                    <div className="card-attributes">
                      <ul className="d-flex align-items-center">
                        <li
                          className="d-flex align-items-center"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="color"
                        >
                          <FontAwesomeIcon icon={faFillDrip}/> <span>{t("serviceCard.color")} <FontAwesomeIcon icon={faCaretRight}/></span> 
                        <span className='bubble'>
                          {
                          i18n.language==="ar"?car.color_ar:car.color_en}
                        </span>
                        </li>
                      </ul>
                    </div>
                    <div className="card-price d-flex align-items-center justify-content-between flex-wrap">
                        <p className='p-0 m-0'>
                          <span className="price__from">{t("serviceCard.from")}</span>
                          <span className="price__num">{`$${car.price_dollar}`}</span>
                          <span className="price__text">{t("serviceCard.perNeight")}</span>
                        </p>
                        {car.id?
                         <Link to={`/${type}-details-page/:${car.id}`}
                        className="see-details-btn transition d-none d-md-block"
                         >{t("serviceCard.seeDetails")}
                         </Link>
                      :null
                      }
                      </div>
                  </div>
    </div>
    </div>
  )
}

export default CarCard