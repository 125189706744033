import {useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getAllTowns } from '../../Redux/Actions/townsAction'
const TownsHook = () => {
    const dispatch = useDispatch()
    const res = useSelector(state =>state.townsReducer.allTowns)
    const loading = useSelector(state =>state.townsReducer.loading)
    useEffect(()=>{
        const get =async()=>{
            await dispatch(getAllTowns())
        }
        get()
    },[])
    let towns =[];
    try{
        if(res){
            towns=res.data
        }
        else{
            towns=[]
        }
    }   
    catch(e){}
    return [loading,towns]
}

export default TownsHook