import React,{useState} from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from "../../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
/*======================================================================*/
/*imgs*/
import payementImg from "../../../imgs/payment-img.png";
import money from '../../../imgs/public/money.png'
/*End imgs*/
/*======================================================================*/
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faPenToSquare, faUser } from "@fortawesome/free-regular-svg-icons";
import { faBan, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import ServiceDetailsHook from "../../../CustomHooks/ServicesHooks/ServiceDetailsHook";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import RestReserveHook from '../../../CustomHooks/UserHooks/Rests/RestReservHook'
import { useTranslation } from "react-i18next";
const RestBookingPage = () => {
  const param = useParams();
  const newParam = param.id.slice(1, param.id.length);
const [serviceDetails,loading] = ServiceDetailsHook("rests",newParam)
    const [paymentMethodToggle,setPaymentMethodToggle] = useState(1)
/*======================================================================*/
   const  [partner,onChangePartner,phone,onChangePhone,,,,,,,onConfirmReserve,isPress,reserveLoading,,,,,,,]
    = RestReserveHook(newParam)
      // start code for translate
  const [t,i18n] = useTranslation()
  let title="";
  try{
    if(i18n.language==="ar"){
      title="حجز استراحه"
    }else{
      title="Rest Booking"
    }
  }catch(e){}
  // End code for translate
  return (
    <div className="service-booking-page page">
      <Header activeRests={"activeRests"}/>
      <BreadCrumbSection serviceDetails={serviceDetails} loading={loading} title={title} bg={"bread-bg-5"} />
      <section className="booking-area">
        <div className="container">
          <div className="row">
          <div className="col-lg-8">
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">{t("BookingPage.title")}</h3>
                </div>
                <div className="form-content ">
                  <div className="contact-form-action">
                    <form method="post">
                      <div className="row">
                        <div className="col-lg-6 responsive-column">
                          <div className="input-box">
                            <label className="label-text">{t("BookingPage.name")}</label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faUser}/>
                              <input
                              value={partner}
                              onChange={onChangePartner}
                                className="custom-input"
                                type="text"
                                name="first-name"
                                placeholder={t("BookingPage.name")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 responsive-column">
                          <div className="input-box">
                            <label className="label-text">{t("BookingPage.phone")}</label>
                            <div className="form-group">
                            <FontAwesomeIcon icon={faPhone}/>
                              <input
                              value={phone}
                              onChange={onChangePhone}
                                className="custom-input"
                                type="text"
                                name="phone-number"
                                placeholder={t("BookingPage.phone")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">{t("hotelBookingPage.payment.title")}</h3>
                </div>
                <div className="form-content">
                  <div className="section-tab check-mark-tab text-center pb-4">
                    <ul
                      className="nav nav-tabs justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li onClick={()=>setPaymentMethodToggle(1)}
                       className={paymentMethodToggle === 1?"nav-item active":"nav-item"}>
                          <i className="la la-check icon-element"></i>
                          <img src={payementImg} alt="payment-img" />
                          <span className="d-block pt-2">
                          {t("BookingPage.payment.credit-card.title")}
                          </span>
                      </li>
                      <li  onClick={()=>setPaymentMethodToggle(2)}
                       className={paymentMethodToggle === 2?"nav-item active":"nav-item"}>
                          <i className="la la-check icon-element"></i>
                          <img src={money} className="cash-img" alt="cash-img" />
                          <span className="d-block pt-2">
                          {t("BookingPage.payment.cash")}
                          </span>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    {/* <div className={paymentMethodToggle===1?"tab-pane fade show active":"tab-pane fade show"}
                      role="tabpanel"
                      aria-labelledby="credit-card-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("BookingPage.payment.credit-card.card-holder-name")}
                                </label>
                                <div className="form-group">
                                  <FontAwesomeIcon icon={faCreditCard}/>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder={t("BookingPage.payment.credit-card.card-holder-name")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("BookingPage.payment.credit-card.card-number")}
                                </label>
                                <div className="form-group">
                                <FontAwesomeIcon icon={faCreditCard}/>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder={t("BookingPage.payment.credit-card.card-number")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6 responsive-column">
                                  <div className="input-box">
                                    <label className="label-text">
                                    {t("BookingPage.payment.credit-card.expiry-month")}
                                    </label>
                                    <div className="form-group">
                                    <FontAwesomeIcon icon={faCreditCard}/>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="text"
                                        placeholder="MM"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 responsive-column">
                                  <div className="input-box">
                                    <label className="label-text">
                                    {t("BookingPage.payment.credit-card.expiry-year")}
                                    </label>
                                    <div className="form-group">
                                    <FontAwesomeIcon icon={faCreditCard}/>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="text"
                                        placeholder="YY"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">{t("BookingPage.payment.credit-card.cvv")}</label>
                                <div className="form-group">
                                <FontAwesomeIcon icon={faPenToSquare}/>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder={t("BookingPage.payment.credit-card.cvv")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-box">
                                <div className="form-group">
                                  <div className="custom-checkbox-content">
                                    <label className="custom-checkbox"
                                    >
                                      <span className="checkbox">
                                      {t("BookingPage.payment.credit-card.terms")}
                                      </span>
                                      <input type="checkbox" id="receiveChb" />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="btn-box">
                                <Link to={"/payment-received"}>
                                  <BtnComponent value={t("BookingPage.confirm-booking")}/>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div> */}
                     <div className={paymentMethodToggle===1?"tab-pane fade show active":"tab-pane fade show"}
                      role="tabpanel"
                      aria-labelledby="credit-card-tab"
                    >
                      <h5>{t("BookingPage.payment.credit-card.not-available-now")}</h5>
                    </div>
                    <div className={paymentMethodToggle===2?"tab-pane fade show active":"tab-pane fade show"}
                      id="cash"
                      role="tabpanel"
                      aria-labelledby="cash-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="btn-box" onClick={onConfirmReserve}>
                                <BtnComponent value={t("BookingPage.confirm-booking")}/>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {loading===false?
                serviceDetails?
                <div className="form-box booking-detail-form">
                    <div className="form-title-wrap">
                        <h3 className="title">{t("BookingPage.booking-details.title")}</h3>
                    </div>
                    <div className="form-content">
                        <div className="card-item">
                            <div className="card-img pb-4">
                                {
                                  serviceDetails.attachment_relation?
                                  <Link to={`/rest-details-page/:${newParam}`} className="d-block">
                                    <img src={serviceDetails.attachment_relation[0].url} alt="rest-img"  loading="lazy"/>
                                </Link>
                                :null
                                }
                            </div>
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-between">
                                    {
                                      serviceDetails.name_en && serviceDetails.address ?
                                      <div>
                                        <h3 className="card-title">{serviceDetails.name_en}</h3>
                                        <p className="card-meta">{serviceDetails.address}</p>
                                    </div>
                                    :null
                                    }
                                    <div>
                                    <Link to={`/rest-details-page/:${newParam}`} className="btn ml-1">
                                       <FontAwesomeIcon icon={faPenToSquare}/>
                                    </Link>
                                    </div>
                                </div>
                                {
                                  serviceDetails.stars && serviceDetails.review && serviceDetails.num_reviews?
                                  <div className="card-rating">
                                    <span className="badge-2 text-white">{serviceDetails.stars}/5</span>
                                    <span className="review__text">{serviceDetails.review}</span>
                                    <span className="rating__text">({serviceDetails.num_reviews} {t("BookingPage.booking-details.reviews")})</span>
                                </div>
                                :null
                                }
                                <ul className="list-items list-items-flush py-2">
                                    <li className="font-size-15">
                                    <span className="w-auto d-block mb-n1"><FontAwesomeIcon icon={faCalendarDays}/> {t("BookingPage.booking-details.checkIn")}</span>
                                    {localStorage.getItem("start_date")}
                                    </li>
                                    <li className="font-size-15">
                                        <span className="w-auto d-block mb-n1"><FontAwesomeIcon icon={faCalendarDays}/> {t("BookingPage.booking-details.checkOut")}</span>
                                        {localStorage.getItem("end_date")}
                                    </li>
                                    <li className="font-size-15">
                                        <span className="w-auto d-block mb-n1"><FontAwesomeIcon icon={faLocationDot}/> {t("BookingPage.booking-details.location")}</span>
                                        {serviceDetails.address}
                                    </li>
                                    <li className="font-size-15">
                                        <span className="w-auto d-block mb-n1"><FontAwesomeIcon icon={faBan}/> {t("BookingPage.booking-details.available-cancel")}</span>
                                        {
                                          serviceDetails.available_cancel === "1"?
                                          "No"
                                          :"Yes"
                                        }
                                    </li>
                                </ul>
                                <h3 className="card-title pb-3">{t("BookingPage.order-details.title")}</h3>
                                <ul className="list-items list-items-2 py-3">
                                    <li><span>{t("BookingPage.order-details.subtotal")}</span><span>{`$${serviceDetails.price_dollar}`}</span></li>
                                    <li><span>{t("BookingPage.order-details.totalPrice")}</span><span>{`$${serviceDetails.price_dollar}`}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :<div>
                  <NotFoundData />
                </div>
                :<SpinnerComponent />
              }
            </div>
          </div>
        </div>
      {isPress===true?reserveLoading===true?<SpinnerComponent/>:null:null}
      </section>
      <InfoSection />
      <Footer />
    </div>
  );
};

export default RestBookingPage;
