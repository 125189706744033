import {useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {verifyCode } from '../../Redux/Actions/authAction'
import notify from '../Notify/Notify'
/*import { Navigate } from 'react-router-dom'*/
const VerifyCodeHook = () => {
    const [code,setCode] = useState("")
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.authReducer.verifyCode)

    const onChangeCode = (e)=>{
        setCode(e.target.value)
    }
    const onSubmit = async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("token",code)
        if(code === ""){
            notify("Enter the code","error")
        }
        setLoading(true)
        setIsPress(true)
        await dispatch(verifyCode(formData))
        setLoading(false)
        setIsPress(false)
    }
    useEffect(()=>{
        if(loading === false){
            if(res){
                if(res.status===200){
                    notify(`${res.data.message}`,"success")
                    setTimeout(() => {
                        window.location.href="/resetPasswordPage"
                    }, 1000);
                }else if(res.status===400){
                    notify(`${res.data.message}`,"error")
                }
            }
        }
    },[loading])
    return[code,onChangeCode,onSubmit,loading,isPress]
}

export default VerifyCodeHook