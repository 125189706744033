import React, {useState } from "react";
import "./UserBookingPage.css";
import UserProfileSidebar from "../../../Components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../Components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../Components/User/UserDashboardBread/UserDashboardBread";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'*/
import UserDashboardFooter from "../../../Components/User/UserDashboardFooter/UserDashboardFooter";
import { faBookReader } from "@fortawesome/free-solid-svg-icons";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import GetAllReservationsHook from "../../../CustomHooks/UserHooks/GetAllReservationsHook";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import CancelReservationHook from "../../../CustomHooks/UserHooks/CancelReservationHook";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
const UserBookingPage = () => {
  const [reservatoins, loading] = GetAllReservationsHook();
  const [cancelReservation] = CancelReservationHook();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCancel = (id) => {
    setShow(false);
    cancelReservation(id);
  };
  // start code for translate
  const [t, i18n] = useTranslation();
  // End code for translate
  let title = "";
  try {
    if (i18n.language == "ar") {
      title = "حجوزاتى";
    } else {
      title = "My Booking";
    }
  } catch (e) {}
  return (
    <div className="user-booking-page page">
      <div
        className={
          i18n.language == "ar"
            ? "user-sidebar-wrap-ar d-none d-lg-block"
            : "user-sidebar-wrap d-none d-lg-block"
        }
      >
        <UserProfileSidebar bookingPage={"bookingPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav bookingPageActive={"page-active"} />
        <UserDashboardBread section_title={title} />
        <div className="dashboard-main-content">
          <div className="container-fluid">
            <div className="row row-content">
              {loading === false ? (
                reservatoins ? (
                  reservatoins.data ? (
                    reservatoins.data.canceled &&
                    reservatoins.data.completed &&
                    reservatoins.data.pending ? (
                      <div className="col-lg-12">
                        <div className="form-box">
                          <div className="form-title-wrap">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h3 className="title">{t("userBookingPage.title")}</h3>
                              </div>
                              <span>
                              {t("userBookingPage.total")}{" "}
                                <strong className="color-text">
                                  (
                                  {reservatoins.data.canceled.length +
                                    reservatoins.data.completed.length +
                                    reservatoins.data.pending.length}
                                  )
                                </strong>
                              </span>
                            </div>
                          </div>
                          <div className="form-content">
                            <div className="table-form table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">{t("userBookingPage.form.type")}</th>
                                    <th scope="col">{t("userBookingPage.form.title")}</th>
                                    <th scope="col">{t("userBookingPage.form.location")}</th>
                                    <th scope="col">{t("userBookingPage.form.order-date")}</th>
                                    <th scope="col">{t("userBookingPage.form.execution-date")}</th>
                                    <th scope="col">{t("userBookingPage.form.price")}</th>
                                    <th scope="col">{t("userBookingPage.form.status")}</th>
                                    <th scope="col">{t("userBookingPage.form.action")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reservatoins.data.pending
                                    ? reservatoins.data.pending.map(
                                        (item, index) => {
                                          return (
                                            <tr key={item.id}>
                                              <th scope="row">
                                                <FontAwesomeIcon
                                                  icon={faBookReader}
                                                />
                                                {item.reservationable_type.includes(
                                                  "Hotel"
                                                )
                                                  ? "Hotel"
                                                  : item.reservationable_type.includes(
                                                      "House"
                                                    )
                                                  ? "House"
                                                  : item.reservationable_type.includes(
                                                      "Flight"
                                                    )
                                                  ? "Flight"
                                                  : item.reservationable_type.includes(
                                                      "Car"
                                                    )
                                                  ? "Car"
                                                  : item.reservationable_type.includes(
                                                      "Hostel"
                                                    )
                                                  ? "Resort"
                                                  : item.reservationable_type.includes(
                                                      "Rest"
                                                    )
                                                  ? "Rest"
                                                  : item.reservationable_type.includes(
                                                      "Apartment"
                                                    )
                                                  ? "Apartment"
                                                  : null}
                                              </th>
                                              <td>
                                                <div className="table-content">
                                                  <h3 className="title">
                                                    {i18n.language=="ar"?
                                                      item.reservationable.name_ar
                                                    :
                                                      item.reservationable.name_en
                                                    }
                                                  </h3>
                                                </div>
                                              </td>
                                              <td>
                                                {item.reservationable.address}
                                              </td>
                                              <td>
                                                {item.created_at.slice(0, 10)}
                                              </td>
                                              <td>
                                                {item.reservationable.check_inn}
                                              </td>
                                              <td>
                                                ${item.final_price_dollar}
                                              </td>
                                              <td>
                                                <span className="badge badge-info py-1 px-2">
                                                  {item.status}
                                                </span>
                                              </td>
                                              <td>
                                                <div
                                                  className="table-content cancel-btn"
                                                  onClick={handleShow}
                                                >
                                                  <BtnComponent
                                                    value={t("userBookingPage.form.cancel")}
                                                  />
                                                </div>
                                              </td>
                                              <Modal
                                                show={show}
                                                onHide={handleClose}
                                              >
                                                <Modal.Header>
                                                  <Modal.Title>
                                                  {t("userBookingPage.modal.title")}
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                {t("userBookingPage.modal.body")}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <div
                                                    className="btn-box"
                                                    onClick={handleClose}
                                                  >
                                                    <BtnComponent
                                                      value={t("userBookingPage.modal.cancel")}
                                                    />
                                                  </div>
                                                  <div
                                                    className="btn-box"
                                                    onClick={() =>
                                                      handleCancel(item.id)
                                                    }
                                                  >
                                                    <BtnComponent
                                                      value={t("userBookingPage.modal.cancel-reservation")}
                                                    />
                                                  </div>
                                                </Modal.Footer>
                                              </Modal>
                                            </tr>
                                          );
                                        }
                                      )
                                    : null}
                                  {reservatoins.data.canceled
                                    ? reservatoins.data.canceled.map(
                                        (item, index) => {
                                          return (
                                            <tr key={index}>
                                              <th scope="row">
                                                <FontAwesomeIcon
                                                  icon={faBookReader}
                                                />
                                                {item.reservationable_type.includes(
                                                  "Hotel"
                                                )
                                                  ? "Hotel"
                                                  : item.reservationable_type.includes(
                                                      "Flight"
                                                    )
                                                  ? "Flight"
                                                  : item.reservationable_type.includes(
                                                      "Car"
                                                    )
                                                  ? "Car"
                                                  : item.reservationable_type.includes(
                                                      "Hostel"
                                                    )
                                                  ? "Resort"
                                                  : item.reservationable_type.includes(
                                                      "Rest"
                                                    )
                                                  ? "Rest"
                                                  : item.reservationable_type.includes(
                                                      "Apartment"
                                                    )
                                                  ? "Apartment"
                                                  : null}
                                              </th>
                                              <td>
                                                <div className="table-content">
                                                  <h3 className="title">
                                                    {i18n.language=="ar"?
                                                      item.reservationable.name_ar
                                                    :
                                                      item.reservationable.name_en
                                                    }
                                                  </h3>
                                                </div>
                                              </td>
                                              <td>
                                                {item.reservationable.address}
                                              </td>
                                              <td>
                                                {item.created_at.slice(0, 10)}
                                              </td>
                                              <td>
                                                {item.reservationable.check_inn}
                                              </td>
                                              <td>
                                                ${item.final_price_dollar}
                                              </td>
                                              <td>
                                                <span className="badge badge-info py-1 px-2">
                                                  {item.status}
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : null}
                                  {reservatoins.data.completed
                                    ? reservatoins.data.completed.map(
                                        (item, index) => {
                                          return (
                                            <tr key={index}>
                                              <th scope="row">
                                                <FontAwesomeIcon
                                                  icon={faBookReader}
                                                />
                                                {item.reservationable_type.includes(
                                                  "Hotel"
                                                )
                                                  ? "Hotel"
                                                  : item.reservationable_type.includes(
                                                      "Flight"
                                                    )
                                                  ? "Flight"
                                                  : item.reservationable_type.includes(
                                                      "Car"
                                                    )
                                                  ? "Car"
                                                  : item.reservationable_type.includes(
                                                      "Hostel"
                                                    )
                                                  ? "Resort"
                                                  : item.reservationable_type.includes(
                                                      "Rest"
                                                    )
                                                  ? "Rest"
                                                  : item.reservationable_type.includes(
                                                      "Department"
                                                    )
                                                  ? "Department"
                                                  : null}
                                              </th>
                                              <td>
                                                <div className="table-content">
                                                  <h3 className="title">
                                                    {i18n.language=="ar"?
                                                      item.reservationable.name_ar
                                                    :
                                                      item.reservationable.name_en
                                                    }
                                                  </h3>
                                                </div>
                                              </td>
                                              <td>
                                                {item.reservationable.address}
                                              </td>
                                              <td>
                                                {item.created_at.slice(0, 10)}
                                              </td>
                                              <td>
                                                {item.reservationable.check_inn}
                                              </td>
                                              <td>
                                                ${item.final_price_dollar}
                                              </td>
                                              <td>
                                                <span className="badge badge-info py-1 px-2">
                                                  {item.status}
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NotFoundData />
                    )
                  ) : (
                    <SpinnerComponent />
                  )
                ) : (
                  <SpinnerComponent />
                )
              ) : (
                <SpinnerComponent />
              )}
            </div>
            <div className="border-top mt-5"></div>
            <div className="row row-footer align-items-center">
              <UserDashboardFooter />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserBookingPage;
