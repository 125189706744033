import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
const UserDashboardFooter = () => {
  return (
    <div className='row user-dashboard-footer' style={{alignItems:"center"}}>
         <div className="col-lg-7">
                        {/* <div className="copy-right padding-top-30px">
                            <p className="copy__desc">
                                &copy; Copyright Nozoluna 2023. Made with
                                <span className="la la-heart"></span> by
                                <a  href="https://kw4s.com" style={{color:"#e8586d"}}
                                  >Khatab</a
                                >
                              </p>
                        </div> */}
                    </div>
                    <div className="col-lg-5">
                        <div className="copy-right-content text-right padding-top-30px">
                            <ul className="social-profile">
                                <li><a href="#"><FontAwesomeIcon icon={faFacebook}/></a></li>
                                <li><a href="#"><FontAwesomeIcon icon={faTwitter}/></a></li>
                                <li><a href="#"><FontAwesomeIcon icon={faInstagram}/></a></li>
                                <li><a href="#"><FontAwesomeIcon icon={faLinkedin}/></a></li>
                            </ul>
                        </div>
                    </div>
    </div>
  )
}

export default UserDashboardFooter