import { faBriefcase, faExchange, faPlane, faWifi } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

const WhyFlyWithUs = () => {
  const [t,i18n]=useTranslation()
  return (
    <section className="why-fly-with-us info-area info-bg container"> 
    <div className='row'>
    <div className='section-heading my-4'>
        <h2 className='sec__title'>{t("Flights.WhyFlyNozoulna.title")}</h2>
    </div>
    <div className="col-sm-6 col-lg-3">
      <div className="icon-box icon-layout-2 d-flex">
        <div className="info-icon info-icon-1 flex-shrink-0 bg-rgb text-color-2">
            <FontAwesomeIcon icon={faBriefcase}/>
        </div>
        <div className="info-content">
          <h4 className="info__title">{t("Flights.WhyFlyNozoulna.item1.title")}</h4>
          <p className="info__desc">
          {t("Flights.WhyFlyNozoulna.item1.description")}
          </p>
        </div>
      </div>
    </div>
    <div className="col-sm-6 col-lg-3">
      <div className="icon-box icon-layout-2 d-flex">
        <div className="info-icon info-icon-2 flex-shrink-0 bg-rgb-2 text-color-3">
            <FontAwesomeIcon icon={faExchange}/>
        </div>
        <div className="info-content">
          <h4 className="info__title">{t("Flights.WhyFlyNozoulna.item2.title")}</h4>
          <p className="info__desc">
          {t("Flights.WhyFlyNozoulna.item2.description")}
          </p>
        </div>
      </div>
    </div>
    <div className="col-sm-6 col-lg-3">
      <div className="icon-box icon-layout-2 d-flex">
        <div className="info-icon info-icon-3 flex-shrink-0 bg-rgb-3 text-color-4">
            <FontAwesomeIcon icon={faWifi}/>
        </div>
        <div className="info-content">
          <h4 className="info__title">{t("Flights.WhyFlyNozoulna.item3.title")}</h4>
          <p className="info__desc">
          {t("Flights.WhyFlyNozoulna.item3.description")}
          </p>
        </div>
      </div>
    </div>
    <div className="col-sm-6 col-lg-3">
      <div className="icon-box icon-layout-2 d-flex">
        <div className="info-icon info-icon-4 flex-shrink-0 bg-rgb-4 text-color-5">
            <FontAwesomeIcon icon={faPlane}/>
        </div>
        <div className="info-content">
          <h4 className="info__title">{t("Flights.WhyFlyNozoulna.item4.title")}</h4>
          <p className="info__desc">
          {t("Flights.WhyFlyNozoulna.item4.description")}
          </p>
        </div>
      </div>
    </div>
    </div>
  </section>
  )
}

export default WhyFlyWithUs