import React from 'react'
import { SingleTourFeature } from '../../SingleTourFeature/SingleTourFeature'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
const AmenitiesSection = ({serviceDetails}) => {
  const [t,i18n]=useTranslation()
    let value=""
    try{
        if(i18n.language==="ar"){
            value="وسائل الراحه"
        }else{
            value="amenities"
        }
    }catch(e){}
  return (
    <section id="amenities" className="amenities-section">
      <h4 className="mini-custom-title">{value}</h4>
                  <div className="single-content-item padding-top-40px padding-bottom-20px">
                    <div className="amenities-feature-item pt-4">
                      <div className="row">
                        {serviceDetails.amenitie?
                          serviceDetails.amenitie.length>0?
                          serviceDetails.amenitie.map((amen)=>{
                            return(
                              <div className="col-6 col-md-4" key={amen.id}>
                              <SingleTourFeature title={amen.title} icon={faCheck} />
                            </div>
                            )
                          })
                          :<NotFoundData/>
                          :<NotFoundData/>
                        }
                      </div>
                    </div>
                  </div>
    </section>
  )
}

export default AmenitiesSection