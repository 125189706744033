import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const PaymentRecievedHook = () => {
    const res = useSelector((state)=>state.UserReducer.makeReservation)
    const loading = useSelector(state=> state.UserReducer.loading)
    const Navigate = useNavigate()
    // start code for translate
    const [t,i18n]=useTranslation()
    // End code for translate
    // get user,partner_two,partner_three from local storage
      let user, partner_two, partner_three;
      if (localStorage.getItem("NozoulnaUser") != null) {
        user = JSON.parse(localStorage.getItem("NozoulnaUser"));
      }
      if (localStorage.getItem("partner_two") != null) {
        partner_two = localStorage.getItem("partner_two");
      }
      if (localStorage.getItem("partner_Three") != null) {
        partner_three = localStorage.getItem("partner_Three");
      }
    // remove localStorage what I no longer need it
    const removeLocalStorage =()=>{
        let i=0;
        let localKeys=[]
        // loop in the localStorage and store what i need to remove in localKeys array
        while(i<localStorage.length){
            if(localStorage.key(i)!="NozoulnaUser"&&localStorage.key(i)!="NozoulnaToken"&&localStorage.key(i)!="i18nextLng"){
                localKeys.push(localStorage.key(i))
            }
            i++
        }
        // loop on localKeys array and remove all what it contains
        for(let j=0; j<localKeys.length; j++){
            localStorage.removeItem(localKeys[j])
        }
    }
    // on click book complete fn
    const onBookComplete =()=>{
       removeLocalStorage()
        Navigate("/payment-complete")
    }
    let data = [];
    try{
        if(res){
            if(res.data && res.data.data){
                data = res.data.data[0];
            }
        }else{
            data = [];
        }
    }catch(e){}
    // get the final date
    if (data) {
        let date_one = new Date(data.start_date);
        let date_two = new Date(data.end_date);
        var finalDate = Math.floor((date_two - date_one) / 1000 / 60 / 60 / 24);
      }
      // store cancel id in the cancelId variable
    let cancelId ="";
    try{
        if(res){
            if(res.data && res.data.data && res.data.data[0]){
                cancelId=res.data.data[0].id
            }else{
                cancelId=""
            }
        }
    }catch(e){}
    
    return [data,loading,cancelId,onBookComplete,finalDate,i18n,t,user,partner_three,partner_two]
}

export default PaymentRecievedHook