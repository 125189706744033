import React from 'react'
import '../../../Components/Sections/InfoSection/InfoSection.css'
import { Col, Container, Row } from 'react-bootstrap'
import SpinnerComponent from '../../Utility/SpinnerComponent/SpinnerComponent'
import WishlistHook from '../../../CustomHooks/UserHooks/WishlistHook'
import Pagenation from '../../Utility/Pagenation/Pagenation'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
import AllFlightsHook from '../../../CustomHooks/FlightsHooks/AllFlightsHook'
import FlightCard from '../FlightCard/FlightCard'
const FlightsContainer = () => {
    const [allFlights,loading,onPress,allFlightsNum,] = AllFlightsHook()
    const  [,,wishlistItemsId] = WishlistHook()
  return (
    <Container className='flights-content flights-container position-relative min-vh-100'>
        <Row>
        {loading === false ?
              (
                allFlights?
                allFlights.data?
                allFlights.data.length>=1?
                allFlights.data.map((flight)=>{
                  return(
                    <Col xs="12" sm="6" lg="4" key={flight.id}>
                    <FlightCard favItem={wishlistItemsId} flight={flight}/>
                    </Col>
                  )
                })
                :<NotFoundData />
                :<NotFoundData />
                :<NotFoundData />
              )
              :<SpinnerComponent />
              }
              {
                allFlights?
                allFlights.data?
                allFlights.data.length>=1?
                <Pagenation pageCount={allFlightsNum} onPress={onPress}/>
                :null
                :null
                :null
              }
        </Row>
    </Container>
  )
}

export default FlightsContainer