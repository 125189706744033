import React from 'react'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart,faUser } from '@fortawesome/free-regular-svg-icons';
import {faCartShopping, faTag } from '@fortawesome/free-solid-svg-icons';
import UpdateWishlistHook from '../../../CustomHooks/UserHooks/UpdateWishlistHook';
import { useTranslation } from 'react-i18next';
import TripReservHook from '../../../CustomHooks/UserHooks/Trips/TripReservHook';
const TripBookingInfoSection = ({id}) => {
    const [,onAddToWishlist] = UpdateWishlistHook()
    const [t,i18n] = useTranslation()
    const[,,partner,onChangePartner,discount_code_id,onChangeDiscount,onClickBookNow] = TripReservHook(id)
  return (
    <section className='booking-info-section'>
    <div className="contact-form-action">
      <form action="#">
                  <div className="input-box">
                          <label className="label-text">
                          {t("Trips.trip_booking_info_section.discount")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faTag}/>
                            <input
                            value={discount_code_id}
                            onChange={onChangeDiscount}
                              className="custom-input"
                              type="text"
                              name="discount-code"
                              placeholder={t("Trips.trip_booking_info_section.discount")}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("Trips.trip_booking_info_section.name")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partner}
                            onChange={onChangePartner}
                              className="custom-input"
                              type="text"
                              name="name"
                              placeholder= {t("Trips.trip_booking_info_section.name")}
                            />
                          </div>
                        </div>
      </form>
    </div>
        <div className="btn-box btn-box-1 pt-2" onClick={()=>onClickBookNow(id)}>
            <BtnComponent icon={<FontAwesomeIcon icon={faCartShopping} />}value={t("Trips.trip_booking_info_section.book_now")}/>
        </div>
        <div className="btn-box btn-box-2 pt-2" onClick={()=>onAddToWishlist(id,"trips")}>
          <BtnComponent icon={<FontAwesomeIcon icon={faHeart} />} value={t("Trips.trip_booking_info_section.add_to_wishlist")}/>
        </div>
</section>
  )
}

export default TripBookingInfoSection