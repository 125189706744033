import React from 'react'
import './ForgetPasswordPage.css'
import Header from "../../Components/GeneralSections/Header/Header";
import Footer from "../../Components/GeneralSections/Footer/Footer";
import SpinnerComponent from '../../Components/Utility/SpinnerComponent/SpinnerComponent'
import BtnComponent from '../../Components/Utility/BtnComponent/BtnComponent'
import BreadCrumbSection from '../../Components/Sections/BreadCrumbSection/BreadCrumbSection'
import VerifyEmailHook from '../../CustomHooks/Auth/VerifyEmailHook'
import { useTranslation } from 'react-i18next'
const VerifyEmailPage = () => {
    const [code,onChangeCode,onSubmit,loading,isPress]= VerifyEmailHook()
     //start code for translate
const [t,i18n]=useTranslation()
//End code for translate
let title="";
try{
  if(i18n.language==="ar"){
    title="تاكيد الايميل"
  }else{
    title="Verify Email"
  }
}catch(e){}
      return (
        <>
          <Header activeHome={"activeHome"}/>
          <BreadCrumbSection serviceDetails={""} loading={false} bg={"bread-bg-8"} title={title} />
          <div className="page forget-password-page">
            <section className="contact-area padding-top-100px padding-bottom-70px">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 mx-auto">
                    <div className="form-box recover-box-parent">
                      <div className="form-title-wrap">
                        <h3 className="title">{t("verifyEmailPage.title")}</h3>
                        <p className="font-size-15 pt-2">
                        {t("verifyEmailPage.description")}
                        </p>
                      </div>
                      <div className="form-content ">
                        <div className="contact-form-action">
                          <form method="post">
                            <div className="input-box recover-box">
                              <label className="label-text">{t("verifyEmailPage.code")}</label>
                              <div className="form-group">
                                <span className="la la-envelope-o form-icon"></span>
                                <input
                                value={code}
                                onChange={onChangeCode}
                                  className="custom-input"
                                  type="text"
                                  name="code"
                                  placeholder={t("verifyEmailPage.code")}
                                />
                              </div>
                            </div>
                            <div className="btn-box" onClick={onSubmit}>
                              <BtnComponent value={t("verifyEmailPage.send")}/>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {isPress ===true? loading === true ? <SpinnerComponent /> : null : null}
          </div>
          <Footer />
        </>
      );
}

export default VerifyEmailPage