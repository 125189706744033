import React, { useState } from 'react'
import { SingleTourFeature } from '../../SingleTourFeature/SingleTourFeature'
import {faCheck,faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import CustomCarousel from '../../Utility/CustomCarousel/CustomCarousel'
import NotFoundData from '../../../Components/Utility/NotFoundData/NotFoundData'
import { useTranslation } from 'react-i18next'
import ResortReservHook from '../../../CustomHooks/UserHooks/Resorts/ResortReservHook'
const ResortRoomCard = ({room,id}) => {
    const [showImages,setShowImages] = useState("showing-images")
    const showImagesFn = ()=>{
        if(showImages === "showing-images"){
            setShowImages("showing-images show")
        }else{
            setShowImages("showing-images")
        }
    }
    const  [,,,,,onChangeRoomId,,,,,,,,,,,,,,,,,,,,,]= ResortReservHook(id)
    // start code for translate
    const [t,i18n]=useTranslation()
    // End code for translate
  return (
    <div className='room-card'>
         <div className="cabin-type">
            <div className="cabin-type-item seat-selection-item d-flex">
                <div className="cabin-type-img flex-shrink-0">
                    {room && room.room_images[0]?
                    <img src={`https://multi.nozoluna.com/public/dash/assets/img/${room.room_images[0].image}`}
                    alt="room-img" loading='lazy'/>
                    :<NotFoundData/>
                    }
                </div>
                <div className="cabin-type-detail">
                        {room.type?
                        room.description_en&&room.description_ar?
                        i18n.language==="ar"?
                        <h3 className="title">{room.type}:{room.description_ar}</h3>
                        :
                        <h3 className="title">{room.type}:{room.description_en}</h3>
                        :null
                        :null
                        }
                    <div className="row">
                        {
                            room?
                            room.amenitie.map((amen,index)=>{
                                return(
                                    <div className="col-6" key={index}>
                                    <SingleTourFeature title={amen.title} icon={faCheck}/>
                                    </div>
                                )
                            })
                            :null
                        }
                    </div>
                    <div className="room-photos">
                        <button className="btn" onClick={showImagesFn} data-src="images/img1.jpg"
                           data-fancybox="gallery"
                           data-caption="Showing image - 01"
                           data-speed="700">
                            <FontAwesomeIcon icon={faImages}/> {t("availabilitySection.room-card.room-photos")}
                        </button>
                        <div className={showImages}>
                            <div className='row h-100'>
                                <div className='col-sm-12 my-4'>
                                <div className='controls'>
                                <div className='showing-images-btn-close' onClick={showImagesFn}>
                                    <span className='custom-icon'>
                                        <FontAwesomeIcon icon={faXmark}/>
                                    </span>
                                </div>
                            </div>
                                </div>
                                <div className='col-sm-12 my-4'>
                                <div className='carousel-parent'>
                                <CustomCarousel show={1}>
                                {room?
                                    room.room_images.length>=1?
                                    room.room_images.map((roomImg,index)=>{
                                        return(
                                            <img src={`https://multi.nozoluna.com/public/dash/assets/img/${roomImg.image}`}
                                            key={index} alt='room-img' loading='lazy'/>
                                        )
                                    })
                                    :<NotFoundData />
                                    :null
                                }
                                </CustomCarousel>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cabin-price d-flex">
                    <p className="text-uppercase font-size-14">{t("availabilitySection.room-card.per-night")}<strong className="mt-n1 text-black font-size-18 font-weight-black d-block">{`$${room.price_dollar}`}</strong></p>
                    <div className="custom-checkbox mb-0">
                        <label className='custom-checkbox'>
                        {t("availabilitySection.room-card.select")}
                            <input type='checkbox' className='checkbox' value={room.id} onChange={onChangeRoomId}/>
                            <span className='checkmark'></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResortRoomCard