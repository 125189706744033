import React from 'react'
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import InfoSection from '../../../Components/Sections/InfoSection/InfoSection'
import { useTranslation } from 'react-i18next'
import CarsContainer from '../../../Components/Cars/CarsContainer/CarsContainer';
import CarsPageHeadSection from '../../../Components/Cars/CarsPageHeadSection/CarsPageHeadSection';
const CarsPage = () => {
   // start code to translate
   const [t,i18n]=useTranslation()
   // end code to translate
   let title = "";
   try{
    if(i18n.language=="ar"){
      title="السيارات"
     }else{
      title="cars"
     }
   }catch(e){}
  return (
    <div className='services-page'>
      <Header activeCars={"activeCars"} />
      <CarsPageHeadSection bg={"cars-page-head-bg"} service_type={"car"}/>
      <div className='container'>
        <div className='row row-mid my-2'>
            <h2 className='custom-title'>{t("carsPage.title")}</h2>
          <div className='col-12'>
            <CarsContainer />
          </div>
        </div>
      </div>
      <InfoSection />
      <Footer />
    </div>
  )
}

export default CarsPage