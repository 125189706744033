import React from 'react'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons'
import AddSuggestionHook from '../../../CustomHooks/UserHooks/AddSuggestionHook'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const AddSuggestionSection = () => {
    const [name,onChangeName,email,onChangeEmail,title,onChangeTitle,message,onChangeMessage,
        onSubmit,,] = AddSuggestionHook()

        const [t,i18n] = useTranslation()
  return (
    <section className='add-suggestion-section'>
                  <h3 className="title stroke-shape">{t("detailsSidebar.add-suggestion-section.title")}</h3>
                  <div className="enquiry-forum">
                    <div className="form-box">
                      <div className="form-content">
                        <div className="contact-form-action">
                          <form method="post">
                            <div className="input-box">
                              <label className="label-text">{t("detailsSidebar.add-suggestion-section.name")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                  value={name}
                                  onChange={onChangeName}
                                  className="custom-input"
                                  type="text"
                                  name="text"
                                  placeholder={t("detailsSidebar.add-suggestion-section.name")}
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">{t("detailsSidebar.add-suggestion-section.email")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <input
                                value={email}
                                onChange={onChangeEmail}
                                  className="custom-input"
                                  type="email"
                                  name="email"
                                  placeholder={t("detailsSidebar.add-suggestion-section.email")}
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">{t("detailsSidebar.add-suggestion-section.suggestion-title")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <input
                                value={title}
                                onChange={onChangeTitle}
                                  className="custom-input"
                                  type="text"
                                  name="title"
                                  placeholder={t("detailsSidebar.add-suggestion-section.suggestion-title")}
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">{t("detailsSidebar.add-suggestion-section.your-suggestion")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faPencil} />
                                <textarea
                                value={message}
                                onChange={onChangeMessage}
                                  className="message-control custom-input"
                                  name="suggestion"
                                  placeholder={t("detailsSidebar.add-suggestion-section.your-suggestion")}
                                ></textarea>
                              </div>
                            </div>
                            <div className="input-box">
                              <div className="form-group">
                                <div className="custom-checkbox-wrap mb-0">
                                  <label className="custom-checkbox">
                                  {t("detailsSidebar.add-suggestion-section.agree")}
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="btn-box" onClick={onSubmit}>
                              <BtnComponent value={t("detailsSidebar.add-suggestion-section.submit-suggestion")} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
    </section>
  )
}

export default AddSuggestionSection