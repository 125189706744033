import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { CancelReservation } from '../../Redux/Actions/userActions'
import Notify from '../Notify/Notify'
const CancelReservationHook = () => {
    const dispatch = useDispatch()
    const [loadingReserve,setLoading] = useState(true)
    const res = useSelector((state) => state.UserReducer.cancelReservation)

    const cancelReservation = async(reservation_id)=>{
    const formData = new FormData()
    formData.append("reservation_id",reservation_id)
    /*formData.append("cancel_reason")*/
    /* formData.append("bank_number")*/
        setLoading(true)
        await dispatch(CancelReservation(formData))
        setLoading(false)
    }

    useEffect(()=>{
        if(loadingReserve === false){
            if(res){
                console.log(res)
                if(res.status===200 || res.status === 201){
                    Notify(res.data.message,"success")
                }else if(res.status === 404){
                    Notify(res.data.message,"warn")
                }else{
                    Notify(res.data.message,"error")
                }
            }
        }
    },[loadingReserve])
    return [cancelReservation,loadingReserve]
}

export default CancelReservationHook