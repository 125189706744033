/*import types of actions*/
import {
    GET_ALL_SERVICE,
    GET_SEARCH_RESULT_SERVICE,
    GET_ERROR,
    GET_SERVICE_DETAILS,
  } from "../TypesOfActions";
  /* import useGetData*/
  import { GetDataHook } from "../../Hooks/GetDataHook";
  
  /* action to get all hotels*/
  export const GetAllServiceAction = (service_type,category_id) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await GetDataHook(`api/service?paginate=9&service_type=${service_type}&category_id=${category_id}`)
      dispatch({
        type: GET_ALL_SERVICE,
        payload: response.data,
      });
    } catch (e) {
      /*Statement that is executed if an exception is thrown in the try-block.*/
      dispatch({
        type: GET_ERROR,
        payload: "error" + e,
      });
    } finally {
    }
  };
  /* action to get all hotels*/
  export const GetSearchResultService = (service_type,category_id,queryString) => async (dispatch) => {
    /*The statements to be executed.*/
  try {
    const response = await GetDataHook(`api/service?service_type=${service_type}&category_id=${category_id}&paginate=9&${queryString}`)
    dispatch({
      type:GET_SEARCH_RESULT_SERVICE,
      payload: response.data,
    });
  } catch (e) {
    /*Statement that is executed if an exception is thrown in the try-block.*/
    dispatch({
      type: GET_ERROR,
      payload: "error" + e,
    });
  } finally {
  }
  };
  /* action to get hotel details*/
  export const GetServiceDetails = (service_type,service_id) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
        const response = await GetDataHook(`api/${service_type}/single/${service_id}`)
      dispatch({
        type: GET_SERVICE_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      /*Statement that is executed if an exception is thrown in the try-block.*/
      dispatch({
        type: GET_ERROR,
        payload: "error" + e,
      });
    } finally {
    }
  };
  
  