import React from "react";
import './SingleTourFeature.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SingleTourFeature = ({icon,title,dis}) => {

  return (
    <div className="single-tour-feature d-flex align-items-center">
      <div className="single-feature-icon custom-icon">
        <FontAwesomeIcon icon={icon}/>
      </div>
      <div className="single-feature-titles">
        <h3 className="title font-size-15 font-weight-medium">{title}</h3>
        <span>{dis}</span>
      </div>
    </div>
  );
};
