import React,{useState } from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from "../../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
//imgs
import payementImg from "../../../imgs/payment-img.png";
import money from "../../../imgs/public/money.png";
//End imgs
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalendar,faClock,faEnvelope,faFlag,faPenToSquare,faUser,} from "@fortawesome/free-regular-svg-icons";
import {faBan,faLocation,faPassport,faPhone, faPlane} from "@fortawesome/free-solid-svg-icons";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import ServiceDetailsHook from "../../../CustomHooks/ServicesHooks/ServiceDetailsHook";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import { useTranslation } from "react-i18next";
import FlightReserveHook from "../../../CustomHooks/UserHooks/Flights/FlightReserveHook";
const FlightBookingPage = () => {
  const param = useParams();
  const newParam = param.id.slice(1, param.id.length);
  const [serviceDetails,loading] = ServiceDetailsHook("plan-flights",newParam);
  const [paymentMethodToggle, setPaymentMethodToggle] = useState(1);
  /*======================================================================*/
  const [,,,,,,,,partner_name,onChange_partner_name,
    partner_phone,onChange_partner_phone,partner_address,onChange_partner_address,
    partner_email,onChange_partner_email,partner_gender,onChange_partner_gender,
    partner_birth,onChange_partner_birth,partner_passport_number,onChange_partner_passport_number,
    partner_nationality,onChange_partner_nationality,reserveLoading,isPress,,,
    ,onConfirmReserve
] = FlightReserveHook(newParam)
  // start code for translate
  const [t, i18n] = useTranslation();
  let title = "";
  try {
    if (i18n.language === "ar") {
      title = "حجز رحلة طيران";
    } else {
      title = "Book a flight";
    }
  } catch (e) {}

  return (
    <div className="service-booking-page page">
      <Header activeFlights={"activeFlights"} />
      <BreadCrumbSection serviceDetails={serviceDetails} loading={loading} title={title} bg={"bread-bg-5"} />
      <section className="booking-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">{t("BookingPage.title")}</h3>
                </div>
                <div className="form-content ">
                  <div className="contact-form-action">
                    <form method="post">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                              {t("BookingPage.name")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faUser} />
                              <input
                                value={partner_name}
                                onChange={onChange_partner_name}
                                className="custom-input"
                                type="text"
                                name="first-name"
                                placeholder={t("BookingPage.name")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                              {t("BookingPage.phone")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faPhone} />
                              <input
                                value={partner_phone}
                                onChange={onChange_partner_phone}
                                className="custom-input"
                                type="text"
                                name="phone-number"
                                placeholder={t("BookingPage.phone")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                             {t("Flights.FlightBookingPage.form.email")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faEnvelope} />
                              <input
                                value={partner_email}
                                onChange={onChange_partner_email}
                                className="custom-input"
                                type="email"
                                name="mail"
                                placeholder= {t("Flights.FlightBookingPage.form.email")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                            {t("Flights.FlightBookingPage.form.address")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faLocation} />
                              <input
                                value={partner_address}
                                onChange={onChange_partner_address}
                                className="custom-input"
                                type="text"
                                name="address"
                                placeholder={t("Flights.FlightBookingPage.form.address")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                            {t("Flights.FlightBookingPage.form.date_of_birth")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faCalendar} />
                              <input
                                value={partner_birth}
                                onChange={onChange_partner_birth}
                                className="custom-input"
                                type="date"
                                name="birth"
                                placeholder={t("Flights.FlightBookingPage.form.date_of_birth")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                            {t("Flights.FlightBookingPage.form.gender")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faUser} />
                              <input
                                value={partner_gender}
                                onChange={onChange_partner_gender}
                                className="custom-input"
                                type="text"
                                name="address"
                                placeholder={t("Flights.FlightBookingPage.form.male_female")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                            {t("Flights.FlightBookingPage.form.passport_number")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faPassport} />
                              <input
                                value={partner_passport_number}
                                onChange={onChange_partner_passport_number}
                                className="custom-input"
                                type="text"
                                name="passport"
                                placeholder={t("Flights.FlightBookingPage.form.passport_number")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <label className="label-text">
                            {t("Flights.FlightBookingPage.form.nationality")}
                            </label>
                            <div className="form-group">
                              <FontAwesomeIcon icon={faFlag} />
                              <input
                                value={partner_nationality}
                                onChange={onChange_partner_nationality}
                                className="custom-input"
                                type="text"
                                name="nationality"
                                placeholder={t("Flights.FlightBookingPage.form.nationality")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">{t("BookingPage.payment.title")}</h3>
                </div>
                <div className="form-content">
                  <div className="section-tab check-mark-tab text-center pb-4">
                    <ul
                      className="nav nav-tabs justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li
                        onClick={() => setPaymentMethodToggle(1)}
                        className={
                          paymentMethodToggle === 1
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <i className="la la-check icon-element"></i>
                        <img src={payementImg} alt="payment-img" />
                        <span className="d-block pt-2">
                          {t("BookingPage.payment.credit-card.title")}
                        </span>
                      </li>
                      <li
                        onClick={() => setPaymentMethodToggle(2)}
                        className={
                          paymentMethodToggle === 2
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <i className="la la-check icon-element"></i>
                        <img src={money} className="cash-img" alt="cash-img" />
                        <span className="d-block pt-2">
                          {t("BookingPage.payment.cash")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    {/* <div className={paymentMethodToggle===1?"tab-pane fade show active":"tab-pane fade show"}
                      role="tabpanel"
                      aria-labelledby="credit-card-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("BookingPage.payment.credit-card.card-holder-name")}
                                </label>
                                <div className="form-group">
                                  <FontAwesomeIcon icon={faCreditCard}/>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder={t("BookingPage.payment.credit-card.card-holder-name")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("BookingPage.payment.credit-card.card-number")}
                                </label>
                                <div className="form-group">
                                <FontAwesomeIcon icon={faCreditCard}/>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder={t("BookingPage.payment.credit-card.card-number")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6 responsive-column">
                                  <div className="input-box">
                                    <label className="label-text">
                                    {t("BookingPage.payment.credit-card.expiry-month")}
                                    </label>
                                    <div className="form-group">
                                    <FontAwesomeIcon icon={faCreditCard}/>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="text"
                                        placeholder="MM"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 responsive-column">
                                  <div className="input-box">
                                    <label className="label-text">
                                    {t("BookingPage.payment.credit-card.expiry-year")}
                                    </label>
                                    <div className="form-group">
                                    <FontAwesomeIcon icon={faCreditCard}/>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="text"
                                        placeholder="YY"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">{t("BookingPage.payment.credit-card.cvv")}</label>
                                <div className="form-group">
                                <FontAwesomeIcon icon={faPenToSquare}/>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder={t("BookingPage.payment.credit-card.cvv")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-box">
                                <div className="form-group">
                                  <div className="custom-checkbox-content">
                                    <label className="custom-checkbox"
                                    >
                                      <span className="checkbox">
                                      {t("BookingPage.payment.credit-card.terms")}
                                      </span>
                                      <input type="checkbox" id="receiveChb" />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="btn-box">
                                <Link to={"/payment-received"}>
                                  <BtnComponent value={t("BookingPage.confirm-booking")}/>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div> */}
                    <div
                      className={
                        paymentMethodToggle === 1
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      role="tabpanel"
                      aria-labelledby="credit-card-tab"
                    >
                      <h5>
                        {t("BookingPage.payment.credit-card.not-available-now")}
                      </h5>
                    </div>
                    <div
                      className={
                        paymentMethodToggle === 2
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="cash"
                      role="tabpanel"
                      aria-labelledby="cash-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="btn-box"
                                onClick={onConfirmReserve}
                              >
                                <BtnComponent
                                  value={t("BookingPage.confirm-booking")}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {loading === false ? (
                serviceDetails ? (
                  <div className="form-box booking-detail-form">
                    <div className="form-title-wrap">
                      <h3 className="title">
                        {t("BookingPage.booking-details.title")}
                      </h3>
                    </div>
                    <div className="form-content">
                      <div className="card-item">
                        <div className="card-img pb-4">
                          {serviceDetails.attachment_relation?(
                            <Link
                              to={`/flight-details-page/:${newParam}`}
                              className="d-block"
                            >
                              <img
                                src={serviceDetails.attachment_relation[0].url}
                                alt="flight-img"
                                loading="lazy"
                              />
                            </Link>
                          ) : null}
                        </div>
                        <div className="card-body p-0">
                          <div className="card-body-head border-bottom d-flex justify-content-between">
                                <div>
                                  <h3 className="title">
                                    {
                                    i18n.language === "ar"
                                      ? serviceDetails.off_town_ar +" الى "+serviceDetails.inn_town_ar
                                      : serviceDetails.off_town_en +" to "+serviceDetails.inn_town_en
                                      }
                                  </h3>
                                  <p className="card-meta color-text font-size-15">
                                    {
                                        serviceDetails.is_return==="0"?t("Flights.FlightBookingPage.one_way_flight"):
                                        t("Flights.FlightBookingPage.going_and_back")
                                    }
                                  </p>
                                </div>
                            <div>
                              <Link
                                to={`/flight-details-page/:${newParam}`}
                                className="btn ml-1"
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Link>
                            </div>
                          </div>
                            <div className="card-rating py-2">
                                  <span className="badge-2 text-white">{serviceDetails.stars}/5</span>
                                  <span className="font-size-15 color-gold">{t("Flights.FlightBookingPage.average")}</span>
                                  <span className="review__text">{serviceDetails.review}</span>
                            </div>
                          <ul className="list-items list-items-flush py-2">
                            <li className="font-size-15 border-bottom py-1">
                              <span className="w-auto d-block mb-n1 font-size-15">
                                <FontAwesomeIcon icon={faPlane} /> {t("Flights.FlightBookingPage.list1.item1")}
                              </span>
                              <span className="font-size-14 font-weight-regular color-text">{serviceDetails.flight_date} {t("Flights.FlightBookingPage.list1.at")} {serviceDetails.flight_time}</span>
                            </li>
                            <li className="font-size-15 border-bottom py-1">
                              <span className="w-auto d-block color-text font-weight-regular">
                                <FontAwesomeIcon icon={faClock} /> {serviceDetails.duration} {t("Flights.FlightBookingPage.list1.item2")}
                              </span>
                            </li>
                            <li className="font-size-15 border-bottom py-1">
                              <span className="w-auto d-block mb-n1 font-size-15">
                                <FontAwesomeIcon icon={faPlane} /> {t("Flights.FlightBookingPage.list1.item3")}
                              </span>
                              <span className="font-size-14 font-weight-regular color-text">{serviceDetails.arrive_date} at {serviceDetails.arrive_time}</span>
                            </li>
                            <li className="font-size-15  py-2">
                              <span className="w-auto d-block mb-n1">
                                <FontAwesomeIcon icon={faBan} />{" "}
                                {t(
                                  "BookingPage.booking-details.available-cancel"
                                )}
                              </span>
                              {serviceDetails.available_cancel === "1"
                                ? "No"
                                : "Yes"}
                            </li>
                          </ul>
                          <h3 className="title pb-3 border-bottom">
                            {t("BookingPage.order-details.title")}
                          </h3>
                          <ul className="list-items list-items-2 py-3">
                            <li className="font-size-17">
                              <span className="font-weight-medium">{t("Flights.FlightBookingPage.list2.item1")}</span>
                              <span >{i18n.language==="ar"?serviceDetails.plan_air_name_ar:serviceDetails.plan_air_name_en}</span>
                            </li>
                            <li className="font-size-17">
                              <span className="font-weight-medium">{t("Flights.FlightBookingPage.list2.item2")}</span>
                              <span>{localStorage.getItem("flight_type")}</span>
                            </li>
                            <li className="font-size-17">
                              <span className="font-weight-medium">{t("Flights.FlightBookingPage.list2.item3")}</span>
                              <span>{`$${serviceDetails.economic_price}`}</span>
                            </li>
                            <li className="font-size-17 py-1 border-bottom">
                              <span className="font-weight-medium">{t("Flights.FlightBookingPage.list2.item4")}</span>
                              <span >{localStorage.getItem("flight_quantity")}</span>
                            </li>
                            <li className="font-size-17">
                              <span className="font-weight-medium">{t("Flights.FlightBookingPage.list2.item5")}</span>
                              <span>{`$${serviceDetails.tax_price}`}</span>
                            </li>
                            <li className="font-size-17">
                              <span className="font-weight-medium">{t("Flights.FlightBookingPage.list2.item6")}</span>
                              <span>{`$${serviceDetails.economic_price}`}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <NotFoundData />
                  </div>
                )
              ) : (
                <SpinnerComponent />
              )}
            </div>
          </div>
        </div>
      </section>
      {isPress===true?reserveLoading===true?<SpinnerComponent />:null:null}
      <InfoSection />
      <Footer />
    </div>
  );
};

export default FlightBookingPage;
