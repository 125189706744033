import React from 'react'
import Header from '../../../Components/GeneralSections/Header/Header';
import InfoSection from '../../../Components/Sections/InfoSection/InfoSection';
import Footer from '../../../Components/GeneralSections/Footer/Footer';
import { useTranslation } from 'react-i18next';
import FlightsPageHead from '../../../Components/Flights/FlightsPageHead/FlightsPageHead';
import FlightsContainer from '../../../Components/Flights/FlightsContainer/FlightsContainer'
import WhyFlyWithUs from '../../../Components/Flights/WhyFlyWithUs/WhyFlyWithUs';
import { Container } from 'react-bootstrap';
import FlightsDiscountBox from '../../../Components/Flights/FlightsDiscountBox/FlightsDiscountBox';
const FlightsPage = () => {
    // start code to translate
    const [t,i18n]=useTranslation()
    // end code to translate
    let title = "";
    try{
     if(i18n.language=="ar"){
       title="رحلات الطيران"
      }else{
       title="plan flights"
      }
    }catch(e){}
   return (
     <div className='page flights-page services-page'>
       <Header activeFlights={"activeFlights"} />
       <FlightsPageHead/>
       <WhyFlyWithUs />
       <Container>
         <div className='row row-mid my-2'>
             <h2 className='custom-title'>{t("Flights.FlightsPage.container_title")}</h2>
           <div className='col-12'>
            <FlightsContainer />
           </div>
         </div>
       </Container>
       <FlightsDiscountBox />
       <InfoSection />
       <Footer />
     </div>
   )
}

export default FlightsPage