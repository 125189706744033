import {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { GetServiceDetails } from '../../Redux/Actions/ServicesAction';

const ServiceDetailsHook = (service_type,service_id) => {
    const dispatch = useDispatch();
    const response = useSelector((state) => state.ServicesReducer.serviceDetails);
    const loading = useSelector((state) => state.ServicesReducer.serviceDetailsLoading);
    useEffect(() => {
      const get =async()=>{
        await dispatch(GetServiceDetails(service_type,service_id));
      }
      get()
    },[]);

    let serviceDetails =[];
    try{
      if(response){
        serviceDetails=response.data
      }else{
        serviceDetails=[]
      }
    }catch(e){}

    return [serviceDetails,loading]
}

export default ServiceDetailsHook