import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faHandPointUp } from '@fortawesome/free-regular-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
const ServiceHeadSection = ({bg,service_type}) => {
    // start code for translate
const [t,i18n]= useTranslation()
// End code for translate
  return (
    <section className={`page-head-section ${bg}`}>
        <div className='overlay'></div>
        <div className='container fluid'>
        <div className='row h-100 section-content'>
        <div className='left-side col-12'>
        <article className='section-description'>
            <h2 className='title'>{t("ServiceHeadSection.find_the_best")} {t("ServiceHeadSection.that_fits_your_needs")}</h2>
            <p>{t("ServiceHeadSection.your_enjoy")}</p>
        </article>
        <div className='details'>
            <ul>
                <li><FontAwesomeIcon icon={faHandPointUp}/> {t("ServiceHeadSection.details.item1")}</li>
                <li><FontAwesomeIcon icon={faCreditCard}/> {t("ServiceHeadSection.details.item2")}</li>
                <li><FontAwesomeIcon icon={faUserTie}/> {t("ServiceHeadSection.details.item3")}</li>
            </ul>
        </div>
        </div>
        </div>
        </div>
        <svg className="hero-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100 10 100 0 0 10"></polygon>
        </svg>
    </section>
  )
}

export default ServiceHeadSection