import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SpinnerComponent from '../../Utility/SpinnerComponent/SpinnerComponent'
import WishlistHook from '../../../CustomHooks/UserHooks/WishlistHook'
import Pagenation from '../../Utility/Pagenation/Pagenation'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
import AllServiceHook from '../../../CustomHooks/ServicesHooks/AllServiceHook'
import ServiceCard from '../../ServiceComs/ServiceCard/ServiceCard'
const ApartmentsContainer = () => {
    const [allService,loading,onPress,allServiceNum] = AllServiceHook("apartments","8")
    const  [,,wishlistItemsId] = WishlistHook()
  return (
    <Container className='resorts-content resorts-container position-relative'>
        <Row>
        {loading === false ?
              (
                allService?
                allService.data?
                allService.data.length>=1?
                allService.data.map((apartment)=>{
                  return(
                    <Col xs="12" md="6" lg="6" xl="4" key={apartment.id}>
                    <ServiceCard service_type={"apartments"} favItem={wishlistItemsId} service={apartment}/>
                    </Col>
                  )
                })
                :<NotFoundData />
                :<NotFoundData />
                :<NotFoundData />
              )
              :<SpinnerComponent />
              }
              {
                allService?
                allService.data?
                allService.data.length>=1?
                <Pagenation pageCount={allServiceNum} onPress={onPress}/>
                :null
                :null
                :null
              }
        </Row>
    </Container>
  )
}

export default ApartmentsContainer