import { GET_ALL_TOWNS,GET_ERROR } from "../TypesOfActions";

const initialState={
    allTowns:[],
    loading:true,
}
const townsReducer = (state=initialState,action)=>{
switch(action.type){
    case GET_ALL_TOWNS:
        return{
            ...state,
            allTowns:action.payload,
            loading:false
        }
    case GET_ERROR:
        return{
            allTowns:action.payload,
            loading:true
        }
        default:
            return state
}
}

export default townsReducer