import { useState ,useEffect} from "react";
import notify from "../../CustomHooks/Notify/Notify"
import { useDispatch, useSelector } from "react-redux";
import { signUpAction } from "../../Redux/Actions/authAction";
import anonymous from '../../imgs/public/anonymous.jpg'
import { useNavigate } from "react-router-dom";
const SignUpHook = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate()
  const response = useSelector((state) => state.authReducer.createNewUser);
  //states
  const [image, setImage] = useState(anonymous);
  const [firstName, setFristName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setloading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  // function for states
  // when user choose photo this method will store this photo
  const onChangeImage =(event)=>{
    if(event.target.files && event.target.files[0]){
      setImage(URL.createObjectURL(event.target.files[0]))
      setSelectedFile(event.target.files[0])
    }
  }
  const onChangeFirstName = (e) => {
    setFristName(e.target.value);
  };
  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPass = (e) => {
    setConfirmPass(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  const onChangeGender = (e) => {
    setGender(e.target.value)
  };
  /* validation email*/
  const isEmail = (emailAdress) => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex)) return true;
    else return false;
  };
  /*function validation*/
  const validation = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === ""||
      confirmPass===""||
      city===""||
      phone===""||
      gender===""
    ) {
      notify("please complete the data", "error");
    } else if (isEmail(email) === false) {
      notify("Invalid email");
    } else if (phone.length <= 10) {
      notify("please complete the phone number", "error");
    } else if (confirmPass !== password) {
      notify("please check your confirm password", "error");
    }
  };
  /* on submit fn*/
  const onSubmit = async (e) => {
    const formData = new FormData();
    e.preventDefault();
    validation();
    formData.append("image",selectedFile)
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("passwordconfirm", confirmPass);
    formData.append("gender",gender);
    formData.append("city", city);
    setIsPress(true);
    setloading(true);
    await dispatch(signUpAction(formData));
    setloading(false);
    setIsPress(false);
  };
  useEffect(() => {
    if (loading === false) {
      if (response) {
        if (response.status === 200) {
          if(response.data.data){
            localStorage.setItem("NozoulnaUser",JSON.stringify(response.data.data))
            if (response.data.data.token) {
              localStorage.setItem("NozoulnaToken",response.data.data.token);
            }
          }
            notify(`${response.data.message}`,"success");
            setTimeout(() => {
              Navigate("/verifyEmailPage")
            },1000);
          }else if(response.status === 422){
            notify(`${response.data.message}`,"error")
          }
        if (response.data.errors) {
            if(response.data.errors.email!=null){
                notify(`${response.data.errors.email}`,"error")
            }else if(response.data.errors.image!=null){
                notify(`${response.data.errors.image}`,"error")
            }else if(response.data.errors.password!=null){
                notify(`${response.data.errors.password}`,"error")
            }
        }
      }
    }
  }, [loading]);
  return [
    firstName,lastName,password,confirmPass,email,city,gender,phone,image,onChangeImage,
    onChangeFirstName,onChangeLastName,onChangePassword,onChangeConfirmPass
    ,onChangeCity,onChangeGender,onChangePhone,onChangeEmail,loading,isPress,onSubmit
  ];
};

export default SignUpHook;
