import {useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getAllQuestions } from '../../Redux/Actions/questionsAction'
const QuestionsHook = () => {
    const dispatch = useDispatch()
    const res = useSelector(state =>state.QuestionsReducer.allQuestions)
    const loading = useSelector(state =>state.QuestionsReducer.loading)
    useEffect(()=>{
        const get =async()=>{
            await dispatch(getAllQuestions())
        }
        get()
    },[])
    let questions =[];
    try{
        if(res){
            questions=res.data
        }
        else{
            questions=[]
        }
    }   
    catch(e){}
    return [loading,questions]
}

export default QuestionsHook