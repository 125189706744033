/* import types of actions*/
import { GET_ALL_FLIGHTS,GET_ERROR,GET_FLIGHT_DETAILS,GET_SEARCH_RESULT_FLIGHTS, MAKE_FLIGHT_RESERVATION } from "../TypesOfActions";

/* initial state in an object*/
const initialState = {
  allFlights: [],
  flightDetails:[],
  searchResultFlights: [],
  flightReservation:[],
  loading: true,
};

const FlightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FLIGHTS:
      return {
        ...state,
        allFlights:action.payload,
        loading: false,
        }
        case GET_SEARCH_RESULT_FLIGHTS:
      return {
        allFlights:action.payload,
        loading: false,
        }
    case GET_FLIGHT_DETAILS:
      return {
        ...state,
        flightDetails:action.payload,
        loading: false,
        }
        case MAKE_FLIGHT_RESERVATION:
          return{
            flightReservation:action.payload,
            loading:false,
          }
        case GET_ERROR:
            return{
                allFlights:action.payload,
                flightDetails:action.payload,
                searchResultFlights:action.payload,
                loading:true
            }
            default:
                return state
    }

};
export default FlightsReducer;
