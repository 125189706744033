import React from "react";
import "./ForgetPasswordPage.css";
import Header from "../../Components/GeneralSections/Header/Header";
import Footer from "../../Components/GeneralSections/Footer/Footer";
import SpinnerComponent from "../../Components/Utility/SpinnerComponent/SpinnerComponent";
import BtnComponent from "../../Components/Utility/BtnComponent/BtnComponent";
import ForgetPasswordHook from "../../CustomHooks/Auth/ForgetPasswordHook";
import BreadCrumbSection from "../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import { useTranslation } from "react-i18next";
const ForgetPasswordPage = () => {
  const [email, onChangeEmail, onSubmit, loading, isPress] =
    ForgetPasswordHook();
    //start code for translate
    const [t,i18n]=useTranslation()
    //End code for translate
    let title="";
    try{
      if(i18n.language==="ar"){
        title="إستعادة كلمة المرور"
      }else{
        title="Recover password"
      }
    }catch(e){}
  return (
    <>
      <Header />
      <BreadCrumbSection serviceDetails={""} loading={false} bg={"bread-bg-8"} title={title} />
      <div className="page forget-password-page">
        <section className="contact-area padding-top-100px padding-bottom-70px">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto">
                <div className="form-box recover-box-parent">
                  <div className="form-title-wrap">
                    <h3 className="title">{t("forgetPasswordPage.title")}</h3>
                    <p className="font-size-15 pt-2">
                    {t("forgetPasswordPage.description")}
                    </p>
                  </div>
                  <div className="form-content ">
                    <div className="contact-form-action">
                      <form method="post">
                        <div className="input-box recover-box">
                          <label className="label-text">{t("forgetPasswordPage.email")}</label>
                          <div className="form-group">
                            <span className="la la-envelope-o form-icon"></span>
                            <input
                            value={email}
                            onChange={onChangeEmail}
                              className="custom-input"
                              type="email"
                              name="email"
                              placeholder={t("forgetPasswordPage.email")}
                            />
                          </div>
                        </div>
                        <div className="btn-box" onClick={onSubmit}>
                          <BtnComponent value={t("forgetPasswordPage.send")}/>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isPress ===true? loading === true ? <SpinnerComponent /> : null : null}
      </div>
        <Footer />
    </>
  );
};

export default ForgetPasswordPage;
