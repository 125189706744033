import React from 'react'
import './BtnComponent.css'
const BtnComponent = ({value,url,icon,type}) => {
  return (
        <button type={type} className='btn btn-component transition'>
                {icon} {value}
        </button>
  )
}

export default BtnComponent