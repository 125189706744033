import React,{useMemo,useState} from 'react'
import './Map.css'
import { GoogleMap,useLoadScript,Marker } from '@react-google-maps/api' 
import SpinnerComponent from '../SpinnerComponent/SpinnerComponent'

const Map = ({service}) => {
    // google maps implementation
const {isLoaded}=useLoadScript({
    googleMapsApiKey:"AIzaSyCA_d7wsXFrxjXQEjOVByCFzbfnVR1-4uk",
})
const main_center = useMemo(()=>({lat:26,lng:32}),[])
const center = useMemo(()=>({lat:Number(service.lat),lng:Number(service.long)}),[service])
const storage_center = useMemo(()=>({lat:+localStorage.getItem("lat"),lng:+localStorage.getItem("long")}),[localStorage.getItem("lat")])
const [lat,set_lat]=useState("")
const [long,set_long]=useState("")
  return (
    <section className='google-map'>
         {
                isLoaded?
                service.lat !==null && service.long!==null?
                <GoogleMap
                onClick={ev => {
                    set_lat(ev.latLng.lat())
                    set_long(ev.latLng.lng())
                    localStorage.setItem("lat",ev.latLng.lat())
                    localStorage.setItem("long",ev.latLng.lng())
                  }}
                zoom={10}
                center={center}
                mapContainerClassName='map-container'>
                <Marker position={center}/>
                <Marker position={storage_center}/>
                </GoogleMap>
                :
                <GoogleMap
                onClick={ev => {
                    set_lat(ev.latLng.lat())
                    set_long(ev.latLng.lng())
                    localStorage.setItem("lat",ev.latLng.lat())
                    localStorage.setItem("long",ev.latLng.lng())
                  }}
                zoom={5}
                center={main_center}
                mapContainerClassName='map-container'>
                <Marker position={storage_center}/>
                </GoogleMap>
                :
                <SpinnerComponent />
            }
    </section>
  )
}

export default Map