import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faStar } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useTranslation } from 'react-i18next'
import CarsSearchHook from "../../../CustomHooks/CarsHooks/CarsSearchHook";
import AllCarsHook from "../../../CustomHooks/CarsHooks/AllCarsHook";

function CarsFilterBar() {
    const [,,,,,,,minPrice,maxPrice,,,,,,,,,,onChangeStars,,,,
        ,onChangeMinPrice,onChangeMaxPrice,onChange_is_Luxury,onChange_is_delivery,
        onChange_is_sunroof,onChange_is_four_push,onChange_is_front_push,
        onChange_is_back_push,onChange_is_full_options,
        onChange_is_half_options,,]=CarsSearchHook()
  // all cars hook 
  const [,,,,countOfcars] = AllCarsHook()
  //========================================================================
  //Start range slider
  const [value, setValue] = useState([0, 1000]);
  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };
  //End range slider
  //========================================================================
   // start code to translate
   const [t,i18n]=useTranslation()
   // end code to translate
  return (
    <div className="filter-bar cars-filter-bar">
      <div className="filter-wrap">
        <div className="row">
          <div className="col-xs-12">
            <div className="filter-top d-flex align-items-center justify-content-between">
              <div className="filter-bar-title">
                <h3 className="title">
                  <span>{t("filterBar.title")}</span> <span>{countOfcars}</span>
                </h3>
                <p className="font-size-14">{t("filterBar.description")}</p>
              </div>
              <div className="layout-view d-flex align-items-center">
                <a
                  href="hotel-grid.html"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Grid View"
                >
                  <i className="la la-th-large"></i>
                </a>
                <a
                  href="hotel-list.html"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="List View"
                  className="active"
                >
                  <i className="la la-th-list"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="filter-bottom d-flex align-items-center justify-content-between">
              <div className="filter-bar-filter d-flex flex-wrap align-items-center">
                <div className="row">
                  <div className="col-xs-12 col-md-6 col-lg-3">
                    <div className="filter-option">
                      <h3 className="title">{t("filterBar.filterOptions.filter-option-one.title")}</h3>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-lg-3">
                    <div className="filter-option">
                      <div className="custom-dropdown">
                        <button className="custom-dropdown-btn">
                        {t("filterBar.filterOptions.filter-option-two.title")} <FontAwesomeIcon icon={faAngleDown} />
                        </button>
                        <div className={`custom-dropdown-content-wrapper filter-price-content-wrapper`}>
                          <div className="custom-dropdown-content">
                            <div className="slider-range-wrap">
                              <div className="range-slider">
                                <p className="filter-price">
                                <span> {t("filterBar.filterOptions.filter-option-two.title")}</span>
                                  <span className="parent-span">
                                    <span className="value-0">{`$${minPrice}`}</span>
                                    <span>-</span>
                                    <span className="value-1">{`$${maxPrice}`}</span>
                                  </span>
                                </p>
                                <Box sx={{ width: "100%" }}>
                                  <Slider
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                  />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-lg-3">
                    <div className="filter-option">
                      <div className="custom-dropdown">
                        <button className="custom-dropdown-btn">
                        {t("filterBar.filterOptions.filter-option-three.title")} <FontAwesomeIcon icon={faAngleDown} />
                        </button>
                        <div className={`custom-dropdown-content-wrapper`}>
                          <div className="custom-dropdown-content">
                            <label className="custom-checkbox">
                              <span className="ratings d-flex align-items-center">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <span className="color-text-3 font-size-13 ml-1">
                                  (5)
                                </span>
                              </span>
                              <input
                                value={5}
                                onChange={onChangeStars}
                                type="checkbox"
                                id="r6"
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-checkbox">
                              <span className="ratings d-flex align-items-center">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <span className="color-text-3 font-size-13 ml-1">
                                  (4)
                                </span>
                              </span>
                              <input
                                defaultValue={4}
                                onChange={onChangeStars}
                                type="checkbox"
                                id="r6"
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-checkbox">
                              <span className="ratings d-flex align-items-center">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <span className="color-text-3 font-size-13 ml-1">
                                  (3)
                                </span>
                              </span>
                              <input
                                defaultValue={3}
                                onChange={onChangeStars}
                                type="checkbox"
                                id="r6"
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-checkbox">
                              <span className="ratings d-flex align-items-center">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <span className="color-text-3 font-size-13 ml-1">
                                  (2)
                                </span>
                              </span>
                              <input
                                defaultValue={2}
                                onChange={onChangeStars}
                                type="checkbox"
                                id="r6"
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="custom-checkbox">
                              <span className="ratings d-flex align-items-center">
                                <FontAwesomeIcon icon={faStar} />
                                <span className="color-text-3 font-size-13 ml-1">
                                  (1)
                                </span>
                              </span>
                              <input
                                defaultValue={1}
                                onChange={onChangeStars}
                                type="checkbox"
                                id="r6"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-lg-3">
                    <div className="filter-option">
                      <div className="custom-dropdown">
                        <button className="custom-dropdown-btn">
                        {t("filterBar.filterOptions.filter_option_five.title")} <FontAwesomeIcon icon={faAngleDown} />
                        </button>
                        <div className={`custom-dropdown-content-wrapper custom-dropdown-content-wrapper-categories`}>
                          <div className="custom-dropdown-content">
                          <div className="checkbox-wrap">
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.delivery")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_delivery}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.luxury")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_Luxury}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.sunroof")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_sunroof}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.front_push")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_front_push}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.back_push")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_back_push}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.four_push")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_four_push}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.full_options")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_full_options}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.half_options")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_half_options}/>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarsFilterBar;
