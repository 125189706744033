import React from 'react'
import './AboutUsPage.css'
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from '../../../Components/Sections/BreadCrumbSection/BreadCrumbSection'
const AboutUsPage = () => {
  return (
    <div className='about-us-page'>
        <Header />
        <BreadCrumbSection serviceDetails={""} loading={false} bg={"bread-bg-8"} title={"About us"} />
        <div className='page'>

        </div>
        <Footer />
    </div>
  )
}

export default AboutUsPage