import React from 'react'
import './HotelDetailsSidebar.css'
/*import redux*/
import ServicePriceSection from '../../Sections/ServicePriceSection/ServicePriceSection'
import BookingInfoSection from '../BookingInfoSection/BookingInfoSection';
import AddSuggestionSection from '../../Sections/AddSuggestionSection/AddSuggestionSection';
import AddQuestionSection from '../../Sections/AddQuestionSection/AddQuestionSection';
import GetAQuestionSection from '../../Sections/GetAQuestionSection/GetAQuestionSection';
import WhyBookWithUsSection from '../../Sections/WhyBookWithUsSection/WhyBookWithUsSection';
const HotelDetailsSidebar = ({serviceDetails,loading,service_id}) => {
  return (
    <>
      <div className="sidebar single-content-sidebar mb-0">
                <div className="sidebar-widget sidebar-widget-1 single-content-widget">
                  <div className="sidebar-widget-item">
                   <ServicePriceSection serviceDetails={serviceDetails} loading={loading}/>
                  </div>
                  <div className="sidebar-widget-item">
                   <BookingInfoSection id={service_id}/>
                  </div>
                </div>
                <div className="sidebar-widget sidebar-widget-2 single-content-widget">
                  <AddSuggestionSection />
                </div>
                <div className="sidebar-widget sidebar-widget-3 single-content-widget">
                <WhyBookWithUsSection />
                </div>
                <div className="sidebar-widget sidebar-widget-4 single-content-widget">
                 <GetAQuestionSection />
                </div>
                <div className="sidebar-widget sidebar-widget-2 single-content-widget">
                  <AddQuestionSection />
                </div>
              </div>
    </>
  )
}

export default HotelDetailsSidebar