import {useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { GetAllCarsAction, GetSearchResultCars } from '../../Redux/Actions/CarsActions'

const AllCarsHook = () => {
const dispatch = useDispatch()
const response = useSelector((state)=>state.CarsReducer.allCars)
const loading = useSelector((state)=>state.CarsReducer.loading)

useEffect(()=>{
  const get =async()=>{
    await dispatch(GetAllCarsAction())
  }
  get()
},[])

let allCars=[];
try{
  if(response){
    if(response.data){
        allCars=response.data
    }
  }else{
    allCars=[]
  }
}catch(e){}

 //on press function for pagenation
 const onPress = async (page) => {
  await dispatch(GetSearchResultCars(`page=${page}`));
};

let allCarsNum=0;
try{
  if(response){
    if(response.data){
        allCarsNum=response.data.last_page
    }
  }else{
    allCarsNum=0
  }
}catch(e){}

let countOfcars=0;
try{
    if(response){
        if(response.data){
            countOfcars = response.data.data.length
        }else{
            countOfcars = 0;
        }
    }
}catch(e){}

return [allCars,loading,onPress,allCarsNum,countOfcars]
}
export default AllCarsHook