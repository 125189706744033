import React from 'react'
import './MobileAppSection.css'
import mobile_app_img from '../../../../imgs/public/65968.jpg'
import google_play_img from '../../../../imgs/google-play.png'
import app_store_img from '../../../../imgs/app-store.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const MobileAppSection = () => {
  // start code to translate
  const [t,i18n]=useTranslation()
  // end code to translate
  return (
    <section className="mobile-app-section section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mobile-app-content">
              <div className="section-heading">
                <h2 className="sec__title line-height-55">
                  {t("mobileAppSection.title")}
                </h2>
              </div>
              <ul className="info-list padding-top-30px">
                <li className="d-flex align-items-start mb-3">
                  <span className="la-check icon-element flex-shrink-0 "><FontAwesomeIcon icon={faCheck}/></span>
                  {t("mobileAppSection.list.item1")}
                </li>
                <li className="d-flex align-items-start mb-3">
                  <span className="la-check icon-element flex-shrink-0 "><FontAwesomeIcon icon={faCheck}/></span>
                  {t("mobileAppSection.list.item2")}
                </li>
                <li className="d-flex align-items-start mb-3">
                  <span className="la-check icon-element flex-shrink-0"><FontAwesomeIcon icon={faCheck}/></span>
                  {t("mobileAppSection.list.item3")}
                </li>
              </ul>
              <div className="btn-box padding-top-30px">
                <a target= '_blank' rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.khatab.nozoluna" className="d-inline-block m-3">
                  <img src={app_store_img} alt="app_store_img" loading='lazy'/>
                </a>
                <a target= '_blank' rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.khatab.nozoluna" className="d-inline-block m-3">
                  <img src={google_play_img} alt="google_play_img" loading='lazy'/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mobile-img">
              <img src={mobile_app_img} alt="mobile-img" loading='lazy'/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileAppSection