import { CustomAxios } from "../Api/baseUrl";

/*this is custom hook called useGetData like useEffect and other hooks*/

/* if we need to post data without images*/
const PostDataHook = async(url,params)=>{
    /*
     url for our url from api
     params if we need to put a params 
     params => {key:"value"}
    */
    const response = await CustomAxios.post(url,params)
    return response
}
const PostDataHookToken = async(url,params)=>{
     const config ={
        headers: {"Authorization" : `Bearer ${localStorage.getItem("NozoulnaToken")}`} 
      }
    const response = await CustomAxios.post(url,params,config)
    return response
}

/*if we need to post data with images and dependency token*/
const PostDataHookWithImageToken = async(url,params)=>{
    const config ={
        headers:{"Content-type":"multipart/form-data",
                "Authorization" : `Bearer ${localStorage.getItem("NozoulnaToken")}`}
    }
    const res = await CustomAxios.post(url,params,config)
    return res
}
/*if we need to post data with images*/
const PostDataHookWithImage = async(url,params)=>{
    const config ={
        headers:{"Content-type":"multipart/form-data"}
    }
    const res = await CustomAxios.post(url,params,config)
    return res
}

export {PostDataHook,PostDataHookToken,PostDataHookWithImage,PostDataHookWithImageToken};