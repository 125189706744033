import React from 'react'
import './TownsSection.css'
import TownCard from '../../../TownCard/TownCard'
import TownsHook from '../../../../CustomHooks/TownsHook/TownsHook'
import SpinnerComponent from '../../../Utility/SpinnerComponent/SpinnerComponent'
import NotFoundData from '../../../Utility/NotFoundData/NotFoundData'
import { useTranslation } from 'react-i18next'
const TownsSection = () => {
  const [loading,towns] = TownsHook()
  const [t,i18n]=useTranslation()
  return (
    <section className='towns-section section'>
        <div className="container">
        <div className="row align-items-center">
          <div className='col-12'>
          <div className="section-heading text-center">
              <h2 className="sec__title custom-title">{t("townsSection.title")}</h2>
              <p className="sec__desc pt-3">
              {t("townsSection.description")}
              </p>
            </div>
          </div>
        </div>
       <div className='row towns-cards'>
          {
            loading===false?
            towns?
            towns.map((item,index)=>{return(
              <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 my-2">
              <TownCard item={item}/>
          </div>
            )})
            :<NotFoundData />
            :<SpinnerComponent />
          }
       </div>
      </div>
    </section>
  )
}

export default TownsSection