import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {subscribe } from '../../../Redux/Actions/userActions'
import Notify from '../../Notify/Notify'
const SubscribeHook = () => {
    const dispatch = useDispatch()
    const response = useSelector((state)=>state.UserReducer.subscribe)

    const [email,setEmail] = useState("")
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    /* functions of change states*/
    const onChangeEmail = (e)=>{
        setEmail(e.target.value)
    }
    /*submit functoin*/
    const onSubmit =async(e)=>{
       if(localStorage.getItem("NozoulnaToken")!==null){
        e.preventDefault()
        //validation function
        if(email===""){
            Notify("please enter your email","warn")
            return;
        }else{
        const formData = new FormData()
        formData.append("email",email)
        setLoading(true)
        setIsPress(true)
        await dispatch(subscribe(formData))
        setLoading(false)
        setIsPress(false)
        }
       }else{
        Notify("please login")
       }
    }
    /*useEffect to handle errors and reset the states*/
    useEffect(()=>{
       try{
        if(loading===false){
            if(response.data){
                console.log(response)
                if(response.status===200){
                    Notify(`${response.data.message}`,"success")
                    setEmail("")
                }else if(response.code===400){
                    Notify(`${response.data.message}`,"error")
                }else{
                    Notify(`${response.data.message}`,"error")
                }
            }
        }
       }catch(e){}
    },[loading])

    return [email,onChangeEmail,onSubmit,loading,isPress]
}

export default SubscribeHook