/*
    Redux store
*/
    import { createStore,applyMiddleware} from "redux"; /* to create the store and our middleware*/
    import thunk from "redux-thunk"; /* thunk is a middleware*/
    import RootReducer from "./Reducers/RootReducer"
    /* combine all of reducers*/

/* initial state*/
const initialState = {}
/* thunk => to solve problems of async and await*/
const middleware = [thunk]
const Store = createStore(RootReducer,initialState,applyMiddleware(...middleware))

export default Store