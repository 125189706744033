import React, { useState } from "react";
import "./UserDashboardNav.css";
import anonymous from "../../../imgs/public/anonymous.jpg";
import { Link } from "react-router-dom";
import logo from "../../../imgs/public/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faGear,
  faPowerOff,
  faShoppingCart,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import UserNotificatoinsHook from "../../../CustomHooks/UserHooks/UserNotificatoinsHook";
import DeleteAcountHook from "../../../CustomHooks/Auth/DeleteAcountHook";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
const UserDashboardNav = ({
  profilePageActive,
  bookingPageActive,
  wishlistPageActive,
  settingsPageActive,
  reviewsPageActive,
}) => {
  const [showMobileM, setShowMobielM] = useState("");
  const handleMobileMenuShow = () => {
    showMobileM === "" ? setShowMobielM("show") : setShowMobielM("");
  };
  let user = {};
  if (localStorage.getItem("NozoulnaUser") != null) {
    user = JSON.parse(localStorage.getItem("NozoulnaUser"));
  } else {
    user = {};
  }
  const [nots] = UserNotificatoinsHook();
  /*Start logout function*/
  const logOut = () => {
    if (localStorage.getItem("NozoulnaUser") != null) {
      localStorage.removeItem("NozoulnaUser");
    }
    if (localStorage.getItem("NozoulnaToken") != null) {
      localStorage.removeItem("NozoulnaToken");
    }
    if(localStorage.getItem("flight_quantity")!==null){
      localStorage.removeItem("flight_quantity")
  }
  if(localStorage.getItem("room_id")!==null){
      localStorage.removeItem("room_id")
  }
  if(localStorage.getItem("resort_room_id")!==null){
      localStorage.removeItem("resort_room_id")
  }
  if(localStorage.getItem("apartment_room_id")!==null){
      localStorage.removeItem("apartment_room_id")
  }
  if(localStorage.getItem("start_date")!==null){
      localStorage.removeItem("start_date")
  }
  if(localStorage.getItem("end_date")!==null){
      localStorage.removeItem("end_date")
  }
  if(localStorage.getItem("partner_two")!==null){
      localStorage.removeItem("partner_two")
  }
  if(localStorage.getItem("partner_Three")!==null){
      localStorage.removeItem("partner_Three")
  }
  if(localStorage.getItem("partners_Qty")!==null){
      localStorage.removeItem("partners_Qty")
  }
  if(localStorage.getItem("resort_partners_Qty")!==null){
      localStorage.removeItem("resort_partners_Qty")
  }
  if(localStorage.getItem("apartment_partners_Qty")!==null){
      localStorage.removeItem("apartment_partners_Qty")
  }
  if(localStorage.getItem("adults")!==null){
      localStorage.removeItem("adults")
  }
  if(localStorage.getItem("apartment_adults")!==null){
      localStorage.removeItem("apartment_adults")
  }
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };
  /*End logout function*/
  // start code for delete acount
  const [onDeleteAcount] = DeleteAcountHook();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // End code for delete acount
   // start code for translate
   const [t,i18n]= useTranslation()
   // End code for translate
  return (
    <section className="user-dashboard-nav">
      <div className="dashboard-area">
        <div className="dashboard-nav">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-wrapper">
                  <div className="left-side">
                      <Link to="/">
                    <div className="logo-content">
                        <img className="logo" src={logo} alt="logo" loading='lazy'/>
                    </div>
                      </Link>
                  </div>
                  <div className="right-side">
                    <div className="nav-btn ml-auto d-none d-md-block">
                      <div className="notification-wrap d-flex align-items-center">
                        <div className="notifications mr-2">
                          <div className="filter-option">
                            <div className="custom-dropdown">
                              <button className="custom-dropdown-btn">
                                <FontAwesomeIcon icon={faBell} />
                                {nots ? (
                                  nots.length >= 1 ? (
                                    <span>{nots.length}</span>
                                  ) : null
                                ) : null}
                              </button>
                              <div
                                className={`custom-dropdown-content-wrapper`}
                              >
                                <div className="custom-dropdown-content">
                                  <ul className="notification-menu list-items">
                                    {nots ? (
                                      nots.length >= 1 ? (
                                        nots.map((not) => {
                                          return (
                                            <li className="list-item-1">
                                              <div className="notification-icon">
                                                <FontAwesomeIcon
                                                  icon={faBell}
                                                />
                                              </div>
                                              <div className="notification-content">
                                                <h5>{not}</h5>
                                                <p>{not}</p>
                                              </div>
                                            </li>
                                          );
                                        })
                                      ) : (
                                        <div className="no-nots">
                                          {t("userDashboardNav.no-notifications")}
                                        </div>
                                      )
                                    ) : (
                                      <div className="no-nots">
                                        {t("userDashboardNav.no-notifications")}
                                      </div>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="user-list">
                          <div className="custom-dropdown">
                            {user !== null ? (
                              <button className="custom-dropdown-btn">
                                {user.attachment_relation &&
                                user.attachment_relation.length > 0 ? (
                                  <div className="avatar avatar-sm flex-shrink-0 mr-2">
                                    <img
                                      src={user.attachment_relation[0].url}
                                      alt="team-img"
                                      loading='lazy'
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar avatar-sm flex-shrink-0 mr-2">
                                    <img src={anonymous} alt="anonymous" loading='lazy'/>
                                  </div>
                                )}
                                {user !== null ? (
                                  user.first_name && user.last_name ? (
                                    <span>
                                      {user.first_name} {user.last_name}
                                    </span>
                                  ) : (
                                    <span>......</span>
                                  )
                                ) : null}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="user-menu-open-wrapper d-block d-lg-none"
                      onClick={handleMobileMenuShow}
                    >
                      <div className="user-menu-open custom-icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                    </div>
                    <div
                      className={`mobile-menu-wrapper transition ${showMobileM}`}
                    >
                      <div
                        className="mobile-menu-wrapper-close custom-icon"
                        onClick={handleMobileMenuShow}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                      <div className="mobile-menu-wrapper-content">
                        <div className="user-list my-4">
                          <ul className="list-menu list-items">
                            <li className={`${profilePageActive} list-item-2`}>
                              <Link to="/user-profile-page">
                                <FontAwesomeIcon icon={faUser} /> {t("userProfileSidebar.my-profile")}
                              </Link>
                            </li>
                            <li className={`${bookingPageActive} list-item-2`}>
                              <Link to="/user-booking-page">
                                <FontAwesomeIcon icon={faShoppingCart} /> {t("userProfileSidebar.my-booking")}
                              </Link>
                            </li>
                            <li className={`${reviewsPageActive} list-item-3`}>
                              <Link to="/user-reviews-page">
                                <FontAwesomeIcon icon={faStar} /> {t("userProfileSidebar.my-reviews")}
                              </Link>
                            </li>
                            <li className={`${wishlistPageActive} list-item-4`}>
                              <Link to="/user-wishlist-page">
                                <FontAwesomeIcon icon={faHeart} /> {t("userProfileSidebar.wishlist")}
                              </Link>
                            </li>
                            <li className={`${settingsPageActive} list-item-5`}>
                              <Link to="/user-setting-page">
                                <FontAwesomeIcon icon={faGear} /> {t("userProfileSidebar.settings")}
                              </Link>
                            </li>
                            <li
                              className={`sidebar-menu-item-6`}
                              onClick={logOut}
                            >
                              <Link>
                                <FontAwesomeIcon icon={faPowerOff} /> {t("userProfileSidebar.logout")}
                              </Link>
                            </li>
                            <li
                              className={`sidebar-menu-item-7`}
                              onClick={handleShow}
                            >
                              <Link>
                                <FontAwesomeIcon icon={faTrash} /> {t("userProfileSidebar.delete-acount")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="notifications my-4">
                          <h4>{t("userDashboardNav.notifications")}</h4>
                          <ul className="notification-menu list-items">
                            {nots ? (
                              nots.length >= 1 ? (
                                nots.map((not) => {
                                  return(
                                    <li className="list-item-1">
                                    <div className="notification-icon">
                                      <FontAwesomeIcon icon={faBell} />
                                    </div>
                                    <div className="notification-content">
                                      <h5>{not}</h5>
                                      <p>{not}</p>
                                    </div>
                                  </li>
                                  )
                                })
                              ) : (
                                <div className="no-nots">{t("userDashboardNav.no-notifications")}</div>
                              )
                            ) : (
                              <div className="no-nots">{t("userDashboardNav.no-notifications")}</div>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       {/*Start modal for confirm delete acount*/}
       <Modal show={show} onHide={handleClose}>
                  <Modal.Header>
                    <Modal.Title>{t("userProfileSidebar.modal.title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {t("userProfileSidebar.modal.body")}
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="btn-box" onClick={handleClose}>
                      <BtnComponent value={t("userProfileSidebar.modal.cancel")} />
                    </div>
                    <div className="btn-box" onClick={onDeleteAcount}>
                      <BtnComponent value={t("userProfileSidebar.modal.delete-acount")} />
                    </div>
                  </Modal.Footer>
      </Modal>
      {/*End modal for confirm delete acount*/}
    </section>
  );
};

export default UserDashboardNav;
