import React from 'react'
import './TownCard.css'
import { useTranslation } from 'react-i18next'
import NotFountData from '../../Components/Utility/NotFoundData/NotFoundData'
const TownCard = ({item}) => {
  const [t,i18n]=useTranslation()
  return (
    <div className='town-card transition'>
              {
                item && item.image && item.name_en?
               <>
                <div className="card-img">
                <div className='overlay'></div>
                <img loading="lazy" src={`https://multi.nozoluna.com/public/dash/assets/img/${item.image}`} alt="town-img" />
                <span className="badge">{i18n.language==="ar"?item.name_ar:item.name_en}</span>
              </div>
                <div className="card-body transition">
                <h3 className="card-title">
                  <p>{i18n.language==="ar"?item.name_ar:item.name_en}</p>
                </h3>
              </div>
               </>
                :
                <NotFountData/>
              }
             
    </div>
  )
}

export default TownCard