import React,{useEffect} from 'react';
import './css/App.css';
import HomePage from './Pages/HomePage/HomePage';
import ScrollToTopBtn from './Components/Utility/ScrollToTopBtn/ScrollToTopBtn';
import { BrowserRouter, Navigate, Route,Routes } from 'react-router-dom';
import HotelsPage from './Pages/Hotels/HotelsPage/HotelsPage';
import HotelDetailsPage from './Pages/Hotels/HotelDetailsPage/HotelDetailsPage';
import UserProfilePage from './Pages/User/UserProfilePage/UserProfilePage';
import UserBookingPage from './Pages/User/UserBookingPage/UserBookingPage';
import UserReviewsPage from './Pages/User/UserReviewsPage/UserReviewsPage';
import UserSettingPage from './Pages/User/UserSettingPage/UserSettingPage';
import UserWishlistPage from './Pages/User/UserWishlistPage/UserWishlistPage';
import SearchResultPage from './Pages/Hotels/SearchResultPage/SearchResultPage';
import PaymentComplete from './Pages/PaymentPage/PaymentComplete'
import PaymentReceived from './Pages/PaymentPage/PaymentRecieved'
import HotelBookingPage from './Pages/Hotels/HotelBookingPage/HotelBookingPage'
import TourDetailsPage from './Pages/TourDetailsPage/TourDetailsPage';
import ForgetPasswordPage from './Pages/Auth/ForgetPasswordPage';
import VerifyCodePage from './Pages/Auth/VerifyCodePage';
import ResetPasswordPage from './Pages/Auth/ResetPasswordPage';
import AboutUsPage from './Pages/CompanyPages/AboutUsPage/AboutUsPage';
import SupportPage from './Pages/CompanyPages/SupportPage/SupportPage';
import VerifyEmailPage from './Pages/Auth/VerifyEmailPage';
import HousesPage from './Pages/Houses/HousesPage/HousesPage';
import HouseDetailsPage from './Pages/Houses/HouseDetailsPage/HouseDetailsPage';
import HouseBookingPage from './Pages/Houses/HouseBookingPage/HouseBookingPage';
import HouseSearchResultPage from './Pages/Houses/HouseSearchResultPage/HouseSearchResultPage';
import RestsPage from './Pages/Rests/RestsPage/RestsPage';
import RestDetailsPage from './Pages/Rests/RestDetailsPage/RestDetailsPage';
import RestBookingPage from './Pages/Rests/RestBookingPage/RestBookingPage';
import RestsSearchResultPage from './Pages/Rests/RestsSearchResultPage/RestsSearchResultPage';
import ResortsPage from './Pages/Resorts/ResortsPage/ResortsPage';
import ResortBookingPage from './Pages/Resorts/ResortBookingPage/ResortBookingPage';
import ResortDetailsPage from  './Pages/Resorts/ResortDetailsPage/ResortDetailsPage'
import ResortsSearchResultPage from './Pages/Resorts/ResortsSearchResultPage/ResortsSearchResultPage'
import ApartmentsPage from './Pages/Apartments/ApartmentsPage/ApartmentsPage';
import ApartmentDetailsPage from './Pages/Apartments/ApartmentDetailsPage/ApartmentDetailsPage';
import ApartmentBookingPage from './Pages/Apartments/ApartmentBookingPage/ApartmentBookingPage';
import ApartmentsSearchResultPage from './Pages/Apartments/ApartmentsSearchResultPage/ApartmentsSearchResultPage';
import { useTranslation } from 'react-i18next';
import CarsPage from './Pages/Cars/CarsPage/CarsPage';
import CarsSearchResultPage from './Pages/Cars/CarsSearchResultPage/CarsSearchResultPage';
import CarDetailsPage from './Pages/Cars/CarDetailsPage/CarDetailsPage';
import CarBookingPage from './Pages/Cars/CarBookingPage/CarBookingPage';
import CarPaymentRecievedPage from './Pages/Cars/CarPaymentRecievedPage/CarPaymentRecievedPage';
import CarPaymentCompletePage from './Pages/Cars/CarPaymentCompletePage/CarPaymentCompletePage';
import FlightsPage from './Pages/Flights/FlightsPage/FlightsPage';
import FlightsSearchResultPage from './Pages/Flights/FlightsSearchResultPage/FlightsSearchResultPage';
import FlightDetailsPage from './Pages/Flights/FlightDetailsPage/FlightDetailsPage';
import { ToastContainer } from 'react-toastify';
import FlightBookingPage from './Pages/Flights/FlightBookingPage/FlightBookingPage';
import FlightPaymentRecievedPage from './Pages/Flights/FlightPaymentRecievedPage/FlightPaymentRecievedPage';
import FlightPaymentCompletePage from './Pages/Flights/FlightPaymentCompletePage/FlightPaymentCompletePage';
import TripsPage from './Pages/Trips/TripsPage/TripsPage';
import TripDetailsPage from './Pages/Trips/TripDetailsPage/TripDetailsPage';
import TripBookingPage from './Pages/Trips/TripBookingPage/TripBookingPage';
import TripsSearchResultPage from './Pages/Trips/TripsSearchResultPage/TripsSearchResultPage';
import ProtectRouts from './Components/Utility/ProtectRouts/ProtectRouts';
import ProtectRoutsHook from './CustomHooks/Auth/ProtectRoutsHook';
function App() {
  const[t,i18n]=useTranslation()
  useEffect(()=>{
    document.documentElement.dir=localStorage.getItem("dir")
    document.documentElement.lang=localStorage.getItem("lang")
    i18n.changeLanguage(localStorage.getItem("lang"))
  },[])
  // protect routs hook
  const [,isUser]= ProtectRoutsHook()
  return (
    <div className='App'>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />}/>
        <Route path='/tour-details-page' element={<TourDetailsPage />}/>
        <Route path='/forgetPasswordPage' element={<ForgetPasswordPage />}/>
        <Route path='/verifyCodePage' element={<VerifyCodePage />}/>
        <Route path='/resetPasswordPage' element={<ResetPasswordPage />}/>
        <Route path='/verifyEmailPage' element={<VerifyEmailPage />}/>
        <Route path='/about-us-page' element={<AboutUsPage />}/>
        <Route path='/support-page' element={<SupportPage />}/>
        <Route element={<ProtectRouts auth={isUser}/>}>
        <Route path='/user-profile-page' element={<UserProfilePage />}/>
        <Route path='/user-booking-page' element={<UserBookingPage />}/>
        <Route path='/user-reviews-page' element={<UserReviewsPage />}/>
        <Route path='/user-setting-page' element={<UserSettingPage />}/>
        <Route path='/user-wishlist-page' element={<UserWishlistPage />}/>
        <Route path='/payment-complete' element={<PaymentComplete />}/>
        <Route path='/payment-received' element={<PaymentReceived />}/>
        <Route path='/hotel-booking-page/:id' element={<HotelBookingPage />}/>
        <Route path='/house-booking-page/:id' element={<HouseBookingPage/>}/>
        <Route path='/rest-booking-page/:id' element={<RestBookingPage/>}/>
        <Route path='/resort-booking-page/:id' element={<ResortBookingPage/>}/>
        <Route path='/apartment-booking-page/:id' element={<ApartmentBookingPage/>}/>
        <Route path='/trip-booking-page/:id' element={<TripBookingPage/>}/>
        <Route path='/car-booking-page/:id' element={<CarBookingPage/>}/>
        <Route path='/car-payment-received' element={<CarPaymentRecievedPage/>}/>
        <Route path='/car-payment-complete-page' element={<CarPaymentCompletePage/>}/>
        <Route path='/flight-booking-page/:id' element={<FlightBookingPage/>}/>
        <Route path='/flight-payment-received' element={<FlightPaymentRecievedPage/>}/>
        <Route path='/flight-payment-complete-page' element={<FlightPaymentCompletePage/>}/>
        </Route>
        {/*Hotels Routs*/}
        <Route path='/hotels-page' element={<HotelsPage />}/>
        <Route path='/hotel-details-page/:id' element={<HotelDetailsPage />}/>
        <Route path='/search-result-page' element={<SearchResultPage />}/>
        {/*Houses Routs*/}
        <Route path='/houses-page' element={<HousesPage/>}/>
        <Route path='/house-details-page/:id' element={<HouseDetailsPage/>}/>
        <Route path='/houses-search-result-page' element={<HouseSearchResultPage/>}/>
        {/*Rests Routs*/}
        <Route path='/rests-page' element={<RestsPage/>}/>
        <Route path='/rest-details-page/:id' element={<RestDetailsPage/>}/>
        <Route path='/rests-search-result-page' element={<RestsSearchResultPage/>}/>
        {/*Resorts Routs*/}
        <Route path='/resorts-page' element={<ResortsPage/>}/>
        <Route path='/resort-details-page/:id' element={<ResortDetailsPage/>}/>
        <Route path='/resorts-search-result-page' element={<ResortsSearchResultPage/>}/>
        {/*Apartments Routs*/}
        <Route path='/apartments-page' element={<ApartmentsPage/>}/>
        <Route path='/apartment-details-page/:id' element={<ApartmentDetailsPage/>}/>
        <Route path='/apartments-search-result-page' element={<ApartmentsSearchResultPage />}/>
        {/*Trips Routs*/}
        <Route path='/trips-page' element={<TripsPage/>}/>
        <Route path='/trip-details-page/:id' element={<TripDetailsPage/>}/>
        <Route path='/trips-search-result-page' element={<TripsSearchResultPage />}/>
        {/*Cars Routs*/}
        <Route path='/cars-page' element={<CarsPage />}/>
        <Route path='/cars-search-result-page' element={<CarsSearchResultPage />}/>
        <Route path='/car-details-page/:id' element={<CarDetailsPage/>}/>
        {/*Flights Routs*/}
        <Route path='/planflights-page' element={<FlightsPage />}/>
        <Route path='/flights-search-result-page' element={<FlightsSearchResultPage />}/>
        <Route path='/flight-details-page/:id' element={<FlightDetailsPage/>}/>
        {/*the main route if something went worng*/}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ScrollToTopBtn />
      <ToastContainer />
      </BrowserRouter>
    </div>
  )
}

export default App;
