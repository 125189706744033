import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from "../../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import {useParams } from "react-router-dom";
import LocationSection from "../../../Components/Sections/LocationSection/LocationSection";
import AvailabilitySection from "../../../Components/Sections/AvailabilitySection/AvailabilitySection";
import AmenitiesSection from "../../../Components/Sections/AmenitiesSection/AmenitiesSection";
/*import FaqSection from "../../Components/Sections/FaqSection/FaqSection"; */
import InternalNavbar from "../../../Components/GeneralSections/InternalNavbar/InternalNavbar";
import ReviewsSection from "../../../Components/Sections/ReviewsSection/ReviewsSection";
import ReviewBoxSection from "../../../Components/Sections/ReviewBoxSection/ReviewBoxSection";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
/*import redux*/
import ServiceDetailsHook from "../../../CustomHooks/ServicesHooks/ServiceDetailsHook";
import FaqSection from "../../../Components/Sections/FaqSection/FaqSection";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import RestDetailsSidebar from '../../../Components/Rests/RestDetailsSidebar/RestDetailsSidebar'
import InfoSection from '../../../Components/Sections/InfoSection/InfoSection'
import { useTranslation } from "react-i18next";
const RestDetailsPage = () => {
  const param = useParams();
  const newParam = param.id.slice(1,param.id.length);
  const [serviceDetails,loading] = ServiceDetailsHook("rests",newParam)
   // start code to translate
   const [t,i18n]=useTranslation()
   // end code to translate
   let title = "";
   try{
    if(i18n.language==="ar"){
      title="تفاصيل الاستراحه"
     }else{
      title="rest details"
     }
   }catch(e){}
  return (
    <div className="service-details-page">
      <Header activeResets={"activeResets"} />
      <BreadCrumbSection serviceDetails={serviceDetails} loading={loading} bg={"bread-bg-4"} title={title} />
      <InternalNavbar />
      <div className="single-content-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                serviceDetails ? (
                  <div className="single-content-wrap">
                    <LocationSection service_type={"rest"} serviceDetails={serviceDetails} />
                    <AvailabilitySection serviceDetails={serviceDetails} houseId={newParam}/>
                    <AmenitiesSection serviceDetails={serviceDetails} />
                    <FaqSection serviceDetails={serviceDetails} />
                    <ReviewsSection serviceDetails={serviceDetails} />
                    <ReviewBoxSection service_id={newParam} service_type={"rests"} serviceDetails={serviceDetails}
                    />
                  </div>
                ) : (
                  <NotFoundData />
                )
              ) : (
                <SpinnerComponent />
              )}
            </div>
            <div className="col-lg-4">
              <RestDetailsSidebar serviceDetails={serviceDetails} loading={loading} service_id={newParam}/>
            </div>
          </div>
        </div>
      </div>
      <InfoSection />
      <Footer />
    </div>
  );
};

export default RestDetailsPage;
