import React from "react";
import './TripInfo.css'
import {faStar } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomCarousel from '../../../Components/Utility/CustomCarousel/CustomCarousel'
import DateHook from "../../../CustomHooks/DateHook/DateHook";
const TripInfo = ({serviceDetails}) => {
  const[t,i18n]=useTranslation()
 const [getAllDate,getDayNum,getDayName,getDatesInRange] = DateHook()
  const check_inn_date = serviceDetails.check_inn;
  const check_out_date = serviceDetails.check_out;
  const d1 = new Date(check_inn_date);
  const d2 = new Date(check_out_date);
  let results=getDatesInRange(d1,d2)
 
  return (
    <section className="trip-info text-align-start py-3 my-4">
      <h3 className="trip-info-title text-capitalize">{t("Trips.trip_info.title")}</h3>
     <div className="town-wrapper">
        <h4 className="text-capitalize">{t("Trips.trip_info.town")}</h4>
        <p className="color-text font-size-17">
            {i18n.language==="ar"?serviceDetails.town_ar:serviceDetails.town_en}
        </p>
     </div>
     <div className="description-wrapper">
        <h4 className="text-capitalize">{t("Trips.trip_info.description")}</h4>
        <p className="color-text font-size-17">
        {i18n.language==="ar"?serviceDetails.description_ar:serviceDetails.description_en}
        </p>
     </div>
     <div className="stars d-flex align-items-center justify-content-between">
        <h4>{t("Trips.trip_info.stars")}</h4>
        <p className="color-text font-size-20 m-0 mx-4">
            <span>{serviceDetails.stars}</span><span className="color-gold"><FontAwesomeIcon icon={faStar}/></span>
        </p>
     </div>
      <div className="reservation-details my-4 pb-2">
        <h3 className="my-4 text-capitalize">{t("Trips.trip_info.reservation_details")}</h3>
        <div className="name">
        <h4 className="text-capitalize">{t("Trips.trip_info.name")}</h4>
        <p className="color-text font-size-17">
        {i18n.language==="ar"?serviceDetails.name_ar:serviceDetails.name_en}
        </p>
        </div>
        <div className="check-in-wrapper my-4 border-bottom py-4">
        <h4 className="text-capitalize">{t("Trips.trip_info.check_in")}</h4>
        <p className="color-text font-size-17 text-capitalize">{getAllDate(new Date(check_inn_date))}</p>
        <div className="calender">
            <ul className="d-flex align-items-center justify-content-between h-100">
            <CustomCarousel show={5}>
                    {
                        results.map((item,index)=>{
                            return(
                                <li key={index} className={
                                    getDayNum(item)==getDayNum(new Date(check_inn_date))?
                                    "date-item target-date"
                                    :"date-item"
                                }>
                                    {getDayNum(item)}
                                    <span className="color-text d-block font-size-14">{getDayName(item)}</span>
                                    </li>
                            )
                        })
                    }
                </CustomCarousel>
            </ul>
        </div>
        </div>
        <div className="check-out-wrapper my-4 border-bottom py-4">
        <h4 className="text-capitalize">{t("Trips.trip_info.check_out")}</h4>
        <p className="color-text font-size-17 text-capitalize">{getAllDate(new Date(check_out_date))}</p>
        <div className="calender">
            <ul className="d-flex align-items-center justify-content-between h-100">
                <CustomCarousel show={5}>
                    {
                        results.map((item,index)=>{
                            return(
                                <li key={index} className={
                                    getDayNum(item)==getDayNum(new Date(check_out_date))?
                                    "date-item target-date"
                                    :"date-item"
                                }>
                                {getDayNum(item)}
                                <span className="color-text d-block font-size-14">{getDayName(item)}</span>
                                </li>
                                
                            )
                        })
                    }
                </CustomCarousel>
            </ul>
        </div>
        </div>
        <div className="d-flex align-items-center justify-content-evenly">
            <div className="text-align-center">
                <h4 className="text-capitalize">{t("Trips.trip_info.number_of_days")}</h4>
                <p className="num-days">{serviceDetails.number_of_days}</p>
            </div>
            <div className="text-align-center">
                <h4 className="text-capitalize">{t("Trips.trip_info.neights")}</h4>
                <p className="num-neights">{serviceDetails.number_of_nights}</p>
            </div>
        </div>
      </div>
    </section>
  );
};

export default TripInfo;
