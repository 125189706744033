import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import Notify from '../../../CustomHooks/Notify/Notify'
import {useNavigate } from 'react-router-dom'
import { makeFlightReservation } from '../../../Redux/Actions/FlightsActions'
const  FlightReserveHook = (id) => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const response = useSelector((state)=>state.FlightsReducer.flightReservation)
    /*user from localstorage*/
    let user=[];
    if(localStorage.getItem("NozoulnaUser")!== null){
      user = JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{
      user=[]
    }
    // Start get flight type from localstorage
    let flight_tp="";
    if(localStorage.getItem("flight_type")==="اقتصادى"){
        flight_tp="economic"
    }else if(localStorage.getItem("flight_type")==="كبار الشخصيات"){
        flight_tp="vip"
    }else if(localStorage.getItem("flight_type")==="خاص"){
        flight_tp="special"
    }else{
        flight_tp=localStorage.getItem("flight_type")
    }
    // End get flight type from localstorage
    // all of usestates
    const [quantity,set_quantity] = useState(0)
    const [flight_type,set_flight_type] = useState("....")
    const [discount_code_id,set_discount_code_id] = useState("")
    const [payment_type,setPayment_type] = useState("cash")
    const [partner_name,set_partner_name]=useState("")
    const [partner_phone,set_partner_phone]=useState("")
    const [partner_address,set_partner_address]=useState("")
    const [partner_email,set_partner_email]=useState("")
    const [partner_gender,set_partner_gender]=useState("")
    const [partner_birth,set_partner_birth]=useState("")
    const [partner_passport_number,set_partner_passport_number]=useState("")
    const [partner_nationality,set_partner_nationality]=useState("")
    const [reserveLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    /* on change state functions*/
    const onChange_flight_type=(e)=>{
        e.preventDefault()
        set_flight_type(e.target.id)
        localStorage.setItem("flight_type",e.target.id)
    }
    const onChange_discount_code_id=(e)=>{
        e.preventDefault()
        set_discount_code_id(e.target.value)
    }
    const onChange_quantity=(e)=>{
        e.preventDefault()
        set_quantity(e.target.value)
        localStorage.setItem("flight_quantity",e.target.value)
    }
    // partners quantity control
    const increase =(e)=>{
        e.preventDefault()
        set_quantity(quantity + 1)
         localStorage.setItem("flight_quantity",quantity + 1)
      }
      const decrease =(e)=>{
        e.preventDefault()
        quantity===0?set_quantity(0):set_quantity(quantity - 1)
        localStorage.setItem("flight_quantity",quantity - 1)
      }
    const onChange_payment_type=(e)=>{
        e.preventDefault()
        setPayment_type(e.target.value)
    }
    const onChange_partner_name=(e)=>{
        e.preventDefault()
        set_partner_name(e.target.value)
    }
    const onChange_partner_phone=(e)=>{
        e.preventDefault()
        set_partner_phone(e.target.value)
    }
    const onChange_partner_address=(e)=>{
        e.preventDefault()
        set_partner_address(e.target.value)
    }
    const onChange_partner_email=(e)=>{
        e.preventDefault()
        set_partner_email(e.target.value)
    }
    const onChange_partner_gender=(e)=>{
        e.preventDefault()
        set_partner_gender(e.target.value)
    }
    const onChange_partner_birth=(e)=>{
        e.preventDefault()
        set_partner_birth(e.target.value)
    }
    const onChange_partner_passport_number=(e)=>{
        e.preventDefault()
        set_partner_passport_number(e.target.value)
    }
    const onChange_partner_nationality=(e)=>{
        e.preventDefault()
        set_partner_nationality(e.target.value)
    }
    /*start on click book now*/
    const onClickBookNow =(id)=>{
        if(localStorage.getItem("NozoulnaToken")!==null){
            if(quantity===0){
                Notify("please select the partner count","warn")
            }else if(flight_type==="flight class"){
                Notify("please choose the flight class","warn")
            }else{
                Navigate(`/flight-booking-page/:${id}`)
            }
        }else{
            Notify("please login first","warn")
        }
    }
    /*End on click book now*/
    /*submit function*/
    const onConfirmReserve =async(e)=>{
        e.preventDefault()
        /*validation function*/
        if(!localStorage.getItem("flight_type")||!localStorage.getItem("flight_quantity")){
            Notify("please complete the data","warn")
        }else if(partner_name===""||partner_phone===""||partner_email===""||
        partner_address===""||partner_gender===""||partner_birth===""||partner_passport_number===""
        ||partner_nationality===""){
            Notify("please complete the data","warn")
        }else if(payment_type===""){
            Notify("please select a payment method","warn")
        }
        else{
        const formData = new FormData()
        formData.append("service_type","plan-flights")
        formData.append("service_id",id)
        formData.append("quantity",localStorage.getItem("flight_quantity"))
        formData.append("flight_type",flight_tp)
        formData.append("discount_code_id",discount_code_id)
        formData.append("payment_type",payment_type)
        formData.append("partners.0.name",partner_name)
        formData.append("partners.0.phone",partner_phone)
        formData.append("partners.0.address",partner_address)
        formData.append("partners.0.email",partner_email)
        formData.append("partners.0.gender",partner_gender)
        formData.append("partners.0.date_of_birth",partner_birth)
        formData.append("partners.0.passport_number",partner_passport_number)
        formData.append("partners.0.nationality",partner_nationality)
        setLoading(true)
        setIsPress(true)
        await dispatch(makeFlightReservation(formData))
        setLoading(false)
        setIsPress(false)
        }
    }

    /*useEffect to handle errors and reset the states and show notify*/
    useEffect(()=>{
        if(reserveLoading===false){
            if(response){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                    setTimeout(() => {
                        Navigate("/flight-payment-received")
                    },1000);
                }else if(response.status ===422){
                    if(response.data.errors!==null){
                        if(response.data.errors.start_date){
                            Notify(`${response.data.errors.start_date[0]}`,"error")
                        }else if(response.data.errors.end_date){
                            Notify(`${response.data.errors.end_date[0]}`,"error")
                        }
                    }else{
                        Notify(response.data.message,'error')
                    }
                }else{
                    Notify(response.data.message,'error')
                }
            }
        }
        
    },[reserveLoading])


    return[quantity,onChange_quantity,flight_type,onChange_flight_type,discount_code_id,onChange_discount_code_id,
        payment_type,onChange_payment_type,partner_name,onChange_partner_name,
        partner_phone,onChange_partner_phone,partner_address,onChange_partner_address,
        partner_email,onChange_partner_email,partner_gender,onChange_partner_gender,
        partner_birth,onChange_partner_birth,partner_passport_number,onChange_partner_passport_number,
        partner_nationality,onChange_partner_nationality,reserveLoading,isPress,increase,decrease,
        onClickBookNow,onConfirmReserve
    ]
}

export default FlightReserveHook