import React from 'react'
import './CarBookingInfoSection.css'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart, faUser,faCalendar } from '@fortawesome/free-regular-svg-icons';
import {faCartShopping, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import UpdateWishlistHook from '../../../CustomHooks/UserHooks/UpdateWishlistHook';
import { useTranslation } from 'react-i18next';
import CarReserveHook from '../../../CustomHooks/UserHooks/Cars/CarReserveHook';
const CarBookingInfoSection = ({id}) => {
    const [,onAddToWishlist] = UpdateWishlistHook()
    const [t,i18n] = useTranslation()
    // car reserve hook
    const [,,,,pick_up_date,onChange_pick_up_date,drop_off_date,
      onChange_drop_off_date,,,,,partnerTwo,onChangePartnerTwo,
      partnerThree,onChangePartnerThree,onClickBookNow,,,,onChange_delivery,address,onChange_address]
      =CarReserveHook(id)
  return (
    <section className='booking-info-section car-booking-info-section'>
    <div className="contact-form-action">
                  <form action="#">
                  <div className="input-box">
                          <label className="label-text">
                          {t("CarBookingInfoSection.address")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faLocationDot}/>
                            <input
                            value={address}
                            onChange={onChange_address}
                              className="date-range custom-input"
                              type="text"
                              name="start-date"
                              placeholder={t("CarBookingInfoSection.address")}
                            />
                          </div>
                  </div>
                  <div className="input-box">
                          <label className="label-text">
                          {t("CarBookingInfoSection.pick_up_date")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faCalendar}/>
                            <input
                            value={pick_up_date}
                            onChange={onChange_pick_up_date}
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="start-date"
                              placeholder={t("CarBookingInfoSection.pick_up_date")}
                            />
                          </div>
                  </div>
                  <div className="input-box">
                          <label className="label-text">
                          {t("CarBookingInfoSection.drop_off_date")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faCalendar}/>
                            <input
                            value={drop_off_date}
                            onChange={onChange_drop_off_date}
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="end-date"
                              placeholder= {t("CarBookingInfoSection.drop_off_date")}
                            />
                          </div>
                  </div>
                  <div className="input-box">
                          <label className="label-text">
                          {t("apartmentBookingInfo.second-partner")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partnerTwo}
                            onChange={onChangePartnerTwo}
                              className="custom-input"
                              type="text"
                              name="partner-two"
                              placeholder={t("apartmentBookingInfo.second-partner")}
                            />
                          </div>
                  </div>
                  <div className="input-box">
                          <label className="label-text">
                          {t("apartmentBookingInfo.third-partner")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partnerThree}
                            onChange={onChangePartnerThree}
                              className="custom-input"
                              type="text"
                              name="partner-three"
                              placeholder={t("apartmentBookingInfo.third-partner")}
                            />
                          </div>
                  </div>
                  <div className='input-box'>
                          <div className=' custom-radio-wrapper d-flex align-items-center justify-content-around'>
                          <input type='radio' id='delivery' value={"1"} name='delivery-radio-group' onChange={onChange_delivery}/>
                          <label htmlFor='delivery'>{t("CarBookingInfoSection.delivery")}</label>
                          <input type='radio' id='not-delivery' value={"0"} name='delivery-radio-group' onChange={onChange_delivery}/>
                          <label htmlFor='not-delivery'>{t("CarBookingInfoSection.not_delivery")}</label>
                          </div>
                          <p className='hint text-capitalize color-primary font-size-14'> {t("CarBookingInfoSection.hint")}</p>
                  </div>
                  </form>
    </div>
        <div className="btn-box btn-box-1 pt-2" onClick={()=>onClickBookNow(id)}>
            <BtnComponent icon={<FontAwesomeIcon icon={faCartShopping} />}value={t("apartmentBookingInfo.book-now")}/>
        </div>
        <div className="btn-box btn-box-2 pt-2" onClick={()=>onAddToWishlist(id,"cars")}>
          <BtnComponent icon={<FontAwesomeIcon icon={faHeart} />} value={t("apartmentBookingInfo.add-to-wishlist")}/>
        </div>
</section>
  )
}

export default CarBookingInfoSection