import { GET_ALL_CATEGORIES } from "../TypesOfActions";
import { GetDataHook } from "../../Hooks/GetDataHook";

export const getAllCategories = ()=> async (dispatch)=>{
try{
    const response = await GetDataHook(`api/categories`)
    dispatch({
        type:GET_ALL_CATEGORIES,
        payload:response.data
    })
}
catch(e){
    dispatch({
        type:GET_ALL_CATEGORIES,
        payload:e.response
    })
}finally{}
}

