import React from 'react'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart} from '@fortawesome/free-regular-svg-icons';
import {faCartShopping,faTag} from '@fortawesome/free-solid-svg-icons';
import UpdateWishlistHook from '../../../CustomHooks/UserHooks/UpdateWishlistHook';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import FlightReserveHook from '../../../CustomHooks/UserHooks/Flights/FlightReserveHook';
const FlightBookingInfoSection = ({id}) => {
    const [,onAddToWishlist] = UpdateWishlistHook()
    const [t,i18n] = useTranslation()
    // Flight Reserve Hook
    const [quantity,,flight_type,onChange_flight_type,discount_code_id,
        onChange_discount_code_id,,,,,,,,,,,,,,,,,,,,,increase,decrease,
        onClickBookNow,
    ] = FlightReserveHook(id)
      return (
        <section className='booking-info-section flight-booking-info-section'>
        <div className="contact-form-action">
                      <form>
                      <div className="input-box">
                        <label className="label-text">{t("Flights.FlightBookingInfoSection.discount_code")}</label>
                        <div className="form-group">
                              <FontAwesomeIcon icon={faTag}/>
                                <input
                                value={discount_code_id}
                                onChange={onChange_discount_code_id}
                                  className="custom-input discount-code"
                                  type="text"
                                  name="discount-code"
                                  placeholder={t("Flights.FlightBookingInfoSection.discount_code")}
                                />
                        </div>
                      </div>
                      <div className="input-box">
                        <label className='label-text'>{t("Flights.FlightBookingInfoSection.preferred_class.title")}</label>
                      <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {flight_type}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("Flights.FlightBookingInfoSection.preferred_class.list.item1")}
                                      value="economic"
                                      onClick={onChange_flight_type}
                                      href="#"
                                    >
                                      {t("Flights.FlightBookingInfoSection.preferred_class.list.item1")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("Flights.FlightBookingInfoSection.preferred_class.list.item2")}
                                      value="vip"
                                      onClick={onChange_flight_type}
                                      href="#"
                                    >
                                      {t("Flights.FlightBookingInfoSection.preferred_class.list.item2")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("Flights.FlightBookingInfoSection.preferred_class.list.item3")}
                                      defaultValue="special"
                                      onClick={onChange_flight_type}
                                      href="#"
                                    >
                                      {t("Flights.FlightBookingInfoSection.preferred_class.list.item3")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                    </Dropdown>
                      </div>
                      <div className="count-box d-flex align-items-center justify-content-between">
                          <div className='label'>
                                <p className='m-0'>{t("Flights.FlightBookingInfoSection.partner")}</p>
                                <span className='text-color font-size-12'>{t("Flights.FlightBookingInfoSection.age_18")}</span>
                          </div>
                          <div className='count-control'>
                                <button className='font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative'
                                onClick={decrease}>-</button>
                                <span className='font-size-17 user-select-none font-weight-bold'>{quantity}</span>
                                <button className='font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative'
                                onClick={increase}>+</button>
                          </div>
                      </div>
                      </form>
        </div>
            <div className="btn-box btn-box-1 pt-2" onClick={()=>onClickBookNow(id)}>
                <BtnComponent icon={<FontAwesomeIcon icon={faCartShopping} />}value={t("Flights.FlightBookingInfoSection.btns.book_now")}/>
            </div>
            <div className="btn-box btn-box-2 pt-2" onClick={()=>onAddToWishlist(id,"plan-flights")}>
              <BtnComponent icon={<FontAwesomeIcon icon={faHeart} />} value={t("Flights.FlightBookingInfoSection.btns.add_to_wishlist")}/>
            </div>
    </section>
      )
}

export default FlightBookingInfoSection