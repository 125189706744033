/*
what i installed
    -axios
*/
import axios from "axios";
/*
baseUrl is instance axios => Modified Version from axios i can use it but will not put the url in it 
custom axios by axios.create
*/
export const CustomAxios = axios.create({baseURL:"https://multi.nozoluna.com/public/"})

const baseUrl = "https://multi.nozoluna.com/public";

export default baseUrl;