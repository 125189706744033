import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from "../../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import { Link, useParams } from "react-router-dom";
import AmenitiesSection from "../../../Components/Sections/AmenitiesSection/AmenitiesSection";
/*import FaqSection from "../../Components/Sections/FaqSection/FaqSection"; */
import InternalNavbar from "../../../Components/GeneralSections/InternalNavbar/InternalNavbar";
import ReviewsSection from "../../../Components/Sections/ReviewsSection/ReviewsSection";
import ReviewBoxSection from "../../../Components/Sections/ReviewBoxSection/ReviewBoxSection";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
/*import redux*/
import ServiceDetailsHook from "../../../CustomHooks/ServicesHooks/ServiceDetailsHook";
import FaqSection from "../../../Components/Sections/FaqSection/FaqSection";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import SpinnerTwo from "../../../Components/Utility/SpinnerComponent/SpinnerTwo";
import CarDetailsSidebar from '../../../Components/Cars/CarDetailsSidebar/CarDetailsSidebar'
import { useTranslation } from "react-i18next";
import CarInfo from "../../../Components/Cars/CarInfo/CarInfo";
import InfoSection from '../../../Components/Sections/InfoSection/InfoSection'
const CarDetailsPage = () => {
  const param = useParams();
  const newParam = param.id.slice(1,param.id.length);
  const [serviceDetails,loading] = ServiceDetailsHook("cars",newParam)
   // start code to translate
   const [t,i18n]=useTranslation()
   // end code to translate
   let title = "";
   try{
    if(i18n.language==="ar"){
      title="تفاصيل السياره"
     }else{
      title="car details"
     }
   }catch(e){}
  return (
    <div className="service-details-page">
      <Header activeCars={"activeCars"} />
      <BreadCrumbSection serviceDetails={serviceDetails} loading={loading} bg={"bread-bg-7"} title={title} />
      <InternalNavbar />
      <div className="single-content-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                serviceDetails ? (
                  <div className="single-content-wrap">
                    <CarInfo car={serviceDetails}/>
                    <AmenitiesSection serviceDetails={serviceDetails} />
                    <FaqSection serviceDetails={serviceDetails} />
                    <ReviewsSection serviceDetails={serviceDetails} />
                    <ReviewBoxSection service_id={newParam} service_type={"cars"} serviceDetails={serviceDetails}
                    />
                  </div>
                ) : (
                  <NotFoundData />
                )
              ) : (
                <SpinnerComponent />
              )}
            </div>
            <div className="col-lg-4">
              <CarDetailsSidebar serviceDetails={serviceDetails} loading={loading} service_type={"cars"} service_id={newParam}/>
            </div>
          </div>
        </div>
      </div>
      <InfoSection />
      <Footer />
    </div>
  );
};

export default CarDetailsPage;
