import React from 'react'
import RoomCard from '../../Hotels/RoomCard/RoomCard'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
import { useTranslation } from 'react-i18next'
import ApartmentRoomCard from '../ApartmentRoomCard/ApartmentRoomCard'
const ApartmentAvailabilitySection = ({serviceDetails,apartmentId}) => {
    const [t,i18n]=useTranslation()
    let value=""
    try{
        if(i18n.language==="ar"){
            value="المتوفر"
        }else{
            value="Availability"
        }
    }catch(e){}
  return (
    <section id="availability" className="page-scroll availability-section">
    <h4 className="mini-custom-title">{value}</h4>
    <div className="single-content-item padding-top-40px padding-bottom-30px">
        {
            serviceDetails?
            serviceDetails.rooms?
            serviceDetails.rooms.length>0?
            <h3 className="title">{t("availabilitySection.title")}</h3>
            :<NotFoundData />
            :null
            :null
        }
        {  
            serviceDetails?
            serviceDetails.rooms?
            serviceDetails.rooms.map((room)=>{
                return(
                    <ApartmentRoomCard key={room.id} room={room} id={apartmentId}/>
                )
            })
            :null
            :null
        }
        {
            serviceDetails.room_number?
            <div>
                {
                    i18n.language==="ar"?
                    <p>{serviceDetails.room_description_ar}</p>
                    :
                    <p>{serviceDetails.room_description_en}</p>
                }
            </div>
            :null
        }
    </div>
    </section>
  )
}

export default ApartmentAvailabilitySection