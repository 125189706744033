import React from "react";
import "./UserProfilePage.css";
import UserProfileSidebar from "../../../Components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../Components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../Components/User/UserDashboardBread/UserDashboardBread";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { Link } from "react-router-dom";
import UserDashboardFooter from "../../../Components/User/UserDashboardFooter/UserDashboardFooter";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import { useTranslation } from "react-i18next";
const UserProfilePage = () => {
  let user;
  if (localStorage.getItem("NozoulnaUser") !== null) {
    user = JSON.parse(localStorage.getItem("NozoulnaUser"));
  } else {
    user = null;
  }
  // start code for translate
  const [t, i18n] = useTranslation();
  // End code for translate
  let title = "";
  try {
    if (i18n.language === "ar") {
      title = "الملف الشخصى";
    } else {
      title = "My Porfile";
    }
  } catch (e) {}
  return (
    <div className="user-profile-page page">
      <div
        className={
          i18n.language === "ar"
            ? "user-sidebar-wrap-ar d-none d-lg-block"
            : "user-sidebar-wrap d-none d-lg-block"
        }
      >
        <UserProfileSidebar profilePage={"profilePage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav profilePageActive={"page-active"} />
        <UserDashboardBread section_title={title} />
        <div className="dashboard-main-content">
          <div className="container-fluid">
            <div className="row row-content">
              <div className="col-lg-12">
                <div className="form-box">
                  <div className="form-title-wrap border-bottom-0 pb-0">
                    <h3 className="title">{t("userProfilePage.title")}</h3>
                  </div>
                  {user !== null ? (
                    <div className="form-content">
                      <div className="table-form table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td className="pl-0">
                                <div className="table-content">
                                  <h3 className="title font-weight-medium">
                                  {t("userProfilePage.first-name")}
                                  </h3>
                                </div>
                              </td>
                              <td>:</td>
                              <td>{user.first_name}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="table-content">
                                  <h3 className="title font-weight-medium">
                                  {t("userProfilePage.last-name")}
                                  </h3>
                                </div>
                              </td>
                              <td>:</td>
                              <td>{user.last_name}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="table-content">
                                  <h3 className="title font-weight-medium">
                                  {t("userProfilePage.email")}
                                  </h3>
                                </div>
                              </td>
                              <td>:</td>
                              <td>{user.email}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="table-content">
                                  <h3 className="title font-weight-medium">
                                  {t("userProfilePage.phone")}
                                  </h3>
                                </div>
                              </td>
                              <td>:</td>
                              <td>{user.phone}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                <div className="table-content">
                                  <h3 className="title font-weight-medium">
                                  {t("userProfilePage.city")}
                                  </h3>
                                </div>
                              </td>
                              <td>:</td>
                              <td>{user.city}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="btn-box mt-4">
                        <Link to={"/user-setting-page"}>
                          <BtnComponent value={t("userProfilePage.edit-profile")} />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <SpinnerComponent />
                  )}
                </div>
              </div>
            </div>
            <div className="border-top mt-4"></div>
            <div className="row row-footer align-items-center">
              <UserDashboardFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
