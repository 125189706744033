import {useState } from "react";
import { useDispatch } from "react-redux";
import { GetSearchResultFlights } from "../../Redux/Actions/FlightsActions";
import { useTranslation } from "react-i18next";
const FlightsSearchHook = () => {
  const [t,i18n]=useTranslation()
    const dispatch = useDispatch();
    const [town, setTown] = useState("");
    const [stars, setStars] = useState("");
    const [flight_date, set_flight_date] = useState("");
    const [arrive_date, set_arrive_date] = useState("");
    const [airplane_off, set_airplane_off] = useState("");
    const [airplane_inn, set_airplane_inn] = useState("");
    const [passengers, set_passengers] = useState("....");
    const [plane_class,set_plane_class] = useState("....");
    // search result fn
    const SearchResultsFn = async (queryString) => {
        await dispatch(GetSearchResultFlights(queryString));
      };
      // on click search now
      const on_Click_search =()=>{
        SearchResultsFn(`town=${town}&stars=${stars}&flight_date=${flight_date}&arrive_date=${arrive_date}&
        inn_airport=${airplane_inn}&off_airport=${airplane_off}`)
      }
//stars
  const onChangeStars = (e) => {
    let value = e.target.value;
    if (e.target.checked === true) {
      setStars(value);
    } else if (e.target.checked === false) {
      setStars("");
    } else {
      setStars("");
    }
  };
  // town
  const onChangeTown = (e) => {
    setTown(e.target.value);
  };
  // flight date
  const onChange_flight_date = (e) => {
    set_flight_date(e.target.value);
  };
  // arrive date
  const onChange_arrive_date=(e)=>{
    set_arrive_date(e.target.value)
  }
  // airplane off
  const onChange_airplane_off=(e)=>{
    set_airplane_off(e.target.value)
  }
  // airplane inn
  const onChange_airplane_inn=(e)=>{
    set_airplane_inn(e.target.value)
  }
  // on change plane class
  const onChange_plane_class = (e)=>{
    set_plane_class(e.target.id)
  }
  // on change passengers 
  const onChange_passengers = (e)=>{
    set_passengers(e.target.id)
  }
  // return variables
  return[town,onChangeTown,onChangeStars,flight_date,onChange_flight_date,arrive_date,onChange_arrive_date,airplane_off,
onChange_airplane_off,airplane_inn,onChange_airplane_inn,on_Click_search,plane_class,onChange_plane_class,passengers,onChange_passengers]
}

export default FlightsSearchHook