import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { makeReservation } from '../../../Redux/Actions/userActions'
import Notify from '../../Notify/Notify'
import { useNavigate } from 'react-router-dom'

const  RestReservHook = (service_id) => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const response = useSelector((state)=>state.UserReducer.makeReservation)

    /*user from localstorage*/
    let user=[];
    if(localStorage.getItem("NozoulnaUser")!== null){
      user = JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{
      user=[]
    }
    /* all of usestates */
    const [start_date,setStart_date] = useState("")
    const [end_date,setEnd_date] = useState("")
    const [payment_type,setPayment_type] = useState("cash")
    const [partner,setPartner] = useState(user.first_name||"")
    const [phone,setPhone] = useState(user.phone||"")
    const [partnerTwo,setPartnerTwo] = useState("")
    const [partnerThree,setPartnerThree] = useState("")
    const [discountCodeId,setDiscountCodeId] = useState("")
    const [reserveLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    /* on change state functions*/
   
    const onChangeStartDate=(e)=>{
        setStart_date(e.target.value)
        localStorage.setItem("start_date",e.target.value)
    }
    const onChangeEndDate=(e)=>{
        setEnd_date(e.target.value)
        localStorage.setItem("end_date",e.target.value)
    }
    const onChangePaymentType=(e)=>{
        e.persist()
        setPayment_type(e.target.value)
    }
    const onChangePartner=(e)=>{
        e.persist()
        setPartner(e.target.value)
    }
    const onChangePartnerTwo=(e)=>{
        e.persist()
        setPartnerTwo(e.target.value)
        localStorage.setItem("partner_two",e.target.value)
    }
    const onChangePartnerThree=(e)=>{
        setPartnerThree(e.target.value)
        localStorage.setItem("partner_Three",e.target.value)
    }
    const onChangePhone=(e)=>{
        e.persist()
        setPhone(e.target.value)
    }
    const onChangeDiscountCodeId=(e)=>{
        e.persist()
        setDiscountCodeId(e.target.value)
    }
    /*start on click book now*/
    const onClickBookNow =(id)=>{
        if(localStorage.getItem("NozoulnaToken")!==null){
            if(start_date===""||end_date===""){
                Notify("please complete the data","warn")
            }else{
                Navigate(`/rest-booking-page/:${id}`)
            }
        }else{
            Notify("please login first","warn")
        }
    }
    /*End on click book now*/
    /*submit function*/
    
    const onConfirmReserve =async(e)=>{
        e.preventDefault()
        /*validation function*/
        if(!localStorage.getItem("start_date")||!localStorage.getItem("end_date")||phone===""){
            Notify("please complete the data","warn")
        }else if(payment_type===""){
            Notify("please select a payment method","warn")
        }
        else{
        const formData = new FormData()
        formData.append("service_type","rests")
        formData.append("service_id",service_id)
        formData.append("start_date",localStorage.getItem("start_date"))
        formData.append("end_date",localStorage.getItem("end_date"))
        formData.append("payment_type",payment_type)
        formData.append("partner[0]",partner)
        formData.append("partner[1]",partnerTwo)
        formData.append("partner[2]",partnerThree)
        formData.append("discount_code_id",discountCodeId)
        formData.append("phone",phone)
        setLoading(true)
        setIsPress(true)
        await dispatch(makeReservation(formData))
        setLoading(false)
        setIsPress(false)
        }
    }

    /*useEffect to handle errors and reset the states and show notify*/
    useEffect(()=>{
        if(reserveLoading===false){
            if(response){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                    setTimeout(() => {
                        Navigate("/payment-received")
                    }, 1000);
                }else if(response.status ===422){
                    if(response.data.errors!==null){
                        if(response.data.errors.start_date){
                            Notify(`${response.data.errors.start_date[0]}`,"error")
                        }else if(response.data.errors.end_date){
                            Notify(`${response.data.errors.end_date[0]}`,"error")
                        }
                    }else{
                        Notify(response.data.message,'error')
                    }
                }
                else{
                    Notify(response.data.message,'error')
                }
            }
        }
    },[reserveLoading])


    return [partner,onChangePartner,phone,onChangePhone,start_date,onChangeStartDate,end_date,
        onChangeEndDate,payment_type,onChangePaymentType,onConfirmReserve,isPress,reserveLoading,partnerTwo,onChangePartnerTwo,
        partnerThree,onChangePartnerThree,onClickBookNow,discountCodeId,onChangeDiscountCodeId]
}

export default RestReservHook