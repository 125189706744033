import React from 'react'
import './ReviewsSection.css'
import { useTranslation } from 'react-i18next'
const ReviewsSection = ({serviceDetails}) => {
    const [t,i18n]=useTranslation()
    let value=""
    try{
        if(i18n.language==="ar"){
            value="احصائيات"
        }else{
            value="Statistics"
        }
    }catch(e){}
  return ( 
    <section id="reviews" className="reviews-section section">
        <h4 className="mini-custom-title">{value}</h4>
    <div className="single-content-item">
        <div className="review-container">
            <div className="row align-items-center">
                <div className="col-lg-4">
                    <div className="review-summary">
                        <h2>{serviceDetails.stars}<span>/5</span></h2>
                        <p>{serviceDetails.review}</p>
                        <span>{t("reviewsSection.based-on")} {serviceDetails.num_reviews} {t("reviewsSection.reviews")}</span>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="review-bars">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="progress-item">
                                    <h3 className="progressbar-title">{t("reviewsSection.service")}</h3>
                                    <div className="progressbar-content d-flex align-items-center ">
                                        <div className="progressbar-box ">
                                            <div className="progressbar-line" data-percent={`${serviceDetails.stars}0%`}>
                                                <div style={{width:`${serviceDetails.stars}0%`,backgroundColor:"#e8586d"}} className="progressbar-line-item bar-bg-1"></div>
                                            </div>
                                        </div>
                                        <div className="bar-percent">{serviceDetails.stars}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="progress-item">
                                    <h3 className="progressbar-title">{t("reviewsSection.location")}</h3>
                                    <div className="progressbar-content  d-flex align-items-center justify-content-between">
                                        <div className="progressbar-box ">
                                            <div className="progressbar-line" data-percent={`${serviceDetails.stars}0%`}>
                                                <div style={{width:`${serviceDetails.stars}0%`,backgroundColor:"#28d5a7"}} className="progressbar-line-item"></div>
                                            </div>
                                        </div>
                                        <div className="bar-percent">{serviceDetails.stars}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="progress-item">
                                    <h3 className="progressbar-title">{t("reviewsSection.value-for-money")}</h3>
                                    <div className="progressbar-content d-flex align-items-center justify-content-between">
                                        <div className="progressbar-box">
                                            <div className="progressbar-line" data-percent={`${serviceDetails.stars}0%`}>
                                                <div style={{width:`${serviceDetails.stars}0%`,backgroundColor:"#f9b851"}} className="progressbar-line-item bar-bg-3"></div>
                                            </div>
                                        </div>
                                        <div className="bar-percent">{serviceDetails.stars}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="progress-item">
                                    <h3 className="progressbar-title">{t("reviewsSection.cleanliness")}</h3>
                                    <div className="progressbar-content d-flex align-items-center justify-content-between">
                                        <div className="progressbar-box">
                                            <div className="progressbar-line" data-percent={`${serviceDetails.stars}0%`}>
                                                <div style={{width:`${serviceDetails.stars}0%`,backgroundColor:"#cc08e9"}} className="progressbar-line-item bar-bg-4"></div>
                                            </div>
                                        </div>
                                        <div className="bar-percent">{serviceDetails.stars}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="progress-item">
                                    <h3 className="progressbar-title">{t("reviewsSection.facilities")}</h3>
                                    <div className="progressbar-content d-flex align-items-center justify-content-between">
                                        <div className="progressbar-box">
                                            <div className="progressbar-line" data-percent={`${serviceDetails.stars}0%`}>
                                                <div style={{width:`${serviceDetails.stars}0%`,backgroundColor:"#ff8c2a"}} className="progressbar-line-item bar-bg-5"></div>
                                            </div>
                                        </div>
                                        <div className="bar-percent">{serviceDetails.stars}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default ReviewsSection