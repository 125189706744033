import {useState,useEffect} from 'react'

const ProtectRoutsHook = () => {
  const [user,setUser]= useState(JSON.parse(localStorage.getItem("NozoulnaUser")))
  const [isUser,setIsUser]=useState()

  useEffect(()=>{
    if(user!=null){
        setIsUser(true)
    }else{
        setIsUser(false)
    }
  },[])

  return[user,isUser]
}

export default ProtectRoutsHook