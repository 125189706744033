import React from 'react'
import './Pagenation.css'
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const Pagenation = ({pageCount,onPress}) => {
    const handlePageClick = (data) => {
      onPress(data.selected + 1)
    };
    const [t,i18n]=useTranslation()
  return (
    <ReactPaginate
        breakLabel="..."
        nextLabel={i18n.language==="ar"?"التالى":"next"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={i18n.language==="ar"?"السابق":"previos"}
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
  )
}

export default Pagenation