import React from 'react'
import './TestimonialSection.css'
import CustomCarousel from '../../../Utility/CustomCarousel/CustomCarousel'
import TestimonialCard from '../../../TestimonialCard/TestimonialCard'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import AddWebsiteReviewHook from '../../../../CustomHooks/UserHooks/WebsiteReview/AddWebsiteReviewHook'
import BtnComponent from '../../../Utility/BtnComponent/BtnComponent'
import SpinnerComponent from '../../../Utility/SpinnerComponent/SpinnerComponent'
import GetWebsiteReviewsHook from '../../../../CustomHooks/UserHooks/WebsiteReview/GetWebsiteReviewsHook'
import NotFoundData from '../../../Utility/NotFoundData/NotFoundData'
const TestimonialSection = () => {
  const  [name,onChangeName,review,onChangeReview,,onChangeStars,
    onSubmit,reviewLoading,isPress] = AddWebsiteReviewHook()
  // get website reviews
  const [reviews,loading] = GetWebsiteReviewsHook()
  // start code to translate
  const [t,i18n]=useTranslation()
  // end code to translate
  return (
    <section className="testimonial-section section">
      <div className="container">
      <div className="section-heading">
              <h2 className="sec__title">
                {t("testimonialSection.title")}
              </h2>
            </div>
        <div className="row">
          <div className="col-lg-6">
            <form>
            <div className="rate-option-item">
                     <label>{t("testimonialSection.form.website_review")}</label>
                     <div className="rate-stars-option">
                     <div className="star-rating-1" >
                     <input type="radio" name="stars-1" id="star-a-1" defaultValue="5" onChange={onChangeStars}/>
                     <label htmlFor="star-a-1"></label>
                     <input type="radio" name="stars-1" id="star-b-1" defaultValue="4" onChange={onChangeStars}/>
                     <label htmlFor="star-b-1"></label >
                     <input type="radio" name="stars-1" id="star-c-1" defaultValue="3" onChange={onChangeStars}/>
                     <label htmlFor="star-c-1"></label>
                     <input type="radio" name="stars-1" id="star-d-1" defaultValue="2" onChange={onChangeStars}/>
                     <label htmlFor="star-d-1"></label>
                     <input type="radio" name="stars-1" id="star-e-1" defaultValue="1" onChange={onChangeStars}/>
                     <label htmlFor="star-e-1" ></label>
                     </div>
                     </div>
              </div>
            <div className="input-box">
                              <label className="label-text">{t("testimonialSection.form.name")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                  value={name}
                                  onChange={onChangeName}
                                  className="custom-input"
                                  type="text"
                                  name="text"
                                  placeholder={t("testimonialSection.form.name")}
                                />
                              </div>
              </div>
              <div className="input-box">
                              <label className="label-text">{t("testimonialSection.form.your_review")}</label>
                              <div className="form-group">
                                <textarea
                                value={review}
                                onChange={onChangeReview}
                                  className="message-control custom-input"
                                  name="question"
                                  placeholder={t("testimonialSection.form.your_review")}
                                ></textarea>
                              </div>
              </div>
              <div className='btn-box' onClick={onSubmit}>
                <BtnComponent value={t("testimonialSection.form.submit")}/>
              </div>
            </form>
          </div>
          <div className="col-lg-6 my-2">
            <div className='carousel-parent'>
                <CustomCarousel show={1}>
                {
                loading===false?
                reviews?
                reviews.map((item,index)=>{
                  return(
                    <div key={index}><TestimonialCard item={item}/></div>
                )
                })
                :<NotFoundData />
                :<SpinnerComponent />
              }
                </CustomCarousel>
            </div>
          </div>
        </div>
      </div>
      {
        isPress===true?reviewLoading===true?<SpinnerComponent />:null:null
      }
    </section>
  )
}

export default TestimonialSection