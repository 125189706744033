import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteAcountHook from "../../../CustomHooks/Auth/DeleteAcountHook";
import DateHook from "../../DateHook/DateHook";
const UserProfileSidebarHook = () => {
  const [onDeleteAcount] = DeleteAcountHook();
  const [getAllDate,,,] = DateHook()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // start code for translate
  const [t, i18n] = useTranslation();
  // End code for translate
  // get user from local storage
  let user = null;
  if (localStorage.getItem("NozoulnaUser") != null) {
    user = JSON.parse(localStorage.getItem("NozoulnaUser"));
  } else {
    user =null;
  }
  //Start logout function
  const logOut = () => {
    localStorage.clear()
    setTimeout(() => {
      window.location.href = "/";
    },1000);
  };
  //End logout function

  return [onDeleteAcount, show, handleClose, handleShow, t, i18n, user, logOut,getAllDate];
};

export default UserProfileSidebarHook;
