import React,{useRef,useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown,faCalendarDays,faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import { Collapse, Dropdown } from "react-bootstrap";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { useTranslation } from 'react-i18next';
import FlightsSearchHook from '../../../CustomHooks/FlightsHooks/FlightsSearchHook';
const FlightsSearchPageLand = () => {
const focusInput = useRef();
const [open, setOpen] = useState(false);
  // collaps
  const handleCollaps = () => {
    setOpen(!open);
  };
  // start code for translate
  const [t,i18n]= useTranslation()
  // End code for translate
  const[town,onChangeTown,,flight_date,
    onChange_flight_date,arrive_date,onChange_arrive_date,airplane_off,
    onChange_airplane_off,airplane_inn,onChange_airplane_inn,on_Click_search,
    plane_class,onChange_plane_class,passengers,onChange_passengers]= FlightsSearchHook()
  return (
    <section className='landing-section'>
    <div className={`service-search-result-page-land flights-search-result-land-bg`}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="search-result-content">
                <div className="section-heading">
                  <h2 className="sec__title text-white">
                    {t("Flights.FlightsSearchPageLand.title")}
                  </h2>
                </div>
                {/*Start search field container*/}
                <div className="search-fields-container">
                  {/*Start form*/}
                  <div className="contact-form-action">
                    <form action="#" className="row">
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("Flights.FlightsSearchPageLand.form.item1")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              ref={focusInput}
                              onChange={onChangeTown}
                              value={town}
                              className="custom-input"
                              type="text"
                              placeholder={t("Flights.FlightsSearchPageLand.form.item1")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("Flights.FlightsSearchPageLand.form.item2")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                              onChange={onChange_flight_date}
                              onFocus={(e)=>e.target.type="date"}
                              onBlur={(e)=>e.target.type="text"}
                              value={flight_date}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("Flights.FlightsSearchPageLand.form.item2")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("Flights.FlightsSearchPageLand.form.item3")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                              onChange={onChange_arrive_date}
                              value={arrive_date}
                              onFocus={(e)=>e.target.type="date"}
                              onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("Flights.FlightsSearchPageLand.form.item3")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("Flights.FlightsSearchPageLand.form.item4")}</label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              onChange={onChange_airplane_off}
                              value={airplane_off}
                              className="airplane-off custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("Flights.FlightsSearchPageLand.form.item4")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("Flights.FlightsSearchPageLand.form.item5")}</label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              onChange={onChange_airplane_inn}
                              value={airplane_inn}
                              className="airplane-inn custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("Flights.FlightsSearchPageLand.form.item5")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className='input-box'>
                        <label className="label-text">{t("Flights.FlightsSearchPageLand.form.item6.title")}</label>
                      <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {passengers}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.item6.list.item1")}
                                    defaultValue="1 passenger"
                                    onClick={onChange_passengers}
                                    href="#"
                                  >
                                    {t("Flights.FlightsSearchPageLand.form.item6.list.item1")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.item6.list.item2")}
                                    defaultValue="2 passenger"
                                    onClick={onChange_passengers}
                                    href="#"
                                  >
                                {t("Flights.FlightsSearchPageLand.form.item6.list.item2")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.item6.list.item3")}
                                    defaultValue="3 passenger"
                                    onClick={onChange_passengers}
                                    href="#"
                                  >
                                   {t("Flights.FlightsSearchPageLand.form.item6.list.item3")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.item6.list.item4")}
                                    defaultValue="4 passenger"
                                    onClick={onChange_passengers}
                                    href="#"
                                  >
                                   {t("Flights.FlightsSearchPageLand.form.item6.list.item4")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                    </Dropdown>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/*End form*/}
                  {/*Start More options button*/}
                  <span className="advanced-options-btn" onClick={handleCollaps}
              aria-controls="example-collapse-text"
              aria-expanded={open}>
              {t("Flights.FlightsSearchPageLand.form.more_ptions")} <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                  {/*End More options button*/}
                  {/*Start collaps to show more options*/}
                  <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="advanced-wrap row">
                    <div className='input-box col-lg-4 col-12'>
                        <label className="label-text">{t("Flights.FlightsSearchPageLand.form.collaps.title")}</label>
                      <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                {plane_class}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.collaps.dropdown.item1")}
                                    defaultValue="First class"
                                    onClick={onChange_plane_class}
                                    href="#"
                                  >
                                    {t("Flights.FlightsSearchPageLand.form.collaps.dropdown.item1")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.collaps.dropdown.item2")}
                                    defaultValue="Economy"
                                    onClick={onChange_plane_class}
                                    href="#"
                                  >
                                    {t("Flights.FlightsSearchPageLand.form.collaps.dropdown.item2")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("Flights.FlightsSearchPageLand.form.collaps.dropdown.item3")}
                                    defaultValue="business"
                                    onClick={onChange_plane_class}
                                    href="#"
                                  >
                                 {t("Flights.FlightsSearchPageLand.form.collaps.dropdown.item3")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                    </Dropdown>
                    </div>
                    </div>
                  </div>
                  </Collapse>
                  {/*End collaps to show more options*/}
                  {/*Start search button*/}
                  <div className="btn-box" onClick={on_Click_search}>
                    <BtnComponent value={t("Flights.FlightsSearchPageLand.form.search_now")}/>
                  </div>
                  {/*End search button*/}
                </div>
                {/*End search field container*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bread-svg-box position-relative">
      <svg className="bread-svg" xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div>
    </section>
  )
}

export default FlightsSearchPageLand