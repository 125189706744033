import React,{useState} from "react";
import "./ForgetPasswordPage.css";
import Header from "../../Components/GeneralSections/Header/Header";
import Footer from "../../Components/GeneralSections/Footer/Footer";
import SpinnerComponent from "../../Components/Utility/SpinnerComponent/SpinnerComponent";
import BtnComponent from "../../Components/Utility/BtnComponent/BtnComponent";
import ResetPasswordHook from "../../CustomHooks/Auth/ResetPasswordHook";
import BreadCrumbSection from "../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
const ResetPasswordPage = () => {
  const [
    code,
    onChangeCode,
    newPassword,
    onChangeNewPassword,
    onSubmit,
    loading,
    isPress,
  ] = ResetPasswordHook();
  const [passwordState2,setPasswordState2] = useState("password")
  const [showPassWordIcon2,setShowPasswordIcon2] = useState(faEyeSlash)
  const handlePasswordState2 = ()=>{
    passwordState2 === "password" ? setPasswordState2("text"):setPasswordState2("password");
    showPassWordIcon2 === faEyeSlash ? setShowPasswordIcon2(faEye):setShowPasswordIcon2(faEyeSlash);
}
//start code for translate
const [t,i18n]=useTranslation()
//End code for translate
let title="";
try{
  if(i18n.language=="ar"){
    title="إعادة تعيين كلمة المرور"
  }else{
    title="Reset password"
  }
}catch(e){}
  return (
    <>
      <Header />
      <BreadCrumbSection serviceDetails={""} loading={false} bg={"bread-bg-8"} title={title} />
      <div className="page forget-password-page">
        <section className="contact-area padding-top-100px padding-bottom-70px">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mx-auto">
                <div className="form-box recover-box-parent">
                  <div className="form-title-wrap">
                    <h3 className="title">{t("resetPasswordPage.title")}</h3>
                    <p className="font-size-15 pt-2">
                    {t("resetPasswordPage.description")}
                    </p>
                  </div>
                  <div className="form-content ">
                    <div className="contact-form-action">
                      <form method="post">
                        <div className="input-box recover-box">
                          <label className="label-text">{t("resetPasswordPage.code")}</label>
                          <div className="form-group">
                            <span className="la la-envelope-o form-icon"></span>
                            <input
                            value={code}
                            onChange={onChangeCode}
                              className="custom-input"
                              type="text"
                              name="code"
                              placeholder={t("resetPasswordPage.code")}
                            />
                          </div>
                        </div>
                        <div className="input-box recover-box">
                          <label className="label-text">{t("resetPasswordPage.newPassword")}</label>
                          <div className="form-group">
                          <span className="show-password" onClick={handlePasswordState2}><FontAwesomeIcon icon={showPassWordIcon2}/></span>
                            <input
                            value={newPassword}
                            onChange={onChangeNewPassword}
                              className="custom-input"
                              type={passwordState2}
                              name="pass"
                              placeholder={t("resetPasswordPage.newPassword")}
                            />
                          </div>
                        </div>
                        <div className="btn-box" onClick={onSubmit}>
                          <BtnComponent value={t("resetPasswordPage.send")}/>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isPress ===true? loading === true ? <SpinnerComponent /> : null : null}
      </div>
      <Footer />
    </>
  );
};

export default ResetPasswordPage;
