import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetSearchResultCars } from "../../Redux/Actions/CarsActions";

const CarsSearchHook = () => {
  const dispatch = useDispatch();
  const [town, setTown] = useState("");
  const [name, setName] = useState("");
  const [time_inn, set_time_inn] = useState("9.00Am");
  const [time_out, set_time_out] = useState("9.00Am");
  const [date_inn, set_date_inn] = useState("");
  const [date_out, set_date_out] = useState("");
  const [stars, setStars] = useState("");
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("1000");
  const [is_delivery,set_is_delivery] = useState("0")
  const [is_full_options,set_is_full_options] = useState("0")
  const [is_half_options,set_is_half_options] = useState("0")
  const [is_front_push,set_is_front_push] = useState("0")
  const [is_back_push,set_is_back_push] = useState("0")
  const [is_four_push,set_is_four_push] = useState("0")
  const [is_sunroof,set_is_sunroof] = useState("0")
  const [is_Luxury,set_is_Luxury] = useState("0")

  const SearchResultsFn = async (queryString) => {
    await dispatch(GetSearchResultCars(queryString));
  };
  useEffect(() => {
    SearchResultsFn(`is_delivery=${is_delivery}&is_full_options=${is_full_options}&is_half_options=${is_half_options}
    &is_front_push=${is_front_push}&is_back_push=${is_back_push}&is_four_push=${is_four_push}&is_sunroof=${is_sunroof}
    &is_Luxury=${is_Luxury}&stars=${stars}&min_price=${minPrice}&max_price=${maxPrice}`);
  },[stars,is_full_options,is_half_options,is_front_push,is_back_push,is_four_push,is_sunroof,is_Luxury,minPrice,maxPrice]);
  
  //stars
  const onChangeStars = (e) => {
    let value = e.target.value;
    if (e.target.checked === true) {
      setStars(value);
    } else if (e.target.checked === false) {
      setStars("");
    } else {
      setStars("");
    }
  };
  /*price*/
  const onChangeMinPrice = (e) => {
    setMinPrice(e);
  };
  const onChangeMaxPrice = (e) => {
    setMaxPrice(e);
  };
  /*town*/
  const onChangeTown = (e) => {
    setTown(e.target.value);
  };
  /*car name*/
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  //time inn fn
  const onChangeTime_inn = (e) => {
    set_time_inn(e.target.id);
  };
  // time out fn
  const onChangeTime_out = (e) => {
    set_time_out(e.target.id);
  };
  //date inn fn
  const onChangeDate_inn = (e) => {
    set_date_inn(e.target.value);
  };
  // date out fn 
  const onChangeDate_out = (e) => {
    set_date_out(e.target.value);
  };
  //is_delivery fn
  const onChange_is_delivery =(e)=>{
    if(e.target.checked===true){
        set_is_delivery("1")
    }else{
        set_is_delivery("0")
    }
  }
  //is_full_options fn
  const onChange_is_full_options =(e)=>{
    if(e.target.checked===true){
        set_is_full_options("1")
    }else{
        set_is_full_options("0")
    }
  }
  //onChange_is_half_options fn
  const onChange_is_half_options=(e)=>{
    if(e.target.checked===true){
        set_is_half_options("1")
    }else{
        set_is_half_options("0")
    }
  }
  //onChange_is_front_push fn
  const onChange_is_front_push =(e)=>{
    if(e.target.checked===true){
        set_is_front_push("1")
    }else{
        set_is_front_push("0")
    }
  }
  //onChange_is_back_push fn
  const onChange_is_back_push=(e)=>{
    if(e.target.checked===true){
        set_is_back_push("1")
    }else{
        set_is_back_push("0")
    }
  }
  //onChange_is_four_push  fn
  const onChange_is_four_push =(e)=>{
    if(e.target.checked===true){
        set_is_four_push("1")
    }else{
        set_is_four_push("0")
    }
  }
  //onChange_is_Luxury fn
  const onChange_is_Luxury=(e)=>{
    if(e.target.checked===true){
        set_is_Luxury("1")
    }else{
        set_is_Luxury("0")
    }
  }
  //onChange_is_sunroof fn
  const onChange_is_sunroof=(e)=>{
    if(e.target.checked===true){
        set_is_sunroof("1")
    }else{
        set_is_sunroof("0")
    }
  }
  // on click search now
  const onClickSearchNow = ()=>{
    SearchResultsFn(`town=${town}&stars=${stars}&min_price=${minPrice}&max_price=${maxPrice}
    &time_inn=${time_inn}&time_out=${time_out}&date_inn=${date_inn}&date_out=${date_out}&name=${name}&
    is_delivery=${is_delivery}&is_full_options=${is_full_options}&is_half_options=${is_half_options}
    &is_front_push=${is_front_push}&is_back_push=${is_back_push}&is_four_push=${is_four_push}&is_sunroof=${is_sunroof}
    &is_Luxury=${is_Luxury}`);
  }
  return [town,name,stars,time_inn,time_out,date_inn,date_out,minPrice,maxPrice,is_delivery,is_Luxury,is_front_push,is_back_push,is_four_push
    ,is_full_options,is_half_options,is_sunroof,onChangeTown,onChangeStars,onChangeTime_inn,onChangeTime_out,onChangeDate_inn,onChangeDate_out
    ,onChangeMinPrice,onChangeMaxPrice,onChange_is_Luxury,onChange_is_delivery,onChange_is_sunroof,onChange_is_four_push,onChange_is_front_push
    ,onChange_is_back_push,onChange_is_full_options,
    onChange_is_half_options,onChangeName,onClickSearchNow]
};

export default CarsSearchHook;
