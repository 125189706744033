import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {addWebsiteReview } from '../../../Redux/Actions/userActions'
import Notify from '../../Notify/Notify'
const AddWebsiteReviewHook = () => {
    const dispatch = useDispatch()
    const response = useSelector((state)=>state.UserReducer.addWebsiteReview)
    const [name,setName] = useState("")
    const [stars,setStars] = useState("")
    const [review,setReview] = useState("")
    const [reviewLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    /* functions of change states*/
    const onChangeName = (e)=>{
        setName(e.target.value)
    }
    const onChangeStars = (e)=>{
        setStars(e.target.value)
    }
    const onChangeReview = (e)=>{
        setReview(e.target.value)
    }
    /*submit functoin*/
    const onSubmit =async(e)=>{
        e.preventDefault()
       if(localStorage.getItem("NozoulnaToken")!==null){
        //validation function
        if(name===""||review===""||stars===""){
            Notify("please complete the data","warn")
            return;
        }else{
        const formData = new FormData()
        formData.append("name",name)
        formData.append("review",stars)
        formData.append("comment",review)
        setLoading(true)
        setIsPress(true)
        await dispatch(addWebsiteReview(formData))
        setLoading(false)
        setIsPress(false)
        }
       }else{
        Notify("please login","warn")
       }
    }
    /*useEffect to handle errors and reset the states*/
    useEffect(()=>{
       try{
        if(reviewLoading===false){
            if(response.data){
                console.log(response)
                if(response.status===200){
                    Notify(`${response.data.message}`,"success")
                    setName("")
                    setStars("")
                    setReview("")
                }else if(response.code===400){
                    Notify(`${response.data.message}`,"error")
                }else{
                    Notify(`${response.data.message}`,"error")
                }
            }
        }
       }catch(e){}
    },[reviewLoading])

    return [name,onChangeName,review,onChangeReview,stars,onChangeStars,
            onSubmit,reviewLoading,isPress]
}

export default AddWebsiteReviewHook