import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const FlightPaymentRecievedHook = () => {
    const  res = useSelector((state)=>state.FlightsReducer.flightReservation)
    const loading = useSelector(state=> state.FlightsReducer.loading)
    const Navigate = useNavigate()
    // start code for translate
    const [t,i18n]=useTranslation()
    // End code for translate
    // get the data from local storage
      let user;
      if (localStorage.getItem("NozoulnaUser") != null) {
        user = JSON.parse(localStorage.getItem("NozoulnaUser"));
      }
    // on click book complete fn
    const onBookComplete =()=>{
        if(localStorage.getItem("flight_quantity")!==null){
            localStorage.removeItem("flight_quantity")
        }
        if(localStorage.getItem("flight_type")!==null){
            localStorage.removeItem("flight_type")
        }
        Navigate("/flight-payment-complete-page")
    }
    // store the response to the data variable
    let data = [];
    try{
        if(res){
            if(res.data && res.data.data){
                data = res.data.data[0];
            }
        }else{
            data = [];
        }
    }catch(e){}
    // get cancel id
    let cancelId ="";
    try{
        if(res){
            if(res.data && res.data.data && res.data.data[0]){
                cancelId=res.data.data[0].id
            }else{
                cancelId=""
            }
        }
    }catch(e){}
    
    return [data,loading,cancelId,onBookComplete,user,t,i18n]
}

export default FlightPaymentRecievedHook