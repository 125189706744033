import React from "react";
import "./ReviewBoxSection.css";
import anonymous from '../../../imgs/public/anonymous.jpg'
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faStar } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faThumbsDown, faThumbsUp, faUser } from "@fortawesome/free-regular-svg-icons";
import SpinnerComponent from "../../Utility/SpinnerComponent/SpinnerComponent";
import addCommentHook from '../../../CustomHooks/UserHooks/AddCommentHook'
import LikeUnlikeHook from "../../../CustomHooks/UserHooks/LikeUnlikeHook";
import NotFoundData from '../../../Components/Utility/NotFoundData/NotFoundData'
import { useTranslation } from "react-i18next";
const ReviewBoxSection = ({serviceDetails,service_id,service_type}) => {
  const [name,onChangeName,email,onChangeEmail,comment,onChangeComment,,onChangeStars,
    onSubmit,comLoading,isPress]=addCommentHook(service_id,service_type)
    const [OnLikeUnlike]=LikeUnlikeHook()
    //start code for translate
    const [t,i18n]=useTranslation()
    let value=""
    try{
        if(i18n.language==="ar"){
            value="التعليقات"
        }else{
            value="Comments"
        }
    }catch(e){}
    //End code for translate
  return (
    <section className="review-box-section section">
      <h4 className="mini-custom-title">{value}</h4>
      <div className="single-content-item">
        <div className="comments-list">
          {
            serviceDetails?
            serviceDetails.commentable?
            serviceDetails.commentable.map((comment)=>{
              return(
            <div className="comment" key={comment.id}>
            <div className="comment-avatar">
              <img className="avatar__img" alt="" src={anonymous} />
            </div>
            <div className="comment-body">
              <div className="meta-data">
                {
                  comment.name?
                  <h3 className="comment__author">{comment.name}</h3>
                 :
                  <h4 className="comment__author">{t("reviewBoxSection.no-name")}</h4>
                }
                <div className="meta-data-inner d-flex align-items-center">
                  <span className="ratings d-flex align-items-center">
                    {comment.stars}<FontAwesomeIcon icon={faStar}/>
                  </span>
                  <p className="comment__date">{comment.created_at.slice(0,10)}</p>
                </div>
              </div>
              <p className="comment-content">
                {comment.comment}
              </p>
              <div className="comment-reply d-flex align-items-center justify-content-between">
                <div className="reviews-reaction">
                    <button className="comment-like" id={comment.id}  onClick={()=>OnLikeUnlike(comment.id,"like")}>
                    <FontAwesomeIcon icon={faThumbsUp}/>{comment.like}
                  </button>
                  <button className="comment-dislike" id={comment.id} onClick={()=>OnLikeUnlike(comment.id,"unlike")}>
                    <FontAwesomeIcon icon={faThumbsDown}/>{comment.unlike}
                  </button>
                </div>
              </div>
            </div>
          </div>
              )
            })
            :<NotFoundData />
            :null
          }
        </div>
         <div className="comment-forum">
         <div className="form-box">
           <div className="form-title-wrap">
             <h3 className="title">{t("reviewBoxSection.title")}</h3>
           </div>
           <div className="form-content">
             <div className="rate-option p-2">
               <div className="row">
                 <div className="col-lg-4 responsive-column">
                   <div className="rate-option-item">
                     <label>{t("reviewBoxSection.service")}</label>
                     <div className="rate-stars-option">
                     <div className="star-rating-1" >
                     <input type="radio" name="stars-1" id="star-a-1" defaultValue="5" onChange={onChangeStars}/>
                     <label htmlFor="star-a-1"></label>
                     <input type="radio" name="stars-1" id="star-b-1" defaultValue="4" onChange={onChangeStars}/>
                     <label htmlFor="star-b-1"></label >
                     <input type="radio" name="stars-1" id="star-c-1" defaultValue="3" onChange={onChangeStars}/>
                     <label htmlFor="star-c-1"></label>
                     <input type="radio" name="stars-1" id="star-d-1" defaultValue="2" onChange={onChangeStars}/>
                     <label htmlFor="star-d-1"></label>
                     <input type="radio" name="stars-1" id="star-e-1" defaultValue="1" onChange={onChangeStars}/>
                     <label htmlFor="star-e-1" ></label>
                     </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="contact-form-action">
               <form>
                 <div className="row">
                   <div className="col-lg-6">
                     <div className="input-box">
                       <label className="label-text">{t("reviewBoxSection.name")}</label>
                       <div className="form-group">
                         <FontAwesomeIcon icon={faUser}/>
                         <input
                         value={name}
                         onChange={onChangeName}
                           className="custom-input"
                           type="text"
                           name="name"
                           placeholder={t("reviewBoxSection.name")}
                         />
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-6">
                     <div className="input-box">
                       <label className="label-text">{t("reviewBoxSection.email")}</label>
                       <div className="form-group">
                         <FontAwesomeIcon icon={faEnvelope}/>
                         <input
                         value={email}
                         onChange={onChangeEmail}
                           className="custom-input"
                           type="email"
                           name="mail"
                           placeholder={t("reviewBoxSection.email")}
                         />
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-12">
                     <div className="input-box">
                       <label className="label-text">{t("reviewBoxSection.comment")}</label>
                       <div className="form-group">
                         {/* <FontAwesomeIcon icon={faPencil}/> */}
                         <textarea
                         value={comment}
                         onChange={onChangeComment}
                           className="message-control custom-input"
                           name="comment"
                           placeholder={t("reviewBoxSection.comment")}
                         ></textarea>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-12">
                     <div className="btn-box" onClick={onSubmit}>
                       <BtnComponent icon={<FontAwesomeIcon icon={faComment}/>} type={"submit"} value={t("reviewBoxSection.leave-a-comment")}/>
                     </div>
                   </div>
                 </div>
               </form>
             </div>
           </div>
         </div>
         {isPress===true?comLoading===true?<SpinnerComponent/>:null:null}
       </div>
      </div>
    </section>
  );
};

export default ReviewBoxSection;
