import React,{useState} from 'react'
import './FlightsPageHead.css'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import { faCalendarDays, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const FlightsPageHead = () => {
    const [flightsDropdown, setFlightsDropdown] = useState("Economy");
  const [advancedOFlightsDropdown,setAdvancedOFlightsDropdown] =useState("Economy");
  const [dropdown_6, setDropdown_6] = useState("select");
  const [activeFlights, setActiveFlights] = useState(1);
  // code for translate
  const [t,i18n] = useTranslation()
  return (
    <section className='landing-section flights-page-head'>
    <div className={`service-search-result-page-land`}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="search-result-content z-1">
                <div className="section-heading">
                  <h2 className="sec__title text-white">
                  {t("Flights.FlightsPage.title")}
                  </h2>
                </div>
                <div className='inputs-content'>
                  <div className="tab-content" id="myTabContent3">
                  <div className="section-tab section-tab-2">
                    <ul className="nav nav-tabs" id="myTab3" role="tablist">
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveFlights(1)}
                          className={
                            activeFlights === 1 ? "nav-link active" : "nav-link"
                          }
                          id="one-way-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="one-way"
                          aria-selected="true"
                        >
                          {t("heroWrapper.tab-content.flights.oneWay.title")}
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveFlights(2)}
                          className={
                            activeFlights === 2 ? "nav-link active" : "nav-link"
                          }
                          id="round-trip-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="round-trip"
                          aria-selected="false"
                        >
                          {t("heroWrapper.tab-content.flights.roundTrip.title")}
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveFlights(3)}
                          className={
                            activeFlights === 3 ? "nav-link active" : "nav-link"
                          }
                          id="multi-city-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="multi-city"
                          aria-selected="false"
                        >
                          {t("heroWrapper.tab-content.flights.multiCity.title")}
                        </button>
                      </li>
                    </ul>
                  </div>
                    <div
                      className={
                        activeFlights === 1
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="one-way"
                      role="tabpanel"
                      aria-labelledby="one-way-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-6 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.flyingFrom")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.oneWay.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.flyingTo")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.oneWay.flyingTo")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.departing")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                onFocus={(e)=>e.target.type="date"}
                                onBlur={(e)=>e.target.type="text"}
                                  className="date-range custom-input custom-input"
                                  type="text"
                                  name="daterange-single"
                                  placeholder={t("heroWrapper.tab-content.flights.oneWay.departing")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.class.title")}</label>
                              <div className="form-group select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {dropdown_6}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.flights.oneWay.class.economy")}
                                      defaultValue="Economy"
                                      onClick={(e) =>
                                        setDropdown_6(e.target.id)
                                      }
                                    >
                                      {t("heroWrapper.tab-content.flights.oneWay.class.economy")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.flights.oneWay.class.business")}
                                      defaultValue="Business"
                                      onClick={(e) =>
                                        setDropdown_6(e.target.id)
                                      }
                                    >
                                      {t("heroWrapper.tab-content.flights.oneWay.class.business")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.flights.oneWay.class.firstClass")}
                                      defaultValue="First class"
                                      onClick={(e) =>
                                        setDropdown_6(e.target.id)
                                      }
                                    >
                                      {t("heroWrapper.tab-content.flights.oneWay.class.firstClass")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 btn-flights-section">
                            <div className="btn-box">
                              <Link to="/flights-search-result-page">
                                <BtnComponent value={t("heroWrapper.tab-content.flights.oneWay.searchNow")} />
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={
                        activeFlights === 2
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="round-trip"
                      role="tabpanel"
                      aria-labelledby="round-trip-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-6 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.flyingFrom")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.roundTrip.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.flyingTo")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.roundTrip.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">
                              {t("heroWrapper.tab-content.flights.roundTrip.departing")}
                              </label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                onFocus={(e)=>e.target.type="date"}
                                onBlur={(e)=>e.target.type="text"}
                                  className="date-range custom-input"
                                  type="text"
                                  name="daterange"
                                  placeholder={t("heroWrapper.tab-content.flights.roundTrip.departing")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.passengers")}</label>
                              <div className="form-group">
                                <input type="number" className="custom-input"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.class.title")}</label>
                              <div className="form-group">
                                <div className="select-contain w-auto">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      {flightsDropdown}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        id={t("heroWrapper.tab-content.flights.roundTrip.class.economy")}
                                        defaultValue="No preference"
                                        onClick={(e) =>
                                          setFlightsDropdown(e.target.id)
                                        }
                                        href="#"
                                      >
                                        {t("heroWrapper.tab-content.flights.roundTrip.class.economy")}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={t("heroWrapper.tab-content.flights.roundTrip.class.business")}
                                        defaultValue="Economy"
                                        onClick={(e) =>
                                          setFlightsDropdown(e.target.id)
                                        }
                                        href="#"
                                      >
                                        {t("heroWrapper.tab-content.flights.roundTrip.class.business")}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={t("heroWrapper.tab-content.flights.roundTrip.class.firstClass")}
                                        defaultValue="Compact"
                                        onClick={(e) =>
                                          setFlightsDropdown(e.target.id)
                                        }
                                        href="#"
                                      >
                                      {t("heroWrapper.tab-content.flights.roundTrip.class.firstClass")}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                          <div className="input-box">
                          <label className='label-text'>{t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.title")}</label>
                              <div className="form-group">
                                <div className="select-contain w-auto">
                                <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                {advancedOFlightsDropdown}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id={t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.economy")}
                                  defaultValue="No preference"
                                  onClick={(e) =>
                                    setAdvancedOFlightsDropdown(e.target.id)
                                  }
                                  href="#"
                                >
                                  {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.economy")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  id={t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.business")}
                                  defaultValue="Economy"
                                  onClick={(e) =>
                                    setAdvancedOFlightsDropdown(e.target.id)
                                  }
                                  href="#"
                                >
                                  {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.business")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  id= {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.firstClass")}
                                  defaultValue="Compact"
                                  onClick={(e) =>
                                    setAdvancedOFlightsDropdown(e.target.id)
                                  }
                                  href="#"
                                >
                                  {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.firstClass")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 flights-tab-btn">
                            <div className="btn-box">
                              <Link to="/flights-search-result-page">
                                <BtnComponent value={t("heroWrapper.tab-content.flights.roundTrip.searchNow")} />
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={
                        activeFlights === 3
                          ? "tab-pane fade show multi-flight-wrap active"
                          : "tab-pane fade show multi-flight-wrap"
                      }
                      id="multi-city"
                      role="tabpanel"
                      aria-labelledby="multi-city-tab"
                    >
                      <div className="contact-form-action multi-flight-field d-flex align-items-center">
                        <form action="#" className="row flex-grow-1 align-items-center">
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.flyingFrom")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.multiCity.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.flyingTo")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.multiCity.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.departing")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                onFocus={(e)=>e.target.type="date"}
                                onBlur={(e)=>e.target.type="text"}
                                  className="date-range custom-input date-multi-picker"
                                  id="date"
                                  type="text"
                                  name="daterange-single"
                                  placeholder={t("heroWrapper.tab-content.flights.multiCity.departing")}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-lg-3 pr-0">
                          <div className="input-box">
                            <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.passengers")}</label>
                            <div className="form-group">
                              <input className="custom-input" type="number"/>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 pr-0">
                          <div className="input-box">
                            <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.class.title")}</label>
                            <div className="form-group">
                              <div className="select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {flightsDropdown}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id="Economy"
                                      defaultValue="No preference"
                                      onClick={(e) =>
                                        setFlightsDropdown(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.flights.multiCity.class.economy")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id="Business"
                                      defaultValue="Economy"
                                      onClick={(e) =>
                                        setFlightsDropdown(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.flights.multiCity.class.business")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id="First className"
                                      defaultValue="Compact"
                                      onClick={(e) =>
                                        setFlightsDropdown(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.flights.multiCity.class.firstClass")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 flights-tab-btn">
                          <div className="btn-box">
                            <Link to="/flights-search-result-page">
                              <BtnComponent value={t("heroWrapper.tab-content.flights.multiCity.searchNow")} />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg className="hero-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path d="M761.9,40.6L643.1,24L333.9,93.8L0.1,1H0v99h1000V1"></path>
        </svg>
    </section>
  )
}

export default FlightsPageHead