import React from 'react'
import './FlightsDiscountBox.css'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import { useTranslation } from 'react-i18next'
const FlightsDiscountBox = () => {
  const [t,i18n]=useTranslation()
  return (
    <section className="discount-box">
        <div className='overlay'></div>
            <div className="discount-content">
              <div className="section-heading">
                <h2 className="sec__title mb-2 line-height-50 text-white">
                {t("Flights.FlightsPage.discount_box.title")}
                </h2>
                <p className="sec__desc text-white">
                {t("Flights.FlightsPage.discount_box.description")}
                </p>
              </div>
              <div className="btn-box pt-4">
                <Link>
                <BtnComponent value={t("Flights.FlightsPage.discount_box.find_flights_btn")} icon={<FontAwesomeIcon icon={faArrowRight}/>}/>
                </Link>
              </div>
            </div>
          </section>
  )
}

export default FlightsDiscountBox