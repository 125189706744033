import React from 'react'
import ServiceCardHook from '../../../CustomHooks/ServicesHooks/ServiceCardHook'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
const FlightCard = ({favItem,flight}) => {
    const [wishlistActive,handleClickWishlist,type] = ServiceCardHook(flight,favItem)
    //start code for translate
  const [t,i18n]=useTranslation()
  //End code for translate
  return (
    <div className='service-card-wrapper'>
    <div className='service-card flight-card'>
                  <div className="card-img">
                    {flight?
                    flight.attachment_relation.length>0?
                    flight.attachment_relation[0].url?
                    <Link to={`/flight-details-page/:${flight.id}`}>
                    <img src={flight.attachment_relation[0].url} alt="flight-img" />
                    </Link>
                    :null
                    :null
                    :null
                    }
                    {
                      flight?
                      flight.display==="1"?
                      <span className="badge">{t("serviceCard.mostBooked")}</span>
                      :null
                      :null
                    }
                    <div onClick={()=>handleClickWishlist(flight.id,"plan-flight")}
                      className={`${wishlistActive} icon-element`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Bookmark"
                    >
                    <FontAwesomeIcon icon={faHeart} />
                    </div>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">
                      {
                        flight.id?
                        flight.plan_air_name_en?
                        <Link to={`/${type}-details-page/:${flight.id}`} className='transition'>
                          {i18n.language==="ar"?flight.plan_air_name_ar:flight.plan_air_name_en}
                      </Link>
                        :null
                        :<p>{t("serviceCard.no-name")}</p>
                      }
                    </h3>
                      <p className='card-meta'>
                        {i18n.language==="ar"?`${flight.inn_town_ar} to ${flight.off_town_ar}`:`${flight.inn_town_en} to ${flight.off_town_en}`}
                      </p>
                    <div className="card-rating">
                      <span className="badge_2">{flight.stars}/5</span>
                      <span className="review__text">{t("serviceCard.average")}</span>
                      <span className="rating__text">({flight.num_reviews} {t("serviceCard.reviews")})</span>
                    </div>
                    <div className="card-price d-flex align-items-center justify-content-between flex-wrap">
                      <p className='p-0 m-0'>
                        <span className="price__from">{t("serviceCard.from")} {`$${flight.economic_price}`} </span>
                        <span className="price__num"><del>{`$${flight.special_price}`}</del></span>
                      </p>
                      {flight.id?
                       <Link to={`/flight-details-page/:${flight.id}`}
                      className="see-details-btn transition d-none d-md-block"
                       >{t("serviceCard.seeDetails")}
                       </Link>
                    :null
                    }
                    </div>
                  </div>
    </div>
  </div>
  )
}

export default FlightCard