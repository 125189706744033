import {useState,useEffect} from 'react'
import UpdateWishlistHook from '../UserHooks/UpdateWishlistHook'
import Notify from '../Notify/Notify'

const ServiceCardHook = (service,favItem) => {
  const [onDeleteFromWishlist,onAddToWishlist] = UpdateWishlistHook()
    const [wishlistActive,setWishlistActive] = useState("add-to-wishlist")
    let isFavItem = favItem.some((item)=>item === service.id)
    const [isFav,setIsFav] = useState(false)
    
    useEffect(()=>{
      setIsFav(favItem.some((item)=>item === service.id))
    },[favItem])
  
    useEffect(()=>{
        if(isFav===true){
          setWishlistActive("add-to-wishlist add-to-wishlist-active")
        }else{
          setWishlistActive("add-to-wishlist")
        }
    },[isFav])
  
    const handleClickWishlist =async(id,service_Type)=>{
      if(localStorage.getItem("NozoulnaToken")!==null){
        if(isFav === true){
          await onDeleteFromWishlist(id,service_Type)
          setIsFav(false)
        }else{
          await onAddToWishlist(id,service_Type)
          setIsFav(true)
        }
      }else{
        Notify("please login first","warn")
      }
    }
    let type;
    try{
      if(service){
        if(service.category_id=="1"){
          type="hotel"
        }else if(service.category_id=="2"){
          type="house"
        }else if(service.category_id=="3"){
          type="rest"
        }else if(service.category_id=="4"){
          type="resort"
        }else if(service.category_id=="5"){
          type="trip"
        }else if(service.category_id=="6"){
          type="flight"
        }else if(service.category_id=="7"){
          type="car"
        }else if(service.category_id=="8"){
          type="apartment"
        }
      }
    }catch(e){}
    return [wishlistActive,handleClickWishlist,type]
}

export default ServiceCardHook