import React from 'react'
import './CtaSection.css'
import BtnComponent from '../../../Utility/BtnComponent/BtnComponent'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const CtaSection = () => {
  // start code to translate
  const [t,i18n]=useTranslation()
  // end code to translate
  return (
    <section className="cta-section text-center">
<div className='overlay'></div>
<div className="container">
  <div className="row">
    <div className="col-lg-12">
      <div className="section-heading">
        <h2 className="sec__title text-white line-height-55">
        {t("ctaSection.title")}
        </h2>
      </div>
      <Link to={'/hotels-page'}>
      <div className="btn-box my-4 mx-auto">
        <BtnComponent value={t("ctaSection.btn")}/>
      </div>
      </Link>
    </div>
  </div>
</div>
<svg className="cta-svg" viewBox="0 0 500 150" preserveAspectRatio="none">
  <path
    d="M-31.31,170.22 C164.50,33.05 334.36,-32.06 547.11,196.88 L500.00,150.00 L0.00,150.00 Z"
  ></path>
</svg>
</section>
  )
}

export default CtaSection