import {
  faBuilding,
  faClock,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SingleTourFeature } from "../../SingleTourFeature/SingleTourFeature";
import {
  faBagShopping,
  faGear,
  faMoneyBill,
  faPlane,
  faRightLeft,
  faRotate,
  faShoppingCart,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const FlightDetails = ({serviceDetails}) => {
  const [t,i18n]=useTranslation()
  return (
    <section className="flight-details text-align-start">
      <div id="description" className="page-scroll">
        <div className="single-content-item pb-4">
          <h3 className="title font-size-26">
            {i18n.language==="ar"?
            serviceDetails.off_town_ar + " الى " + serviceDetails.inn_town_ar
            :
            serviceDetails.off_town_en + " to " + serviceDetails.inn_town_en
            }
          </h3>
          <div className="d-flex align-items-center pt-2">
            <p className="mr-2 color-text">
              {
                serviceDetails.is_return==="0"?
                t("Flights.FlightDetails.one_way_flight")
                :
                t("Flights.FlightDetails.going_and_back")
              }
              </p>
            <p>
              <span className="badge-2 font-weight-medium font-size-16">
                {serviceDetails.is_transit} Stop
              </span>
            </p>
          </div>
        </div>
        <div className="section-block"></div>
        <div className="single-content-item py-4">
          <div className="row">
            <div className="col-lg-4 col-sm-4">
              <div className="single-feature-titles text-center mb-3">
                <h3 className="title font-size-15 font-weight-medium">
                  {t("Flights.FlightDetails.flight_take_off")}
                </h3>
                <span className="font-size-13">{serviceDetails.flight_date}, {serviceDetails.flight_time}</span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="feature-titles text-center mb-3">
                <span className="color-primary d-block font-size-20">
                  <FontAwesomeIcon icon={faClock} />
                </span>
                <span className="font-size-14 mt-n2">{serviceDetails.duration} {t("Flights.FlightDetails.hour")}</span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="single-feature-titles text-center mb-3">
                <h3 className="title font-size-15 font-weight-medium">
                  {t("Flights.FlightDetails.flight_landing")}
                </h3>
                <span className="font-size-13">{serviceDetails.arrive_date}, {serviceDetails.arrive_time}</span>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="single-feature-titles text-center border-top border-bottom py-3 mb-4">
                <h3 className="title font-size-15 font-weight-medium">
                  {t("Flights.FlightDetails.total_flight_time")}
                  <span className="font-size-13 d-inline-block ml-1 text-gray">
                    {serviceDetails.duration} {t("Flights.FlightDetails.hour")}
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.airline")}
                dis={
                  i18n.language==="ar"?
                  serviceDetails.plan_air_name_ar:
                  serviceDetails.plan_air_name_en
                }
                icon={faPlane}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.flight_type")}
                dis={t("Flights.FlightDetails.economy")}
                icon={faUser}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.fare_type")}
                dis={t("Flights.FlightDetails.refundable")}
                icon={faRotate}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.cancellation")}
                dis={serviceDetails.available_cancel==="0"?
                t("Flights.FlightDetails.available"):t("Flights.FlightDetails.not_available")}
                icon={faXmark}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.flight_change")}
                dis={serviceDetails.available_cancel==="0"?
                t("Flights.FlightDetails.available"):t("Flights.FlightDetails.not_available")}
                icon={faRightLeft}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.seats")}
                dis={t("Flights.FlightDetails.extra_charge")}
                icon={faShoppingCart}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.inflight_features")}
                dis={t("Flights.FlightDetails.available")}
                icon={faGear}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.base_fare")}
                dis={" $ "+serviceDetails.economic_price}
                icon={faBuilding}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.taxes")}
                dis={" $ "+serviceDetails.tax_price}
                icon={faMoneyBill}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.transit_airport")}
                dis={serviceDetails.transit_ariport==null?"....":serviceDetails.transit_ariport}
                icon={faPlane}
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <SingleTourFeature
                title={t("Flights.FlightDetails.bag_Weight")}
                dis={serviceDetails.bag_weight+" /kg "}
                icon={faBagShopping}
              />
            </div>
          </div>
        </div>
        <div className="section-block"></div>
        <div className="single-content-item padding-top-40px padding-bottom-40px">
          <h3 className="title font-size-20">About
          {
          i18n.language==="ar"?" "+serviceDetails.plan_air_name_ar+" ":" "+serviceDetails.plan_air_name_en+" "
          }
           {t("Flights.FlightDetails.airline")}</h3>
          <p className="py-3">{serviceDetails.description}</p>
        </div>
      </div>
    </section>
  );
};

export default FlightDetails;
