import {useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getAllCategories } from '../../Redux/Actions/categoriesAction'
const CategoriesHook = () => {
    const dispatch = useDispatch()
    const res = useSelector(state =>state.CategoriesReducer.allCategories)
    const loading = useSelector(state =>state.CategoriesReducer.loading)
    useEffect(()=>{
        const get =async()=>{
            await dispatch(getAllCategories())
        }
        get()
    },[])
    let Categories =[];
    try{
        if(res){
            Categories=res.data
        }
        else{
            Categories=[]
        }
    }   
    catch(e){}
    return [loading,Categories]
}

export default CategoriesHook