import React, { useState} from "react";
import "./UserSettingPage.css";
import UserProfileSidebar from "../../../Components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../Components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../Components/User/UserDashboardBread/UserDashboardBread";
import UserDashboardFooter from "../../../Components/User/UserDashboardFooter/UserDashboardFooter";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLock,faPhone,faUpload,faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {faEye,faEyeSlash,faEnvelope,faUser,
} from "@fortawesome/free-regular-svg-icons";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import UpdateProfileHook from "../../../CustomHooks/UserHooks/UpdateProfileHook";
import { Link } from "react-router-dom";
import ChangePasswordHook from "../../../CustomHooks/UserHooks/ChangePasswordHook";
import { useTranslation } from "react-i18next";
const UserSettingPage = () => {
  /*mangement password states*/
  /*second */
  const [showPassWordIcon2, setShowPasswordIcon2] = useState(faEyeSlash);
  const [passwordState2, setPasswordState2] = useState("password");
  /* third*/
  const [showPassWordIcon3, setShowPasswordIcon3] = useState(faEyeSlash);
  const [passwordState3, setPasswordState3] = useState("password");

  const handlePasswordState2 = () => {
    passwordState2 === "password"
      ? setPasswordState2("text")
      : setPasswordState2("password");
    showPassWordIcon2 === faEyeSlash
      ? setShowPasswordIcon2(faEye)
      : setShowPasswordIcon2(faEyeSlash);
  };
  const handlePasswordState3 = () => {
    passwordState3 === "password"
      ? setPasswordState3("text")
      : setPasswordState3("password");
    showPassWordIcon3 === faEyeSlash
      ? setShowPasswordIcon3(faEye)
      : setShowPasswordIcon3(faEyeSlash);
  };
  const [firstName,lastName,city,phone,image,onChangeImage,onChangeFirstName,onChangeLastName,onChangeCity,
    onChangePhone,loading,isPress,onSubmit,
  ] = UpdateProfileHook();
  const [currentEmail,onChangeCurrentEmail,newPass,onChangeNewPass,
    confirmNewPass,onChangeConfirmNewPass,isPressCh,loadingCh,onSubmitCh,
  ] = ChangePasswordHook();
  // start code for translate
  const [t, i18n] = useTranslation();
  // End code for translate
  let title = "";
  try {
    if (i18n.language === "ar") {
      title = "الاعدادات";
    } else {
      title = "settings";
    }
  } catch (e) {}
  return (
    <div className="user-setting-page page">
      <div
        className={
          i18n.language === "ar"
            ? "user-sidebar-wrap-ar d-none d-lg-block"
            : "user-sidebar-wrap d-none d-lg-block"
        }
      >
        <UserProfileSidebar settingPage={"settingPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav settingsPageActive={"page-active"} />
        <UserDashboardBread section_title={title} />
        <div className="dashboard-main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="form-box form-box-1">
                  <div className="form-title-wrap">
                    <h3 className="title">{t("userSettingPage.title")}</h3>
                  </div>
                  <div className="form-content">
                    <div className="user-profile-action d-flex align-items-center pb-4">
                      <div className="form-content-head d-flex justify-content-between">
                        <div className="img-con">
                          <div className="user-img">
                            <img src={image} alt="profile-img" />
                          </div>
                        </div>
                        <div className="upload-btn-box">
                          <p className="pb-2 font-size-15 line-height-24">
                          {t("userSettingPage.description")}
                          </p>
                          <div className="file-upload-wrap file-upload-wrap-2">
                            <label htmlFor="upload-image">
                              <FontAwesomeIcon icon={faUpload} />
                              {t("userSettingPage.uploadPhoto")}
                            </label>
                            <input
                              onChange={onChangeImage}
                              id="upload-image"
                              type="file"
                              name="files"
                              className="multi file-upload-input with-preview d-none"
                              maxLength={"1"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contact-form-action">
                      <form action="#">
                        <div className="row">
                          <div className="col-12 responsive-column">
                            <div className="input-box">
                              <label className="label-text">{t("userSettingPage.first-name")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                  value={firstName}
                                  onChange={onChangeFirstName}
                                  name="first-name"
                                  placeholder={t("userSettingPage.first-name")}
                                  className="custom-input"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 responsive-column">
                            <div className="input-box">
                              <label className="label-text">{t("userSettingPage.last-name")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                  value={lastName}
                                  onChange={onChangeLastName}
                                  name="last-name"
                                  placeholder={t("userSettingPage.last-name")}
                                  className="custom-input"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 responsive-column">
                            <div className="input-box">
                              <label className="label-text">{t("userSettingPage.phone")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faPhone} />
                                <input
                                  value={phone}
                                  onChange={onChangePhone}
                                  name="phone"
                                  placeholder={t("userSettingPage.phone")}
                                  className="custom-input"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 responsive-column">
                            <div className="input-box">
                              <label className="label-text">{t("userSettingPage.city")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faLocationDot} />
                                <input
                                  value={city}
                                  onChange={onChangeCity}
                                  placeholder={t("userSettingPage.city")}
                                  name="city"
                                  className="custom-input"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="btn-box" onClick={onSubmit}>
                              <BtnComponent value={t("userSettingPage.save")}/>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-12">
                  <div className="form-box form-box-2">
                    <div className="form-title-wrap">
                      <h3 className="title">{t("userSettingPage.password.changePassword")}</h3>
                    </div>
                    <div className="form-content">
                      <div className="contact-form-action">
                        <form action="#">
                          <div className="row">
                            <div className="col-12 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("userSettingPage.password.current-email")}
                                </label>
                                <div className="form-group">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                  <input
                                    onChange={onChangeCurrentEmail}
                                    value={currentEmail}
                                    name="mail"
                                    className="custom-input"
                                    type={"email"}
                                    placeholder={t("userSettingPage.password.current-email")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("userSettingPage.password.new-password")}
                                </label>
                                <div className="form-group">
                                  <FontAwesomeIcon icon={faLock} />
                                  <span
                                    className="show-password"
                                    onClick={handlePasswordState2}
                                  >
                                    <FontAwesomeIcon icon={showPassWordIcon2} />
                                  </span>
                                  <input
                                    onChange={onChangeNewPass}
                                    value={newPass}
                                    name="new-pass"
                                    className="custom-input"
                                    type={passwordState2}
                                    placeholder={t("userSettingPage.password.new-password")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                {t("userSettingPage.password.confirm-password")}
                                </label>
                                <div className="form-group">
                                  <FontAwesomeIcon icon={faLock} />
                                  <span
                                    className="show-password"
                                    onClick={handlePasswordState3}
                                  >
                                    <FontAwesomeIcon icon={showPassWordIcon3} />
                                  </span>
                                  <input
                                    onChange={onChangeConfirmNewPass}
                                    value={confirmNewPass}
                                    name="confirm-new-pass"
                                    className="custom-input"
                                    type={passwordState3}
                                    placeholder={t("userSettingPage.password.confirm-password")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="btn-box" onClick={onSubmitCh}>
                                <BtnComponent value={t("userSettingPage.password.changePassword")} />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-box">
                    <div className="form-title-wrap">
                      <h3 className="title pb-2">{t("userSettingPage.forgotPassword.title")}</h3>
                      <p className="font-size-15 line-height-24">
                      {t("userSettingPage.forgotPassword.title")}
                      </p>
                    </div>
                    <div className="form-content">
                      <div className="contact-form-action">
                        <div className="btn-box">
                          <Link to={"/forgetPasswordPage"}>
                            <BtnComponent value={t("userSettingPage.forgotPassword.recover-password")} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-top mt-4"></div>
            <div className="row row-footer align-items-center">
              <UserDashboardFooter />
            </div>
          </div>
        </div>
      </div>
      {isPress === true ? loading === true ? <SpinnerComponent /> : null : null}
      {isPressCh === true ? (
        loadingCh === true ? (
          <SpinnerComponent />
        ) : null
      ) : null}
    </div>
  );
};

export default UserSettingPage;
