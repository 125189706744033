import React from 'react'
import './InfoSection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faPhone, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
const InfoSection = () => {
    const [t,i18n] = useTranslation()
  return (
    <section className="info-area info-bg padding-top-90px padding-bottom-70px">
    <div className="container">
        <div className="row">
            <div className="col-lg-4">
                <a href="#" className="icon-box icon-layout-2 d-flex">
                    <div className="info-icon info-icon-1 flex-shrink-0 bg-rgb text-color-2">
                        <FontAwesomeIcon icon={faPhone}/>
                    </div>
                    <div className="info-content">
                        <h4 className="info__title">{t("InfoSection.help.title")}</h4>
                        <p className="info__desc">
                        {t("InfoSection.help.des")}
                        </p>
                    </div>
                </a>
            </div>
            <div className="col-lg-4">
                <a href="#" className="icon-box icon-layout-2 d-flex">
                    <div className="info-icon info-icon-2 flex-shrink-0 bg-rgb-2 text-color-3">
                        <FontAwesomeIcon icon={faMoneyBill}/>
                    </div>
                    <div className="info-content">
                        <h4 className="info__title">{t("InfoSection.payment.title")}</h4>
                        <p className="info__desc">
                        {t("InfoSection.payment.des")}
                        </p>
                    </div>
                </a>
            </div>
            <div className="col-lg-4">
                <a href="#" className="icon-box icon-layout-2 d-flex">
                    <div className="info-icon info-icon-3 flex-shrink-0 bg-rgb-3 text-color-4">
                        <FontAwesomeIcon icon={faXmark}/>
                    </div>
                    <div className="info-content">
                        <h4 className="info__title">{t("InfoSection.cancel_policy.title")}</h4>
                        <p className="info__desc">
                        {t("InfoSection.cancel_policy.des")}
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
  )
}

export default InfoSection