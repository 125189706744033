import React from 'react'
import './CarsSection.css'
import CustomCarousel from '../../../Utility/CustomCarousel/CustomCarousel'
import CarCard from  '../../../Cars/CarCard/CarCard'
import { useTranslation } from 'react-i18next'
import WishlistHook from '../../../../CustomHooks/UserHooks/WishlistHook'
import SpinnerComponent from '../../../Utility/SpinnerComponent/SpinnerComponent'
import NotFoundData from '../../../Utility/NotFoundData/NotFoundData'
import AllCarsHook from '../../../../CustomHooks/CarsHooks/AllCarsHook'
const CarsSection = () => {
  const [t,i18n]=useTranslation()
  const [allCars,loading,,] = AllCarsHook()
    const  [,,wishlistItemsId] = WishlistHook()
  return (
    <section className='cars-section section'>
          <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading text-center">
              <h2 className="sec__title custom-title">{t("recommendedCars.title")}</h2>
            </div>
          </div>
        </div>
        <div className="row padding-top-50px">
          <div className="col-lg-12">
            <div className="car-wrap">
              <div className='carousel-parent'>
                <CustomCarousel show={4}>
                  {
                    loading===false?
                    allCars?
                    allCars.data?
                    allCars.data.map((car)=>{
                      return(
                        <div key={car.id}>
                          <CarCard favItem={wishlistItemsId} car={car}/>
                        </div>
                      )
                    })
                    :<NotFoundData />
                    :<NotFoundData />
                    :<SpinnerComponent />
                  }
                </CustomCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CarsSection