import { GET_ALL_QUESTIONS ,GET_ERROR} from "../TypesOfActions";

const initialState = {
    allQuestions:[],
    loading:true
}

const QuestionsReducer = (state=initialState,action) =>{
    switch(action.type){
        case GET_ALL_QUESTIONS:
            return{
                ...state,
                allQuestions:action.payload,
                loading:false
            }
        case GET_ERROR:
            return{
                allQuestions:action.payload,
                loading:true
            }
            default:{
                return state
            }
    }
}

export default QuestionsReducer