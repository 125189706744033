import React,{ useState, useEffect }from "react";
import "./HeroWrapper.css";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import { Collapse } from "bootstrap";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import CustomCarousel from "../../../Utility/CustomCarousel/CustomCarousel";
import { useTranslation } from "react-i18next";
const HeroWrapper = () => {
  const Navigate = useNavigate();
  const onClickSearch = () => {
    Navigate("/search-result-page");
  };
  // start code for translate
const [t,i18n]= useTranslation()
// End code for translate
  /*usestate for active class*/
  const [active, setActive] = useState(1);
  /*usestate for active class for section-tab-2 */
  const [activeTab_2, setActiveTab_2] = useState(1);
  /*active for flights tab */
  const [activeFlights, setActiveFlights] = useState(1);
  /*
  -usestate for dropdown menu
  -dropdown for cars tab
  -first dropdown*/
  const [dropdown_1, setDropdown_1] = useState("....");
  /*second dropdown*/
  const [dropdown_2, setDropdown_2] = useState("....");
  /*third dropdown*/
  const [dropdown_3, setDropdown_3] = useState("....");
  /*fourth dropdown*/
  const [dropdown_4, setDropdown_4] = useState("9.00Am");
  /*fifth dropdown*/
  const [dropdown_5, setDropdown_5] = useState("9.00Am");
  /*dropdown for flights sectoin*/
  const [dropdown_6, setDropdown_6] = useState("....");
  /*dropdown for Hotels sectoin*/
  /* const [dropdown_7,setDropdown_7] = useState("select")
  /*dropdown for Trips sectoin*/
  const [dropdown_8, setDropdown_8] = useState("....");
  const [flightsDropdown, setFlightsDropdown] = useState("");
  const [advancedOFlightsDropdown,setAdvancedOFlightsDropdown] =useState(""); 
  /********************************************* */
  /* code for collapse*/
  var [toggle, setToggle] = useState(false);
  useEffect(() => {
    var myCollapse = document.getElementById("collapseTarget");
    var bsCollapse = new Collapse(myCollapse, { toggle: false });
    toggle ? bsCollapse.show() : bsCollapse.hide();
  });
  return (
    <section className="hero-wrapper">
      <div className="hero-box hero-bg">
        <div className="overlay"></div>
        <div className="container fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-10 mx-auto responsive--column-l">
              <div className="hero-content pb-5">
                <header className="ms-header">
                  <h1 className="ms-header__title">
                    {/* {t("heroWrapper.msHeader.title")} */}
                    <div className="ms-slider">
                      <ul className="ms-slider__words">
                        <li className="ms-slider__word">{t("heroWrapper.msHeader.ms-slider-one")}</li>
                        <li className="ms-slider__word">{t("heroWrapper.msHeader.ms-slider-two")}</li>
                        <li className="ms-slider__word">{t("heroWrapper.msHeader.ms-slider-three")}</li>
                        <li className="ms-slider__word">{t("heroWrapper.msHeader.ms-slider-four")}</li>
                      </ul>
                    </div>
                  </h1>
                  <h2 className="ms-header__title ms-header__title_2">{t("heroWrapper.msHeader.waiting-for-you")}</h2>
                </header>
              </div>
              <div className="section-tab text-center pl-4">
              <ul className="nav nav-tabs custom-ul carousel-parent"
                  id="myTab"
                  role="tablist"
                >
                  <CustomCarousel show={8}>
                  <li className="nav-item hotels">
                    <button
                      onClick={() => setActive(1)}
                      className={
                        active === 1
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="hotel-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="hotel"
                      aria-selected="false"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.hotels")}
                    </button>
                  </li>
                  <li className="nav-item houses">
                    <button
                      onClick={() => setActive(2)}
                      className={
                        active === 2
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="houses-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="houses"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.houses")}
                    </button>
                  </li>
                  <li className="nav-item rests">
                    <button
                      onClick={() => setActive(3)}
                      className={
                        active === 3
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="resets-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="resets"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.rests")}
                    </button>
                  </li>
                  <li className="nav-item resorts">
                    <button
                      onClick={() => setActive(4)}
                      className={
                        active === 4
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="resorts-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="resorts"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.resorts")}
                    </button>
                  </li>
                  <li className="nav-item apartments">
                    <button
                      onClick={() => setActive(8)}
                      className={
                        active === 8
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="apartments-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="apartments"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.apartments")}
                    </button>
                  </li>
                  <li className="nav-item trips">
                    <button
                      onClick={() => setActive(5)}
                      className={
                        active === 5
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="package-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="package"
                      aria-selected="false"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.trips")}
                    </button>
                  </li>
                  <li className="nav-item flights">
                    <button
                      onClick={() => setActive(6)}
                      className={
                        active === 6
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="flight-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="flight"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.flights")}
                    </button>
                  </li>
                  <li className="nav-item cars">
                    <button
                      onClick={() => setActive(7)}
                      className={
                        active === 7
                          ? "nav-link d-flex align-items-center active"
                          : "nav-link d-flex align-items-center"
                      }
                      id="car-tab"
                      data-toggle="tab"
                      href="#"
                      role="tab"
                      aria-controls="car"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-hotel"></i>{t("heroWrapper.section-tab.cars")}
                    </button>
                  </li>
                  </CustomCarousel>
                </ul>
              </div>
              <div className="tab-content search-fields-container"
                id="myTabContent"
              >
                <div id="hotels"
                  className={
                    active === 1
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="hotel-tab"
                >
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">
                            {t("heroWrapper.tab-content.hotels.townName")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.hotels.townName")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.hotels.checkIn")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.hotels.checkIn")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.hotels.checkOut")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.hotels.checkOut")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="btn-box" onClick={onClickSearch}>
                    <Link to={"/search-result-page"}>
                      <BtnComponent value={t("heroWrapper.tab-content.hotels.searchNow")} />
                    </Link>
                  </div>
                </div>
                <div id="houses"
                  className={
                    active === 2
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="houses-tab"
                >
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">
                          {t("heroWrapper.tab-content.houses.townName")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.houses.townName")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.houses.checkIn")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.houses.checkIn")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.houses.checkOut")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.houses.checkOut")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="btn-box">
                    <Link to="/houses-search-result-page">
                      <BtnComponent value={t("heroWrapper.tab-content.houses.searchNow")} />
                    </Link>
                  </div>
                </div>
                <div id="rests"
                  className={
                    active === 3
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="rest-tab"
                >
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">
                          {t("heroWrapper.tab-content.rests.townName")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.rests.townName")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.rests.checkIn")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.rests.checkIn")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.rests.checkOut")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.rests.checkOut")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="btn-box">
                    <Link to="/rests-search-result-page">
                      <BtnComponent value={t("heroWrapper.tab-content.rests.searchNow")} />
                    </Link>
                  </div>
                </div>
                <div id="resorts"
                  className={
                    active === 4
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="resorts-tab"
                >
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">
                          {t("heroWrapper.tab-content.resorts.townName")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.resorts.townName")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.resorts.checkIn")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.resorts.checkIn")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.resorts.checkOut")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.resorts.checkOut")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="btn-box">
                    <Link to="/resorts-search-result-page">
                      <BtnComponent value={t("heroWrapper.tab-content.resorts.searchNow")} />
                    </Link>
                  </div>
                </div>
                <div id="apartments"
                  className={
                    active === 8
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="resorts-tab"
                >
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">
                          {t("heroWrapper.tab-content.apartments.townName")}
                          </label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.apartments.townName")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.apartments.checkIn")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.apartments.checkIn")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.apartments.checkOut")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.apartments.checkOut")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="btn-box">
                    <Link to="/apartments-search-result-page">
                      <BtnComponent value={t("heroWrapper.tab-content.apartments.searchNow")} />
                    </Link>
                  </div>
                </div>
                <div id="trips"
                  className={
                    active === 5
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="package-tab"
                >
                  <div className="section-tab section-tab-2">
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                      <li
                        className="nav-item"
                        onClick={() => setActiveTab_2(1)}
                      >
                        <button
                          className={
                            activeTab_2 === 1
                              ? "nav-link active-tab-2"
                              : "nav-link"
                          }
                          id="flight-hotel-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="flight-hotel"
                          aria-selected="true"
                        >
                          {t("heroWrapper.tab-content.trips.tab_list.flight_hotel")}
                        </button>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => setActiveTab_2(2)}
                      >
                        <button
                          className={
                            activeTab_2 === 2
                              ? "nav-link active-tab-2"
                              : "nav-link"
                          }
                          id="flight-hotel-car-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="flight-hotel-car"
                          aria-selected="false"
                        >
                         {t("heroWrapper.tab-content.trips.tab_list.flight_hotel_car")}
                        </button>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => setActiveTab_2(3)}
                      >
                        <button
                          className={
                            activeTab_2 === 3
                              ? "nav-link active-tab-2"
                              : "nav-link"
                          }
                          id="flight-car-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="flight-car"
                          aria-selected="false"
                        >
                         {t("heroWrapper.tab-content.trips.tab_list.flight_car")}
                        </button>
                      </li>
                      {/* <li
                        className="nav-item"
                        onClick={() => setActiveTab_2(4)}
                      >
                        <button
                          className={
                            activeTab_2 === 4
                              ? "nav-link active-tab-2"
                              : "nav-link"
                          }
                          id="hotel-car-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="hotel-car"
                          aria-selected="true"
                        >
                           {t("heroWrapper.tab-content.trips.tab_list.hotel_car")}
                        </button>
                      </li> */}
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent2">
                    <div
                      className={
                        activeTab_2 === 1
                          ? "tab-pane fade show active"
                          : "tab-pane fade show "
                      }
                      id="flight-hotel"
                      role="tabpanel"
                      aria-labelledby="flight-hotel-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.trips.tab_one.flying_from")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.trips.tab_one.city_or_airport")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.trips.tab_one.flying_to")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.trips.tab_one.city_or_airport")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">
                              {t("heroWrapper.tab-content.trips.tab_one.departing_returning")}
                              </label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="date-range custom-input custom-input"
                                  type="date"
                                  name="daterange"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="checkmark-wrap">
                        <label className="custom-checkbox">
                        {t("heroWrapper.tab-content.trips.tab_one.options.item1")}
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                        <label className="custom-checkbox">
                        {t("heroWrapper.tab-content.trips.tab_one.options.item2")}
                          <input type="checkbox"></input>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="btn-box">
                        <Link to="/trips-search-result-page">
                          <BtnComponent value= {t("heroWrapper.tab-content.trips.search_now")} />
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        activeTab_2 === 2
                          ? "tab-pane fade show active"
                          : "tab-pane fade show "
                      }
                      id="flight-hotel-car"
                      role="tabpanel"
                      aria-labelledby="flight-hotel-car-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.trips.tab_one.flying_from")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  type="text"
                                  className="custom-input custom-input"
                                  placeholder={t("heroWrapper.tab-content.trips.tab_one.city_or_airport")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.trips.tab_one.flying_to")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.trips.tab_one.city_or_airport")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">
                              {t("heroWrapper.tab-content.trips.tab_one.departing_returning")}
                              </label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="date-range custom-input"
                                  type="date"
                                  name="daterange"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-3">
                            <div className="input-box">
                              <label className="label-text">
                                Guests and Rooms
                              </label>
                              <div className="form-group">
                                <div className="dropdown dropdown-contain gty-container">
                                  <button
                                    className="dropdown-toggle dropdown-btn"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span
                                      className="adult"
                                      data-text="Adult"
                                      data-text-multi="Adults"
                                    >
                                      0 Adult
                                    </span>
                                    -
                                    <span
                                      className="children"
                                      data-text="Child"
                                      data-text-multi="Children"
                                    >
                                      0 Child
                                    </span>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-wrap">
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Rooms</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="room_number"
                                            defaultValue="0"
                                            className="qty-input"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Adults</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="adult_number"
                                            defaultValue="0"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Children</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="child_number"
                                            defaultValue="0"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </form>
                      </div>
                      <div className="checkmark-wrap">
                        <label className="custom-checkbox">
                        {t("heroWrapper.tab-content.trips.tab_one.options.item1")}
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                        <label className="custom-checkbox">
                        {t("heroWrapper.tab-content.trips.tab_one.options.item2")}
                          <input type="checkbox"></input>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="btn-box">
                        <Link to="/trips-search-result-page">
                          <BtnComponent value={t("heroWrapper.tab-content.trips.search_now")} />
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        activeTab_2 === 3
                          ? "tab-pane fade show active"
                          : "tab-pane fade show "
                      }
                      id="flight-car"
                      role="tabpanel"
                      aria-labelledby="flight-car-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.trips.tab_one.flying_from")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.trips.tab_one.city_or_airport")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.trips.tab_one.flying_to")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.trips.tab_one.city_or_airport")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">
                              {t("heroWrapper.tab-content.trips.tab_one.departing_returning")}
                              </label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="date-range custom-input custom-input"
                                  type="date"
                                  name="daterange"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-3">
                            <div className="input-box">
                              <label className="label-text">Passengers</label>
                              <div className="form-group">
                                <div className="dropdown dropdown-contain gty-container">
                                  <button
                                    className="dropdown-toggle dropdown-btn"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span
                                      className="adult"
                                      data-text="Adult"
                                      data-text-multi="Adults"
                                    >
                                      0 Adult
                                    </span>
                                    -
                                    <span
                                      className="children"
                                      data-text="Child"
                                      data-text-multi="Children"
                                    >
                                      0 Child
                                    </span>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-wrap">
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Rooms</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="room_number"
                                            defaultValue="0"
                                            className="qty-input"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Adults</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="adult_number"
                                            defaultValue="0"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Children</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="child_number"
                                            defaultValue="0"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </form>
                      </div>
                      <div className="checkmark-wrap">
                        <label className="custom-checkbox">
                        {t("heroWrapper.tab-content.trips.tab_one.options.item1")}
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="btn-box">
                        <Link to="/trips-search-result-page">
                          <BtnComponent value={t("heroWrapper.tab-content.trips.search_now")} />
                        </Link>
                      </div>
                    </div>
                    {/* <div
                      className={
                        activeTab_2 === 4
                          ? "tab-pane fade show active"
                          : "tab-pane fade show "
                      }
                      id="hotel-car"
                      role="tabpanel"
                      aria-labelledby="hotel-car-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">Going to</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder="Enter City or property"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">
                                Check in - Check-out
                              </label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="date-range custom-input"
                                  type="date"
                                  name="daterange"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">Room Type</label>
                              <div className="form-group">
                                <div className="select-contain w-auto">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      {dropdown_8}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        id="Single"
                                        defaultValue="Single"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Single
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="Double"
                                        defaultValue="Double"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Double
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="Triple"
                                        defaultValue="Triple"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Triple
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="Quad"
                                        defaultValue="Quad"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Quad
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="King"
                                        defaultValue="King"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        King
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="Twin"
                                        defaultValue="Twin"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Twin
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="Studio"
                                        defaultValue="Studio"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Studio
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id="Villa"
                                        defaultValue="Villa"
                                        onClick={(e) =>
                                          setDropdown_8(e.target.id)
                                        }
                                        href="#"
                                      >
                                        Villa
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-box">
                              <label className="label-text">
                                Guests and Rooms
                              </label>
                              <div className="form-group">
                                <div className="dropdown dropdown-contain gty-container">
                                  <button
                                    className="dropdown-toggle dropdown-btn"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span
                                      className="adult"
                                      data-text="Adult"
                                      data-text-multi="Adults"
                                    >
                                      0 Adult
                                    </span>
                                    -
                                    <span
                                      className="children"
                                      data-text="Child"
                                      data-text-multi="Children"
                                    >
                                      0 Child
                                    </span>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-wrap">
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Rooms</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="room_number"
                                            defaultValue="0"
                                            className="qty-input"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Adults</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="adult_number"
                                            defaultValue="0"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dropdown-item">
                                      <div className="qty-box d-flex align-items-center justify-content-between">
                                        <label>Children</label>
                                        <div className="qtyBtn d-flex align-items-center">
                                          <div className="qtyDec">
                                            <i className="la la-minus"></i>
                                          </div>
                                          <input
                                            type="text"
                                            name="child_number"
                                            defaultValue="0"
                                          />
                                          <div className="qtyInc">
                                            <i className="la la-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="btn-box">
                        <Link to="/trips-search-result-page">
                          <BtnComponent value={"Search Now"} />
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div id="flight"
                  className={
                    active === 6
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="flight-tab"
                >
                  <div className="section-tab section-tab-2">
                    <ul className="nav nav-tabs" id="myTab3" role="tablist">
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveFlights(1)}
                          className={
                            activeFlights === 1 ? "nav-link active" : "nav-link"
                          }
                          id="one-way-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="one-way"
                          aria-selected="true"
                        >
                          {t("heroWrapper.tab-content.flights.oneWay.title")}
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveFlights(2)}
                          className={
                            activeFlights === 2 ? "nav-link active" : "nav-link"
                          }
                          id="round-trip-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="round-trip"
                          aria-selected="false"
                        >
                          {t("heroWrapper.tab-content.flights.roundTrip.title")}
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={() => setActiveFlights(3)}
                          className={
                            activeFlights === 3 ? "nav-link active" : "nav-link"
                          }
                          id="multi-city-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="multi-city"
                          aria-selected="false"
                        >
                          {t("heroWrapper.tab-content.flights.multiCity.title")}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent3">
                    <div
                      className={
                        activeFlights === 1
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="one-way"
                      role="tabpanel"
                      aria-labelledby="one-way-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-6 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.flyingFrom")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.oneWay.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.flyingTo")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.oneWay.flyingTo")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.departing")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                onFocus={(e)=>e.target.type="date"}
                                onBlur={(e)=>e.target.type="text"}
                                  className="date-range custom-input custom-input"
                                  type="text"
                                  name="daterange-single"
                                  placeholder={t("heroWrapper.tab-content.flights.oneWay.departing")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.oneWay.class.title")}</label>
                              <div className="form-group select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {dropdown_6}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.flights.oneWay.class.economy")}
                                      defaultValue="Economy"
                                      onClick={(e) =>
                                        setDropdown_6(e.target.id)
                                      }
                                    >
                                      {t("heroWrapper.tab-content.flights.oneWay.class.economy")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.flights.oneWay.class.business")}
                                      defaultValue="Business"
                                      onClick={(e) =>
                                        setDropdown_6(e.target.id)
                                      }
                                    >
                                      {t("heroWrapper.tab-content.flights.oneWay.class.business")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.flights.oneWay.class.firstClass")}
                                      defaultValue="First class"
                                      onClick={(e) =>
                                        setDropdown_6(e.target.id)
                                      }
                                    >
                                      {t("heroWrapper.tab-content.flights.oneWay.class.firstClass")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 btn-flights-section">
                            <div className="btn-box">
                              <Link to="/flights-search-result-page">
                                <BtnComponent value={t("heroWrapper.tab-content.flights.oneWay.searchNow")} />
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={
                        activeFlights === 2
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="round-trip"
                      role="tabpanel"
                      aria-labelledby="round-trip-tab"
                    >
                      <div className="contact-form-action">
                        <form action="#" className="row align-items-center">
                          <div className="col-lg-6 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.flyingFrom")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.roundTrip.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.flyingTo")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.roundTrip.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">
                              {t("heroWrapper.tab-content.flights.roundTrip.departing")}
                              </label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                onFocus={(e)=>e.target.type="date"}
                                onBlur={(e)=>e.target.type="text"}
                                  className="date-range custom-input"
                                  type="text"
                                  name="daterange"
                                  placeholder={t("heroWrapper.tab-content.flights.roundTrip.departing")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.passengers")}</label>
                              <div className="form-group">
                                <input type="number" className="custom-input"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.roundTrip.class.title")}</label>
                              <div className="form-group">
                                <div className="select-contain w-auto">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      {flightsDropdown}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        id={t("heroWrapper.tab-content.flights.roundTrip.class.economy")}
                                        defaultValue="No preference"
                                        onClick={(e) =>
                                          setFlightsDropdown(e.target.id)
                                        }
                                        href="#"
                                      >
                                        {t("heroWrapper.tab-content.flights.roundTrip.class.economy")}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={t("heroWrapper.tab-content.flights.roundTrip.class.business")}
                                        defaultValue="Economy"
                                        onClick={(e) =>
                                          setFlightsDropdown(e.target.id)
                                        }
                                        href="#"
                                      >
                                        {t("heroWrapper.tab-content.flights.roundTrip.class.business")}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={t("heroWrapper.tab-content.flights.roundTrip.class.firstClass")}
                                        defaultValue="Compact"
                                        onClick={(e) =>
                                          setFlightsDropdown(e.target.id)
                                        }
                                        href="#"
                                      >
                                      {t("heroWrapper.tab-content.flights.roundTrip.class.firstClass")}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <label>{t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.title")}</label>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                {advancedOFlightsDropdown}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  id={t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.economy")}
                                  defaultValue="No preference"
                                  onClick={(e) =>
                                    setAdvancedOFlightsDropdown(e.target.id)
                                  }
                                  href="#"
                                >
                                  {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.economy")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  id={t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.business")}
                                  defaultValue="Economy"
                                  onClick={(e) =>
                                    setAdvancedOFlightsDropdown(e.target.id)
                                  }
                                  href="#"
                                >
                                  {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.business")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  id= {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.firstClass")}
                                  defaultValue="Compact"
                                  onClick={(e) =>
                                    setAdvancedOFlightsDropdown(e.target.id)
                                  }
                                  href="#"
                                >
                                  {t("heroWrapper.tab-content.flights.roundTrip.advancedOptions.firstClass")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="col-lg-3 flights-tab-btn">
                            <div className="btn-box">
                              <Link to="/flights-search-result-page">
                                <BtnComponent value={t("heroWrapper.tab-content.flights.roundTrip.searchNow")} />
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={
                        activeFlights === 3
                          ? "tab-pane fade show multi-flight-wrap active"
                          : "tab-pane fade show multi-flight-wrap"
                      }
                      id="multi-city"
                      role="tabpanel"
                      aria-labelledby="multi-city-tab"
                    >
                      <div className="contact-form-action multi-flight-field d-flex align-items-center">
                        <form
                          action="#"
                          className="row flex-grow-1 align-items-center"
                        >
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.flyingFrom")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.multiCity.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.flyingTo")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  placeholder={t("heroWrapper.tab-content.flights.multiCity.flyingPlace")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.departing")}</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <input
                                onFocus={(e)=>e.target.type="date"}
                                onBlur={(e)=>e.target.type="text"}
                                  className="date-range custom-input date-multi-picker"
                                  id="date"
                                  type="text"
                                  name="daterange-single"
                                  placeholder={t("heroWrapper.tab-content.flights.multiCity.departing")}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="multi-flight-delete-wrap pt-3 pl-3">
                          <button className="multi-flight-remove" type="button">
                            <i className="la la-remove"></i>
                          </button>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        {/* <div className="col-lg-3 pr-0">
                          <div className="form-group">
                            <button
                              className="theme-btn add-flight-btn margin-top-40px w-100"
                              type="button"
                            >
                              <i className="la la-plus mr-1"></i>Add another
                              flight
                            </button>
                          </div>
                        </div> */}
                        <div className="col-lg-3 pr-0">
                          <div className="input-box">
                            <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.passengers")}</label>
                            <div className="form-group">
                              <input className="custom-input" type="number"/>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 pr-0">
                          <div className="input-box">
                            <label className="label-text">{t("heroWrapper.tab-content.flights.multiCity.class.title")}</label>
                            <div className="form-group">
                              <div className="select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {flightsDropdown}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id="Economy"
                                      defaultValue="No preference"
                                      onClick={(e) =>
                                        setFlightsDropdown(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.flights.multiCity.class.economy")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id="Business"
                                      defaultValue="Economy"
                                      onClick={(e) =>
                                        setFlightsDropdown(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.flights.multiCity.class.business")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id="First className"
                                      defaultValue="Compact"
                                      onClick={(e) =>
                                        setFlightsDropdown(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.flights.multiCity.class.firstClass")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 flights-tab-btn">
                          <div className="btn-box">
                            <Link to="/flights-search-result-page">
                              <BtnComponent value={t("heroWrapper.tab-content.flights.multiCity.searchNow")} />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="cars"
                  className={
                    active === 7
                      ? "tab-pane fade show active"
                      : "tab-pane fade show "
                  }
                  role="tabpanel"
                  aria-labelledby="car-tab"
                >
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.pickingUp")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.cars.picking-up-placeholder")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.pick-up-date")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.cars.pick-up-date")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.pick-up-time.title")}</label>
                          <div className="form-group">
                            <div className="select-contain w-auto">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {dropdown_5}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionOne")}
                                    defaultValue="9.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.pick-up-time.optionOne")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionTwo")}
                                    defaultValue="8.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.pick-up-time.optionTwo")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionThree")}
                                    defaultValue="7.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.pick-up-time.optionThree")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionFour")}
                                    defaultValue="6.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.pick-up-time.optionFour")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <form action="#" className="row align-items-center">
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.dropOff")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.cars.drop-off-placeholder")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.drop-off-date")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.cars.drop-off-date")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.drop-off-time.title")}</label>
                          <div className="form-group">
                            <div className="select-contain w-auto">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {dropdown_4}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionOne")}
                                    defaultValue="9.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.drop-off-time.optionOne")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionTwo")}
                                    defaultValue="8.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.drop-off-time.optionTwo")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionThree")}
                                    defaultValue="7.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.drop-off-time.optionThree")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionFour")}
                                    defaultValue="6.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.drop-off-time.optionFour")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="collapse-wrap">
                    <div className="collaps-content text-left">
                      <button
                        className="btn btn-component"
                        onClick={() => setToggle((toggle) => !toggle)}
                      >
                        {t("heroWrapper.tab-content.cars.advancedOptions.title")}
                      </button>
                      <div className="row collapse" id="collapseTarget">
                        <div className="col-lg-3 pr-0">
                          <div className="input-box my-2">
                            <label className="label-text">{t("heroWrapper.tab-content.cars.advancedOptions.carType.title")}</label>
                            <div className="form-group">
                              <div className="select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {dropdown_1}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionOne")}
                                      defaultValue="No preference"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                     {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionOne")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionTwo")}
                                      defaultValue="Economy"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                     {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionTwo")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionThree")}
                                      defaultValue="Compact"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionThree")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionFour")}
                                      defaultValue="Midsize"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionFour")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 pr-0">
                          <div className="input-box my-2">
                            <label className="label-text">
                            {t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.title")}
                            </label>
                            <div className="form-group">
                              <div className="select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {dropdown_2}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionOne")}
                                      defaultValue="No preference"
                                      onClick={(e) =>
                                        setDropdown_2(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionOne")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionTwo")}
                                      defaultValue="ACE Rent A Car"
                                      onClick={(e) =>
                                        setDropdown_2(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionTwo")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionThree")}
                                      defaultValue="Advantage Rent-A-Car"
                                      onClick={(e) =>
                                        setDropdown_2(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionThree")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionFour")}
                                      defaultValue="Alamo Rent A Car"
                                      onClick={(e) =>
                                        setDropdown_2(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.rental-car-company.optionFour")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="input-box my-2">
                            <label className="label-text">{t("heroWrapper.tab-content.cars.advancedOptions.DiscountCode.title")}</label>
                            <div className="form-group">
                              <div className="select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {dropdown_3}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.DiscountCode.optionOne")}
                                      defaultValue="I don't have a code"
                                      onClick={(e) =>
                                        setDropdown_3(e.target.id)
                                      }
                                      href="#"
                                    >
                                     {t("heroWrapper.tab-content.cars.advancedOptions.DiscountCode.optionOne")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.DiscountCode.optionTwo")}
                                      defaultValue="Corporate or contracted"
                                      onClick={(e) =>
                                        setDropdown_3(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.DiscountCode.optionTwo")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-box">
                    <Link to="/cars-search-result-page">
                      <BtnComponent value={t("heroWrapper.tab-content.cars.searchNow")} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg className="hero-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none">
          <path d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"></path>
        </svg>
      </div>
    </section>
  );
};

export default HeroWrapper;
