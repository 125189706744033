import { GET_ALL_TOWNS } from "../TypesOfActions";
import { GetDataHook } from "../../Hooks/GetDataHook";

export const getAllTowns = ()=> async (dispatch)=>{
try{
    const response = await GetDataHook(`api/cities`)
    dispatch({
        type:GET_ALL_TOWNS,
        payload:response.data
    })
}
catch(e){
    dispatch({
        type:GET_ALL_TOWNS,
        payload:e.response
    })
}finally{}
}

