import React, { useState ,useEffect} from "react";
import notify from "../Notify/Notify"
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../Redux/Actions/userActions";
const ChangePasswordHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.UserReducer.changePassword);
  /*states*/
  const [currentEmail, setCurrentEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [loadingCh, setloading] = useState(true);
  const [isPressCh, setIsPress] = useState(false);

  /*function for states*/
 
  const onChangeCurrentEmail = (e) => {
    setCurrentEmail(e.target.value);
  };
  const onChangeNewPass = (e) => {
    setNewPass(e.target.value);
  };
  const onChangeConfirmNewPass = (e) => {
    setConfirmNewPass(e.target.value);
  };
  
  /*function validation*/
  const validation = () => {
    if (
      currentEmail === ""||
      newPass===""||
      confirmNewPass === "") 
      {
      notify("please complete the data", "error");
    }
  };
  /*on submit fn*/
  const onSubmitCh = async (e) => {
    const formData = new FormData();
    e.preventDefault();
    validation();
    formData.append("email",currentEmail)
    formData.append("new_pass", newPass);
    formData.append("confirm_pass",confirmNewPass);
    setIsPress(true);
    setloading(true);
    await dispatch(changePassword(formData));
    setloading(false);
    setIsPress(false);
  };

  useEffect(() => {
    if (loadingCh === false) {
      if (response.data) {
        if (response.status === 200) {
            notify(`${response.data.message}`, "success");
            setCurrentEmail("")
            setNewPass("")
            setConfirmNewPass("")
          }else if(response.status === 422){
            notify(`${response.data.message}`,"error")
          }else{
            notify(`هناك مشكله ما`,"error")
          }
        if (response.data.errors) {
            if(response.data.errors.email!=null){
                notify(`${response.data.errors.email}`,"error")
            }else if(response.data.errors.password!=null){
                notify(`${response.data.errors.password}`,"error")
            }
        }
      }
    }
  }, [loadingCh]);
  return[currentEmail,onChangeCurrentEmail,newPass,onChangeNewPass,
        confirmNewPass,onChangeConfirmNewPass,isPressCh,loadingCh,onSubmitCh]
};

export default ChangePasswordHook;
