import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import FilterBar from "../../../Components/GeneralSections/FilterBar/FilterBar";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
import AllServiceHook from "../../../CustomHooks/ServicesHooks/AllServiceHook";
import ResortsContainer from '../../../Components/Resorts/ResortsContainer/ResortsContainer'
import ServiceSearchResultLand from '../../../Components/ServiceComs/ServiceSearchResultLand/ServiceSearchResultLand'
const ResortsSearchResultPage = () => {
  const [,,,,countOfService] = AllServiceHook("hostels","4")
  return (
    <div className="service-search-result-page">
      <Header activeHome={"activeHome"} />
      <ServiceSearchResultLand bg={"resorts-search-result-land-bg"} service_type={"hostels"} category_id={"4"}/>
      <div className="container">
        <div className="row">
          <FilterBar service_type={"hostels"} category_id={"4"} serviceCount={countOfService} />
          <div className="result-content">
           <ResortsContainer />
          </div>
          <InfoSection />
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResortsSearchResultPage;
