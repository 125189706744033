import React from "react";
import "./SearchFlightCard.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {faPlaneArrival,faPlaneDeparture,} from "@fortawesome/free-solid-svg-icons";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
const SearchFlightCard = ({ flight }) => {
  //start code for translate
  const [t, i18n] = useTranslation();
  //End code for translate
  return (
    <div className="card-item flight-card search-flight-card transition my-2">
      <div className="card-img w-100">
        {flight ? (
          flight.attachment_relation.length > 0 ? (
            flight.attachment_relation[0].url ? (
              <Link to={`/flight-details-page/:${flight.id}`}>
                <img
                  className="h-100 w-100 mw-100"
                  src={flight.attachment_relation[0].url}
                  alt="flight-img"
                />
              </Link>
            ) : null
          ) : null
        ) : null}
      </div>
      <div className="card-body py-3">
        <div className="card-top-title d-flex justify-content-between align-items-center">
          <div>
            <h3 className="card-title font-size-17">
              {i18n.language === "ar" ? flight.off_town_ar : flight.off_town_en}
            </h3>
            <p className="card-meta font-size-14 color-text">
              {flight.is_return=="0"?
              t("Flights.SearchFlightCard.one_way_flight"):
              t("Flights.SearchFlightCard.going_and_back")}
            </p>
          </div>
          <div>
            <div className="text-right">
              <span className="font-weight-regular font-size-14 d-block">
               {t("Flights.SearchFlightCard.avg_person")}
              </span>
              <h6 className="font-weight-bold color-text">
                ${flight.economic_price}
              </h6>
            </div>
          </div>
        </div>
        <div className="flight-details py-3">
          <div className="flight-time pb-3">
            <div className="flight-time-item take-off d-flex py-1 my-1">
              <div className="flex-shrink-0 mx-2 color-primary">
                <FontAwesomeIcon icon={faPlaneDeparture} />
              </div>
              <div>
                <h3 className="card-title font-size-15 font-weight-medium mb-0">
                {t("Flights.SearchFlightCard.take_off")}
                </h3>
                <p className="card-meta color-text font-size-14">
                  {flight.flight_date} {t("Flights.SearchFlightCard.at")} {flight.flight_time}
                </p>
              </div>
            </div>
            <div className="flight-time-item landing d-flex py-1 my-1">
              <div className="flex-shrink-0 mx-2 color-primary">
                <FontAwesomeIcon icon={faPlaneArrival} />
              </div>
              <div>
                <h3 className="card-title font-size-15 font-weight-medium mb-0">
                {t("Flights.SearchFlightCard.landing")}
                </h3>
                <p className="card-meta color-text font-size-14">
                  {flight.arrive_date} {t("Flights.SearchFlightCard.at")} {flight.arrive_time}
                </p>
              </div>
            </div>
          </div>
          <p className="font-size-14 text-center color-text">
            <span className="color-text-2 mr-1">{t("Flights.SearchFlightCard.total_time")}</span>
            {flight.duration} {t("Flights.SearchFlightCard.hour")}
          </p>
        </div>
        <div className="btn-box text-center">
          <Link to={`/flight-details-page/:${flight.id}`}>
            <BtnComponent value={t("Flights.SearchFlightCard.veiw_details")} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchFlightCard;
