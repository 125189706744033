import React from "react";
import "./UserProfileSidebar.css";
import anonymous from "../../../imgs/public/anonymous.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import {faGear,faPowerOff,faShoppingCart,faTrash,} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import UserProfileSidebarHook from "../../../CustomHooks/UserHooks/UserProfileSidebarHook/UserProfileSidebarHook";
const UserProfileSidebar = ({profilePage,bookingPage,reviewPage,wishlistPage,settingPage,logout,}) => {
  const [onDeleteAcount, show, handleClose, handleShow, t,, user, logOut,getAllDate]= UserProfileSidebarHook()
  return (
    <div className="user-profile-sidebar">
      <div className="sidebar-nav">
        <div className="sidebar-nav-body">
          <div className="author-content">
            <div className="d-flex align-items-center">
              <div className="author-img avatar-sm">
                {user !== null ? (
                  user.attachment_relation &&
                  user.attachment_relation.length > 0 ? (
                    <img src={user.attachment_relation[0].url} alt="user-img" loading="lazy"/>
                  ) : (
                    <img src={anonymous} alt={"anonymous"}  loading="lazy"/>
                  )
                ) : (
                  null
                )}
              </div>
              <div className="author-bio">
                {
                user !== null ? 
                  user.updated_at ? 
                    <div>
                      <h4 className="author__title">
                        {user.first_name || "..."} {user.last_name || "..."}
                      </h4>
                      <span className="author__meta">
                      {getAllDate(new Date(user.created_at)) || "..."}
                      </span>
                    </div>
                    :null
                    :null
                }
              </div>
            </div>
          </div>
          <div className="sidebar-menu-wrap">
            <ul className="sidebar-menu list-items">
                <Link to="/user-profile-page" className={`sidebar-menu-item-1 sidebar-menu-item  ${profilePage}`}>
                  <span><FontAwesomeIcon icon={faUser} /></span> <span>{t("userProfileSidebar.my-profile")}</span>
                </Link>
                <Link to="/user-booking-page"className={`sidebar-menu-item-2 sidebar-menu-item ${bookingPage}`}>
                  <FontAwesomeIcon icon={faShoppingCart} /> {t("userProfileSidebar.my-booking")}
                </Link>
                <Link to="/user-reviews-page" className={`sidebar-menu-item-3 sidebar-menu-item ${reviewPage}`}>
                  <FontAwesomeIcon icon={faStar} /> {t("userProfileSidebar.my-reviews")}
                </Link>
                <Link to="/user-wishlist-page" className={`sidebar-menu-item-4 sidebar-menu-item ${wishlistPage}`}>
                  <FontAwesomeIcon icon={faHeart} /> {t("userProfileSidebar.wishlist")}
                </Link>
                <Link to="/user-setting-page" className={`sidebar-menu-item-5 sidebar-menu-item ${settingPage}`}>
                  <FontAwesomeIcon icon={faGear} /> {t("userProfileSidebar.settings")}
                </Link>
                <Link className={`sidebar-menu-item-6 sidebar-menu-item ${logout}`} onClick={logOut}>
                  <FontAwesomeIcon icon={faPowerOff} /> {t("userProfileSidebar.logout")}
                </Link>
              <li className={`sidebar-menu-item-7 sidebar-menu-item ${logout}`} onClick={handleShow}>
                  <FontAwesomeIcon icon={faTrash} /> {t("userProfileSidebar.delete-acount")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*Start modal for confirm delete acount*/}
      <Modal show={show} onHide={handleClose}>
                  <Modal.Header>
                    <Modal.Title>{t("userProfileSidebar.modal.title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {t("userProfileSidebar.modal.body")}
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="btn-box" onClick={handleClose}>
                      <BtnComponent value={t("userProfileSidebar.modal.cancel")} />
                    </div>
                    <div className="btn-box" onClick={onDeleteAcount}>
                      <BtnComponent value={t("userProfileSidebar.modal.delete-acount")} />
                    </div>
                  </Modal.Footer>
      </Modal>
      {/*End modal for confirm delete acount*/}
    </div>
  );
};

export default UserProfileSidebar;
