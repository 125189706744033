import {useState,useEffect} from 'react'
import notify from '../../CustomHooks/Notify/Notify'
import { useDispatch ,useSelector} from 'react-redux'
import { googleLogin, loginAction } from "../../Redux/Actions/authAction"
// import jwt decode to convert google response to json i can read
import { jwtDecode } from "jwt-decode";
const LoginHook = () => {
    const dispatch = useDispatch()
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    const response = useSelector((state)=>state.authReducer.loginUser)
    // states for google login
    const [google_login_loading,set_google_login_loading] = useState(true)
    const googleLoginResponse = useSelector((state)=>state.authReducer.googleLogin)
    //functions for states
    const onChangeEmail =(e)=>{
        setEmail(e.target.value)
    }
    //validation email
    const isEmail =(emailAdress)=>{
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
      if (emailAdress.match(regex)) 
        return true; 
    
       else 
        return false; 
    }
    /*function for validation*/
    const validation =()=>{
        if(email===""||password===""){
            notify("please complete the data","error")
        }
        if(isEmail(email)===false){
            notify("Invalid email","error")
        }
        
    }
    const onChangePassword =(e)=>{
        setPassword(e.target.value)
    }
    /* let response;*/
    const onSubmit =async(e)=>{
        e.preventDefault()
        const formData =new FormData()
        formData.append("email",email)
        formData.append("password",password)
        setIsPress(true)
        setLoading(true)
        validation()
        await dispatch(loginAction(formData))
        setLoading(false)
        setIsPress(false)
    }
    useEffect(()=>{
        try{
            if(loading===false){
                if(response.data){
                    if(response.status===200){
                        if(response.data.data.token){
                            localStorage.setItem("NozoulnaToken",response.data.data.token)
                            localStorage.setItem("NozoulnaUser",JSON.stringify(response.data.data))
                        }else{
                            localStorage.removeItem("NozoulnaToken")
                            localStorage.removeItem("NozoulnaUser")
                        }
                        if(response.data.message){
                            notify(`${response.data.message}`,"success")
                        }else{
                            notify("login success","success")
                        }
                        setEmail("")
                        setPassword("")
                        setTimeout(() => {
                            window.location.href = "/"
                        },1000);
                    }else if(response.status===422){
                        if(response.data.errors){
                            if(response.data.errors.email){
                                notify(response.data.errors.email[0],"error")
                            }
                        }else if(response.data.message){
                                if(response.data.message ==="يوجد شي خطا !"){
                                   localStorage.removeItem("NozoulnaToken")
                                   localStorage.removeItem("NozoulnaUser")
                                   notify(`${response.data.message}`,"error")
                               }
                            
                        }
                        else{
                            notify("there is a problem",'error')
                        }
                    }else{
                        notify("there is a problem")
                    }
                }else{
                    notify("there is no data","error")
                }
            }
        }catch(e){
            localStorage.removeItem("NozoulnaToken")
            localStorage.removeItem("NozoulnaUser")
    }
    },[loading])

//******************************************************************************* */
    // code for login using google
    // google login function
    const googleLoginFn =async(decoded)=>{
        const formData =new FormData()
        formData.append("email",decoded.email)
        formData.append("password",decoded.sub)
        formData.append("first_name",decoded.given_name)
        formData.append("last_name",decoded.family_name)
        formData.append("social_id",decoded.sub)
        setIsPress(true)
        set_google_login_loading(true)
        await dispatch(googleLogin(formData))
        set_google_login_loading(false)
        setIsPress(false)
        console.log(decoded.sub)
        console.log(decoded.given_name)
        console.log(decoded.family_name)
        console.log(decoded.email)
    }
    // google login success fn
    const googleLoginSuccess = async(response)=>{
        const decoded = jwtDecode(response.credential);
        googleLoginFn(decoded)
    }
    // google login error fn
    const googleLoginError =(response)=>{
        console.log(response)
    }
    useEffect(()=>{
        if(google_login_loading===false){
            if(googleLoginResponse){
                console.log(googleLoginResponse)
                if(googleLoginResponse.status==200){
                    if(googleLoginResponse.data.data){
                        localStorage.setItem("NozoulnaToken",googleLoginResponse.data.data.token)
                            localStorage.setItem("NozoulnaUser",JSON.stringify(googleLoginResponse.data.data))
                    }else{
                        localStorage.removeItem("NozoulnaToken")
                        localStorage.removeItem("NozoulnaUser")
                    }
                    if(googleLoginResponse.data.message){
                        notify(`${googleLoginResponse.data.message}`,"success")
                    }else{
                        notify("login success","success")
                    }
                    // reload the page
                    setTimeout(() => {
                        window.location.reload()
                    },1000);
                }else{
                    notify("there is a problem","warn")
                }
            }
        }
    },[google_login_loading])

    return [loading,email,password,onChangeEmail,onChangePassword,onSubmit,isPress,googleLoginSuccess,googleLoginError,google_login_loading]
}

export default LoginHook