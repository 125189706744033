import { useState ,useEffect} from "react";
import notify from "../Notify/Notify"
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../Redux/Actions/userActions";
import anonymous from '../../imgs/public/anonymous.jpg'
const UpdateProfileHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.UserReducer.updateProfile);
  /*states*/
  const [image, setImage] = useState(anonymous);
  const [firstName, setFristName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setloading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  
  let user;
  if(localStorage.getItem("NozoulnaUser")!== null){
    user = JSON.parse(localStorage.getItem("NozoulnaUser"))
  }else{user=null}

  /*function for states*/
  /* when user choose photo this method will store this photo*/
  const onChangeImage =(event)=>{
    if(event.target.files && event.target.files[0]){
      setImage(URL.createObjectURL(event.target.files[0]))
      setSelectedFile(event.target.files[0])
    }
  }
  const onChangeFirstName = (e) => {
    setFristName(e.target.value);
  };
  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  /*function validation*/
  const validation = () => {
    if (
      firstName === ""||
      lastName === ""||
      city===""||
      phone==="") 
      {
      return notify("please complete the data", "error");
    }  else if (phone.length <= 10) {
      return notify("please complete the phone number", "error");
    }
  };
  /*on submit fn*/
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    validation();
    formData.append("image",selectedFile)
    formData.append("first_name",firstName);
    formData.append("last_name",lastName);
    formData.append("phone",phone);
    formData.append("city",city);
    setIsPress(true);
    setloading(true);
    await dispatch(updateProfile(formData));
    setloading(false);
    setIsPress(false);
  };
  useEffect(() => {
    if (loading === false) {
      if(response) {
        if (response.status === 200) {
          if(response.data.data){
            localStorage.setItem("NozoulnaUser",JSON.stringify(response.data.data))
          }
            notify(`${response.data.message}`,"success");
            setFristName("")
            setLastName("")
            setCity("")
            setPhone("")
            setImage(anonymous)

          }else if(response.status === 422){
            notify(`${response.data.message}`,"error")
          }
        if (response.data.errors) {
            if(response.data.errors.email!=null){
                notify(`${response.data.errors.email}`,"error")
            }else if(response.data.errors.image!=null){
                notify(`${response.data.errors.image}`,"error")
            }else if(response.data.errors.password!=null){
                notify(`${response.data.errors.password}`,"error")
            }
        }
      }
    }
  },[loading]);
  return [
    firstName,lastName,city,phone,image,onChangeImage,
    onChangeFirstName,onChangeLastName
    ,onChangeCity,onChangePhone,loading,isPress,onSubmit
  ];
};

export default UpdateProfileHook;
