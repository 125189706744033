import {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../Redux/Actions/userActions";
const UserNotificatoinsHook = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.UserReducer.notifications);

    
  useEffect(()=>{
    if(localStorage.getItem("NozoulnaToken")!==null){
      const getNots =async()=>{
        await dispatch(getNotifications())
    }
      getNots()
    }else{
      return
    }
  },[])

    let nots = [];
    try{
        if(response){
            nots=response.data.data
        }else{
            nots=[]
        }
    }
    catch(e){}
    return [nots]
};

export default UserNotificatoinsHook;
