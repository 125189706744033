import React from 'react'
import './ClientsLogoSection.css'
import client_logo_img_1 from '../../../../imgs/client-logo.png'
import client_logo_img_2 from '../../../../imgs/client-logo2.png'
import client_logo_img_3 from '../../../../imgs/client-logo3.png'
import client_logo_img_4 from '../../../../imgs/client-logo4.png'
import client_logo_im_5 from '../../../../imgs/client-logo5.png'
import client_logo_img_6 from '../../../../imgs/client-logo6.png'
const ClientsLogoSection = () => {
  return (
    <section className="clientlogo-section section text-center"
>
<div className="container">
  <div className="row">
    <div className="col-lg-12">
      <div className="client-logo">
        <div className="client-logo-item">
          <img src={client_logo_img_1} alt="brand_image"  loading="lazy"/>
        </div>
        <div className="client-logo-item">
          <img src={client_logo_img_2} alt="brand_image"  loading="lazy"/>
        </div>
        <div className="client-logo-item">
          <img src={client_logo_img_3} alt="brand_image"  loading="lazy" />
        </div>
        <div className="client-logo-item">
          <img src={client_logo_img_4} alt="brand_image"  loading="lazy" />
        </div>
        <div className="client-logo-item">
          <img src={client_logo_im_5} alt="brand_image"  loading="lazy" />
        </div>
        <div className="client-logo-item">
          <img src={client_logo_img_6} alt="brand_image"  loading="lazy" />
        </div>
      </div>
    </div>
  </div>
</div>
</section>
  )
}

export default ClientsLogoSection