import { GET_ALL_CATEGORIES ,GET_ERROR} from "../TypesOfActions";

const initialState = {
    allCategories:[],
    loading:true
}

const CategoriesReducer = (state=initialState,action) =>{
    switch(action.type){
        case GET_ALL_CATEGORIES:
            return{
                ...state,
                allCategories:action.payload,
                loading:false
            }
        case GET_ERROR:
            return{
                allCategories:action.payload,
                loading:true
            }
            default:{
                return state
            }
    }
}

export default CategoriesReducer