import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {postLikeUnlike } from '../../Redux/Actions/userActions'
import Notify from '../Notify/Notify'
const LikeUnlikeHook = () => {
    const dispatch = useDispatch()
    const response = useSelector((state)=>state.UserReducer.postLikeUnlike)
    const [likeUnLikeLoading,setLoading] = useState(true)

    /*submit functoin*/
    const OnLikeUnlike =async(id,value)=>{
        const formData = new FormData()
        formData.append("comment_id",id)
        formData.append(value,"1")
        setLoading(true)
        await dispatch(postLikeUnlike(formData))
        setLoading(false)
        
    }
    /*useEffect to handle errors and reset the states*/
    useEffect(()=>{
        if(likeUnLikeLoading===false){
            if(response){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                }else{
                    Notify(`${response.data.message}`,"error")
                }
            }
        }
    },[likeUnLikeLoading])

    return [OnLikeUnlike,likeUnLikeLoading]
}

export default LikeUnlikeHook