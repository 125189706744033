import React from "react";
import SpinnerTwo from "../../../Components/Utility/SpinnerComponent/SpinnerTwo";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import { useTranslation } from "react-i18next";
const ServicePriceSection = ({serviceDetails,loading}) => {
  const [t, i18n] = useTranslation();
  return (
    <section className="hotel-price-section">
      <div className="sidebar-book-title-wrap mb-3">
        <h3>{t("detailsSidebar.service-price-section.title")}</h3>
        <div className="d-flex align-items-center">
          {loading === false ? (
            serviceDetails ? (
              serviceDetails.price_dollar ? (
                <div className="prices-box">
                  <div>
                    <span className="text-value ml-2 mr-1">{`$${serviceDetails.price_dollar}.00`}</span>
                    <span className="before-price"><del>{`$${Number(serviceDetails.price_dollar) + 20}.00`}</del></span>
                  </div>
                </div>
              ):serviceDetails.economic_price?(
                <div className="prices-box">
                <div>
                  <span className="text-value ml-2 mr-1">{`$${serviceDetails.economic_price}.00`}</span>
                  <span className="before-price"><del>{`$${Number(serviceDetails.economic_price) + 20}.00`}</del></span>
                </div>
              </div>
              ) : (
                <NotFoundData />
              )
            ) : (
              <SpinnerTwo />
            )
          ) : (
            <SpinnerTwo />
          )}
        </div>
      </div>
    </section>
  );
};

export default ServicePriceSection;
