import React from 'react'
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import FilterBar from '../../../Components/GeneralSections/FilterBar/FilterBar'
import FilterSidebar from '../../../Components/GeneralSections/FilterSidebar/FilterSidebar'
import InfoSection from '../../../Components/Sections/InfoSection/InfoSection'
import AllServiceHook from '../../../CustomHooks/ServicesHooks/AllServiceHook'
import TripsContainer from '../../../Components/Trips/TripsContainer/TripsContainer'
import { useTranslation } from 'react-i18next'
import ServiceHeadSection from '../../../Components/ServiceComs/ServiceHeadSection/ServiceHeadSection'
const TripsPage = () => {
  const [,,,,countOfService] = AllServiceHook("trips","5")
   // start code to translate
   const [t,i18n]=useTranslation()
   // end code to translate
   let title = "";
   try{
    if(i18n.language=="ar"){
      title="رحله"
     }else{
      title="trip"
     }
   }catch(e){}
  return (
    <div className='services-page'>
      <Header activeTrips={"activeTrips"} />
      <ServiceHeadSection bg={"trips-page-head-bg"} service_type={title}/>
      <div className='container'>
        <div className='row row-top'>
          <div className='col-lg-12'>
            <FilterBar service_type={"trips"} category_id={"5"} serviceCount={countOfService}/>
          </div>
        </div>
        <div className='row row-mid my-2'>
          <div className='col-xs-12 col-md-4 col-lg-3'>
            <FilterSidebar service_type={"trips"} category_id={"5"}/>
          </div>
          <div className='col-xs-12 col-md-8 col-lg-9'>
            <TripsContainer />
          </div>
        </div>
      </div>
      <InfoSection />
      <Footer />
    </div>
  )
}

export default TripsPage