import { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getAllReservations} from '../../Redux/Actions/userActions'
const GetAllReservationsHook= () => {
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.UserReducer.allReservations)
    const loading = useSelector((state)=>state.UserReducer.loading)
    
    useEffect(()=>{
        const getdata =async()=>{
            await dispatch(getAllReservations())
        }
        getdata()
    },[])

    let reservatoins =[];

    try{
        if(res){
            reservatoins=res.data
        }else{
            reservatoins=[]
        }
    }catch(e){}


    return [reservatoins,loading]
}


export default GetAllReservationsHook