/* import types of actions*/
import { GET_ALL_CARS,GET_ERROR,GET_CAR_DETAILS,GET_SEARCH_RESULT_CARS, MAKE_CAR_RESERVATION } from "../TypesOfActions";

/* initial state in an object*/
const initialState = {
  allCars: [],
  carDetails:[],
  searchResultCars: [],
  carReservation:[],
  loading: true,
};

const CarsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CARS:
      return {
        ...state,
        allCars:action.payload,
        loading: false,
        }
        case GET_SEARCH_RESULT_CARS:
      return {
        allCars:action.payload,
        loading: false,
        }
    case GET_CAR_DETAILS:
      return {
        ...state,
        carDetails:action.payload,
        loading: false,
        }
        case MAKE_CAR_RESERVATION:
          return{
            carReservation:action.payload,
            loading:false,
          }
        case GET_ERROR:
            return{
                allCars:action.payload,
                carDetails:action.payload,
                searchResultCars:action.payload,
                loading:true
            }
            default:
                return state
    }

};
export default CarsReducer;
