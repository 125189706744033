import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { makeReservation } from '../../../Redux/Actions/userActions'
import Notify from '../../Notify/Notify'
import { useNavigate } from 'react-router-dom'

const  TripReservHook = (service_id) => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const response = useSelector((state)=>state.UserReducer.makeReservation)

    //user from localstorage
    let user=[];
    if(localStorage.getItem("NozoulnaUser")!== null){
      user = JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{
      user=[]
    }
    /* all of usestates */
    const [payment_type,setPayment_type] = useState("cash")
    const [discount_code_id,set_discount_code_id] = useState("")
    const [partner,setPartner] = useState(user.first_name||"")
    const [reserveLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    /* on change state functions*/
    const onChangePaymentType=(e)=>{
        setPayment_type(e.target.value)
    }
    const onChangePartner=(e)=>{
        setPartner(e.target.value)
    }
    const onChangeDiscount=(e)=>{
        set_discount_code_id(e.target.value)
    }
    /*start on click book now*/
    const onClickBookNow =(id)=>{
        if(localStorage.getItem("NozoulnaToken")!==null){
            if(partner === ""){
                Notify("please enter the name","warn")
            }else{
                Navigate(`/trip-booking-page/:${id}`)
            }
        }else{
            Notify("please login first","warn")
        }
    }
    /*End on click book now*/
    /*submit function*/
    
    const onConfirmReserve =async(e)=>{
        e.preventDefault()
        /*validation function*/
        if(partner===""){
            Notify("please complete the data","warn")
        }else if(payment_type===""){
            Notify("please select a payment method","warn")
        }
        else{
        const formData = new FormData()
        formData.append("service_type","trips")
        formData.append("service_id",service_id)
        formData.append("payment_type",payment_type)
        formData.append("partner[0]",partner)
        setLoading(true)
        setIsPress(true)
        await dispatch(makeReservation(formData))
        setLoading(false)
        setIsPress(false)
        }
    }

    /*useEffect to handle errors and reset the states and show notify*/
    useEffect(()=>{
        if(reserveLoading===false){
            if(response){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                    setTimeout(() => {
                        Navigate("/payment-received")
                    }, 1000);
                }else if(response.status ===422){
                    if(response.data.errors!==null){
                      Notify("there is a problem",'error')
                    }else{
                        Notify(response.data.message,'error')
                    }
                }
                else{
                    Notify(response.data.message,'error')
                }
            }
        }
    },[reserveLoading])


    return [payment_type,onChangePaymentType,partner,onChangePartner,discount_code_id,onChangeDiscount,
    onClickBookNow,onConfirmReserve,isPress,reserveLoading]
}

export default TripReservHook