import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getAllWishlist,} from "../../Redux/Actions/userActions";

const WishlistHook = () => {
  const dispatch = useDispatch();
  /*all wishlist selectors */
  const allWishlistRes = useSelector((state) => state.UserReducer.allWishlist);
  const wishlistLoading = useSelector((state) => state.UserReducer.wishlistLoading);
  /*all wishlist items id */
  const [wishlistItemsId, setWishlistItemsId] = useState([]);
  const [loading, setLoading] = useState(true);
  /*useEffect to get all wishlist */
  useEffect(() => {
    if(localStorage.getItem("NozoulnaToken")!==null){
        const getdata = async () => {
          setLoading(true);
          await dispatch(getAllWishlist());
          setLoading(false);
        };
        getdata();
    }else{
        return
    }
  },[wishlistLoading]);
  useEffect(() => {
    if (loading===false) {
      if (allWishlistRes){
        if(allWishlistRes.status === 200 ){
          if (
            allWishlistRes.data &&
            allWishlistRes.data.data &&
            allWishlistRes.data.data.length >= 1) {
            setWishlistItemsId(allWishlistRes.data.data.map((item) => item.savedable.id));
          } else {
            setWishlistItemsId([]);
          }
          }
          else if(allWishlistRes.status === 404){
            return
          }
          else{
            return
          }
        }
        
    }
  },[loading]);
  let wishlist = [];
  try {
    if(allWishlistRes){
      if(allWishlistRes.status === 200){
        if (
          allWishlistRes.data &&
          allWishlistRes.data.data.length >= 1
        ) {
          wishlist = allWishlistRes.data;
        } else if(allWishlistRes.data.data === null){
          wishlist = [];
        }else{
          wishlist=[]
        }
      }
      else{
        wishlist=[]
      }
    }
   
  } catch (e) {}

  return [wishlist,wishlistLoading,wishlistItemsId];
};

export default WishlistHook;
