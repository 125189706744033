import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import CarPaymentRecievedHook from '../../../CustomHooks/CarsHooks/CarPaymentRecievedHook'
const CarPaymentRecievedPage = () => {
  const [data,loading,,onBookComplete,user,partner_two,partner_three,finalDate,t,] = CarPaymentRecievedHook();
  return (
    <div className="payment-recieved">
      <Header activeHome={"activeHome"} />
      <section className="payment-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-box payment-received-wrap mb-0">
                <div className="form-title-wrap">
                  <div className="step-bar-wrap text-center">
                    <ul className="step-bar-list d-flex align-items-center justify-content-around">
                      <li className="step-bar flex-grow-1 step-bar-active">
                        <span className="icon-element">1</span>
                        <p className="pt-2 color-text-2 progress-p">
                          {t("paymentRecievedPage.choose-your-car")}
                        </p>
                      </li>
                      <li className="step-bar flex-grow-1 step-bar-active">
                        <span className="icon-element">2</span>
                        <p className="pt-2 color-text-2 progress-p">
                        {t("paymentRecievedPage.your-booking-and-payment-details")}
                        </p>
                      </li>
                      <li className="step-bar step-not-completed flex-grow-1">
                        <span className="icon-element">3</span>
                        <p className="pt-2 color-text-2 progress-p">
                        {t("paymentRecievedPage.booking-completed")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-content">
                  {loading === false ? (
                    data ? (
                      <>
                        {data.reservationable && data.customer ? (
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="payment-received-list">
                                <h3 className="title font-size-24">
                                  {data.reservationable.name_en}
                                </h3>
                                <div className="card-rating">
                                  <span className="badge-2 badge-warning">
                                    {data.reservationable.stars}/5
                                  </span>
                                  <span className="review__text text-warning">
                                    {data.reservationable.review}
                                  </span>
                                  <span className="rating__text">
                                    ({data.reservationable.num_reviews} {t("paymentRecievedPage.payment-received-list.reviews")})
                                  </span>
                                </div>
                                <ul className="list-items list-items-2 py-3">
                                  <li>
                                    <span>{t("paymentRecievedPage.payment-received-list.location")}</span>
                                    <span>{data.reservationable.address}</span>
                                  </li>
                                  <li>
                                    <span>{t("paymentRecievedPage.payment-received-list.pick_up")}</span>
                                    <span>{data.start_date}</span>
                                  </li>
                                  <li>
                                    <span>{t("paymentRecievedPage.payment-received-list.drop_off")}</span>
                                    <span>{data.end_date}</span>
                                  </li>
                                  <li>
                                    <span>{t("paymentRecievedPage.payment-received-list.details")}</span>
                                    <span>
                                      {finalDate} {t("paymentRecievedPage.payment-received-list.neight")} ,({data.adult})
                                    </span>
                                  </li>
                                  <li>
                                    <span>{t("paymentRecievedPage.payment-received-list.client")}</span>
                                    <span>
                                      {data.customer.first_name}{" "}
                                      {data.customer.last_name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card-item card-item-list payment-received-card">
                                <div className="card-img">
                                  <img src={data.cardetails.driver_licence} alt="licence-img" />
                                </div>
                                <div className="card-body">
                                  <h3 className="card-title">
                                  {t("paymentRecievedPage.payment-received-list.location")} {data.cardetails.address}
                                  </h3>
                                  <div className="card-price">
                                    <span className="price__from">{t("paymentRecievedPage.payment-received-card.from")}</span>
                                    <span className="price__num">
                                      ${data.price_dollar}
                                    </span>
                                    <span className="price__text">
                                    {t("paymentRecievedPage.payment-received-card.perNeight")}
                                    </span>
                                  </div>
                                  <div className="section-block"></div>
                                  <h5>{t("paymentRecievedPage.payment-received-card.partners")}</h5>
                                  <p className="card-text">
                                    {user.first_name},{partner_two},
                                    {partner_three}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <NotFoundData />
                        )}
                        {data.customer ? (
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="payment-received-list">
                                <h3 className="title">{t("paymentRecievedPage.payment-received-list2.received")}</h3>
                                <p>
                                {t("paymentRecievedPage.payment-received-list2.thank-you")} {data.customer.first_name} {t("paymentRecievedPage.payment-received-list2.your-order")}
                                </p>
                                <div className="table-form table-responsive pt-3 pb-3">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">{t("paymentRecievedPage.payment-received-list2.order")}</th>
                                        <th scope="col">{t("paymentRecievedPage.payment-received-list2.reserve-date")}</th>
                                        <th scope="col">{t("paymentRecievedPage.payment-received-list2.total")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">#{data.id}</th>
                                        <td>{data.created_at.slice(0, 10)}</td>
                                        <td>${data.price_dollar}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <p>
                                {t("paymentRecievedPage.payment-received-list2.des")}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="payment-received-list">
                                <h3 className="title">{t("paymentRecievedPage.payment-received-list3.payment-details")}</h3>
                                <div className="table-form table-layout-2 table-responsive pt-3">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">{t("paymentRecievedPage.payment-received-list3.car")}</th>
                                        <th scope="col" className="text-right">
                                        {t("paymentRecievedPage.payment-received-list3.total")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">
                                          <div className="table-content">
                                            <p className="font-size-13 text-gray line-height-20 font-weight-medium">
                                              <span className="mr-2 color-text-2">
                                              {t("paymentRecievedPage.payment-received-list3.start-date")}
                                              </span> {data.start_date}
                                            </p>
                                            <p className="font-size-13 text-gray line-height-20 font-weight-medium">
                                              <span className="mr-2 color-text-2">
                                              {t("paymentRecievedPage.payment-received-list3.end-date")}
                                              </span> {data.end_date}
                                            </p>
                                          </div>
                                        </th>
                                        <td>
                                          <div className="table-content text-right">
                                            <h5 className="title color-text">
                                              ${data.final_price_dollar}
                                            </h5>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <div className="table-content">
                                            <p className="title">{t("paymentRecievedPage.payment-received-list3.subtotal")}</p>
                                          </div>
                                        </th>
                                        <td>
                                          <div className="table-content text-right">
                                            <h5 className="title color-text">
                                              ${data.final_price_dollar}
                                            </h5>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <div className="table-content">
                                            <p className="title">{t("paymentRecievedPage.payment-received-list3.order-total")}</p>
                                          </div>
                                        </th>
                                        <td>
                                          <div className="table-content text-right">
                                            <h5 className="title color-text">
                                              ${data.final_price_dollar}
                                            </h5>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="section-block mt-3"></div>
                              <div
                                className="btn-box text-center pt-4"
                                onClick={onBookComplete}
                              >
                                <BtnComponent value={t("paymentRecievedPage.book-complete")} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <NotFoundData />
                        )}
                      </>
                    ) : (
                      <NotFoundData />
                    )
                  ) : (
                    <SpinnerComponent />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CarPaymentRecievedPage;
