import React, { useState } from "react";
import "../Auth.css";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import SpinnerComponent from "../../Utility/SpinnerComponent/SpinnerComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope,faEye,faEyeSlash,faUser,} from "@fortawesome/free-regular-svg-icons";
import {faLocationDot,faLock,faPhone,faUpload,faXmark,} from "@fortawesome/free-solid-svg-icons";
import SignUpHook from "../../../CustomHooks/Auth/SignUpHook";
//imports for translate 
import { useTranslation } from "react-i18next";

const SignUp = ({ handleClick }) => {
  const [firstName,lastName,password,confirmPass,email,city,,phone,image,onChangeImage,onChangeFirstName,onChangeLastName,
    onChangePassword,onChangeConfirmPass,onChangeCity,onChangeGender,onChangePhone,onChangeEmail,loading,isPress,onSubmit,
  ] = SignUpHook();
  const [showpass, setShowpass] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(faEyeSlash);
  const showpassFn = () => {
    if (showpass === "password" && eyeIcon === faEyeSlash) {
      setShowpass("text");
      setEyeIcon(faEye);
    } else {
      setShowpass("password");
      setEyeIcon(faEyeSlash);
    }
  };
  // Start code for translate
   const [t,i18n] = useTranslation()
  // End code for translate 
  return (
    <div className="sign-up-wrapper">
      <div className={i18n.language=="ar"?"sign-up-popup sign-up-popup-ar":"sign-up-popup"}>
        <div className="form-box form-box-1">
          <div className="form-title-wrap">
            <h3 className="title">{t("signUpPopup.title")}</h3>
          </div>
          {/*Start close button */}
          <button
            onClick={handleClick}
            type="button"
            className="close-btn"
            data-dismiss="modal"
            aria-label="Close"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          {/*End close button */}
          {/*Start form content*/}
          <div className="form-content">
            <div className="user-profile-action d-flex align-items-center pb-2">
              <div className="form-content-head d-flex justify-content-between">
                <div className="img-content">
                  <div className="user-img">
                    <img src={image} alt="user-img" />
                  </div>
                </div>
                <div className="upload-btn-box">
                  <p className="pb-2 font-size-14 line-height-24">
                   {t("signUpPopup.instruction")}
                  </p>
                  <div className="file-upload-wrap file-upload-wrap-2">
                    <label htmlFor="upload-image">
                      <FontAwesomeIcon icon={faUpload} />
                      {t("signUpPopup.uploadImage")}
                    </label>
                    <input
                      onChange={onChangeImage}
                      id="upload-image"
                      type="file"
                      name="files"
                      className="multi file-upload-input with-preview d-none"
                      maxLength={"1"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-action">
              <form action="#">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.firstName")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faUser} />
                        <input
                          value={firstName}
                          onChange={onChangeFirstName}
                          name="first-name"
                          placeholder={t("signUpPopup.firstName")}
                          className="custom-input"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.lastName")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faUser} />
                        <input
                          value={lastName}
                          onChange={onChangeLastName}
                          name="last-name"
                          placeholder={t("signUpPopup.lastName")}
                          className="custom-input"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.email")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <input
                          value={email}
                          onChange={onChangeEmail}
                          name="mail"
                          placeholder={t("signUpPopup.email")}
                          required
                          className="custom-input"
                          type="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.phone")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faPhone} />
                        <input
                          value={phone}
                          onChange={onChangePhone}
                          name="phone"
                          placeholder={t("signUpPopup.phone")}
                          className="custom-input"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="check-gender custom-radio-wrapper">
                      <input
                        type="radio"
                        id="contactChoice1"
                        name="gender"
                        value="0"
                        onChange={onChangeGender}
                      />
                      <label htmlFor="contactChoice1">{t("signUpPopup.male")}</label>
                      <input
                        type="radio"
                        id="contactChoice2"
                        name="gender"
                        value="1"
                        onChange={onChangeGender}
                      />
                      <label htmlFor="contactChoice2">{t("signUpPopup.female")}</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.city")}</label>
                      <div className="form-group">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <input
                          value={city}
                          onChange={onChangeCity}
                          placeholder={t("signUpPopup.city")}
                          name="city"
                          className="custom-input"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.password")}</label>
                      <div className="form-group">
                        <span className="input-icon">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                        <span onClick={showpassFn} className="show-password">
                          <FontAwesomeIcon icon={eyeIcon} />
                        </span>
                        <input
                          value={password}
                          onChange={onChangePassword}
                          className="custom-input"
                          type={showpass}
                          name="pass"
                          placeholder={t("signUpPopup.password")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">{t("signUpPopup.confirmPassword")}</label>
                      <div className="form-group">
                        <span className="input-icon">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                        <span onClick={showpassFn} className="show-password">
                          <FontAwesomeIcon icon={eyeIcon} />
                        </span>
                        <input
                          value={confirmPass}
                          onChange={onChangeConfirmPass}
                          className="custom-input"
                          type={showpass}
                          name="pass"
                          placeholder={t("signUpPopup.confirmPassword")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 my-3">
                    <div className="btn-box" onClick={onSubmit}>
                      <BtnComponent value={t("signUpPopup.signUpBtn")} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
           {/*End form content*/}
        </div>
        {isPress === true ? (
          loading === true ? (
            <SpinnerComponent />
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default SignUp;
