import React from 'react'
import './ServiceCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import ServiceCardHook from '../../../CustomHooks/ServicesHooks/ServiceCardHook'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const ServiceCard = ({service,service_type,favItem}) => {
  const [wishlistActive,handleClickWishlist,type] = ServiceCardHook(service,favItem)
  //start code for translate
  const [t,i18n]=useTranslation()
  //End code for translate
  return (
    <div className='service-card-wrapper'>
      <div className='service-card primary-service-card transition'>
                    <div className="card-img">
                      {service?
                      service.attachment_relation.length>0?
                      service.attachment_relation[0].url?
                      <Link to={`/${type}-details-page/:${service.id}`}>
                      <img src={service.attachment_relation[0].url} alt="service-img" loading='lazy'/>
                      </Link>
                      :null
                      :null
                      :null
                      }
                      {
                        service?
                        service.display==="1"?
                        <span className="badge">{t("serviceCard.mostBooked")}</span>
                        :null
                        :null
                      }
                      <div onClick={()=>handleClickWishlist(service.id,service_type)}
                        className={`${wishlistActive} icon-element`}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Bookmark"
                      >
                      <FontAwesomeIcon icon={faHeart} />
                      </div>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        {
                          service.id?
                          service.name_en?
                          <Link to={`/${type}-details-page/:${service.id}`} className='transition'>
                            {i18n.language=="ar"?service.name_ar:service.name_en}
                        </Link>
                          :null
                          :<p>{t("serviceCard.no-name")}</p>
                        }
                      </h3>
                        <p className='card-meta'>
                          {/* {i18n.language=="ar"?`${service.address},${service.town_ar}`:`${service.address},${service.town_en}`} */}
                          {i18n.language=="ar"?service.town_ar:service.town_en}
                        </p>
                      <div className="card-rating">
                        <span className="badge_2">{service.stars}/5</span>
                        <span className="review__text">{t("serviceCard.average")}</span>
                        <span className="rating__text">({service.num_reviews} {t("serviceCard.reviews")})</span>
                      </div>
                      <div className="card-price d-flex align-items-center justify-content-between flex-wrap"
                      >
                        <p className='p-0 m-0'>
                          <span className="price__from">{t("serviceCard.from")}</span>
                          <span className="price__num">{`$${service.price_dollar}`}</span>
                          <span className="price__text">{t("serviceCard.perNeight")}</span>
                        </p>
                        {service.id?
                         <Link to={`/${type}-details-page/:${service.id}`}
                        className="see-details-btn transition d-none d-md-block"
                         >{t("serviceCard.seeDetails")}
                         </Link>
                      :null
                      }
                      </div>
                    </div>
      </div>
    </div>
  )
}

export default ServiceCard