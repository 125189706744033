import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SingleTourFeature } from "../../SingleTourFeature/SingleTourFeature";
import { useTranslation } from "react-i18next";

const FlightFareRules = () => {
  const[t,i18n]=useTranslation()
  return (
    <section className="flight-fare-rules text-align-start py-3 my-4">
      <h3 className="title font-size-20">{t("Flights.FlightFareRules.title")}</h3>
      <div className="fare-feature-item padding-top-30px pb-2">
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature
              title={t("Flights.FlightFareRules.item1.title")}
              icon={faCheck}
            />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature
              title={t("Flights.FlightFareRules.item2.title")}
              icon={faCheck}
            />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature
              title={t("Flights.FlightFareRules.item3.title")}
              icon={faCheck}
            />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature
              title={t("Flights.FlightFareRules.item4.title")}
              icon={faCheck}
            />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature
              title={t("Flights.FlightFareRules.item5.title")}
              icon={faCheck}
            />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature
              title={t("Flights.FlightFareRules.item6.title")}
              icon={faCheck}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlightFareRules;
