import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { Link } from "react-router-dom";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import FlightPaymentRecievedHook from "../../../CustomHooks/FlightsHooks/FlightPaymentRecievedHook";
const FlightPaymentCompletePage = () => {
  const [data, loading, , , , t, i18n] = FlightPaymentRecievedHook();
  return (
    <div className="payment-complete">
      <Header activeHome={"activeHome"} />
      <section className="payment-area section-bg section-padding min-vh-100">
        <div className="container">
          {loading === false ? (
            data ? (
              data.reservationable && data.customer ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-box payment-received-wrap mb-0">
                      <div className="form-title-wrap">
                        <div className="step-bar-wrap text-center">
                          <ul className="step-bar-list d-flex align-items-center justify-content-around">
                            <li className="step-bar flex-grow-1 step-bar-active">
                              <span className="icon-element">1</span>
                              <p className="pt-2 color-text-2 progress-p">
                                {t("paymentCompletePage.choose-your-flight")}
                              </p>
                            </li>
                            <li className="step-bar flex-grow-1 step-bar-active">
                              <span className="icon-element">2</span>
                              <p className="pt-2 color-text-2 progress-p">
                                {t(
                                  "paymentCompletePage.your-booking-and-payment-details"
                                )}
                              </p>
                            </li>
                            <li className="step-bar flex-grow-1 step-bar-active">
                              <span className="icon-element">3</span>
                              <p className="pt-2 color-text-2 progress-p">
                                {t("paymentCompletePage.booking-completed")}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-content">
                        <div className="payment-complete-list">
                          <div className="d-flex align-items-center thanks-box">
                            <span className="thanks-box-icon">
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <div>
                              <h3 className="title pb-1">
                                {t("paymentCompletePage.form-content.thanks")}
                                {" "+data.customer.first_name}
                              </h3>
                              <h3 className="title">
                                {t(
                                  "paymentCompletePage.form-content.your-booking-in"
                                )}
                                {i18n.language === "ar"
                                  ? data.reservationable.off_town_ar +
                                    " الى " +
                                    data.reservationable.inn_town_ar
                                  : data.reservationable.off_town_en +
                                    " to " +
                                    data.reservationable.inn_town_en}{" "}
                                {t(
                                  "paymentCompletePage.form-content.is-confirmed"
                                )}
                              </h3>
                            </div>
                          </div>
                          <ul className="list-items py-4">
                            <li>
                              <span>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                              {i18n.language==="ar"?data.reservationable.off_town_ar +" الى "+data.reservationable.inn_town_ar
                                    :data.reservationable.off_town_en +" to "+data.reservationable.inn_town_en}
                              <strong className="text-black"></strong>{" "}
                              {t(
                                "paymentCompletePage.form-content.is-expecting-you"
                              )}{" "}
                              <strong className="text-black">
                                {data.reservationable.fligth_date}
                              </strong>
                            </li>
                            <li>
                              <span>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                              {t("paymentCompletePage.form-content.des1")}
                              {i18n.language==="ar"?" "+data.reservationable.off_town_ar +" الى "+data.reservationable.inn_town_ar+" "
                                    :data.reservationable.off_town_en +" to "+data.reservationable.inn_town_en +" "}
                              ,{t("paymentCompletePage.form-content.des2")}
                            </li>
                            <li>
                              <span>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                              {t("paymentCompletePage.form-content.des3")}
                            </li>
                          </ul>
                          <h3 className="title">
                          {i18n.language==="ar"?data.reservationable.off_town_ar +" الى "+data.reservationable.inn_town_ar
                                    :data.reservationable.off_town_en +" to "+data.reservationable.inn_town_en}
                          </h3>
                          <p>{t("paymentCompletePage.form-content.airline")}: {i18n.language==="ar"?data.reservationable.plan_air_name_ar:data.reservationable.plan_air_name_en}</p>
                          <ul className="list-items list-items-3 list-items-4 last-list-items py-4">
                            <li>
                              <span>
                                {t("paymentCompletePage.form-content.flight_date")}
                              </span>
                              <span>{data.reservationable.flight_date}</span>
                            </li>
                            <li>
                              <span>
                                {t("paymentCompletePage.form-content.arrive_date")}
                              </span>
                              <span>{data.reservationable.arrive_date}</span>
                            </li>
                            <li>
                              <span>
                                {t(
                                  "paymentCompletePage.form-content.prepayment"
                                )}
                              </span>
                              <span>
                                {t("paymentCompletePage.form-content.des4")}
                              </span>
                            </li>
                          </ul>
                          <div className="btn-box">
                            <Link to={"/"}>
                              <BtnComponent
                                value={t(
                                  "paymentCompletePage.form-content.home"
                                )}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <NotFoundData />
              )
            ) : (
              <NotFoundData />
            )
          ) : (
            <SpinnerComponent />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default FlightPaymentCompletePage;
