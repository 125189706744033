import React,{useRef,useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown,faCalendarDays,faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Collapse, Dropdown } from "react-bootstrap";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { useTranslation } from 'react-i18next';
import CarsSearchHook from '../../../CustomHooks/CarsHooks/CarsSearchHook';
const CarsSearchPageLand = () => {
const focusInput = useRef();
const [value, setValue] = useState([0,1000]);
const [open, setOpen] = useState(false);

  // collaps
  const handleCollaps = () => {
    setOpen(!open);
  };
 const [town,name,,time_inn,time_out,date_inn,date_out,minPrice,maxPrice,,,,,
  ,,,,onChangeTown,,onChangeTime_inn,onChangeTime_out,onChangeDate_inn,onChangeDate_out
  ,onChangeMinPrice,onChangeMaxPrice,onChange_is_Luxury,onChange_is_delivery,onChange_is_sunroof,
  onChange_is_four_push,onChange_is_front_push,onChange_is_back_push,onChange_is_full_options,
  onChange_is_half_options,onChangeName,onClickSearchNow]=CarsSearchHook()

  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };
  // start code for translate
  const [t,i18n]= useTranslation()
  // End code for translate
  return (
    <section className='landing-section'>
    <div className={`service-search-result-page-land cars-search-result-land-bg`}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="search-result-content">
                <div className="section-heading">
                  <h2 className="sec__title text-white">
                    {t("CarsSearchResultPage.title")}
                  </h2>
                </div>
                <div className="search-fields-container margin-top-30px">
                  <div className="contact-form-action">
                    <form action="#" className="row">
                      <div className="col-lg-4 col-sm-12 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("CarsSearchResultPage.form.pick_up_location")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              ref={focusInput}
                              onChange={onChangeTown}
                              value={town}
                              className="custom-input"
                              type="text"
                              placeholder={t("CarsSearchResultPage.form.pick_up_location")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("CarsSearchResultPage.form.car_name")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              ref={focusInput}
                              onChange={onChangeName}
                              value={name}
                              className="custom-input"
                              type="text"
                              placeholder={t("CarsSearchResultPage.form.car_name")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("CarsSearchResultPage.form.pick_up_date")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                              onChange={onChangeDate_inn}
                              onFocus={(e)=>e.target.type="date"}
                              onBlur={(e)=>e.target.type="text"}
                              value={date_inn}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("CarsSearchResultPage.form.pick_up_date")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className='input-box'>
                        <label className="label-text">{t("CarsSearchResultPage.form.pick_up_time")}</label>
                      <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {time_inn}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id="9.00Am"
                                    defaultValue="9.00Am"
                                    onClick={onChangeTime_inn}
                                    href="#"
                                  >
                                    9.00Am
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="8.00Am"
                                    defaultValue="8.00Am"
                                    onClick={onChangeTime_inn}
                                    href="#"
                                  >
                                  8.00Am
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="7.00Am"
                                    defaultValue="7.00Am"
                                    onClick={onChangeTime_inn}
                                    href="#"
                                  >
                                   7.00Am
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="6.00Am"
                                    defaultValue="6.00Am"
                                    onClick={onChangeTime_inn}
                                    href="#"
                                  >
                                   6.00Am
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                    </Dropdown>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6">
                        <div className="input-box">
                          <label className="label-text">{t("CarsSearchResultPage.form.drop_off_date")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                              onChange={onChangeDate_out}
                              value={date_out}
                              onFocus={(e)=>e.target.type="date"}
                              onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("CarsSearchResultPage.form.drop_off_date")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6">
                        <div className='input-box'>
                        <label className="label-text">{t("CarsSearchResultPage.form.drop_off_time")}</label>
                      <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {time_out}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id="9.00Am"
                                    defaultValue="9.00Am"
                                    onClick={onChangeTime_out}
                                    href="#"
                                  >
                                    9.00Am
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="8.00Am"
                                    defaultValue="8.00Am"
                                    onClick={onChangeTime_out}
                                    href="#"
                                  >
                                  8.00Am
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="7.00Am"
                                    defaultValue="7.00Am"
                                    onClick={onChangeTime_out}
                                    href="#"
                                  >
                                   7.00Am
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="6.00Am"
                                    defaultValue="6.00Am"
                                    onClick={onChangeTime_out}
                                    href="#"
                                  >
                                   6.00Am
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                    </Dropdown>
                        </div>
                      </div>
                    </form>
                  </div>
                  <span
              className="advanced-options-btn"
              onClick={handleCollaps}
              aria-controls="example-collapse-text"
              aria-expanded={open}>
            {t("CarsSearchResultPage.form.moreOptions")} <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                  <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="advanced-wrap">
                    <div className="range-slider">
                      <p className="filter-price">
                      {t("CarsSearchResultPage.filter-price")}
                        <span className="parent-span">
                          <span className="value-0">{`$${minPrice}`}</span>
                          <span> - </span>
                          <span className="value-1">{`$${maxPrice}`}</span>
                        </span>
                      </p>
                    <Box sx={{ width:"100%"}}>
                    <Slider
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                    />
                  </Box>
                    </div>
                    <div className="checkbox-wrap">
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.delivery")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_delivery}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.luxury")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_Luxury}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.sunroof")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_sunroof}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.front_push")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_front_push}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.back_push")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_back_push}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.four_push")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_four_push}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.full_options")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_full_options}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("CarsSearchResultPage.checkBox.half_options")}</span>
                        <input type="checkbox" className="checkbox" onChange={onChange_is_half_options}/>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    </div>
                  </div>
                  </Collapse>
                  <div className="btn-box" onClick={onClickSearchNow}>
                    <BtnComponent value={t("CarsSearchResultPage.searchNow")}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bread-svg-box position-relative">
      <svg className="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div>
    </section>
  )
}

export default CarsSearchPageLand