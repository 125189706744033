import React  from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import FilterBar from "../../../Components/GeneralSections/FilterBar/FilterBar";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
import RestsContainer from '../../../Components/Rests/RestsContainer/RestsContainer'
import AllServiceHook from "../../../CustomHooks/ServicesHooks/AllServiceHook";
import ServiceSearchResultLand from '../../../Components/ServiceComs/ServiceSearchResultLand/ServiceSearchResultLand'
const RestsSearchResultPage = () => {
  const [,,,,countOfService] = AllServiceHook("rests","3")
  return (
    <div className="service-search-result-page">
      <Header activeHome={"activeHome"} />
      <ServiceSearchResultLand bg={"rests-search-result-land-bg"} service_type={"rests"} category_id={"3"}/>
      <div className="container">
        <div className="row">
          <FilterBar service_type={"rests"} category_id={"3"} serviceCount={countOfService} />
          <div className="result-content">
           <RestsContainer />
          </div>
          <InfoSection />
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RestsSearchResultPage;
