import React from 'react'
import HeroWrapper from '../../Components/Home/Sections/HeroWrapper/HeroWrapper'
import PopularHotels from '../../Components/Home/Sections/PopularHotels/PopularHotels'
import TownsSection from '../../Components/Home/Sections/TownsSection/TownsSection'
import CarsSection from '../../Components/Home/Sections/CarsSection/CarsSection'
import CtaSection from '../../Components/Home/Sections/CtaSection/CtaSection'
import TestimonialSection from '../../Components/Home/Sections/TestimonialSection/TestimonialSection'
import ClientsLogoSection from '../../Components/Home/Sections/ClientsLogoSection/ClientsLogoSection'
import MobileAppSection from '../../Components/Home/Sections/MobileAppSection/MobileAppSection'
import Header from "../../Components/GeneralSections/Header/Header";
import Footer from "../../Components/GeneralSections/Footer/Footer";
import { ToastContainer } from 'react-toastify'
const HomePage = () => {
  return (
    <div className='home-page'>
        <Header activeHome={"activeHome"}/>
        <HeroWrapper />
        <PopularHotels />
        <TownsSection/>
        <CarsSection />
        <CtaSection />
        <TestimonialSection />
        <ClientsLogoSection />
        <MobileAppSection />
        <Footer />
    </div>
  )
}

export default HomePage