import {useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import notify from '../Notify/Notify'
import { verifyEmail } from '../../Redux/Actions/authAction'

const VerifyEmailHook = () => {
    const [code,setCode] = useState("")
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.authReducer.verifyEmail)
    let user =[];
    if(localStorage.getItem("NozoulnaUser")!==null){
        user=JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{user=[]}
    const onChangeCode = (e)=>{
        setCode(e.target.value)
    }
    const onSubmit = async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("otp",code)
        formData.append("email",user.email)
        if(code === ""){
            notify("please write the code","error")
        }else{
            setLoading(true)
            setIsPress(true)
            await dispatch(verifyEmail(formData))
            setLoading(false)
            setIsPress(false)
        }
    }
    useEffect(()=>{
        if(loading === false){
            if(res){
                if(res.status===200){
                    notify(`${res.data.message}`,"success")
                    setTimeout(() => {
                        window.location.href="/"
                    }, 1000);
                }else if(res.status===400){
                    notify(`${res.data.message}`,"error")
                }
                if (res.data.errors) {
                    if(res.data.errors.token!=null){
                        notify(`${res.data.errors.token}`,"error")
                    }else if(res.data.errors.password!=null){
                        notify(`${res.data.errors.password}`,"error")
                    }
                }
            }
        }
    },[loading])
    return [code,onChangeCode,onSubmit,loading,isPress]
}

export default VerifyEmailHook