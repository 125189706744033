import {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllFlightsAction, GetSearchResultFlights } from '../../Redux/Actions/FlightsActions'

const AllFlightsHook = () => {
    const dispatch = useDispatch()
    const response = useSelector((state)=>state.FlightsReducer.allFlights)
    const loading = useSelector((state)=>state.FlightsReducer.loading)
    
    useEffect(()=>{
      const get =async()=>{
        await dispatch(GetAllFlightsAction())
      }
      get()
    },[])
    
    let allFlights=[];
    try{
      if(response){
        if(response.data){
            allFlights=response.data
        }
      }else{
        allFlights=[]
      }
    }catch(e){}
    
     //on press function for pagenation
     const onPress = async (page) => {
      await dispatch(
        GetSearchResultFlights(`page=${page}`)
      );
    };
    
    let allFlightsNum=0;
    try{
      if(response){
        if(response.data){
            allFlightsNum=response.data.last_page
        }
      }else{
        allFlightsNum=0
      }
    }catch(e){}
    
    let countOfFlights=0;
    try{
        if(response){
            if(response.data){
                countOfFlights = response.data.data.length
            }else{
                countOfFlights = 0;
            }
        }
    }catch(e){}
    
    return [allFlights,loading,onPress,allFlightsNum,countOfFlights]
    
}

export default AllFlightsHook