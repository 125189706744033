import React from 'react'
import { faDollar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const WhyBookWithUsSection = () => {
  const [t,i18n] = useTranslation()
  return (
    <section className='why-book-with-us-section'>
        <h3 className="title stroke-shape">{t("detailsSidebar.why-book-with-us-section.title")}</h3>
                  <div className="sidebar-list">
                    <ul className="list-items">
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>{t("detailsSidebar.why-book-with-us-section.list.item1")}</span>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>{t("detailsSidebar.why-book-with-us-section.list.item2")}</span>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>{t("detailsSidebar.why-book-with-us-section.list.item3")}</span>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>{t("detailsSidebar.why-book-with-us-section.list.item4")}</span>
                      </li>
                    </ul>
                  </div>
    </section>
  )
}

export default WhyBookWithUsSection