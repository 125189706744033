import {
    CREATE_NEW_USER,
    LOGIN_USER,
    GET_CURERNT_USER,
    FORGET_PASSWORD,
    VERIFY_CODE,RESET_PASSWORD,VERIFY_EMAIL,DELETE_ACOUNT, GOOGLE_LOGIN}
     from "../TypesOfActions";
import {GetDataHookToken} from '../../Hooks/GetDataHook'
import { PostDataHook } from "../../Hooks/PostDataHook";

export const signUpAction = (formData)=> async (dispatch) =>{
    try{
            const response = await PostDataHook(`api/user/register`,formData)
        dispatch({
            type:CREATE_NEW_USER,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:CREATE_NEW_USER,
        payload:e.response,
        })
    }
}
export const loginAction = (formData)=> async (dispatch) =>{
    try{
        const response = await PostDataHook(`api/user/login`,formData)
        dispatch({
            type:LOGIN_USER,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:LOGIN_USER,
        payload:e.response,
        })
    }
}
export const googleLogin = (formData)=> async (dispatch) =>{
    try{
        const response = await PostDataHook(`api/user/complete_social_login`,formData)
        dispatch({
            type:GOOGLE_LOGIN,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:GOOGLE_LOGIN,
        payload:e.response,
        })
    }
}
export const getLoggedUser = () => async (dispatch) => {
    try {
        const response = await GetDataHookToken(`api/v1/users/getMe`);
        dispatch({
            type: GET_CURERNT_USER,
            payload: response,
            loading: true
        })

    } catch (e) {
        dispatch({
            type: GET_CURERNT_USER,
            payload: e.response,
        })
    }
}

export const forgetPassword =(formData)=> async(dispatch)=>{
    try{
            const response = await PostDataHook("api/user/password/email",formData)
        dispatch({
            type:FORGET_PASSWORD,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
            type:FORGET_PASSWORD,
            payload:e.response
        })
    }
}
export const verifyEmail =(formData)=> async(dispatch)=>{
    try{
            const response = await PostDataHook("api/user/phoneVerify",formData)
        dispatch({
            type:VERIFY_EMAIL,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
            type:VERIFY_EMAIL,
            payload:e.response
        })
    }
}
export const verifyCode =(formData)=> async(dispatch)=>{
    try{
            const response = await PostDataHook("api/user/password/token/check",formData)
        dispatch({
            type:VERIFY_CODE,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
            type:VERIFY_CODE,
            payload:e.response
        })
    }
}
export const resetPassword =(formData)=> async(dispatch)=>{
    try{
            const response = await PostDataHook("api/user/password/reset",formData)
        dispatch({
            type:RESET_PASSWORD,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
            type:RESET_PASSWORD,
            payload:e.response
        })
    }
}
export const deleteAcount =()=> async(dispatch)=>{
    try{
            const response = await GetDataHookToken("api/user/delet_user")
        dispatch({
            type:DELETE_ACOUNT,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
            type:DELETE_ACOUNT,
            payload:e.response
        })
    }
}
