import { CustomAxios } from "../Api/baseUrl"

/*this is custom hook called useDeleteData like useEffect and other hooks*/
const DeleteDataHook = async(url)=>{
    /*url for our url from api*/
    const config ={
        headers: {"Authorization" : `Bearer ${localStorage.getItem("NozoulnaToken")}`} 
      }
    const res = await CustomAxios.delete(url,config)
    return res.data
}

export default DeleteDataHook