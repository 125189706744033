import { CustomAxios } from "../Api/baseUrl";

/* this is custom hook called useGetData like useEffect and other hooks*/
const GetDataHook = async(url,params)=>{
    /* url for our url from api*/
    /* params if we need to put a params (we will use those params when we need to post something to api)*/
    const res = await CustomAxios.get(url,params)
    return res
}

/* when we need to get a user information using api not localstorage*/
const GetDataHookToken = async (url) => {
        const config = {
            headers: {"Authorization":`Bearer ${localStorage.getItem("NozoulnaToken")}` }
        }
        const res = await CustomAxios.get(url,config);
        return res
}

export {GetDataHook,GetDataHookToken}
