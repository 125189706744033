import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const CarPaymentRecievedHook = () => {
    const  res = useSelector((state)=>state.CarsReducer.carReservation)
    const loading = useSelector(state=> state.CarsReducer.loading)
    const Navigate = useNavigate()
    // start code for translate
    const [t,i18n]=useTranslation()
    // End code for translate
    // get the data from local storage
      let user, partner_two, partner_three;
      if (localStorage.getItem("NozoulnaUser") != null) {
        user = JSON.parse(localStorage.getItem("NozoulnaUser"));
      }
      if (localStorage.getItem("partner_two") != null) {
        partner_two = localStorage.getItem("partner_two");
      }
      if (localStorage.getItem("partner_Three") != null) {
        partner_three = localStorage.getItem("partner_Three");
      }
    // on click book complete fn
    const onBookComplete =()=>{
        if(localStorage.getItem("room_id")!==null){
            localStorage.removeItem("room_id")
        }
        if(localStorage.getItem("start_date")!==null){
            localStorage.removeItem("start_date")
        }
        if(localStorage.getItem("end_date")!==null){
            localStorage.removeItem("end_date")
        }
        if(localStorage.getItem("partner_two")!==null){
            localStorage.removeItem("partner_two")
        }
        if(localStorage.getItem("partner_Three")!==null){
            localStorage.removeItem("partner_Three")
        }
        if(localStorage.getItem("partners_Qty")!==null){
            localStorage.removeItem("partners_Qty")
        }
        if(localStorage.getItem("adults")!==null){
            localStorage.removeItem("adults")
        }
        if(localStorage.getItem("pick_up_date")!==null){
            localStorage.removeItem("pick_up_date")
        }
        if(localStorage.getItem("drop_off_date")!==null){
            localStorage.removeItem("drop_off_date")
        }
        if(localStorage.getItem("delivery")!==null){
            localStorage.removeItem("delivery")
        }
        if(localStorage.getItem("address")!==null){
            localStorage.removeItem("address")
        }
        Navigate("/car-payment-complete-page")
    }
    // store the response to the data variable
    let data = [];
    try{
        if(res){
            if(res.data && res.data.data){
                data = res.data.data[0];
            }
        }else{
            data = [];
        }
    }catch(e){}
    if (data) {
        let date_one = new Date(data.start_date);
        let date_two = new Date(data.end_date);
        var finalDate = Math.floor((date_two - date_one) / 1000 / 60 / 60 / 24);
      }
    // get cancel id
    let cancelId ="";
    try{
        if(res){
            if(res.data && res.data.data && res.data.data[0]){
                cancelId=res.data.data[0].id
            }else{
                cancelId=""
            }
        }
    }catch(e){}
    
    return [data,loading,cancelId,onBookComplete,user,partner_two,partner_three,finalDate,t,i18n]
}

export default CarPaymentRecievedHook