import {useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { forgetPassword } from '../../Redux/Actions/authAction'
import notify from '../Notify/Notify'
const ForgetPasswordHook = () => {
    const [email,setEmail] = useState("")
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.authReducer.forgetPassword)

    const onChangeEmail = (e)=>{
        setEmail(e.target.value)
    }
    const onSubmit = async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("email",email)
        if(email === ""){
            notify("Set an email","error")
        }
        setLoading(true)
        setIsPress(true)
        await dispatch(forgetPassword(formData))
        setLoading(false)
        setIsPress(false)
    }
    useEffect(()=>{
        if(loading === false){
            if(res){
                if(res.status===200){
                    notify(`${res.data.message}`,"success")
                    setTimeout(() => {
                        window.location.href="/verifyCodePage"
                    }, 1000);
                }else if(res.status===400){
                    notify(`${res.data.message}`,"error")
                }
            }
        }
    },[loading])
    return[email,onChangeEmail,onSubmit,loading,isPress]
}

export default ForgetPasswordHook