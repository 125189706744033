import React, {useState } from "react";
import "./UserReviewsPage.css";
import avatar from "../../../imgs/public/anonymous.jpg";
import UserProfileSidebar from "../../../Components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../Components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../Components/User/UserDashboardBread/UserDashboardBread";
import UserDashboardFooter from "../../../Components/User/UserDashboardFooter/UserDashboardFooter";
import { faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetUserReviewsHook from "../../../CustomHooks/UserHooks/GetUserReviewsHook";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import { useTranslation } from "react-i18next";
const UserReviewsPage = () => {
  const [reviews, loading, user, onDeleteComment] = GetUserReviewsHook();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = (comment_id) => {
    setShow(false)
    onDeleteComment(comment_id)
  }
  // start code for translate
  const [t,i18n]= useTranslation()
  // End code for translate
  let title=""
  try{
    if(i18n.language==="ar"){
        title="تعليقاتى"
    }else{
        title="My Reviews"
    }
  }catch(e){}
  return (
    <div className="user-reviews-page page">
      <div className={i18n.language==="ar"?'user-sidebar-wrap-ar d-none d-lg-block':'user-sidebar-wrap d-none d-lg-block'}>
        <UserProfileSidebar reviewPage={"reviewPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav reviewsPageActive={"page-active"} />
        <UserDashboardBread section_title={title} />
        <div className="dashboard-main-content">
          <div className="container-fluid">
            <div className="row row-content">
            <div className="col-lg-12">
                  <div className="form-box">
                    <div className="form-title-wrap">
                      <h3 className="title">{t("userReviewsPage.title")}</h3>
                      {loading === false ? (
                        reviews && reviews.data && reviews.data.comments ? (
                          <p className="font-size-14">
                            {reviews.data.comments.length} {t("userReviewsPage.results")}
                          </p>
                        ) : (
                          "0"
                        )
                      ) : (
                        <p>{t("userReviewsPage.waiting")}...</p>
                      )}
                    </div>
                    <div className="form-content">
                      <div className="comments-list">
                        {loading === false ? (
                          reviews ?
                          reviews.data ? (
                            reviews.data.comments ? (
                              reviews.data.comments.map((comment,index) => (
                                <div className="comment" key={index}>
                                  <Modal show={show} onHide={handleClose}>
                                    <Modal.Header>
                                      <Modal.Title>{t("userReviewsPage.modal.title")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    {t("userReviewsPage.modal.body")}
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <div
                                        className="btn-box"
                                        onClick={handleClose}
                                      >
                                        <BtnComponent value={t("userReviewsPage.modal.cancel")}/>
                                      </div>
                                      <div
                                        className="btn-box"
                                        onClick={() =>handleDelete(comment.id)}
                                      >
                                        <BtnComponent value={t("userReviewsPage.modal.delete-comment")} />
                                      </div>
                                    </Modal.Footer>
                                  </Modal>
                                 <div className="d-flex">
                                   <div className="comment-avatar">
                                     {user.attachment_relation &&
                                     user.attachment_relation.length > 0 ? (
                                       <img
                                         className="avatar__img"
                                         alt=""
                                         src={user.attachment_relation[0].url}
                                       />
                                     ) : (
                                       <img
                                         className="avatar__img"
                                         alt=""
                                         src={avatar}
                                       />
                                     )}
                                   </div>
                                   <div className="comment-body">
                                     <div className="meta-data">
                                       <h3 className="comment__author">
                                         {comment.name}
                                       </h3>
                                       <div className="meta-data-inner d-flex">
                                         <span className="ratings d-flex align-items-center">
                                           {comment.stars}
                                           <FontAwesomeIcon icon={faStar} />
                                         </span>
                                         <p className="comment__date">
                                           {comment.created_at.slice(0, 10)}
                                         </p>
                                       </div>
                                     </div>
                                     <p className="comment-content mb-0">
                                       {comment.comment}
                                     </p>
                                   </div>
                                 </div>
                                  <div className="delete-comment-btn"onClick={handleShow}>
                                    <BtnComponent
                                      icon={<FontAwesomeIcon icon={faTrash} />}
                                    />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <NotFoundData />
                            )
                          ) : (
                            <SpinnerComponent />
                          ) : (
                            <SpinnerComponent />
                          )
                        ) : (
                          <SpinnerComponent />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="border-top mt-5"></div>
            <div className="row row-footer">
              <UserDashboardFooter />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserReviewsPage;
