/* import types of actions*/
import { GET_ALL_HOTELS,GET_ERROR,GET_HOTEL_DETAILS,GET_SEARCH_RESULT_HOTELS } from "../TypesOfActions";

/* initial state in an object*/
const initialState = {
  hotels: [],
  hotelsLoading: true,
  hotelDetails:[],
  hotelDetailsLoading: true,
  searchResultHotels:[],
  searchResultHotelsLoading:true
};

const HotelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HOTELS:
      return {
        ...state,
        hotels:action.payload,
        hotelsLoading: false,
        }
        case GET_SEARCH_RESULT_HOTELS:
      return {
        hotels:action.payload,
        hotelsLoading: false,
        }
    case GET_HOTEL_DETAILS:
      return {
        ...state,
        hotelDetails:action.payload,
        hotelDetailsLoading: false,
        }
        case GET_ERROR:
            return{
                hotels:action.payload,
                hotelsLoading:true,
                hotelDetails:action.payload,
                hotelDetailsLoading:true,
                commentStatus:action.payload,
                commentLoading:true,
                searchResultHotels:action.payload,
                searchResultHotelsLoading: true,
            }
            default:
                return state
    }

};
export default HotelsReducer;
