import React, { useState } from "react";
import "./LocationSection.css";
import { faVideo, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faImages } from "@fortawesome/free-regular-svg-icons";
import CustomCarousel from "../../Utility/CustomCarousel/CustomCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Map from "../../Utility/Map/Map";

const LocationSection = ({service_type, serviceDetails }) => {
  const [showImages, setShowImages] = useState("showing-images");
  const [showVideo, setShowVideo] = useState("showing-video");
  const showImagesFn = () => {
    if (showImages === "showing-images") {
      setShowImages("showing-images show");
    } else {
      setShowImages("showing-images");
    }
  };
  const showVideoFn = () => {
    if (showVideo === "showing-video") {
      setShowVideo("showing-video show");
    } else {
      setShowVideo("showing-video");
    }
  };
  // start code for translate
  const [t, i18n] = useTranslation();
  // End code for translate
  return (
    <section className="location-section">
      <div id="description" className="page-scroll">
        <div className="location-section-head">
          <Map service={serviceDetails}/>
          <h3 className="title font-size-26 pt-4">{i18n.language === "ar"? serviceDetails.name_ar: serviceDetails.name_en}</h3>
          <div className="dis d-flex align-items-center pt-2">
            {i18n.language === "ar" ? (
              <p className="mr-2">{`${serviceDetails.address} ${serviceDetails.town_ar}`}</p>
            ) : (
              <p className="mr-2">{`${serviceDetails.address} ${serviceDetails.town_en}`}</p>
            )}
            <p>
              <span className="badge-2 badge-warning text-white font-size-16">
                {`${serviceDetails.stars}/5`}
              </span>
              {i18n.language === "ar" ? (
                <span>{`(${serviceDetails.num_reviews} تقييم)`}</span>
              ) : (
                <span>{`(${serviceDetails.num_reviews} Reviews)`}</span>
              )}
            </p>
          </div>
        </div>
          <div className="single-content-item">
            <h3 className="title font-size-20">
              {i18n.language==="ar"?"حول"+ serviceDetails.name_ar:"About"+serviceDetails.name_en}
            </h3>
            <p className="py-3">{i18n.language==="ar"?serviceDetails.description_ar:serviceDetails.description_en}</p>
          </div>
        <div className="service-media d-flex align-items-center">
          <div className="service-photos mx-2 my-2">
            <button
              className="btn"
              onClick={showImagesFn}
              data-src="images/img1.jpg"
              data-fancybox="gallery"
              data-caption="Showing image - 01"
              data-speed="700"
            >
              <FontAwesomeIcon icon={faImages} />{" "}
              {i18n.language === "ar" ? <span>الصور</span> : <span>photos</span>}
            </button>
            <div className={showImages}>
              <div className="row h-100">
                <div className="col-sm-12 my-4">
                  <div className="controls">
                    <div
                      className="showing-images-btn-close"
                      onClick={showImagesFn}
                    >
                      <span className="custom-icon">
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 my-4">
                  <div className="carousel-parent">
                    <CustomCarousel show={1}>
                      {serviceDetails && serviceDetails.attachment_relation
                        ? serviceDetails.attachment_relation.map(
                            (hotelImg, index) => {
                              return (
                                <img
                                  src={`${hotelImg.url}`}
                                  key={index}
                                  alt="hotel-img"
                                />
                              );
                            }
                          )
                        : null}
                    </CustomCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="service-video mx-2 my-2">
            <button
              className="btn"
              onClick={showVideoFn}
              data-fancybox="gallery"
              data-caption="Showing video - 01"
              data-speed="700"
            >
              <FontAwesomeIcon icon={faVideo} /> <span>{i18n.language==="ar"?"فيديو":"Video"}</span> 
            </button>
            <div className={showVideo}>
              <div className="row h-100">
                <div className="col-sm-12 my-4">
                  <div className="controls">
                    <div
                      className="showing-video-btn-close"
                      onClick={showVideoFn}
                    >
                      <span className="custom-icon">
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 my-4">
                  {serviceDetails ? (
                    <video width="320" height="240" controls>
                      <source
                        src={`https://multi.nozoluna.com/public/dash/assets/img/${serviceDetails.video}`}
                        type="video/mp4"
                      />
                      <p>there is a problem</p>
                    </video>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationSection;
