import React from 'react'
import '../../../Components/Sections/InfoSection/InfoSection.css'
import { Col, Container, Row } from 'react-bootstrap'
import SpinnerComponent from '../../Utility/SpinnerComponent/SpinnerComponent'
import Pagenation from '../../Utility/Pagenation/Pagenation'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
import AllFlightsHook from '../../../CustomHooks/FlightsHooks/AllFlightsHook'
import SearchFlightCard from '../SearchFlightCard/SearchFlightCard'
const SearchFlightsContainer = () => {
    const [allFlights,loading,onPress,allFlightsNum,] = AllFlightsHook()
  return (
    <Container className='flights-content min-vh-100 flights-container position-relative'>
        <Row>
        {loading === false ?
              (
                allFlights?
                allFlights.data?
                allFlights.data.length>=1?
                allFlights.data.map((flight)=>{
                  return(
                    <Col xs="12" sm="6" lg="4" key={flight.id}>
                    <SearchFlightCard flight={flight}/>
                    </Col>
                  )
                })
                :<NotFoundData />
                :<NotFoundData />
                :<NotFoundData />
              )
              :<SpinnerComponent />
              }
              {
                allFlights?
                allFlights.data?
                allFlights.data.length>=1?
                <Pagenation pageCount={allFlightsNum} onPress={onPress}/>
                :null
                :null
                :null
              }
        </Row>
    </Container>
  )
}

export default SearchFlightsContainer