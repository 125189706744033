/* import types of actions*/
import { GET_ALL_SERVICE,GET_ERROR,GET_SERVICE_DETAILS,GET_SEARCH_RESULT_SERVICE } from "../TypesOfActions";

/* initial state in an object*/
const initialState = {
  allService: [],
  serviceLoading: true,
  serviceDetails:[],
  serviceDetailsLoading: true,
  searchResultService: [],
  searchResultServiceLoading:true
};

const ServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SERVICE:
      return {
        ...state,
        allService:action.payload,
        serviceLoading: false,
        }
        case GET_SEARCH_RESULT_SERVICE:
      return {
        allService:action.payload,
        serviceLoading: false,
        }
    case GET_SERVICE_DETAILS:
      return {
        ...state,
        serviceDetails:action.payload,
        serviceDetailsLoading: false,
        }
        case GET_ERROR:
            return{
                allService:action.payload,
                serviceLoading:true,
                serviceDetails:action.payload,
                serviceDetailsLoading:true,
                searchResultService:action.payload,
                searchResultServiceLoading: true,
            }
            default:
                return state
    }

};
export default ServicesReducer;
