import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

const GetAQuestionSection = () => {
  const [t,i18n] = useTranslation()
  return (
    <section className='get-a-question-section'>
        <h3 className="title stroke-shape">{t("detailsSidebar.get-a-question-section.title")}</h3>
                  <p className="font-size-14 line-height-24" style={{textAlign:"start"}}>
                  {t("detailsSidebar.get-a-question-section.description")}
                  </p>
                  <div className="sidebar-list pt-3">
                    <ul className="list-items">
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>{" "}
                        <p className='phone-numbers'>{t("detailsSidebar.get-a-question-section.phone")}</p>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>{" "}
                        <a href="mailto:info@nozoulna.com">{t("detailsSidebar.get-a-question-section.email")}</a>
                      </li>
                    </ul>
                  </div>
    </section>
  )
}

export default GetAQuestionSection