import React, { useState } from "react";
import "./FaqSection.css";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import QuestionsHook from "../../../CustomHooks/Questions/QuestionsHook";
import SpinnerComponent from "../../Utility/SpinnerComponent/SpinnerComponent";
import { useTranslation } from "react-i18next";
import NotFoundData from "../../Utility/NotFoundData/NotFoundData";
const FaqSection = ({serviceDetails}) => {
  const [open_1, setOpen_1] = useState(false);
  const [icon1, setIcon1] = useState(faPlus);
  const handleBtn1 = () => {
    setOpen_1(!open_1);
    if (icon1 === faPlus) {
      setIcon1(faMinus);
    } else {
      setIcon1(faPlus);
    }
  };
  const [loading,questions] = QuestionsHook()

  const [t,i18n]=useTranslation()
    let value=""
    try{
        if(i18n.language==="ar"){
            value="الاسئله الشائعه"
        }else{
            value="FAQs"
        }
    }catch(e){}
  return (
    <section id="faq" className="faq-section">
    <h4 className="mini-custom-title">{value}</h4>
      <div className="single-content-item padding-top-40px padding-bottom-40px">
        <div className="faq-collaps">
          <div className="q-card">
            <Button
              onClick={handleBtn1}
              aria-controls="example-collapse-text"
              aria-expanded={open_1}
            >
              <div>
                <FontAwesomeIcon icon={icon1} /> 
              </div>
                {t("faqSection.faqs")}
            </Button>
            <Collapse in={open_1}>
              <div id="example-collapse-text">
               {
                loading===false?
                questions?
                questions.map((item)=>{return(
                  <div className="q-card-item" key={item.id}>
                    {
                      i18n.language==="ar"?
                      <h5 className="question-title">{item.question_ar}?</h5>
                      :
                      <h5 className="question-title">{item.question_en}?</h5>
                    }
                    {
                      item.answers.map((item)=>{return(
                        i18n.language==="ar"?
                        <p className="answer-value" key={item.id}>{item.answer_ar}.</p>
                        :
                        <p className="answer-value" key={item.id}>{item.answer_en}.</p>
                      )})
                    }
                  </div>
                )})
                :<NotFoundData />
                :<SpinnerComponent />
               }
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
