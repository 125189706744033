import React from 'react'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendar, faHeart, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import UpdateWishlistHook from '../../../CustomHooks/UserHooks/UpdateWishlistHook';
import HouseReservHook from '../../../CustomHooks/UserHooks/Houses/HouseReservHook';
import { useTranslation } from 'react-i18next';

const HouseBookingInfoSection = ({id}) => {
    const [,onAddToWishlist] = UpdateWishlistHook()
    const   [,,,,start_date,onChangeStartDate,end_date,
      onChangeEndDate,,,,,,partnerTwo,onChangePartnerTwo,
      partnerThree,onChangePartnerThree,onClickBookNow]=HouseReservHook(id)
      const [t,i18n] = useTranslation()
  return (
    <section className='booking-info-section'>
        <div className="contact-form-action">
                      <form action="#">
                        <div className="input-box">
                          <label className="label-text">
                          {t("houseBookingInfo.check-in")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faCalendar}/>
                            <input
                            value={start_date}
                            onChange={onChangeStartDate}
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="start-date"
                              placeholder={t("houseBookingInfo.check-in")}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("houseBookingInfo.check-out")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faCalendar}/>
                            <input
                            value={end_date}
                            onChange={onChangeEndDate}
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="end-date"
                              placeholder={t("houseBookingInfo.check-out")}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("houseBookingInfo.second-partner")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partnerTwo}
                            onChange={onChangePartnerTwo}
                              className="custom-input"
                              type="text"
                              name="partner-two"
                              placeholder={t("houseBookingInfo.second-partner")}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("houseBookingInfo.third-partner")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partnerThree}
                            onChange={onChangePartnerThree}
                              className="custom-input"
                              type="text"
                              name="partner-three"
                              placeholder={t("houseBookingInfo.third-partner")}
                            />
                          </div>
                        </div>
                      </form>
        </div>
            <div className="btn-box btn-box-1 pt-2" onClick={()=>onClickBookNow(id)}>
                <BtnComponent icon={<FontAwesomeIcon icon={faCartShopping} />}value={t("houseBookingInfo.book-now")}/>
            </div>
            <div className="btn-box btn-box-2 pt-2" onClick={()=>onAddToWishlist(id,"houses")}>
              <BtnComponent icon={<FontAwesomeIcon icon={faHeart} />} value={t("houseBookingInfo.add-to-wishlist")}/>
            </div>
    </section>
  )
}

export default HouseBookingInfoSection