import React ,{useState,useEffect}from 'react'
import './CarsPageHeadSection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCreditCard, faHandPointUp } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faMapLocationDot, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import { Link } from 'react-router-dom'
import { Collapse } from "bootstrap";
const CarsPageHeadSection = ({bg,service_type}) => {
    const [dropdown_1, setDropdown_1] = useState("select");
    const [dropdown_2, setDropdown_2] = useState("select");
    const [dropdown_3, setDropdown_3] = useState("select");
    const [dropdown_4, setDropdown_4] = useState("9.00Am");
    const [dropdown_5, setDropdown_5] = useState("9.00Am");
    // start code for translate
const [t,i18n]= useTranslation()
// End code for translate
 /* code for collapse*/
 var [toggle, setToggle] = useState(false);
 useEffect(() => {
   var myCollapse = document.getElementById("collapseTarget");
   var bsCollapse = new Collapse(myCollapse, { toggle: false });
   toggle ? bsCollapse.show() : bsCollapse.hide();
 });
  return (
    <section className={`page-head-section ${bg}`}>
        <div className='overlay'></div>
        <div className='row h-100 w-100 mw-100 section-content'>
        <div className='left-side col-12 col-md-6'>
        <article className='section-description'>
            <h2 className='title'>{t("ServiceHeadSection.find_the_best")} {t("ServiceHeadSection.that_fits_your_needs")}</h2>
            <p>{t("ServiceHeadSection.your_enjoy")}</p>
        </article>
        <div className='details'>
        <ul>
                <li><FontAwesomeIcon icon={faHandPointUp}/> {t("ServiceHeadSection.details.item1")}</li>
                <li><FontAwesomeIcon icon={faCreditCard}/> {t("ServiceHeadSection.details.item2")}</li>
                <li><FontAwesomeIcon icon={faUserTie}/> {t("ServiceHeadSection.details.item3")}</li>
            </ul>
        </div>
        </div>
        <div className='right-side col-12 col-md-6'>
        <div className="cars-search-form">
                    <h4 className='title'>{t("carsPage.title_2")}</h4>
                  <div className="contact-form-action">
                    <form action="#" className="row align-items-center">
                      <div className="col-md-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.pickingUp")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.cars.picking-up-placeholder")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.pick-up-date")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.cars.pick-up-date")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.pick-up-time.title")}</label>
                          <div className="form-group">
                            <div className="select-contain w-auto">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {dropdown_5}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionOne")}
                                    defaultValue="9.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.pick-up-time.optionOne")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionTwo")}
                                    defaultValue="8.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.pick-up-time.optionTwo")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionThree")}
                                    defaultValue="7.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.pick-up-time.optionThree")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.pick-up-time.optionFour")}
                                    defaultValue="6.00Am"
                                    onClick={(e) => setDropdown_5(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.pick-up-time.optionFour")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 pr-0">
                          <div className="input-box my-2">
                            <label className="label-text">{t("heroWrapper.tab-content.cars.advancedOptions.carType.title")}</label>
                            <div className="form-group">
                              <div className="select-contain w-auto">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    {dropdown_1}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionOne")}
                                      defaultValue="No preference"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                     {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionOne")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionTwo")}
                                      defaultValue="Economy"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                     {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionTwo")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionThree")}
                                      defaultValue="Compact"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionThree")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      id={t("heroWrapper.tab-content.cars.advancedOptions.carType.optionFour")}
                                      defaultValue="Midsize"
                                      onClick={(e) =>
                                        setDropdown_1(e.target.id)
                                      }
                                      href="#"
                                    >
                                      {t("heroWrapper.tab-content.cars.advancedOptions.carType.optionFour")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                    </form>
                  </div>
                  <div className="collapse-wrap">
                    <div className="collaps-content text-start">
                      <button className="collaps-btn"
                        onClick={() => setToggle((toggle) => !toggle)}
                      >
                        {t("heroWrapper.tab-content.cars.advancedOptions.title")} <FontAwesomeIcon icon={faChevronDown}/>
                      </button>
                      <div className="row collapse" id="collapseTarget">
                      <form action="#" className="row align-items-center">
                      <div className="col-12 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.dropOff")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              className="custom-input custom-input"
                              type="text"
                              placeholder={t("heroWrapper.tab-content.cars.drop-off-placeholder")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.drop-off-date")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("heroWrapper.tab-content.cars.drop-off-date")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-box">
                          <label className="label-text">{t("heroWrapper.tab-content.cars.drop-off-time.title")}</label>
                          <div className="form-group">
                            <div className="select-contain w-auto">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {dropdown_4}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionOne")}
                                    defaultValue="9.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.drop-off-time.optionOne")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionTwo")}
                                    defaultValue="8.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.drop-off-time.optionTwo")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionThree")}
                                    defaultValue="7.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                   {t("heroWrapper.tab-content.cars.drop-off-time.optionThree")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id={t("heroWrapper.tab-content.cars.drop-off-time.optionFour")}
                                    defaultValue="6.00Am"
                                    onClick={(e) => setDropdown_4(e.target.id)}
                                    href="#"
                                  >
                                    {t("heroWrapper.tab-content.cars.drop-off-time.optionFour")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                      </div>
                    </div>
                  </div>
                  <div className="btn-box">
                    <Link to="/cars-search-result-page">
                      <BtnComponent value={t("heroWrapper.tab-content.cars.searchNow")} />
                    </Link>
                  </div>
        </div>
        </div>
        </div>
        <svg className="hero-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100 10 100 0 0 10"></polygon>
        </svg>
    </section>
  )
}

export default CarsPageHeadSection