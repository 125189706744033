import {useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { GetAllServiceAction, GetSearchResultService } from '../../Redux/Actions/ServicesAction'

const AllServiceHook = (service_type,category_id) => {
/*Data from reducer*/
const dispatch = useDispatch()
const response = useSelector((state)=>state.ServicesReducer.allService)
const loading = useSelector((state)=>state.ServicesReducer.serviceLoading)

useEffect(()=>{
  const get =async()=>{
    await dispatch(GetAllServiceAction(service_type,category_id))
  }
  get()
},[])

let allService=[];
try{
  if(response){
    if(response.data){
      allService=response.data
    }
  }else{
    allService=[]
  }
}catch(e){}

 //on press function for pagenation
 const onPress = async (page) => {
  await dispatch(
    GetSearchResultService(service_type,category_id,`page=${page}`)
  );
};

let allServiceNum=0;
try{
  if(response){
    if(response.data){
      allServiceNum=response.data.last_page
    }
  }else{
    allServiceNum=0
  }
}catch(e){}

let countOfService=0;
try{
    if(response){
        if(response.data){
            countOfService = response.data.data.length
        }else{
            countOfService = 0;
        }
    }
}catch(e){}

return [allService,loading,onPress,allServiceNum,countOfService]
}
export default AllServiceHook