import React from "react";
import noData from "../../../imgs/public/noData.png";
import "./NotFoundData.css";
import { useTranslation } from "react-i18next";
const NotFoundData = () => {
  const [t, i18n] = useTranslation();
  return (
    <div className="not-found">
      {i18n.language == "ar" ? (
        <h4 className="not-found-title">لا توجد بيانات</h4>
      ) : (
        <h4 className="not-found-title">not found data</h4>
      )}
      <img src={noData} alt="no-data" className="no-data-img" loading="lazy" />
    </div>
  );
};

export default NotFoundData;
