import React,{useRef,useState} from 'react'
import './ServiceSearchResultLand.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown,faCalendarDays,faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import SideBarSearchHook from "../../../CustomHooks/Search/SideBarSearchHook";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Collapse } from "react-bootstrap";
import BtnComponent from "../../../Components/Utility/BtnComponent/BtnComponent";
import { useTranslation } from 'react-i18next';
const ServiceSearchResultLand = ({service_type,category_id,bg}) => {
    const focusInput = useRef();
  const [open, setOpen] = useState(false);
  // collaps
  const handleCollaps = () => {
    setOpen(!open);
  };
  const [,,minPrice,maxPrice,onChangeMinPrice,onChangeMaxPrice,town,onChangeTown,,,,check_inn,onChangeCheck_inn,,,
  ]= SideBarSearchHook(service_type,category_id);
  const [value, setValue] = useState([0,1000]);
  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };
  // start code for translate
  const [t,i18n]= useTranslation()
  // End code for translate
  return (
    <section className='landing-section'>
    <div className={`service-search-result-page-land ${bg}`}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="search-result-content">
                <div className="section-heading">
                  <h2 className="sec__title text-white">
                    {t("searchResultPage.title")}
                  </h2>
                </div>
                <div className="search-fields-container margin-top-30px">
                  <div className="contact-form-action">
                    <form action="#" className="row">
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("searchResultPage.form.town")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <input
                              ref={focusInput}
                              onChange={onChangeTown}
                              value={town}
                              className="custom-input"
                              type="text"
                              placeholder={t("searchResultPage.form.town")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 pr-0">
                        <div className="input-box">
                          <label className="label-text">{t("searchResultPage.form.from")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                              onChange={onChangeCheck_inn}
                              onFocus={(e)=>e.target.type="date"}
                              onBlur={(e)=>e.target.type="text"}
                              value={check_inn}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("searchResultPage.form.from")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6">
                        <div className="input-box">
                          <label className="label-text">{t("searchResultPage.form.to")}</label>
                          <div className="form-group">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            <input
                              onChange={onChangeCheck_inn}
                              onFocus={(e)=>e.target.type="date"}
                              onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="daterange-single"
                              placeholder={t("searchResultPage.form.to")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <span
              className="advanced-options-btn"
              onClick={handleCollaps}
              aria-controls="example-collapse-text"
              aria-expanded={open}>
             {t("searchResultPage.form.moreOptions")} <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                  <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="advanced-wrap">
                    <div className="range-slider">
                      <p className="filter-price">
                      {t("searchResultPage.filter-price")}
                        <span className="parent-span">
                          <span className="value-0">{`$${minPrice}`}</span>
                          <span> - </span>
                          <span className="value-1">{`$${maxPrice}`}</span>
                        </span>
                      </p>
                    <Box sx={{ width:"100%"}}>
                    <Slider
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                    />
                  </Box>
                    </div>
                    <div className="checkbox-wrap">
                      <label className="custom-checkbox">
                        <span>{t("searchResultPage.checkBox.food")}</span>
                        <input type="checkbox" className="checkbox" value={"1"}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("searchResultPage.checkBox.outdoors")}</span>
                        <input type="checkbox" className="checkbox" value={"1"}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("searchResultPage.checkBox.sightseeing-tours")}</span>
                        <input type="checkbox" className="checkbox" value={"1"}/>
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        <span>{t("searchResultPage.checkBox.Workshope")}</span>
                        <input type="checkbox" className="checkbox" value={"1"}/>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    </div>
                  </div>
                  </Collapse>
                  <div className="btn-box">
                    <BtnComponent value={"search now"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bread-svg-box position-relative">
      <svg className="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div>
    </section>
  )
}

export default ServiceSearchResultLand