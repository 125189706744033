/*hotels actions */
export const GET_ALL_HOTELS="GET_ALL_HOTELS"
export const GET_HOTEL_DETAILS="GET_HOTEL_DETAILS"
export const GET_SEARCH_RESULT_HOTELS="GET_SEARCH_RESULT_HOTELS"
export const CREATE_COMMENT="CREATE_COMMENT"

//Start Services actions
export const GET_ALL_SERVICE="GET_ALL_SERVICE"
export const GET_SERVICE_DETAILS="GET_SERVICE_DETAILS"
export const GET_SEARCH_RESULT_SERVICE="GET_SEARCH_RESULT_SERVICE"
// End Services Actions
//Start Cars actions
export const GET_ALL_CARS="GET_ALL_CARS"
export const GET_CAR_DETAILS="GET_CAR_DETAILS"
export const GET_SEARCH_RESULT_CARS="GET_SEARCH_RESULT_CARS"
export const MAKE_CAR_RESERVATION ="MAKE_CAR_RESERVATION"
//Start Flights actions
export const GET_ALL_FLIGHTS="GET_ALL_FLIGHTS"
export const GET_FLIGHT_DETAILS="GET_FLIGHT_DETAILS"
export const GET_SEARCH_RESULT_FLIGHTS="GET_SEARCH_RESULT_FLIGHTS"
export const MAKE_FLIGHT_RESERVATION ="MAKE_FLIGHT_RESERVATION"
// End Cars Actions
/*authentication actions*/
export const CREATE_NEW_USER ="CREATE_NEW_USER"
export const LOGIN_USER ="LOGIN_USER"
export const GOOGLE_LOGIN ="GOOGLE_LOGIN"
export const GET_CURERNT_USER ="GET_CURERNT_USER"
export const FORGET_PASSWORD ="FORGET_PASSWORD"
export const VERIFY_CODE ="VERIFY_CODE"
export const VERIFY_EMAIL ="VERIFY_EMAIL"
export const  RESET_PASSWORD ="RESET_PASSWORD"
export const  DELETE_ACOUNT ="DELETE_ACOUNT"
/*All Towns */
export const GET_ALL_TOWNS = "GET_ALL_TOWNS"
/*All Categories */
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES"
/*All Questions */
export const GET_ALL_QUESTIONS = "GET_ALL_QUESTIONS"
/*================================================*/
/*user actions*/
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const CHANGE_EMAIL = "CHANGE_EMAIL"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const GET_NOTIFICATIONS ="GET_NOTIFICATIONS"

export const ADD_SUGGESTION ="ADD_SUGGESTION"
export const ADD_COMMENT ="ADD_COMMENT"
export const DELETE_COMMENT ="DELETE_COMMENT"
export const POST_LIKE_UNLIKE ="POST_LIKE_UNLIKE"

export const ADD_TO_WISHLIST ="ADD_TO_WISHLIST"
export const GET_ALL_WISHLIST ="GET_ALL_WISHLIST"
export const DELETE_FROM_WISHLIST ="DELETE_FROM_WISHLIST"
export const GET_USER_REVIEWS ="GET_USER_REVIEWS"

export const GET_ALL_RESERVATIONS ="GET_ALL_RESERVATIONS"
export const MAKE_RESERVATION ="MAKE_RESERVATION"
export const CANCEL_RESERVATION ="CANCEL_RESERVATION"

//website review action type
export const ADD_WEBSITE_REVIEW = "ADD_WEBSITE_REVIEW"
export const GET_WEBSITE_REVIEWS = "GET_WEBSITE_REVIEWS"

// subscribe in website services
export const SUBSCRIBE = "SUBSCRIBE"
export const GET_ERROR="GET_ERROR"