import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SpinnerComponent from '../../Utility/SpinnerComponent/SpinnerComponent'
import WishlistHook from '../../../CustomHooks/UserHooks/WishlistHook'
import Pagenation from '../../Utility/Pagenation/Pagenation'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
import CarCard from '../CarCard/CarCard'
import AllCarsHook from '../../../CustomHooks/CarsHooks/AllCarsHook'
const CarsContainer = () => {
    const [allCars,loading,onPress,allCarsNum] = AllCarsHook()
    const  [,,wishlistItemsId] = WishlistHook()
  return (
    <Container className='cars-content cars-container position-relative'>
        <Row>
        {loading === false ?
              (
                allCars?
                allCars.data?
                allCars.data.length>=1?
                allCars.data.map((car)=>{
                  return(
                    <Col xs="12" sm="6" lg="4" key={car.id}>
                    <CarCard favItem={wishlistItemsId} car={car}/>
                    </Col>
                  )
                })
                :<NotFoundData />
                :<NotFoundData />
                :<NotFoundData />
              )
              :<SpinnerComponent />
              }
              {
                allCars?
                allCars.data?
                allCars.data.length>=1?
                <Pagenation pageCount={allCarsNum} onPress={onPress}/>
                :null
                :null
                :null
              }
        </Row>
    </Container>
  )
}

export default CarsContainer