import React from 'react'
import './InternalNavbar.css'
import { useTranslation } from 'react-i18next'
const InternalNavbar = () => {
  const [t,i18n]=useTranslation()
  return (
    <div className="single-content-navbar-wrap menu section-bg"
    id="single-content-navbar">
    <div className="container h-100">
      <div className="row h-100">
        <div className="col-lg-12">
          <div className="single-content-nav h-100" id="single-content-nav">
            <ul>
              <li>
                <a
                  data-scroll="description"
                  href="#description"
                  className="scroll-link active"
                >
                {t("internalNavbar.details")}
                </a>
              </li>
              <li>
                <a
                  data-scroll="availability"
                  href="#availability"
                  className="scroll-link"
                >
                  {t("internalNavbar.availability")}
                </a>
              </li>
              <li>
                <a
                  data-scroll="amenities"
                  href="#amenities"
                  className="scroll-link"
                >
                  {t("internalNavbar.amenities")}
                </a>
              </li>
              <li>
                <a data-scroll="faq" href="#faq" className="scroll-link">
                {t("internalNavbar.faq")}
                </a>
              </li>
              <li>
                <a
                  data-scroll="reviews"
                  href="#reviews"
                  className="scroll-link"
                >
                 {t("internalNavbar.reviews")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default InternalNavbar