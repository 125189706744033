import React, { useState,useEffect } from "react";
import "./Header.css";
// Start imgs
import logo from "../../../imgs/public/logo.png";
import houses from "../../../imgs/public/houses_logo.png";
import hotels from "../../../imgs/public/apartment_logo.png";
import rests from "../../../imgs/public/resets_logo.png";
import resorts from "../../../imgs/public/resorts_logo.png";
import trips from '../../../imgs/public/trips_logo.png'
import flights from "../../../imgs/public/flights_logo.png";
import cars from '../../../imgs/public/cars_logo.png'
import home_logo from '../../../imgs/public/home_logo.png'
import hostels from '../../../imgs/public/home_logo.png'
import apartment from '../../../imgs/public/apartment_logo.png'
import anonymous from '../../../imgs/public/anonymous.jpg'
// End imgs
// Start icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
// End icons
import { Link } from "react-router-dom";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import SignUp from "../../Authentication/SignUp/SignUp";
import Login from "../../Authentication/Login/Login";
import UserNotificatoinsHook from "../../../CustomHooks/UserHooks/UserNotificatoinsHook";
import CategoriesHook from "../../../CustomHooks/CategoriesHook/CategoriesHook";
import SpinnerTwo from "../../Utility/SpinnerComponent/SpinnerTwo";
//Start imports for translation
import { useTranslation } from "react-i18next";
//End imports for translation 
//=================================================================================================
const Header = ({activeHome,activeHotels,activeHouses,activeResets,
  activeCars,activeFlights,activeApartments,activeTrips,activeResorts,}) => {
  /*Start code for get user from local storage*/
  const [user,setUser] = useState({})
  useEffect(()=>{
    if(localStorage.getItem("NozoulnaUser")!=null){
      setUser(JSON.parse(localStorage.getItem("NozoulnaUser")))
    }else{
      setUser(null)
    }
  },[])
  /*End code for get user from local storage*/
  /*Start useTranslation hook for translate the header  */
  const [t,i18n] = useTranslation()
  useEffect(() => {
    document.documentElement.dir = localStorage.getItem("dir");
    document.documentElement.lang= localStorage.getItem("lang")
 },[i18n,i18n.language]);
 const changeLangToAr =()=>{
  i18n.changeLanguage("ar")
  localStorage.setItem("dir",i18n.dir(i18n.language))
    localStorage.setItem("lang",i18n.language)
 }
 const changeLangToEn =()=>{
  i18n.changeLanguage("en")
  localStorage.setItem("dir",i18n.dir(i18n.language))
    localStorage.setItem("lang",i18n.language)
 }
  /*End useTranslation hook for translate the header  */
  /*Start state for active class*/
  const [seensignup, setSeensignup] = useState(false);
  const [seenlogin, setSeenlogin] = useState(false);
  const [header_W_show,setHeader_w_show] = useState("");
  /*End states for active class*/
  /*start Functions for signup and login*/
  const handleClickSignUp = () => {
    setSeensignup(!seensignup);
  };
  const handleClickLogin = () => {
    setSeenlogin(!seenlogin);
  };
  /*End Functions for signup and login*/
  /*Start Function for mobile header menu*/
  const handleMobileHeaderWrapper = ()=>{
    header_W_show === ""?setHeader_w_show("header-wrapper-show"):setHeader_w_show("")
  }
  /*End Function for mobile header menu*/
  /*Start Hook to display user notifications*/
  const  [nots] = UserNotificatoinsHook()
  /*End Hook to display user notifications*/
  /*Start Hook to display categories available*/
  const [loading,Categories] = CategoriesHook()
  /*End Hook to display categories available*/ 
  return (
    <header className="header-area">
      <div className="header-top-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-2 col-lg-6 col-md-6">
              <div className="header-top-content">
                <div className="header-left head d-flex justify-content-start">
                  <Link to="/">
                    <img className="logo" alt="logo"  loading="lazy" src={logo}></img>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-10 col-lg-6 col-md-6">
              <div className="header-top-content">
                <div className="header-right d-flex justify-content-end align-items-center">
                <div className="header-right-action head mx-1 lang-box">
                  {
                    i18n.language==="en"&&
                    <div onClick={changeLangToAr}>
                      <span className="lang-btn transition">Ar</span>
                    </div>
                  }
                  {
                    i18n.language==="ar"&&
                    <div onClick={changeLangToEn}>
                      <span className="lang-btn transitoin">En</span>
                    </div>
                  }
                  </div>
                  {
                    user!==null?
                  <div className="header-right-action head">
                    <div className="select-contain select--contain w-auto">
                      <select className="select-contain-select select">
                        <option defaultValue="1">USD</option>
                        <option defaultValue="2">SAR</option>
                      </select>
                    </div>
                  </div>
                    :null
                  }
                  
                  {user!==null?
                  <div className="noti d-none d-md-block header-right-action head mx-2">
                     <div className="notifications mr-2">
                          <div className="filter-option">
                            <div className="custom-dropdown">
                              <button className="custom-dropdown-btn transition">
                                <FontAwesomeIcon icon={faBell} />
                                {nots?
                                  nots.length >= 1?
                                  <span>{nots.length}</span>
                                  :null
                                  :null
                                  }
                              </button>
                              <div className={`custom-dropdown-content-wrapper`}>
                                <div className="custom-dropdown-content">
                                  <ul className="notification-menu list-items">
                                    {nots?
                                      nots.length>=1?
                                      nots.map((not)=>{return(
                                        <li className="list-item-1">
                                        <div className="notification-icon">
                                            <FontAwesomeIcon icon={faBell}/>
                                        </div>
                                        <div className="notification-content">
                                            <h5>{not}</h5>
                                            <p>{not}</p>
                                        </div>
                                    </li>

                                      )}
                                      )
                                      :<div className="no-nots">No notifications</div>
                                      :<div className="no-nots">No notifications</div>
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                </div>
                  :null
                  }
                  {user!==null?
                  user.attachment_relation&&user.attachment_relation.length>0?
                  <div className="header-right-user">
                  <Link to={"/user-profile-page"}><img className="user-profile-img transition"
                   src={user.attachment_relation[0].url}
                    alt={user.attachment_relation[0].usage}
                    /></Link>
                  </div>
                  :
                  <div className="header-right-user">
                  <Link to={"/user-profile-page"}><img className="user-profile-img transition"
                   src={anonymous}
                    alt={"anonymous"}
                    /></Link>
                </div>
                :null
                }
                {
                  user===null?
                  <div className="header-right-action ho">
                    <span
                      onClick={handleClickSignUp}
                      className="mx-2 btn-box sign-up-btn"
                    >
                      <BtnComponent value={t("header.signUp")} />
                    </span>
                    {seensignup ? (
                      <SignUp handleClick={handleClickSignUp} />
                    ) : null}
                    <span
                      onClick={handleClickLogin}
                      className="btn-box login-btn"
                    >
                      <BtnComponent value={t("header.login")} />
                    </span>
                    {seenlogin ? (
                      <Login handleClick={handleClickLogin} />
                    ) : null}
                  </div>
                  :
                  null
                }
                  <div className="header-right-bars hero-wrapper-show-btn d-block d-lg-none mx-2" onClick={handleMobileHeaderWrapper}>
                      <BtnComponent icon={<FontAwesomeIcon icon={faBars}/>}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`header-menu-wrapper ${header_W_show}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="menu-wrapper">
                <div className="logo">
                  <div className="menu-toggler">
                    <i className="la la-bars"></i>
                    <i className="la la-times"></i>
                  </div>
                </div>
                <div className="main-menu-content">
                  <nav className="my-4">
                    <ul className="nav d-flex justify-content-start align-items-center">
                        <Link to="/" className={`nav-item ${activeHome}`}>
                          <img
                            alt="img_4"
                            src={home_logo}
                            className="pr-1 pb-1"
                            width="20px"
                            loading="lazy"
                          />
                          {i18n.language==="ar"?"الرئيسيه":"Home"}
                        </Link>
                      {
                        loading===false?
                        Categories?
                        Categories.map((item,index)=>{return(
                        <Link to={`/${item.name_en}-page`}
                        key={index} className={
                          `nav-item ${item.relation_name==="hotels"?activeHotels:
                          item.relation_name==="houses"?activeHouses:
                          item.relation_name==="apartments"?activeApartments:
                          item.relation_name==="rests"?activeResets:
                          item.relation_name==="hostels"?activeResorts:
                          item.relation_name==="trips"?activeTrips:
                          item.relation_name==="cars"?activeCars:
                        item.relation_name==="planFlights"?activeFlights:null}`
                          }
                        >
                          <img
                            alt="category-img"  loading="lazy"
                            src={item.relation_name ==="hotels"?hotels:
                            item.relation_name ==="houses"?houses:
                            item.relation_name ==="rests"?rests:
                            item.relation_name ==="resorts"?resorts:
                            item.relation_name ==="apartments"?apartment:
                            item.relation_name ==="hostels"?hostels:
                            item.relation_name ==="planFlights"?flights:
                            item.relation_name ==="trips"?trips:
                            item.relation_name ==="cars"?cars:null}
                            className="pr-1 pb-1"
                            width="20px"/>
                            {i18n.language==="ar"?item.name:item.name_en}
                        </Link>
                        )})
                        :null
                        :<SpinnerTwo />
                      }
                    </ul>
                  </nav>
                  <div className="close-header-menu-wrapper-btn d-block d-lg-none" onClick={handleMobileHeaderWrapper}>
                   <BtnComponent icon={ <FontAwesomeIcon icon={faXmark}/>}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
