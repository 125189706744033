import React from 'react'
import './TestimonialCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const TestimonialCard = ({item}) => {
  return (
    <div className='testimonial-card-wrapper' >
        <div className='testimonial-card'>
        <div className="testi-desc-box">
                  <p className="testi__desc">
                    {item.comment}
                  </p>
                </div>
                <div className="author-content d-flex align-items-center">
                  <div className="author-bio">
                    <h4 className="author__title">{item.name}</h4>
                    <span className="ratings badge-2">
                      <span>{item.review}</span> <FontAwesomeIcon icon={faStar}/>
                    </span>
                  </div>
                </div>
        </div>
    </div>
  )
}

export default TestimonialCard