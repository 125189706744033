import React from 'react'
import './PopularHotels.css'
import CustomCarousel from '../../../Utility/CustomCarousel/CustomCarousel'
import SpinnerComponent from "../../../../Components/Utility/SpinnerComponent/SpinnerComponent"
import WishlistHook from '../../../../CustomHooks/UserHooks/WishlistHook'
import NotFoundData from '../../../Utility/NotFoundData/NotFoundData'
import { useTranslation } from 'react-i18next'
import AllServiceHook from '../../../../CustomHooks/ServicesHooks/AllServiceHook'
import HomeServiceCard from '../../../ServiceComs/HomeServiceCard/HomeServiceCard'
const PopularHotels = () => {
  const [allService,loading,,] = AllServiceHook("hotels","1")
  const [,,wishlistItemsId] = WishlistHook()
// start code for translate
const [t,i18n] = useTranslation()
// End code for translate
  return (
    <section className='popular-hotels section'>
        <h2 className='custom-title'>{t("popularHotels.title")}</h2>
        <div className='carousel-parent'>
            {loading===false ?
        <CustomCarousel show={5}>
              {
                allService.data?
                allService.data.map((hotel)=>{
                  return(
                    <div key={hotel.id}>
                      <HomeServiceCard service_type={"hotels"} favItem={wishlistItemsId} service={hotel} />
                    </div>
                  )
                }):<NotFoundData />
                }
            </CustomCarousel>
            :<SpinnerComponent />
            }
        </div>
    </section>
  )
}

export default PopularHotels