import {useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {resetPassword } from '../../Redux/Actions/authAction'
import notify from '../Notify/Notify'
const ResetPasswordHook = () => {
    const [code,setCode] = useState("")
    const [newPassword,setNewPassword] = useState("")
    const [loading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.authReducer.resetPassword)

    const onChangeCode = (e)=>{
        setCode(e.target.value)
    }
    const onChangeNewPassword = (e)=>{
        setNewPassword(e.target.value)
    }
    const onSubmit = async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("token",code)
        formData.append("password",newPassword)
        if(code === ""||newPassword===""){
            notify("complete the data","error")
        }
        setLoading(true)
        setIsPress(true)
        await dispatch(resetPassword(formData))
        setLoading(false)
        setIsPress(false)
    }
    useEffect(()=>{
        if(loading === false){
            if(res){
                console.log(res)
                if(res.status===200){
                    notify(`${res.data.message}`,"success")
                    setTimeout(() => {
                        window.location.href="/"
                    }, 1000);
                }else if(res.status===400){
                    notify(`${res.data.message}`,"error")
                }
                if (res.data.errors) {
                    if(res.data.errors.token!=null){
                        notify(`${res.data.errors.token}`,"error")
                    }else if(res.data.errors.password!=null){
                        notify(`${res.data.errors.password}`,"error")
                    }
                }
            }
        }
    },[loading])
    return[code,onChangeCode,newPassword,onChangeNewPassword,onSubmit,loading,isPress]
}

export default ResetPasswordHook