import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { addSuggestion } from '../../Redux/Actions/userActions'
import Notify from '../Notify/Notify'
const AddSuggestionHook = () => {
    const dispatch = useDispatch()
    const response = useSelector((state)=>state.UserReducer.addSuggestion)
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [title,setTitle] = useState("")
    const [message,setMessage] = useState("")
    const [sugLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    /*functions of change states*/
    const onChangeName = (e)=>{
        setName(e.target.value)
    }
    const onChangeEmail = (e)=>{
        setEmail(e.target.value)
    }
    const onChangeTitle = (e)=>{
        setTitle(e.target.value)
    }
    const onChangeMessage = (e)=>{
        setMessage(e.target.value)
    }
    
    /*submit functoin*/
    const onSubmit =async(e)=>{
        e.preventDefault()
        /*validation function*/
        if(name===""||email===""||title===""||message===""){
            Notify("please complete the data","warn")
            return;
        }else{
        const formData = new FormData()
        formData.append("name",name)
        formData.append("email",email)
        formData.append("title",title)
        formData.append("content",message)
        setLoading(true)
        setIsPress(true)
        await dispatch(addSuggestion(formData))
        setLoading(false)
        setIsPress(false)
        }
    }
    /*useEffect to handle errors and reset the states*/
    useEffect(()=>{
        if(sugLoading===false){
            if(response.data){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                    setName("")
                    setEmail("")
                    setTitle("")
                    setMessage("")
                }else{
                    Notify(`${response.data.message}`,"error")
                }
            }
        }
    },[sugLoading])

    return [name,onChangeName,email,onChangeEmail,title,onChangeTitle,message,onChangeMessage,
            onSubmit,sugLoading,isPress]
}

export default AddSuggestionHook