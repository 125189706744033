import React, { useState } from "react";
import "../Auth.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF,faInstagram,faLinkedinIn,faTwitter,} from "@fortawesome/free-brands-svg-icons";
import { faLock, faXmark } from "@fortawesome/free-solid-svg-icons";
import {faEnvelope,faEye,faEyeSlash,} from "@fortawesome/free-regular-svg-icons";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import LoginHook from "../../../CustomHooks/Auth/LoginHook";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import { useTranslation } from "react-i18next";
// import google login methods
import {GoogleOAuthProvider ,GoogleLogin} from '@react-oauth/google'
const Login = ({ handleClick }) => {
  const [loading,email,password,onChangeEmail,onChangePassword,
    onSubmit,isPress,googleLoginSuccess,googleLoginError,google_login_loading]
  = LoginHook();
  const [showpass, setShowpass] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(faEyeSlash);
  const showpassFn = () => {
    if (showpass === "password" && eyeIcon === faEyeSlash) {
      setShowpass("text");
      setEyeIcon(faEye);
    } else {
      setShowpass("password");
      setEyeIcon(faEyeSlash);
    }
  };
  //start code for translate
  const [t, i18n] = useTranslation();
  //End code for translate
  return (
    <div className="login-wrapper">
      <div className={i18n.language == "ar" ? "login-popup login-popup-ar" : "login-popup"}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom">
              <div>
                <h5 className="modal-title title" id="exampleModalLongTitle2">
                  {t("loginPopup.title")}
                </h5>
                <p className="font-size-15 font-weight-medium color-text">{t("loginPopup.welcome")}</p>
              </div>
              <button
                onClick={handleClick}
                type="button"
                className="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="modal-body">
              <div className="contact-form-action">
                <form method="post">
                  <div className="input-box">
                    <label className="label-text">
                      {t("loginPopup.email")}
                    </label>
                    <div className="form-group">
                      <span className="input-icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <input
                        value={email}
                        onChange={onChangeEmail}
                        className="custom-input"
                        type="email"
                        name="mail"
                        placeholder={t("loginPopup.email")}
                      />
                    </div>
                  </div>
                  <div className="input-box">
                    <label className="label-text">
                      {t("loginPopup.password")}
                    </label>
                    <div className="form-group">
                      <span className="input-icon">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                      <span onClick={showpassFn} className="show-password">
                        <FontAwesomeIcon icon={eyeIcon} />
                      </span>
                      <input
                        value={password}
                        onChange={onChangePassword}
                        className="custom-input"
                        type={showpass}
                        name="password"
                        placeholder={t("loginPopup.password")}
                      />
                    </div>
                  </div>
                  {/*Start forget password link*/}
                  <a className="font-size-14 text-capitalize" href="/forgetPasswordPage">
                    {t("loginPopup.forgetPassword")}
                  </a>
                  {/*End forget password link*/}
                  {/*Start login button*/}
                  <div className="btn-box pt-3 pb-4" onClick={onSubmit}>
                    <BtnComponent value={t("loginPopup.loginBtn")} />
                  </div>
                  {/*End login button*/}
                  {/*Start login using google*/}
                  <div className="google-login-wrapper action-box text-center">
                    <p className="font-size-14 text-capitalize">{t("loginPopup.orLoginUsing")}</p>
                    <GoogleOAuthProvider clientId="1093890352630-ssm8qudsghd5p6iep3sfck51f3haapla.apps.googleusercontent.com">
                    <GoogleLogin
                    onSuccess={googleLoginSuccess}
                    onError={googleLoginError}
                    />
                    </GoogleOAuthProvider>
                    {/* <ul className="social-profile py-3">
                      <li>
                        <a href="#">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                      </li>
                    </ul> */}
                  </div>
                  {/*End login using google*/}
                </form>
              </div>
            </div>
          </div>
        </div>
        {isPress === true ? (
          loading === true ? (
            <SpinnerComponent />
          ) : null
        ) : null}
        {isPress === true ? (
          google_login_loading === true ? (
            <SpinnerComponent />
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default Login;
