/*import types of actions*/
import {
    GET_ALL_CARS,
    GET_SEARCH_RESULT_CARS,
    GET_ERROR,
    GET_CAR_DETAILS,
    MAKE_CAR_RESERVATION,
  } from "../TypesOfActions";
  /* import useGetData*/
  import { GetDataHook } from "../../Hooks/GetDataHook";
import {PostDataHookWithImageToken } from "../../Hooks/PostDataHook";
  
  /* action to get all cars*/
  export const GetAllCarsAction = () => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await GetDataHook(`api/service?paginate=9&service_type=cars&category_id=7`)
      dispatch({
        type: GET_ALL_CARS,
        payload: response.data,
      });
    } catch (e) {
      /*Statement that is executed if an exception is thrown in the try-block.*/
      dispatch({
        type: GET_ERROR,
        payload: "error" + e,
      });
    } finally {
    }
  };
  /* action to get all cars*/
  export const GetSearchResultCars = (queryString) => async (dispatch) => {
    /*The statements to be executed.*/
  try {
    const response = await GetDataHook(`api/service?service_type=cars&category_id=7&paginate=9&${queryString}`)
    dispatch({
      type:GET_SEARCH_RESULT_CARS,
      payload: response.data,
    });
  } catch (e) {
    /*Statement that is executed if an exception is thrown in the try-block.*/
    dispatch({
      type: GET_ERROR,
      payload: "error" + e,
    });
  } finally {
  }
  };
  /* action to get car details*/
  export const GetCarDetails = (car_id) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
        const response = await GetDataHook(`api/cars/single/${car_id}`)
      dispatch({
        type: GET_CAR_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      /*Statement that is executed if an exception is thrown in the try-block.*/
      dispatch({
        type: GET_ERROR,
        payload: "error" + e,
      });
    } finally {
    }
  };
  export const makeCarReservation = (formData) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await PostDataHookWithImageToken("api/reserve",formData)
      dispatch({
        type:MAKE_CAR_RESERVATION,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:MAKE_CAR_RESERVATION,
        payload: e.response
      });
    } finally {
    }
};
  