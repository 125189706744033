import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
// import i18next for translation
import './i18n'
import App from './App';
/*import bootstrap*/
import "bootstrap/dist/css/bootstrap.min.css";
/*====================================================================*/
/*React redux*/
import {Provider} from 'react-redux';
import Store from './Redux/Store';
/*====================================================================*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <App />
    </Provider>
    );
