/* import combine reducers */
import { combineReducers } from "redux";
/* import reducers to combine them in root reducer*/
import HotelsReducer from "./HotelsReducer";
import authReducer from "./authReducer";
import UserReducer from '../Reducers/UserReducer'
import townsReducer from "./townsReducer";
import CategoriesReducer from "./CategoriesReducer";
import QuestionsReducer from './QuestionsReducer'
import ServicesReducer from './ServicesReducer'
import CarsReducer from "./CarsReducer";
import FlightsReducer from "./FlightsReducer";
/* combine reducer*/
export default combineReducers({
    ServicesReducer:ServicesReducer,
    HotelsReducer:HotelsReducer,
    authReducer:authReducer,
    UserReducer:UserReducer,
    townsReducer:townsReducer,
    CategoriesReducer:CategoriesReducer,
    QuestionsReducer:QuestionsReducer,
    CarsReducer:CarsReducer,
    FlightsReducer:FlightsReducer,
})