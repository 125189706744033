/*import types of actions*/
import {
    GET_ALL_FLIGHTS,
    GET_SEARCH_RESULT_FLIGHTS,
    GET_ERROR,
    GET_FLIGHT_DETAILS,
    MAKE_FLIGHT_RESERVATION,
  } from "../TypesOfActions";
import { GetDataHook } from "../../Hooks/GetDataHook";
import {PostDataHookToken} from "../../Hooks/PostDataHook";

  //action to get all flights
  export const GetAllFlightsAction = () => async (dispatch) => {
    try {
      const response = await GetDataHook(`api/service?paginate=9&service_type=plan-flights&category_id=6`)
      dispatch({
        type: GET_ALL_FLIGHTS,
        payload: response.data,
      });
    } catch (e) {
      //Statement that is executed if an exception is thrown in the try-block.
      dispatch({
        type: GET_ERROR,
        payload: "error" + e,
      });
    } finally {
    }
  };
  //action to get all flights
  export const GetSearchResultFlights = (queryString) => async (dispatch) => {
  try {
    const response = await GetDataHook(`api/service?service_type=plan-flights&category_id=6&paginate=9&${queryString}`)
    dispatch({
      type:GET_SEARCH_RESULT_FLIGHTS,
      payload: response.data,
    });
  } catch (e) {
    //Statement that is executed if an exception is thrown in the try-block.
    dispatch({
      type: GET_ERROR,
      payload: "error" + e,
    });
  } finally {
  }
  };
  //action to get flight details
  export const GetFlightDetails = (flight_id) => async (dispatch) => {
    try {
        const response = await GetDataHook(`api/plan-flights/single/${flight_id}`)
      dispatch({
        type: GET_FLIGHT_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      //Statement that is executed if an exception is thrown in the try-block
      dispatch({
        type: GET_ERROR,
        payload: "error" + e,
      });
    } finally {
    }
  };
  // make a plan flight reservation
  export const makeFlightReservation = (formData) => async (dispatch) => {
    try {
      const response = await PostDataHookToken("api/reserve",formData)
      dispatch({
        type:MAKE_FLIGHT_RESERVATION,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:MAKE_FLIGHT_RESERVATION,
        payload: e.response
      });
    } finally {
    }
};
  