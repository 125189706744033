import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import FilterBar from "../../../Components/GeneralSections/FilterBar/FilterBar";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
import AllServiceHook from "../../../CustomHooks/ServicesHooks/AllServiceHook";
import ServiceSearchResultLand from '../../../Components/ServiceComs/ServiceSearchResultLand/ServiceSearchResultLand'
import TripsContainer from "../../../Components/Trips/TripsContainer/TripsContainer";
const TripsSearchResultPage = () => {
  const [,,,,countOfService] = AllServiceHook("trips","5")
  return (
    <div className="service-search-result-page">
      <Header activeTrips={"activeTrips"} />
      <ServiceSearchResultLand bg={"trips-search-result-land-bg"} service_type={"trips"} category_id={"5"}/>
      <div className="container">
        <div className="row">
          <FilterBar service_type={"trips"} category_id={"5"} serviceCount={countOfService} />
          <div className="result-content">
           <TripsContainer />
          </div>
          <InfoSection />
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TripsSearchResultPage;
