/*
Tasks of reducer 
    - take the actions and update the state just this is a task of reducer
    - Reducer should be pure functions and no any side effect
    - Actions cant handle async request alone
    - Reducer take something and return the same thing after update

    So,where should add async (rest API) code?
*/

/* all of imports */
import {UPDATE_PROFILE,CHANGE_EMAIL,
    CHANGE_PASSWORD,GET_NOTIFICATIONS,
    ADD_SUGGESTION,ADD_COMMENT,POST_LIKE_UNLIKE,GET_ALL_WISHLIST,
    ADD_TO_WISHLIST,DELETE_FROM_WISHLIST
    ,GET_USER_REVIEWS,DELETE_COMMENT,GET_ALL_RESERVATIONS,MAKE_RESERVATION,CANCEL_RESERVATION,
    ADD_WEBSITE_REVIEW,GET_WEBSITE_REVIEWS,SUBSCRIBE,
    GET_ERROR
} from '../TypesOfActions'

/* initial state in one object*/
const initialState = {
    /*user profile */
   updateProfile:[],
   changeEmail:[],
   changePassword:[],
   /*notifications */
   notifications:[],
   /*add suggestion */
   addSuggestion:[],
   /*comment */
   addComment:[],
   deleteComment:[],
   /*comment like unlike */
   postLikeUnlike:[],
   /*wishlist */
   addToWishlist:[],
   deleteFromWishlist:[],
   allWishlist:[],
   wishlistLoading:true,
   /*reviews */
   getUserReviews:[],
   /*reservations */
   allReservations:[],
    makeReservation:[],
    cancelReservation:[],
    //website review
    addWebsiteReview:[],
    allWebsiteReviews:[],
    websiteReviewLoading:true,
    //subscribe
    subscribe:[],
   loading:true,
}

const UserReducer = (state=initialState,action)=>{
    switch(action.type){
        case UPDATE_PROFILE:
            return{
                updateProfile:action.payload,
                loading:false,
            }
        case CHANGE_EMAIL:
            return{
                changeEmail:action.payload,
                loading:false,
            }
        case CHANGE_PASSWORD:
            return{
                changePassword:action.payload,
                loading:false,
            }
        case GET_NOTIFICATIONS:
            return{
                ...state,
                notifications:action.payload,
                loading:false,
            }
        case  ADD_SUGGESTION:
            return{
                addSuggestion:action.payload,
                loading:false,
            }
        case  ADD_COMMENT:
            return{
                addComment:action.payload,
                loading:false,
            }
        case  DELETE_COMMENT:
            return{
                deleteComment:action.payload,
                loading:false,
            }
        case  POST_LIKE_UNLIKE:
            return{
                postLikeUnlike:action.payload,
                loading:false,
            }
        case  ADD_TO_WISHLIST:
            return{
                addToWishlist:action.payload,
                wishlistLoading:true,
            }
        case  GET_ALL_WISHLIST:
            return{
                allWishlist:action.payload,
                wishlistLoading:false,
            }
        case  DELETE_FROM_WISHLIST:
            return{
                deleteFromWishlist:action.payload,
                wishlistLoading:true,
            }
        case  GET_USER_REVIEWS:
            return{
                ...state,
                getUserReviews:action.payload,
                loading:false,
            }
        case MAKE_RESERVATION:
            return{
                makeReservation:action.payload,
                loading:false,
            }
        case GET_ALL_RESERVATIONS:
            return{
                ...state,
                allReservations:action.payload,
                loading:false,
            }
        case CANCEL_RESERVATION:
            return{
                cancelReservation:action.payload,
                loading:false,
            }
        case ADD_WEBSITE_REVIEW:
            return{
                addWebsiteReview:action.payload,
                loading:false,
            }
        case GET_WEBSITE_REVIEWS:
            return{
                ...state,
                allWebsiteReviews:action.payload,
                websiteReviewLoading:false,
            }
        case SUBSCRIBE:
            return{
                ...state,
                subscribe:action.payload,
                loading:false,
            }
        case GET_ERROR:
            return{
                cancelReservation:action.payload,
                allWishlist:action.payload,
                notifications:action.payload,
                allReservations:action.payload,
                getUserReviews:action.payload,
                allWebsiteReviews:action.payload,
                addWebsiteReview:action.payload,
                loading:true,
            }
        default:
            return state;
    }
}

export default UserReducer