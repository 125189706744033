import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import Notify from '../../../CustomHooks/Notify/Notify'
import {useNavigate } from 'react-router-dom'
import { makeCarReservation } from '../../../Redux/Actions/CarsActions'
import licence_img from '../../../imgs/public/licence_img.jpg'
const  CarReserveHook = (id) => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const response = useSelector((state)=>state.CarsReducer.carReservation)
    /*user from localstorage*/
    let user=[];
    if(localStorage.getItem("NozoulnaUser")!== null){
      user = JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{
      user=[]
    }
    //all of usestates
    const [address,set_address]=useState("")
    const [pick_up_date,set_pick_up_date] = useState("")
    const [drop_off_date,set_drop_off_date] = useState("")
    const [payment_type,setPayment_type] = useState("cash")
    const [partner,setPartner] = useState(user.first_name||"")
    const [partnerTwo,setPartnerTwo] = useState("")
    const [partnerThree,setPartnerThree] = useState("")
    const [phone,setPhone] = useState(user.phone||"")
    const [reserveLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
    const [licence_photo,set_licence_photo]= useState("")
    const [image,set_image]=useState(licence_img)
    const [delivery,set_delivery]=useState("")
    const [lat,set_lat]=useState(localStorage.getItem("lat"))
    const [long,set_long]=useState(localStorage.getItem("long"))

    /* on change state functions*/
    const onChange_pick_up_date=(e)=>{
        e.preventDefault()
        set_pick_up_date(e.target.value)
        localStorage.setItem("pick_up_date",e.target.value)
    }
    const onChange_drop_off_date=(e)=>{
        e.preventDefault()
        set_drop_off_date(e.target.value)
        localStorage.setItem("drop_off_date",e.target.value)
    }
    const onChangePaymentType=(e)=>{
        e.preventDefault()
        setPayment_type(e.target.value)
    }
    const onChange_address=(e)=>{
        e.preventDefault()
        set_address(e.target.value)
        localStorage.setItem("address",e.target.value)
    }
    const onChange_delivery=(e)=>{
        e.preventDefault()
        set_delivery(e.target.value)
        localStorage.setItem("delivery",e.target.value)
    }
    // on change licence photo
    const onChange_licence_photo=(event)=>{
        event.preventDefault()
        if(event.target.files && event.target.files[0]){
            set_image(URL.createObjectURL(event.target.files[0]))
            set_licence_photo(event.target.files[0])
          }
    }
    const onChangePartner=(e)=>{
        e.preventDefault()
        setPartner(e.target.value)
    }
    const onChangePartnerTwo=(e)=>{
        e.preventDefault()
        setPartnerTwo(e.target.value)
        localStorage.setItem("partner_two",e.target.value)
    }
    const onChangePartnerThree=(e)=>{
        e.preventDefault()
        setPartnerThree(e.target.value)
        localStorage.setItem("partner_Three",e.target.value)
    }
    const onChangePhone=(e)=>{
        e.preventDefault()
        setPhone(e.target.value)
    }
    /*start on click book now*/
    const onClickBookNow =(id)=>{
        if(localStorage.getItem("NozoulnaToken")!==null){
            if(pick_up_date===""||drop_off_date===""||address===""||delivery===""){
                Notify("please complete the data","warn")
            }else if(lat==null || long==null){
                Notify("please select your delivery address on the map")
            }
            else{
                Navigate(`/car-booking-page/:${id}`)
            }
        }else{
            Notify("please login first","warn")
        }
    }
    /*End on click book now*/
    /*submit function*/
    const onConfirmReserve =async(e)=>{
        e.preventDefault()
        /*validation function*/
        if(!localStorage.getItem("pick_up_date")||!localStorage.getItem("drop_off_date")||phone===""){
            Notify("please complete the data","warn")
        }else if(payment_type===""){
            Notify("please select a payment method","warn")
        }else if(licence_photo===""){
            Notify("please select a licence photo","warn")
        }
        else{
        const formData = new FormData()
        formData.append("service_type","cars")
        formData.append("service_id",id)
        formData.append("start_date",localStorage.getItem("pick_up_date"))
        formData.append("end_date",localStorage.getItem("drop_off_date"))
        formData.append("payment_type",payment_type)
        formData.append("partner[0]",partner)
        formData.append("partner[1]",localStorage.getItem("partner_two"))
        formData.append("partner[2]",localStorage.getItem("partner_Three"))
        formData.append("phone",phone)
        formData.append("is_delivery",localStorage.getItem("delivery"))
        formData.append("driver_licence",licence_photo)
        formData.append("address",localStorage.getItem("address"))
        formData.append("lat",lat)
        formData.append("long",long)
        setLoading(true)
        setIsPress(true)
        await dispatch(makeCarReservation(formData))
        setLoading(false)
        setIsPress(false)
        }
    }
    //useEffect to handle errors and reset the states and show notify
    useEffect(()=>{
        if(reserveLoading===false){
            if(response){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                    setTimeout(() => {
                        Navigate("/car-payment-received")
                    }, 1000);
                }else if(response.status ===422){
                    if(response.data.errors!==null){
                        if(response.data.errors.start_date){
                            Notify(`${response.data.errors.start_date[0]}`,"error")
                        }else if(response.data.errors.end_date){
                            Notify(`${response.data.errors.end_date[0]}`,"error")
                        }
                    }else{
                        Notify(response.data.message,'error')
                    }
                }
                else{
                    Notify(response.data.message,'error')
                }
            }
        }
    },[reserveLoading])

    return [partner,onChangePartner,phone,onChangePhone,pick_up_date,onChange_pick_up_date,drop_off_date,
        onChange_drop_off_date,payment_type,onChangePaymentType,onConfirmReserve,isPress,partnerTwo,onChangePartnerTwo,
        partnerThree,onChangePartnerThree,onClickBookNow,licence_photo,onChange_licence_photo,delivery,onChange_delivery,
        address,onChange_address,image,isPress,reserveLoading]
}

export default CarReserveHook