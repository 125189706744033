
const DateHook = () => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const options_2={
        day: 'numeric',
      }
      const options_3={
        weekday: 'long'
      }
    function getAllDate(date = new Date(), locale = 'en-US') {
        return date.toLocaleDateString(locale, options);
      }
    function getDayNum(date = new Date(), locale = 'en-US') {
        return date.toLocaleDateString(locale, options_2);
      }
    function getDayName(date = new Date(), locale = 'en-US') {
        return date.toLocaleDateString(locale, options_3);
      }
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
      
        const dates = [];
      
        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      
        return dates;
      }

    return[getAllDate,getDayNum,getDayName,getDatesInRange]
}

export default DateHook