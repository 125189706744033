import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SingleTourFeature } from "../../SingleTourFeature/SingleTourFeature";
import { useTranslation } from "react-i18next";

const FlightBaggage = () => {
  const[t,i18n]=useTranslation()
  return (
    <section className="flight-baggage text-align-start py-3 my-4">
      <h3 className="title font-size-20">{t("Flights.FlightBaggage.title")}</h3>
      <p className="color-text font-size-16">
      {t("Flights.FlightBaggage.description")}
      </p>
      <div className="fare-feature-item padding-top-30px pb-2">
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item1.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item2.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item3.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item4.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item5.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item6.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item7.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item8.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item9.title")} icon={faBriefcase} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <SingleTourFeature title={t("Flights.FlightBaggage.item10.title")} icon={faBriefcase} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlightBaggage;
