import React from 'react'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import MakeReservationHook from '../../../CustomHooks/UserHooks/MakeReservationHook';
import UpdateWishlistHook from '../../../CustomHooks/UserHooks/UpdateWishlistHook';
import { useTranslation } from 'react-i18next';

const BookingInfoSection = ({id}) => {
    const [,onAddToWishlist] = UpdateWishlistHook()
    const [,,,,,,start_date,onChangeStartDate,end_date,
      onChangeEndDate,,,quantity,,,,adults,partnerTwo,onChangePartnerTwo,partnerThree,onChangePartnerThree,
     ,,onClickBookNow,increasePartners,decreasePartners,increaseAdults,decreaseAdults]=MakeReservationHook(id)
      //start code for translate
      const [t,i18n] = useTranslation()
      //End code for translate
  return (
    <section className='booking-info-section'>
        <div className="contact-form-action">
                      <form action="#">
                        <div className="input-box">
                          <label className="label-text">
                            {t("hotelBookingInfo.check-in")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faCalendar}/>
                            <input
                            value={start_date}
                            onChange={onChangeStartDate}
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              placeholder={t("hotelBookingInfo.check-in")}
                              name="start-date"
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("hotelBookingInfo.check-out")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faCalendar}/>
                            <input
                            value={end_date}
                            onChange={onChangeEndDate}
                            onFocus={(e)=>e.target.type="date"}
                            onBlur={(e)=>e.target.type="text"}
                              className="date-range custom-input"
                              type="text"
                              name="end-date"
                              placeholder={t("hotelBookingInfo.check-out")}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("hotelBookingInfo.second-partner")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partnerTwo}
                            onChange={onChangePartnerTwo}
                              className="custom-input"
                              type="text"
                              name="partner-two"
                              placeholder={t("hotelBookingInfo.second-partner")}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">
                          {t("hotelBookingInfo.third-partner")}
                          </label>
                          <div className="form-group">
                          <FontAwesomeIcon icon={faUser}/>
                            <input
                            value={partnerThree}
                            onChange={onChangePartnerThree}
                              className="custom-input"
                              type="text"
                              name="partner-three"
                              placeholder={t("hotelBookingInfo.third-partner")}
                            />
                          </div>
                        </div>
                      </form>
        </div>
        <div className="sidebar-widget-item">
            <div className="count-box parnters-count d-flex align-items-center justify-content-between">
                          <div className='label'>
                                <p className='m-0'>{t("Flights.FlightBookingInfoSection.partner")}</p>
                                <span className='text-color font-size-12'>{t("Flights.FlightBookingInfoSection.age_18")}</span>
                          </div>
                          <div className='count-control'>
                                <button className='font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative'
                                onClick={decreasePartners}>-</button>
                                <span className='font-size-17 user-select-none font-weight-bold'>{quantity}</span>
                                <button className='font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative'
                                onClick={increasePartners}>+</button>
                          </div>
            </div>
            <div className="count-box adults-count d-flex align-items-center justify-content-between">
                          <div className='label'>
                                <p className='m-0'>{t("hotelBookingInfo.adults-count")}</p>
                                <span className='text-color font-size-12'>{t("hotelBookingInfo.age")}</span>
                          </div>
                          <div className='count-control'>
                                <button className='font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative'
                                onClick={decreaseAdults}>-</button>
                                <span className='font-size-17 user-select-none font-weight-bold'>{adults}</span>
                                <button className='font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative'
                                onClick={increaseAdults}>+</button>
                          </div>
            </div>
            </div>
            <div className="btn-box btn-box-1 pt-2" onClick={()=>onClickBookNow(id)}>
                <BtnComponent icon={<FontAwesomeIcon icon={faCartShopping} />}value={t("hotelBookingInfo.book-now")}/>
            </div>
            <div className="btn-box btn-box-2 pt-2" onClick={()=>onAddToWishlist(id,"hotels")}>
              <BtnComponent icon={<FontAwesomeIcon icon={faHeart} />} value={t("hotelBookingInfo.add-to-wishlist")}/>
            </div>
    </section>
  )
}

export default BookingInfoSection