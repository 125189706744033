import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import FilterBar from "../../../Components/GeneralSections/FilterBar/FilterBar";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
import AllServiceHook from "../../../CustomHooks/ServicesHooks/AllServiceHook";
import ApartmentsContainer from "../../../Components/Apartments/ApartmentsContainer/ApartmentsContainer";
import ServiceSearchResultLand from '../../../Components/ServiceComs/ServiceSearchResultLand/ServiceSearchResultLand'
const ApartmentsSearchResultPage = () => {
  const [,,,,countOfService] = AllServiceHook("apartments","8")
  return (
    <div className="service-search-result-page">
      <Header activeHome={"activeHome"} />
      <ServiceSearchResultLand bg={"apartments-search-result-land-bg"} service_type={"apartments"} category_id={"8"}/>
      <div className="container">
        <div className="row">
          <FilterBar service_type={"apartments"} category_id={"8"} serviceCount={countOfService} />
          <div className="result-content">
           <ApartmentsContainer />
          </div>
          <InfoSection />
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ApartmentsSearchResultPage;
