import React from 'react'
import './SupportPage.css'
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from '../../../Components/Sections/BreadCrumbSection/BreadCrumbSection'
const SupportPage = () => {
  return (
    <div className='support-page page'>
        <Header />
        <BreadCrumbSection serviceDetails={""}loading={false} bg={"bread-bg-8"} title={"Support"} />
        <div className='page'>

        </div>
        <Footer />
    </div>
  )
}

export default SupportPage