import React from 'react'
import SpinnerComponent from '../../../Components/Utility/SpinnerComponent/SpinnerComponent'
import WishlistHook from '../../../CustomHooks/UserHooks/WishlistHook'
import NotFoundData from '../../Utility/NotFoundData/NotFoundData'
import { Col, Row } from 'react-bootstrap'
import AllServiceHook from '../../../CustomHooks/ServicesHooks/AllServiceHook'
import ServiceCard from '../../ServiceComs/ServiceCard/ServiceCard'
const SuggestedHotels = () => {
  const [allService,loading,,] = AllServiceHook("hotels","1")
  const [,,wishlistItemsId] = WishlistHook()
  return (
    <div className='suggested-hotels row'>
        <div className='carousel-parent d-none d-xl-block'>
        <Row>
              {loading === false ?
              (
                allService.data?
                allService.data.map((hotel)=>{
                  return(
                    <Col key={hotel.id} xs="12" md="4" lg="3">
                      <ServiceCard service_type={"hotels"} favItem={wishlistItemsId} service={hotel}/>
                    </Col>
                  )
                }):<NotFoundData />
              )
              :<SpinnerComponent />
              }
            </Row>
        </div>
    </div>
  )
}

export default SuggestedHotels