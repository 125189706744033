import {useEffect,useState} from 'react'
import { useDispatch} from "react-redux";
import { GetSearchResultService } from '../../Redux/Actions/ServicesAction';

const FilterBarSearchHook = (service_type,category_id) => {
    const dispatch = useDispatch()
    const [stars,setStars] = useState("")
    const [minPrice,setMinPrice] = useState("0")
    const [maxPrice,setMaxPrice] = useState("1000")

    const onChangeStars =(e)=>{
        let value = e.target.value
        if(e.target.checked===true){
            setStars(value)
        }else if(e.target.checked===false){
            setStars("")
        }
    }
    useEffect(()=>{
        const getResults=async()=>{
            await dispatch(GetSearchResultService(service_type,category_id,`stars=${stars}`))
        }
        getResults()
    },[stars])

    const onChangeMinPrice=(e)=>{
        setMinPrice(e)
    }
    const onChangeMaxPrice=(e)=>{
        setMaxPrice(e)
    }
    useEffect(()=>{
    const getResults=async()=>{
        await dispatch(GetSearchResultService(service_type,category_id,`stars=${stars}&min_price=${minPrice}&max_price=${maxPrice}`))
        }
        getResults()
    },[minPrice,maxPrice])

    return [stars,onChangeStars,minPrice,maxPrice,onChangeMinPrice,onChangeMaxPrice]
}

export default FilterBarSearchHook