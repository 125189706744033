import {GetDataHook, GetDataHookToken} from "../../Hooks/GetDataHook";
import {PostDataHookToken } from "../../Hooks/PostDataHook";
import DeleteDataHook from '../../Hooks/DeleteDataHook'
import {
   UPDATE_PROFILE,
   CHANGE_PASSWORD,
   GET_NOTIFICATIONS,
   ADD_SUGGESTION,
   ADD_COMMENT,
   DELETE_COMMENT,
   POST_LIKE_UNLIKE,
   ADD_TO_WISHLIST,
   GET_ALL_WISHLIST,
   DELETE_FROM_WISHLIST,
   GET_USER_REVIEWS,
   GET_ALL_RESERVATIONS,MAKE_RESERVATION,CANCEL_RESERVATION,ADD_WEBSITE_REVIEW,GET_WEBSITE_REVIEWS,SUBSCRIBE
} from "../TypesOfActions";

export const updateProfile = (formData)=> async (dispatch) =>{
    try{
        const response = await PostDataHookToken("api/user/update-profile",formData)
        dispatch({
            type:UPDATE_PROFILE,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:UPDATE_PROFILE,
        payload:e.response,
        })
    }
}
export const changePassword = (formData)=> async (dispatch) =>{
    try{
            const response = await PostDataHookToken("api/rechangepass",formData)
        dispatch({
            type:CHANGE_PASSWORD,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:CHANGE_PASSWORD,
        payload:e.response,
        })
    }
}
export const getNotifications = ()=> async (dispatch) =>{
    try{
        const response = await GetDataHookToken("api/user/nots")
        dispatch({
            type:GET_NOTIFICATIONS,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:GET_NOTIFICATIONS,
        payload:e.response,
        })
    }
}
export const addSuggestion = (formData)=> async (dispatch) =>{
    try{
        const response = await PostDataHookToken("api/make-suggestion",formData)
        dispatch({
            type:ADD_SUGGESTION,
            payload:response,
            loading:true
        })
    }
    catch(e){
        dispatch({
        type:ADD_SUGGESTION,
        payload:e.response,
        })
    }
}
/* CRUD comments*/
export const addComment = (formData) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await PostDataHookToken("api/comment",formData)
      dispatch({
        type: ADD_COMMENT,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type: ADD_COMMENT,
        payload: e.response
      });
    } finally {
    }
  };
export const deleteComment = (id) => async (dispatch) => {
  try {
      const response = await GetDataHookToken(`api/delete-comment/${id}`)
      dispatch({
        type:DELETE_COMMENT,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:DELETE_COMMENT,
        payload: e.response
      });
    } finally {
    }
  };
/* like and unlik*/
export const postLikeUnlike = (formData) => async (dispatch) => {
    /*The statements to be executed*/
    try {
      const response = await PostDataHookToken("api/user/like_unlike",formData)
      dispatch({
        type:POST_LIKE_UNLIKE,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:POST_LIKE_UNLIKE,
        payload: e.response
      });
    } finally {
    }
};
/* the CRUD wishlist*/ 
export const addToWishlist = (id,formData) => async (dispatch) => {
    /*The statements to be executed.*/  
    try {
      const response = await PostDataHookToken(`api/save/${id}`,formData)
      dispatch({
        type:ADD_TO_WISHLIST,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:ADD_TO_WISHLIST,
        payload: e.response
      });
    } finally {
    }
};
export const getAllWishlist = () => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await GetDataHookToken("api/user/saved")
      dispatch({
        type:GET_ALL_WISHLIST,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:GET_ALL_WISHLIST,
        payload:e.response
      });
    } finally {
    }
};
export const deleteFromWishlist = (id,service_Type) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await GetDataHookToken(`api/unsave/${id}?service_type=${service_Type}`)
      dispatch({
        type:DELETE_FROM_WISHLIST,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:DELETE_FROM_WISHLIST,
        payload: e.response
      });
    } finally {
    }
};
/*user reviews*/
export const getUserReviews = () => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await GetDataHookToken("api/user/my_profile")
      dispatch({
        type:GET_USER_REVIEWS,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:GET_USER_REVIEWS,
        payload: e.response
      });
    } finally {
    }
};
/* Reservations*/
export const makeReservation = (formData) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await PostDataHookToken("api/reserve",formData)
      dispatch({
        type:MAKE_RESERVATION,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:MAKE_RESERVATION,
        payload: e.response
      });
    } finally {
    }
};
export const getAllReservations = () => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await GetDataHookToken("api/user/reservations")
      dispatch({
        type:GET_ALL_RESERVATIONS,
        payload: response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:GET_ALL_RESERVATIONS,
        payload: e.response
      });
    } finally {
    }
};
export const CancelReservation = (formData) => async (dispatch) => {
    /*The statements to be executed.*/
    try {
      const response = await PostDataHookToken("api/user/cancel_reservation",formData)
      dispatch({
        type:CANCEL_RESERVATION,
        payload:response,
        loading: true,
      });
    } catch (e) {
      dispatch({
        type:CANCEL_RESERVATION,
        payload:e.response
      });
    } finally {
    }
};
//website review action
export const addWebsiteReview = (formData) => async (dispatch) => {
  /*The statements to be executed.*/
  try {
    const response = await PostDataHookToken("api/website_review",formData)
    dispatch({
      type:ADD_WEBSITE_REVIEW,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type: ADD_WEBSITE_REVIEW,
      payload: e.response
    });
  } finally {
  }
};
export const getWebsiteReviews = () => async (dispatch) => {
  try {
    const response = await GetDataHook("api/website_reviews")
    dispatch({
      type:GET_WEBSITE_REVIEWS,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type:GET_WEBSITE_REVIEWS,
      payload:e.response
    });
  } finally {
  }
};
export const subscribe = (formData) => async (dispatch) => {
  try {
    const response = await PostDataHookToken("api/subscribe_email",formData)
    dispatch({
      type:SUBSCRIBE,
      payload: response,
      loading: true,
    });
  } catch (e) {
    dispatch({
      type:SUBSCRIBE,
      payload:e.response
    });
  } finally {
  }
};
