import { GET_ALL_QUESTIONS } from "../TypesOfActions";
import { GetDataHook } from "../../Hooks/GetDataHook";

export const getAllQuestions = ()=> async (dispatch)=>{
try{
    const response = await GetDataHook(`api/questions`)
    dispatch({
        type:GET_ALL_QUESTIONS,
        payload:response.data
    })
}
catch(e){
    dispatch({
        type:GET_ALL_QUESTIONS,
        payload:e.response
    })
}finally{}
}

