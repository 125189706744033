import React,{useState} from 'react'
import './CarInfo.css'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { SingleTourFeature } from '../../SingleTourFeature/SingleTourFeature'
import { faCar, faImages, faVideo, faXmark } from '@fortawesome/free-solid-svg-icons'
import Map from '../../Utility/Map/Map'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomCarousel from '../../Utility/CustomCarousel/CustomCarousel'
const CarInfo = ({car}) => {
    const [t,i18n]= useTranslation()
    const [showImages, setShowImages] = useState("showing-images");
    const [showVideo, setShowVideo] = useState("showing-video");
    const showImagesFn = () => {
      if (showImages === "showing-images") {
        setShowImages("showing-images show");
      } else {
        setShowImages("showing-images");
      }
    };
    const showVideoFn = () => {
      if (showVideo === "showing-video") {
        setShowVideo("showing-video show");
      } else {
        setShowVideo("showing-video");
      }
    };
  return (
    <section className='car-info'>
        <div className='car-location'>
            <Map service={car}/>
        </div>
        <div className='section-head'>
        <h3 className='title'>{
            i18n.language==="ar"?`${car.name_ar} ${car.type_ar}`:
            `${car.name_en} ${car.type_en}`
        }</h3>
        <p>{i18n.language==="ar"?car.town_ar:car.town_en}<span className='badge-2'>{car.stars}</span> ({car.num_reviews} {t("CarInfo.review")})</p>
        </div>
        <div className="service-media d-flex align-items-center">
          <div className="service-photos mx-2 my-2">
            <button
              className="btn"
              onClick={showImagesFn}
              data-src="images/img1.jpg"
              data-fancybox="gallery"
              data-caption="Showing image - 01"
              data-speed="700"
            >
              <FontAwesomeIcon icon={faImages} /> <span>{t("CarInfo.gallary")}</span>
            </button>
            <div className={showImages}>
              <div className="row h-100">
                <div className="col-sm-12 my-4">
                  <div className="controls">
                    <div
                      className="showing-images-btn-close"
                      onClick={showImagesFn}
                    >
                      <span className="custom-icon">
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 my-4">
                  <div className="carousel-parent">
                    <CustomCarousel show={1}>
                      {car && car.attachment_relation
                        ? car.attachment_relation.map(
                            (carImg, index) => {
                              return (
                                <img
                                  src={`${carImg.url}`}
                                  key={index}
                                  alt="car-img"
                                />
                              );
                            }
                          )
                        : null}
                    </CustomCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="service-video mx-2 my-2">
            <button
              className="btn"
              onClick={showVideoFn}
              data-fancybox="gallery"
              data-caption="Showing video - 01"
              data-speed="700"
            >
              <FontAwesomeIcon icon={faVideo} /> <span>{t("CarInfo.video")}</span> 
            </button>
            <div className={showVideo}>
              <div className="row h-100">
                <div className="col-sm-12 my-4">
                  <div className="controls">
                    <div
                      className="showing-video-btn-close"
                      onClick={showVideoFn}
                    >
                      <span className="custom-icon">
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 my-4">
                  {car ? (
                    <video width="320" height="240" controls>
                      <source
                        src={`https://multi.nozoluna.com/public/dash/assets/img/${car.video}`}
                        type="video/mp4"
                      />
                      <p>there is a problem</p>
                    </video>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row className='car-pickUp-dropOff-details'>
            <Col xs="12" sm="6">
            <ul>
                <li>
                    <h6>{t("CarInfo.pick_up_date")}</h6>
                    <p>{car.date_inn}</p>
                </li>
                <li>
                    <h6>{t("CarInfo.pick_up_time")}</h6>
                    <p>{car.time_inn}</p>
                </li>
                <li>
                    <h6>{t("CarInfo.location")}</h6>
                    <p>{car.address}</p>
                </li>
            </ul>
            </Col>
            <Col xs="12" sm="6">
            <ul>
                <li>
                    <h6>{t("CarInfo.drop_off_date")}</h6>
                    <p>{car.date_out}</p>
                </li>
                <li>
                    <h6>{t("CarInfo.drop_off_time")}</h6>
                    <p>{car.time_out}</p>
                </li>
                <li>
                    <h6>{t("CarInfo.location")}</h6>
                    <p>{car.address}</p>
                </li>
            </ul>
            </Col>
        </Row>
        <div className='car-categories'>
            <Row>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_Luxury==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.luxury")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_back_push==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.back_push")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_delivery==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.delivery")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_four_push==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.four_push")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_front_push==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.front_push")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_full_options==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.full_options")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_half_options==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.half_options")}/>
                    </Col>
                <Col xs="6" sm="4">
                       <SingleTourFeature icon={faCar} dis={car.is_sunroof==="1"?t("CarInfo.available"):t("CarInfo.not_available")} title={t("CarInfo.sunroof")}/>
                    </Col>
            </Row>
        </div>
        <div className='description'>
            <h4>{t("CarInfo.car_rental_information")}</h4>
            <p>{i18n.language==="ar"?car.description_ar:car.description_en
        }</p>
        </div>
    </section>
  )
}

export default CarInfo