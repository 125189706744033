import React from 'react'
import './BreadCrumbSection.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SpinnerTwo from '../../Utility/SpinnerComponent/SpinnerTwo'
const BreadCrumbSection = ({serviceDetails,loading,bg,title}) => {
    // start code to translate
   const [t,i18n]=useTranslation()
   // end code to translate
  return (
    <section className='breadcrumb-section-wrapper'>
         <div className="breadcrumb-top-bar text-align-start">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-list breadcrumb-top-list"style={{textAlign:"start"}}>
                  <ul className="list-items text-capitalize bg-transparent p-0 font-weight-regular font-size-15">
                  <li>
                      <Link to="/">{i18n.language==="ar"?"الرئيسيه":"Home"}</Link>
                    </li>
                    <li>{title}</li>
                    <li>
                      {loading === false ? (
                        serviceDetails?
                        serviceDetails.name_en?
                       i18n.language==="ar"?serviceDetails.name_ar:serviceDetails.name_en
                       :
                       i18n.language==="ar"?serviceDetails.off_town_ar +" الى "+serviceDetails.inn_town_ar:
                       serviceDetails.off_town_en +" to "+serviceDetails.inn_town_en
                       :null
                      ) : (
                        <SpinnerTwo />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className={`breadcrumb-section ${bg}`}>
        <div className='overlay'></div>
    <div className="breadcrumb-wrap">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="breadcrumb-content">
                        <div className="section-heading">
                            <h2 className="sec__title text-white">{title}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="breadcrumb-list text-right">
                        <ul className="list-items internal-list">
                            <li><Link to="/">{i18n.language==="ar"?"الرئيسيه":"Home"}</Link></li>
                            <li>{title}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bread-svg-box">
        <svg className="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div>
        </section>
    </section>
  )
}

export default BreadCrumbSection