import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { addComment } from '../../Redux/Actions/userActions'
import Notify from '../Notify/Notify'
const AddCommentHook = (service_id,service_type) => {
    const dispatch = useDispatch()
    const response = useSelector((state)=>state.UserReducer.addComment)

    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [stars,setStars] = useState("")
    const [comment,setComment] = useState("")
    /*const [service_type,setService_type] = useState("")*/
    /* const [service_id,setService_id] = useState("")*/
    const [comLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    /* functions of change states*/
    const onChangeName = (e)=>{
        setName(e.target.value)
    }
    const onChangeEmail = (e)=>{
        setEmail(e.target.value)
    }
    const onChangeStars = (e)=>{
        setStars(e.target.value)
    }
    const onChangeComment = (e)=>{
        setComment(e.target.value)
    }
    /*submit functoin*/
    const onSubmit =async(e)=>{
        e.preventDefault()
       if(localStorage.getItem("NozoulnaToken")!==null){
        /* validation function*/
        if(name===""||email===""||comment===""||stars===""){
            Notify("please complete the data","warn")
            return;
        }else{
        const formData = new FormData()
        formData.append("name",name)
        formData.append("email",email)
        formData.append("stars",stars)
        formData.append("comment",comment)
        formData.append("service_type",service_type)
        formData.append("service_id",service_id)
        setLoading(true)
        setIsPress(true)
        await dispatch(addComment(formData))
        setLoading(false)
        setIsPress(false)
        }
       }else{
        Notify("Please Login first","warn")
       }
    }
    /*useEffect to handle errors and reset the states*/
    useEffect(()=>{
       try{
        if(comLoading===false){
            if(response.data){
                if(response.status===200){
                    Notify(`${response.data.message}`,"success")
                    setName("")
                    setEmail("")
                    setStars("")
                    setComment("")
                    window.location.reload()
                }else if(response.code===400){
                    Notify(`${response.data.message}`,"error")
                }else{
                    Notify(`${response.data.message}`,"error")
                }
            }
        }
       }catch(e){}
    },[comLoading])

    return [name,onChangeName,email,onChangeEmail,comment,onChangeComment,stars,onChangeStars,
            onSubmit,comLoading,isPress]
}

export default AddCommentHook