import { useEffect,useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { deleteComment, getUserReviews } from '../../Redux/Actions/userActions'
import Notify from '../Notify/Notify'
const GetUserReviewsHook = () => {
    const dispatch = useDispatch()
    const res = useSelector((state)=>state.UserReducer.getUserReviews)
    const loading = useSelector((state)=>state.UserReducer.loading)
    const deleteCommentRes = useSelector((state)=>state.UserReducer.deleteComment)
    const [loadingDelete,setLoadingDelete] = useState(true)
    
    useEffect(()=>{
        const getdata =async()=>{
            await dispatch(getUserReviews())
        }
        getdata()
    },[])

    let user=[];
    let reviews =[];

    if(localStorage.getItem("NozoulnaUser")!=null){
        user=JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{user=[]}
    
    try{
        if(res){
            reviews=res.data
        }else{
            reviews=[]
        }
    }catch(e){}


    const onDeleteComment = async(id)=>{
        setLoadingDelete(true)
        await dispatch(deleteComment(id))
        setLoadingDelete(false)
    }
    /*useEffect fro Delete comment */
    useEffect(()=>{
        if(loadingDelete===false){
            if(deleteCommentRes){
                if(deleteCommentRes.code===200){
                    Notify(deleteCommentRes.message,"success")
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }else{
                    Notify(deleteCommentRes.message,"error")
                }
            }
        }
    },[loadingDelete])
    return [reviews,loading,user,onDeleteComment]
}


export default GetUserReviewsHook