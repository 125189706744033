import React, {useState } from "react";
import "./TourDetailsPage.css";
import BreadCrumbSection from "../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import { Link } from "react-router-dom";
import LocationSection from "../../Components/Sections/LocationSection/LocationSection";
import AvailabilitySection from "../../Components/Sections/AvailabilitySection/AvailabilitySection";
import AmenitiesSection from "../../Components/Sections/AmenitiesSection/AmenitiesSection";
import FaqSection from "../../Components/Sections/FaqSection/FaqSection";
/*import HotelCard from "../../Components/HotelCard/HotelCard"; */
import InternalNavbar from "../../Components/GeneralSections/InternalNavbar/InternalNavbar";
import ReviewsSection from "../../Components/Sections/ReviewsSection/ReviewsSection";
import ReviewBoxSection from "../../Components/Sections/ReviewBoxSection/ReviewBoxSection";
import { Dropdown } from "react-bootstrap";
import BtnComponent from "../../Components/Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faDollar,
  faEye,
  faPencil,
  faPhone,
  faShare,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelope,
  faHeart,
  faUser,
} from "@fortawesome/free-regular-svg-icons";

import avatar from "../../imgs/team1.jpg";
import Header from "../../Components/GeneralSections/Header/Header";
import Footer from "../../Components/GeneralSections/Footer/Footer";
import SuggestedHotels from "../../Components/Hotels/SuggestedHotels/SuggestedHotels";

const TourDetailsPage = () => {
  const [dropdownV, setDropdownV] = useState("Rooms");
  return (
    <div className="tour-details-page">
        <Header activeHotels={"activeHotels"}/>
      <section className="breadcrumb-top-bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-list breadcrumb-top-list">
                <ul className="list-items bg-transparent radius-none p-0">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Hotels</li>
                  <li>Hilton Hotel and Resorts</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BreadCrumbSection title={"Tours Details"} bg={"bread-bg-4"}/>
      <InternalNavbar />
      <div className="single-content-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content-wrap">
                <LocationSection />
                <AvailabilitySection />
                <AmenitiesSection />
                <FaqSection />
                <ReviewsSection />
                <ReviewBoxSection />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar single-content-sidebar mb-0">
                <div className="sidebar-widget sidebar-widget-1 single-content-widget">
                  <div className="sidebar-widget-item">
                    <div className="sidebar-book-title-wrap mb-3">
                      <h3>Popular</h3>
                      <p>
                        <span className="text-form">From</span>
                        <span className="text-value ml-2 mr-1">$399.00</span>{" "}
                        <span className="before-price">$412.00</span>
                      </p>
                    </div>
                  </div>
                  <div className="sidebar-widget-item">
                    <div className="contact-form-action">
                      <form action="#">
                        <div className="input-box">
                          <label className="label-text">Check in - Check out</label>
                          <div className="form-group">
                            <span className="la la-calendar form-icon"></span>
                            <input
                              className="date-range custom-input"
                              type="text"
                              name="daterange"
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <label className="label-text">Rooms</label>
                          <div className="form-group">
                            <div className="select-contain w-auto">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  Rooms
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    id="1 Room"
                                    onClick={(e) => setDropdownV(e.target.id)}
                                  >
                                    1 Room
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="2 Room"
                                    onClick={(e) => setDropdownV(e.target.id)}
                                  >
                                    2 Room
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="3 Room"
                                    onClick={(e) => setDropdownV(e.target.id)}
                                  >
                                    3 Room
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    id="4 Room"
                                    onClick={(e) => setDropdownV(e.target.id)}
                                  >
                                    4 Room
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="sidebar-widget-item">
                    <div className="qty-box mb-2 d-flex align-items-center justify-content-between">
                      <label>
                        Adults <span>Age 18+</span>
                      </label>
                      <div className="qtyBtn d-flex align-items-center">
                        <div className="qtyDec">
                          <i className="la la-minus"></i>
                        </div>
                        <input
                          className="custom-input"
                          type="text"
                          name="qtyInput"
                          defaultValue="0"
                        />
                        <div className="qtyInc">
                          <i className="la la-plus"></i>
                        </div>
                      </div>
                    </div>
                    <div className="qty-box mb-2 d-flex align-items-center justify-content-between">
                      <label className="font-size-16">
                        Children <span>2-12 years old</span>
                      </label>
                      <div className="qtyBtn d-flex align-items-center">
                        <div className="qtyDec">
                          <i className="la la-minus"></i>
                        </div>
                        <input
                          className="custom-input"
                          type="text"
                          name="qtyInput"
                          defaultValue="0"
                        />
                        <div className="qtyInc">
                          <i className="la la-plus"></i>
                        </div>
                      </div>
                    </div>
                    <div className="qty-box mb-2 d-flex align-items-center justify-content-between">
                      <label className="font-size-16">
                        Infants <span>0-2 years old</span>
                      </label>
                      <div className="qtyBtn d-flex align-items-center">
                        <div className="qtyDec">
                          <i className="la la-minus"></i>
                        </div>
                        <input
                          className="custom-input"
                          type="text"
                          name="qtyInput"
                          defaultValue="0"
                        />
                        <div className="qtyInc">
                          <i className="la la-plus"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-box btn-box-1 pt-2">
                    <Link to={"/tour-booking-page"}>
                    <BtnComponent
                      icon={<FontAwesomeIcon icon={faCartShopping} />}
                      value={"Book Now"}
                    />
                    </Link>
                  </div>
                  <div className="btn-box btn-box-2 pt-2">
                    <BtnComponent
                      icon={<FontAwesomeIcon icon={faHeart} />}
                      value={"Add To WishList"}
                    />
                  </div>
                  <div className="share-box d-flex align-items-center justify-content-between pt-2">
                    <a
                      href="#"
                      className="btn theme-btn-hover-gray font-size-15"
                      data-toggle="modal"
                      data-target="#sharePopupForm"
                    >
                      <FontAwesomeIcon icon={faShare} />
                      Share
                    </a>
                    <p>
                      <FontAwesomeIcon icon={faEye} />
                      3456
                    </p>
                  </div>
                </div>
                <div className="sidebar-widget sidebar-widget-2 single-content-widget">
                  <h3 className="title stroke-shape">Enquiry Form</h3>
                  <div className="enquiry-forum">
                    <div className="form-box">
                      <div className="form-content">
                        <div className="contact-form-action">
                          <form method="post">
                            <div className="input-box">
                              <label className="label-text">Your Name</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                  className="custom-input"
                                  type="text"
                                  name="text"
                                  placeholder="Your name"
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">Your Email</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <input
                                  className="custom-input"
                                  type="email"
                                  name="email"
                                  placeholder="Email address"
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">Message</label>
                              <div className="form-group">
                                <FontAwesomeIcon icon={faPencil} />
                                <textarea
                                  className="message-control custom-input"
                                  name="message"
                                  placeholder="Write message"
                                ></textarea>
                              </div>
                            </div>
                            <div className="input-box">
                              <div className="form-group">
                                <div className="custom-checkbox-wrap mb-0">
                                  <label className="custom-checkbox">
                                    I agree with{" "}
                                    <a href="#">Terms of Service</a> and
                                    <a href="#">Privacy Statement</a>
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="btn-box">
                              <BtnComponent value={"Submit Enquiry"} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget sidebar-widget-3 single-content-widget">
                  <h3 className="title stroke-shape">Why Book With Us?</h3>
                  <div className="sidebar-list">
                    <ul className="list-items">
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>No-hassle best price guarantee</span>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>Customer care available 24/7</span>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>Hand-picked Tours & Activities</span>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faDollar} />
                        </span>
                        <span>Free Travel Insureance</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget sidebar-widget-4 single-content-widget">
                  <h3 className="title stroke-shape">Get a Question?</h3>
                  <p className="font-size-14 line-height-24">
                    Do not hesitate to give us a call. We are an expert team and
                    we are happy to talk to you.
                  </p>
                  <div className="sidebar-list pt-3">
                    <ul className="list-items">
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>{" "}
                        <a href="#">+ 61 23 8093 3400</a>
                      </li>
                      <li>
                        <span className="custom-icon">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>{" "}
                        <a href="mailto:info@nozoulna.com">info@nozoulna.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget sidebar-widget-5 single-content-widget">
                  <h3 className="title stroke-shape">Organized by</h3>
                  <div className="author-content">
                      <div className="author-img">
                        <a href="#">
                          <img src={avatar} alt="testimonial image" />
                        </a>
                      </div>
                      <div className="author-bio">
                        <h4 className="author__title">
                          <a href="#">royaltravelagency</a>
                        </h4>
                        <span className="author__meta">Member Since 2017</span>
                        <span className="ratings d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                        </span>
                        <span className="ml-2">305 Reviews</span>
                        <div className="btn-box pt-3">
                         <BtnComponent value={"Ask a Question"}/>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="related-tour-area my-5">
        <div className="container">
          <div className="section-heading text-center">
            <h2 className="sec__title custom-title">You might also like</h2>
          </div>
          <div className="row">
            <SuggestedHotels />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TourDetailsPage;
