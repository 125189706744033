import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {addToWishlist,deleteFromWishlist,} from "../../Redux/Actions/userActions";
import Notify from "../Notify/Notify";

const UpdateWishlistHook = () => {
  const dispatch = useDispatch();
  /*Delete from wishlist selectors */
  const deleteFromWishlistRes = useSelector((state) => state.UserReducer.deleteFromWishlist);
  const [loadingDelete, setLoadingDelete] = useState(true);
  /*add to wishlist selectors*/
  const responseAddTo = useSelector((state) => state.UserReducer.addToWishlist);
  const [LoadingAdd, setLoadingAdd] = useState(true);

  /*========================================================================*/
  /*on add to wishlist functoin*/
  const onAddToWishlist = async (id,service_Type) => {
    if(localStorage.getItem("NozoulnaToken")!==null){
        const formData = new FormData();
        formData.append("service_type",service_Type);
        setLoadingAdd(true);
        await dispatch(addToWishlist(id,formData));
        setLoadingAdd(false);
    }else{
        Notify("please login","warn")
    }
  };
  useEffect(() => {
    if (LoadingAdd===false) {
        if (responseAddTo) {
          if (responseAddTo.status === 200) {
            Notify(`${responseAddTo.data.message}`, "success");
          }else if (responseAddTo.status === 401) {
            if (responseAddTo.data && responseAddTo.data.message === "Unauthenticated.") {
              Notify(`please login`, "warn");
            }
          }else{
            Notify(responseAddTo.data.message,"error")
          }
        }
    }
  },[LoadingAdd]);
  /*========================================================================*/
  /*on delete from wishlist */
  const onDeleteFromWishlist = async (id,service_Type) => {
    if(localStorage.getItem("NozoulnaToken")!==null){
        setLoadingDelete(true);
        await dispatch(deleteFromWishlist(id,service_Type));
        setLoadingDelete(false);
    }else{
        return
    }
  };
  useEffect(() => {
    if (loadingDelete === false) {
        if (deleteFromWishlistRes) {
          if (deleteFromWishlistRes.code === 200) {
              Notify(deleteFromWishlistRes.message, "warn");
            
          } else if (deleteFromWishlistRes.status === 401) {
            if (
                deleteFromWishlistRes.data &&
                deleteFromWishlistRes.data.message === "Unauthenticated."
            ) {
              Notify(`please login`, "warn");
            }
          } else {
            if (deleteFromWishlistRes.data) {
              Notify(deleteFromWishlistRes.data.message, "error");
            }
          }
        }
    }
  },[loadingDelete]);

  return [onDeleteFromWishlist,onAddToWishlist];
};

export default UpdateWishlistHook;
