import React from "react";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faHeart,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import UpdateWishlistHook from "../../../CustomHooks/UserHooks/UpdateWishlistHook";
import ResortReservHook from "../../../CustomHooks/UserHooks/Resorts/ResortReservHook";
import { useTranslation } from "react-i18next";
const ResortBookingInfoSection = ({ id }) => {
  const [, onAddToWishlist] = UpdateWishlistHook();
  const [,,,,,,start_date,onChangeStartDate,end_date,
    onChangeEndDate,,,quantity,decreasePartners,increasePartners,,,,
    ,,partnerTwo,onChangePartnerTwo,partnerThree,onChangePartnerThree,
   ,,onClickBookNow]= ResortReservHook(id);
  const [t, i18n] = useTranslation();
  return (
    <section className="booking-info-section">
      <div className="contact-form-action">
        <form action="#">
          <div className="input-box">
            <label className="label-text">
              {t("resortBookingInfo.check-in")}
            </label>
            <div className="form-group">
              <FontAwesomeIcon icon={faCalendar} />
              <input
                value={start_date}
                onChange={onChangeStartDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                className="date-range custom-input"
                type="text"
                name="start-date"
                placeholder={t("resortBookingInfo.check-in")}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">
              {t("resortBookingInfo.check-out")}
            </label>
            <div className="form-group">
              <FontAwesomeIcon icon={faCalendar} />
              <input
                value={end_date}
                onChange={onChangeEndDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                className="date-range custom-input"
                type="text"
                name="end-date"
                placeholder={t("resortBookingInfo.check-out")}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">
              {t("resortBookingInfo.second-partner")}
            </label>
            <div className="form-group">
              <FontAwesomeIcon icon={faUser} />
              <input
                value={partnerTwo}
                onChange={onChangePartnerTwo}
                className="custom-input"
                type="text"
                name="partner-two"
                placeholder={t("resortBookingInfo.second-partner")}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">
              {t("resortBookingInfo.third-partner")}
            </label>
            <div className="form-group">
              <FontAwesomeIcon icon={faUser} />
              <input
                value={partnerThree}
                onChange={onChangePartnerThree}
                className="custom-input"
                type="text"
                name="partner-three"
                placeholder={t("resortBookingInfo.third-partner")}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="sidebar-widget-item">
        <div className="count-box d-flex align-items-center justify-content-between">
          <div className="label">
            <p className="m-0">{t("resortBookingInfo.partners-count")}</p>
            <span className="text-color font-size-12">
              {t("resortBookingInfo.age")}
            </span>
          </div>
          <div className="count-control">
            <button
            className="font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative"
              onClick={decreasePartners}
            >-
            </button>
            <span className="font-size-17 user-select-none font-weight-bold">
              {quantity}
            </span>
            <button
              className="font-size-20 user-select-none mx-2 p-2 rounded-circle border-0 transition font-weight-bold position-relative"
              onClick={increasePartners}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div
        className="btn-box btn-box-1 pt-2"
        onClick={() => onClickBookNow(id)}
      >
        <BtnComponent
          icon={<FontAwesomeIcon icon={faCartShopping} />}
          value={t("resortBookingInfo.book-now")}
        />
      </div>
      <div
        className="btn-box btn-box-2 pt-2"
        onClick={() => onAddToWishlist(id, "hostels")}
      >
        <BtnComponent
          icon={<FontAwesomeIcon icon={faHeart} />}
          value={t("resortBookingInfo.add-to-wishlist")}
        />
      </div>
    </section>
  );
};

export default ResortBookingInfoSection;
