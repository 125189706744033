import React from "react";
import "./UserWishlistPage.css";
import UserProfileSidebar from "../../../Components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../Components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../Components/User/UserDashboardBread/UserDashboardBread";
import UserDashboardFooter from "../../../Components/User/UserDashboardFooter/UserDashboardFooter";
import WishlistHook from "../../../CustomHooks/UserHooks/WishlistHook";
import { ToastContainer } from "react-toastify";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import {Col } from "react-bootstrap";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import ServiceCard from '../../../Components/ServiceComs/ServiceCard/ServiceCard'
import { useTranslation } from "react-i18next";
const UserWishlistPage = () => {
  const [wishlist,wishlistLoading,wishlistItemsId] = WishlistHook();
  // start code for translate
  const [t,i18n]= useTranslation()
  // End code for translate
  let title=""
  try{
    if(i18n.language==="ar"){
        title="قائمة الامنيات"
    }else{
        title="My wishlist"
    }
  }catch(e){}
  return (
    <div className="user-wishlist-page page">
      <div className={i18n.language==="ar"?'user-sidebar-wrap-ar d-none d-lg-block':'user-sidebar-wrap d-none d-lg-block'}>
        <UserProfileSidebar wishlistPage={"wishlistPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav wishlistPageActive={"page-active"}/>
        <UserDashboardBread section_title={title} />
        <div className="dashboard-main-content">
          <div className="container-fluid">
            <div className="row row-content">
            <div className="col-lg-12">
                  <div className="form-box">
                    <div className="form-title-wrap">
                      <h3 className="title">{t("userWishlistPage.title")}</h3>
                      {wishlistLoading === false ? (
                        wishlist ? (
                          wishlist.data ? (
                            <p className="font-size-14">
                              {wishlist.data.length} {t("userWishlistPage.results")}
                            </p>
                          ) : (
                            <p>0</p>
                          )
                        ) : null
                      ) : (
                        <p>{t("userWishlistPage.waiting")}...</p>
                      )}
                    </div>
                    <div className="form-content pt-5 pb-0">
                      <div className="row">
                        {wishlistLoading === false ? (
                          wishlist ? (
                            wishlist.data ? (
                              wishlist.data.map((service) => (
                                <Col xs="12" md="6" lg="6" xl="4" key={service.id}>
                                    <ServiceCard service_type={service.type} favItem={wishlistItemsId} service={service.savedable}/>
                                </Col>
                              ))
                            ) : (
                              <NotFoundData />
                            )
                          ) : (
                            <SpinnerComponent />
                          )
                        ) : (
                          <SpinnerComponent />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="border-top mt-5"></div>
            <div className="row row-footer align-items-center">
              <UserDashboardFooter />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserWishlistPage;
