import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { makeReservation } from '../../../Redux/Actions/userActions'
import Notify from '../../Notify/Notify'
import { useNavigate } from 'react-router-dom'

const  ResortReservHook = (service_id) => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const response = useSelector((state)=>state.UserReducer.makeReservation)

    /*user from localstorage*/
    let user=[];
    if(localStorage.getItem("NozoulnaUser")!== null){
      user = JSON.parse(localStorage.getItem("NozoulnaUser"))
    }else{
      user=[]
    }
    /* all of usestates */
    const [quantity,setQuantity] = useState(0)
    const [start_date,setStart_date] = useState("")
    const [end_date,setEnd_date] = useState("")
    const [payment_type,setPayment_type] = useState("cash")
    const [room_id,setRoom_id] = useState(localStorage.getItem("resort_room_id"))
    const [room_number,setRoom_number] = useState("")
    const [adults,setAdults] = useState(1)
    const [partner,setPartner] = useState(user.first_name)
    const [partnerTwo,setPartnerTwo] = useState("")
    const [partnerThree,setPartnerThree] = useState("")

    const [phone,setPhone] = useState(user.phone)
    const [reserveLoading,setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)
      // partners quantity control
      const increasePartners =(e)=>{
        e.preventDefault()
        setQuantity(quantity + 1)
         localStorage.setItem("resort_partners_Qty",quantity + 1)
      }
      const decreasePartners =(e)=>{
        e.preventDefault()
        quantity===0?setQuantity(0):setQuantity(quantity - 1)
        localStorage.setItem("resort_partners_Qty",quantity - 1)
      }
    const onChangeStartDate=(e)=>{
        setStart_date(e.target.value)
        localStorage.setItem("start_date",e.target.value)
    }
    const onChangeEndDate=(e)=>{
        setEnd_date(e.target.value)
        localStorage.setItem("end_date",e.target.value)
    }
    const onChangePaymentType=(e)=>{
        setPayment_type(e.target.value)
    }
    const onChangeRoomId=(e)=>{
        if(e.target.checked === true){
            setRoom_id(e.target.value)
            localStorage.setItem("resort_room_id",e.target.value)
        }else {
            setRoom_id("")
            localStorage.removeItem("resort_room_id")
        }
    }
    const onChangeRoomNumber=(e)=>{
        setRoom_number(e.target.value)
        localStorage.setItem("room_number",e.target.value)
    }
    const onChangePartner=(e)=>{
        setPartner(e.target.value)
    }
    const onChangePartnerTwo=(e)=>{
        setPartnerTwo(e.target.value)
        localStorage.setItem("partner_two",e.target.value)
    }
    const onChangePartnerThree=(e)=>{
        setPartnerThree(e.target.value)
        localStorage.setItem("partner_Three",e.target.value)
    }
    const onChangePhone=(e)=>{
        setPhone(e.target.value)
    }
    const onChangeAdults=(e)=>{
        setAdults(e.target.value)
        localStorage.setItem("adults",e.target.value)
    }
    /*start on click book now*/
    const onClickBookNow =(id)=>{
        if(localStorage.getItem("NozoulnaToken")!==null){
            if(start_date===""||end_date===""){
                Notify("please complete the data","warn")
            }else if(!localStorage.getItem("resort_room_id")){
                Notify("please select a room","warn")
            }else if(quantity>1){
                if(partnerTwo===""){
                    Notify("please write the second partner name","warn")
                }else if(partnerThree===""){
                    Notify("please write the third partner name","warn")
                }else{
                    Navigate(`/resort-booking-page/:${id}`)
                }
            }else{
                Navigate(`/resort-booking-page/:${id}`)
            }
        }else{
            Notify("please login first","warn")
        }
    }
    /*End on click book now*/
    //submit function
    const onConfirmReserve =async(e)=>{
        e.preventDefault()
        /*validation function*/
        if(!localStorage.getItem("start_date")||!localStorage.getItem("end_date")||!localStorage.getItem("resort_room_id")){
            Notify("please complete the data","warn")
        }else if(payment_type===""){
            Notify("please select a payment method","warn")
        }
        else{
        const formData = new FormData()
        formData.append("service_type","hostels")
        formData.append("service_id",service_id)
        formData.append("quantity",localStorage.getItem("resort_partners_Qty"))
        formData.append("start_date",localStorage.getItem("start_date"))
        formData.append("end_date",localStorage.getItem("end_date"))
        formData.append("payment_type",payment_type)
        formData.append("room_id",localStorage.getItem("resort_room_id"))
        formData.append("partner[0]",partner)
        formData.append("partner[1]",partnerTwo)
        formData.append("partner[2]",partnerThree)
        formData.append("phone",phone)
        setLoading(true)
        setIsPress(true)
        await dispatch(makeReservation(formData))
        setLoading(false)
        setIsPress(false)
        }
    }

    /*useEffect to handle errors and reset the states and show notify*/
    useEffect(()=>{
        if(reserveLoading===false){
            if(response){
                if(response.status === 200){
                    Notify(`${response.data.message}`,"success")
                    setTimeout(() => {
                        Navigate("/payment-received")
                    }, 1000);
                }else if(response.status ===422){
                    if(response.data.errors!==null){
                        if(response.data.errors.room_id){
                            Notify(`${response.data.errors.room_id[0]}`,"error")
                        }else if(response.data.errors.start_date){
                            Notify(`${response.data.errors.start_date[0]}`,"error")
                        }else if(response.data.errors.end_date){
                            Notify(`${response.data.errors.end_date[0]}`,"error")
                        }
                    }else{
                        Notify(response.data.message,'error')
                    }
                }
                else{
                    Notify(response.data.message,'error')
                }
            }
        }
    },[reserveLoading])

    return [partner,onChangePartner,phone,onChangePhone,room_id,
        onChangeRoomId,start_date,onChangeStartDate,end_date,
        onChangeEndDate,payment_type,onChangePaymentType,
        quantity,decreasePartners,increasePartners,onConfirmReserve,isPress,reserveLoading,
        adults,onChangeAdults,partnerTwo,onChangePartnerTwo,
        partnerThree,onChangePartnerThree,
       room_number,onChangeRoomNumber,onClickBookNow]
}

export default ResortReservHook