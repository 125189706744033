import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import InfoSection from "../../../Components/Sections/InfoSection/InfoSection";
import {ToastContainer } from "react-toastify";
import CarsContainer from "../../../Components/Cars/CarsContainer/CarsContainer";
import CarsSearchPageLand from "../../../Components/Cars/CarsSearchPageLand/CarsSearchPageLand";
import CarsFilterBar from "../../../Components/Cars/CarsFilterBar/CarsFilterBar";
const CarsSearchResultPage = () => {
  return (
    <div className="service-search-result-page">
      <Header activeCars={"activeCars"} />
      <CarsSearchPageLand />
      <div className="container">
        <div className="row">
          <CarsFilterBar/>
          <div className="result-content">
           <CarsContainer />
          </div>
          <InfoSection />
          <div></div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default CarsSearchResultPage;
