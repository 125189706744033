import React from 'react'
import './Footer.css'
import logo from '../../../imgs/public/logo.png'
import BtnComponent from '../../Utility/BtnComponent/BtnComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SubscribeHook from '../../../CustomHooks/UserHooks/Subscribe/SubscribeHook'
import SpinnerComponent from '../../Utility/SpinnerComponent/SpinnerComponent'
const Footer = () => {
  const [email,onChangeEmail,onSubmit,loading,isPress]=SubscribeHook()
  // start code to translate
  const [t,i18n]=useTranslation()
  // end code to translate
  return (
    <section className="footer-section section">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="footer-item">
            <div className="footer-logo">
              <a href="index.html" className="foot__logo"><img className='logo' src={logo} alt="logo"  loading="lazy"/></a>
            </div>
            <p className="footer__desc">{t("footer.logo-col.description")}</p>
           
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-item">
            <h4
              className="title curve-shape margin-bottom-20px"
              data-text="curvs"
            >
              {t("footer.company-col.title")}
            </h4>
            <ul className="list-items list--items">
              <li>- <Link to={"/about-us-page"}>{t("footer.company-col.list.item1")}</Link></li>
              <li>- <Link to={"/support-page"}>{t("footer.company-col.list.item2")}</Link></li>
              {/* <li>- <Link to={"/about-us-page"}>{t("footer.company-col.list.item3")}</Link></li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-item">
            <h4
              className="title curve-shape  margin-bottom-20px"
              data-text="curvs"
            >
            {t("footer.our-informations-col.title")}
            </h4>
            <ul className="list-items pt-3">
              <li>- {t("footer.our-informations-col.list.item1")}</li>
              <li>- {t("footer.our-informations-col.list.item2")}</li>
              <li>- <a href="#">{t("footer.our-informations-col.list.item3")}</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-item">
            <h4
              className="title curve-shape  margin-bottom-20px"
              data-text="curvs"
            >
             {t("footer.subscribe-now-col.title")}
            </h4>
            <div className="contact-form-action">
              <form action="#">
                <div className="input-box">
                  <label className="label-text">{t("footer.subscribe-now-col.label")}</label>
                  <div className='d-flex align-items-center'>
                  <div className="form-group mb-0">
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <input
                      className="form-control cusotm-input"
                      type="email"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      placeholder={t("footer.subscribe-now-col.label")}
                    />
                  </div>
                  <div className='submit-btn' onClick={onSubmit}>
                    <BtnComponent value={t("footer.subscribe-now-col.btn")}/>
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center footer-down">
        <div className="col-lg-6">
          {/* <div className="copy-right padding-top-30px">
            <p className="copy__desc">
            {t("footer.copyRight.title")}
              <span className="la la-heart"></span>
              <a href="https://kw4s.com" target='_blank'
                >{t("footer.copyRight.link")}</a>
            </p>
          </div> */}
        </div>
        <div className="col-lg-6">
          <div className="footer-social-box text-right">
            <ul className="social-profile">
              <li>
                <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
              </li>
              <li>
                <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
              </li>
              <li>
                <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
              </li>
              <li>
                <a href="#"><FontAwesomeIcon icon={faLinkedinIn}/></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {isPress===true?loading===true?<SpinnerComponent/>:null:null}
  </section>
  )
}

export default Footer