import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetSearchResultService } from "../../Redux/Actions/ServicesAction";

const SideBarSearchHook = (service_type,category_id) => {
  const dispatch = useDispatch();
  const [town, setTown] = useState("");
  const [name, setName] = useState("");
  const [check_inn, setCheck_in] = useState("");
  const [check_out, setCheck_out] = useState("");
  const [rooms, setRooms] = useState("");
  const [stars, setStars] = useState("");
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("1000");

  const SearchResultsFn = async (queryString) => {
    await dispatch(GetSearchResultService(service_type,category_id,queryString));
  };
  useEffect(() => {
    SearchResultsFn(`town=${town}&stars=${stars}&min_price=${minPrice}&max_price=${maxPrice}
        &check_inn=${check_inn}&check_out=${check_out}&rooms=${rooms}&name=${name}`);
  },[town,stars,minPrice,maxPrice,check_inn,check_out,rooms,name]);
  
  /*stars*/
  const onChangeStars = (e) => {
    let value = e.target.value;
    if (e.target.checked === true) {
      setStars(value);
    } else if (e.target.checked === false) {
      setStars("");
    } else {
      setStars("");
    }
  };
  /*price*/
  const onChangeMinPrice = (e) => {
    setMinPrice(e);
  };
  const onChangeMaxPrice = (e) => {
    setMaxPrice(e);
  };
  /*town*/
  const onChangeTown = (e) => {
    setTown(e.target.value);
  };
  /*hotel name*/
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  /*check_inn*/
  const onChangeCheck_inn = (e) => {
    setCheck_in(e.target.value);
  };
  //check_out
  const onChangeCheck_out = (e) => {
    setCheck_out(e.target.value);
  };
  /* rooms*/
  const onChangeRooms = (e) => {
    setRooms(e.target.value);
  };

  return [stars,onChangeStars,minPrice,maxPrice,onChangeMinPrice,onChangeMaxPrice,town,onChangeTown,
    SearchResultsFn,check_out,onChangeCheck_out,check_inn,onChangeCheck_inn,onChangeRooms,rooms,name,onChangeName
  ];
};

export default SideBarSearchHook;
