import React from "react";
import Header from "../../../Components/GeneralSections/Header/Header";
import Footer from "../../../Components/GeneralSections/Footer/Footer";
import BreadCrumbSection from "../../../Components/Sections/BreadCrumbSection/BreadCrumbSection";
import { useParams } from "react-router-dom";
import AmenitiesSection from "../../../Components/Sections/AmenitiesSection/AmenitiesSection";
/*import FaqSection from "../../Components/Sections/FaqSection/FaqSection"; */
import InternalNavbar from "../../../Components/GeneralSections/InternalNavbar/InternalNavbar";
import ReviewsSection from "../../../Components/Sections/ReviewsSection/ReviewsSection";
import ReviewBoxSection from "../../../Components/Sections/ReviewBoxSection/ReviewBoxSection";
import SpinnerComponent from "../../../Components/Utility/SpinnerComponent/SpinnerComponent";
import ServiceDetailsHook from "../../../CustomHooks/ServicesHooks/ServiceDetailsHook";
import FaqSection from "../../../Components/Sections/FaqSection/FaqSection";
import NotFoundData from "../../../Components/Utility/NotFoundData/NotFoundData";
import { useTranslation } from "react-i18next";
import FlightDetailsSidebar from "../../../Components/Flights/FlightDetailsSidebar/FlightDetailsSidebar";
import FlightDetails from "../../../Components/Flights/FlightDetails/FlightDetails";
import FlightFareRules from "../../../Components/Flights/FlightFareRules/FlightFareRules";
import FlightBaggage from "../../../Components/Flights/FlightBaggage/FlightBaggage";
import WhyFlyWithUs from "../../../Components/Flights/WhyFlyWithUs/WhyFlyWithUs";
const FlightDetailsPage = () => {
  const param = useParams();
  const newParam = param.id.slice(1, param.id.length);
  const [serviceDetails, loading] = ServiceDetailsHook("plan-flights",newParam);
  // start code to translate
  const [t, i18n] = useTranslation();
  // end code to translate
  let title = "";
  try {
    if (i18n.language === "ar") {
      title = "تفاصيل رحلة الطيران";
    } else {
      title = "Flight details";
    }
  } catch (e) {}
  return (
    <div className="service-details-page">
      <Header activeFlights={"activeFlights"} />
      <BreadCrumbSection
        serviceDetails={serviceDetails}
        loading={loading}
        bg={"bread-bg-5"}
        title={title}
      />
      <InternalNavbar />
      <div className="single-content-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                serviceDetails ? (
                  <div className="content-wrap">
                    <FlightDetails serviceDetails={serviceDetails} />
                    <FlightFareRules />
                    <FlightBaggage />
                    <AmenitiesSection serviceDetails={serviceDetails} />
                    <FaqSection serviceDetails={serviceDetails} />
                    <ReviewsSection serviceDetails={serviceDetails} />
                    <ReviewBoxSection
                      service_id={newParam}
                      service_type={"plan-flights"}
                      serviceDetails={serviceDetails}
                    />
                  </div>
                ) : (
                  <NotFoundData />
                )
              ) : (
                <SpinnerComponent />
              )}
            </div>
            <div className="col-lg-4">
              <FlightDetailsSidebar
                serviceDetails={serviceDetails}
                loading={loading}
                service_id={newParam}
              />
            </div>
          </div>
        </div>
      </div>
      <WhyFlyWithUs />
      <Footer />
    </div>
  );
};

export default FlightDetailsPage;
