import React from 'react'
import './ForgetPasswordPage.css'
import Header from "../../Components/GeneralSections/Header/Header";
import Footer from "../../Components/GeneralSections/Footer/Footer";
import SpinnerComponent from '../../Components/Utility/SpinnerComponent/SpinnerComponent'
import BtnComponent from '../../Components/Utility/BtnComponent/BtnComponent'
import VerifyCodeHook from '../../CustomHooks/Auth/VerifyCodeHook'
import BreadCrumbSection from '../../Components/Sections/BreadCrumbSection/BreadCrumbSection'
import { useTranslation } from 'react-i18next'
const VerifyCodePage = () => {
    const [code,onChangeCode,onSubmit,loading,isPress] = VerifyCodeHook()
    //start code for translate
const [t,i18n]=useTranslation()
//End code for translate
let title="";
try{
  if(i18n.language=="ar"){
    title="تاكيد الكود"
  }else{
    title="Verify Code"
  }
}catch(e){}
    return (
      <>
      <Header/>
      <BreadCrumbSection serviceDetails={""} loading={false} bg={"bread-bg-8"} title={title} />
      <div className="page forget-password-page">
        <section className="contact-area">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto">
                <div className="form-box recover-box-parent">
                  <div className="form-title-wrap">
                    <h3 className="title">{t("verifyCodePage.title")}</h3>
                    <p className="font-size-15 pt-2">
                    {t("verifyCodePage.description")}
                    </p>
                  </div>
                  <div className="form-content ">
                    <div className="contact-form-action">
                      <form method="post">
                        <div className="input-box recover-box">
                          <label className="label-text">{t("verifyCodePage.code")}</label>
                          <div className="form-group">
                            <span className="la la-envelope-o form-icon"></span>
                            <input
                            value={code}
                            onChange={onChangeCode}
                              className="custom-input"
                              type="text"
                              name="code"
                              placeholder={t("verifyCodePage.code")}
                            />
                          </div>
                        </div>
                        <div className="btn-box" onClick={onSubmit}>
                          <BtnComponent value={t("verifyCodePage.send")}/>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isPress ===true? loading === true ? <SpinnerComponent /> : null : null}
      </div>
        <Footer />
    </>
    )
}

export default VerifyCodePage